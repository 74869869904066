import createReducer from "./createReducer";
import {    
    formActions,    
    SaveFormStart,
    SaveFormSuccess,
    SaveFormError,
    SAVE_FORM_START,
    SAVE_FORM_ERROR,
    SAVE_FORM_SUCCESS,
    LOAD_RESULTS_START,
    LOAD_RESULTS_ERROR,
    LOAD_RESULTS_SUCCESS,
    LoadFormSuccess,
    LOAD_SUBMIT_FORM_SUCCESS,
} from '../actions/formSubmit.action'

export interface FormDataState {
    state: string;
    error: string;
    data: any;
}

const initialState: FormDataState = {
    state: null,
    error: null,
    data: []
}

function saveFormStart(state:FormDataState, action: SaveFormStart): FormDataState {    
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function loadResultsSuccess(state:FormDataState, action: SaveFormStart): FormDataState {    
    console.log(state);
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function loadResetForm(state:FormDataState, action: LoadFormSuccess): FormDataState {    
    return {
        ...state,
        state: action.type,
        error: null,
    }
}


function saveFormSuccess(state:FormDataState, action: SaveFormSuccess): FormDataState {
    const elementsIndex = state.data.findIndex(gs => gs.id == action.data.id);
    const data = [...state.data];
    if (elementsIndex >= 0) {
        data[elementsIndex] = {        
            ...action.data
        }
    } else {
        data.push(action.data);
    }
   
    return {
        ...state,
        data: [
            ...data
        ],
        state: action.type,
        error: null,        
    }
}

function saveFormError(state:FormDataState, action: SaveFormError): FormDataState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const FormSubmitReducer =
    (state: FormDataState, action: formActions): FormDataState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_SUBMIT_FORM_SUCCESS]: loadResetForm,
            [SAVE_FORM_START] : saveFormStart,
            [SAVE_FORM_SUCCESS]: saveFormSuccess,
            [SAVE_FORM_ERROR]: saveFormError,
            [LOAD_RESULTS_SUCCESS]: loadResultsSuccess,
           
        });

        return fnUpdateState(state, action);
    }

export default FormSubmitReducer;