import { Alert, AlertColor,Snackbar  } from '@mui/material';
import React, {useState} from 'react';

interface AlertProps {
    msg: string;
    severity: string
}

export default function AlertItems(props:AlertProps) {
    const [open, setOpen] = useState(true);    

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    var alertColor:AlertColor;
    if (props.severity === "success" ||props.severity === "info" ||props.severity === "warning" ||props.severity === "error" ) {
        alertColor = props.severity;
    } 

    return(
        
            open && 
            <Alert onClose={handleClose} severity={alertColor} sx={{ width: '100%' }}>
                {props.msg}
            </Alert>
    )
}