//@ts-nocheck
import createReducer from "./createReducer";
import {
    LoadProjectCampaignAction,
    LoadProjectCampaignError,
    LoadProjectCampaignSuccess,
    SaveProjectAction,
    SaveProjectSuccessAction,
    SaveProjectError,
    UpdateProjectAction,
    UpdateProjectSuccessAction,
    UpdateProjectError,
    SaveCampaignAction,
    SaveCampaignSuccessAction,
    SaveCampaignError,
    UpdateCampaignAction,
    UpdateCampaignError,
    UpdateCampaignSuccessAction,
    ProjectCampaignActions,
    BusinessResponse,
    LOAD_PROJECTS_CAMPAIGNS_START,
    LOAD_PROJECTS_CAMPAIGNS_SUCCESS,
    LOAD_PROJECTS_CAMPAIGNS_ERROR,
    SAVE_PROJECTS_START,
    SAVE_PROJECTS_SUCCESS,
    SAVE_PROJECTS_ERROR,
    UPDATE_PROJECTS_START,
    UPDATE_PROJECTS_SUCCESS,
    UPDATE_PROJECTS_ERROR,
    SAVE_CAMPAIGNS_START,
    SAVE_CAMPAIGNS_SUCCESS,
    SAVE_CAMPAIGNS_ERROR,
    UPDATE_CAMPAIGNS_START,
    UPDATE_CAMPAIGNS_SUCCESS,
    UPDATE_CAMPAIGNS_ERROR,
    SHOW_MODAL_PROJECT_CLOSE,
    SHOW_MODAL_PROJECT_OPEN,
    SHOW_MODAL_CAMPAIGN_OPEN,
    SHOW_MODAL_CAMPAIGN_CLOSE
} from "../actions/administration.action";

export interface ProjectCampaignState {
    state: string;
    error: string;
    projectCampaigns: BusinessResponse;
    modalProjectState: boolean;
    modalCampaignState: boolean;
}

const initialState: ProjectCampaignState = {
    state: null,
    error: null,
    projectCampaigns: [],
    modalProjectState: false,
    modalCampaignState: false
}

function showProjectModalOpen(state: ProjectCampaignState, action: ShowModalAction): ProjectCampaignState {
    return {
        ...state,
        modalProjectState: true,
        modalCampaignState: false
    }
}

function showCampaignModalOpen(state: ProjectCampaignState, action: ShowModalAction): ProjectCampaignState {
    return {
        ...state,
        modalCampaignState: true,
        modalProjectState: false
    }
}
function showProjectModalClose(state: ProjectCampaignState, action: ShowModalAction): ProjectCampaignState {
    return {
        ...state,
        modalProjectState: false,
        error:''
    }
}

function showCampaignModalClose(state: ProjectCampaignState, action: ShowModalAction): ProjectCampaignState {
    return {
        ...state,
        modalCampaignState: false,
        error:''

    }
}

function loadProjectsCampaigns(state: ProjectCampaignState, action: LoadProjectCampaignAction): ProjectCampaignState {
    return {
        ...state,
        state: action.type,
        error: null,
        projectCampaigns: []
    }
}

function loadProjectsCampaignsSuccess(state: ProjectCampaignState, action: LoadProjectCampaignSuccess): ProjectCampaignState {
    return {
        ...state,
        state: action.type,
        error: null,
        projectCampaigns: action.projectCampaigns
    }
}

function loadProjectsCampaignsError(state: ProjectCampaignState, action: LoadProjectCampaignError): ProjectCampaignState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        projectCampaigns: []
    }
}

function saveProjectStart(state: ProjectCampaignState, action: SaveProjectAction): ProjectCampaignState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function saveProjectSuccess(state: ProjectCampaignState, action: SaveProjectSuccessAction): ProjectCampaignState {    
    return {
        ...state,
        projectCampaigns: {
            ...state.projectCampaigns,
            projects:[
                ...state.projectCampaigns.projects,
                {...action.project}
            ]
        },
        state: action.type,
        error: null,
        modalProjectState: false
    }
}

function saveProjectError(state: ProjectCampaignState, action: SaveProjectError): ProjectCampaignState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        modalProjectState: true
    }
}

function updateProjectStart(state: ProjectCampaignState, action: UpdateProjectAction): ProjectCampaignState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function updateProjectSuccess(state: ProjectCampaignState, action: UpdateProjectSuccessAction): ProjectCampaignState {
    const elementsIndex = state.projectCampaigns.projects.findIndex(project => project.projectId == action.project.id);
    const projectsArray = [...state.projectCampaigns.projects];
    console.log("admin reducer",action.project)
    projectsArray[elementsIndex] = {
        ...projectsArray[elementsIndex],
        ...action.project,
        hoursOfOperation: {
            endTime: projectsArray[elementsIndex].hoursOfOperation.endTime,
            startTime: projectsArray[elementsIndex].hoursOfOperation.startTime,
            timezone: action.project.timezone
        },
        minimumRatePerMinute: {
            value: projectsArray[elementsIndex].minimumRatePerMinute.value,
            currency: action.project.currency            
        }        
    }
    
    return {
        ...state,
        projectCampaigns: {
            ...state.projectCampaigns,
            projects:[
                ...projectsArray
            ]
        },
        state: action.type,
        error: null,
        modalProjectState: false
    }
}

function updateProjectError(state: ProjectCampaignState, action: UpdateProjectError): ProjectCampaignState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        modalProjectState: true
    }
}

function saveCampaignStart(state: ProjectCampaignState, action: SaveCampaignAction): ProjectCampaignState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function saveCampaignSuccess(state: ProjectCampaignState, action: SaveCampaignSuccessAction): ProjectCampaignState {    
    const elementsIndex = state.projectCampaigns.projects.findIndex(project => project.projectId == action.campaign.projectId);
    const projectsArray = [...state.projectCampaigns.projects];
    projectsArray[elementsIndex] = {
        ...projectsArray[elementsIndex],
        campaigns: [
            ...projectsArray[elementsIndex].campaigns,
            {...action.campaign}
        ]
    }
    return {
        ...state,
        projectCampaigns: {
            ...state.projectCampaigns,
            projects:[
                ...projectsArray
            ]            
        },
        state: action.type,
        error: null,
        modalCampaignState: false
    }
}

function saveCampaignError(state: ProjectCampaignState, action: SaveCampaignError): ProjectCampaignState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        modalCampaignState: true
    }
}

function updateCampaignStart(state: ProjectCampaignState, action: UpdateCampaignAction): ProjectCampaignState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function getExistingProjectIndex (projects, campaignId) {
    let value = [];
    for (let eachProject of projects) {
        value = eachProject.campaigns.find(campaign => campaign.campaignId == campaignId);
        if (value) break;
    }

    const elementsIndex = projects.findIndex(project => project.projectId == value.projectId);
    return elementsIndex;
}



function updateCampaignSuccess(state: ProjectCampaignState, action: UpdateCampaignSuccessAction): ProjectCampaignState {    
    const projectsArray = [...state.projectCampaigns.projects];
    let campaignsArray = [];
    const elementsIndex = state.projectCampaigns.projects.findIndex(project => project.projectId == action.campaign.projectId);    
    
    //Get the existig project ID.
    const existingProjectIndex = getExistingProjectIndex(state.projectCampaigns.projects, action.campaign.id);
    if (elementsIndex === existingProjectIndex) {
        campaignsArray = [...projectsArray[elementsIndex].campaigns];
        const matchingCampaignIndex = projectsArray[elementsIndex].campaigns.findIndex(campaign => campaign.campaignId == action.campaign.id);
        //Project not changed, just update campaign        
        campaignsArray[matchingCampaignIndex]={
            ...campaignsArray[matchingCampaignIndex],
            ...action.campaign
        }        
        projectsArray[elementsIndex] = {
            ...projectsArray[elementsIndex],
            campaigns: [
                ...campaignsArray
            ]
        }
    } else {
        //Project changed, delete from old project and add to new one.
        const matchingOldIndex = projectsArray[existingProjectIndex].campaigns.findIndex(campaign => campaign.campaignId == action.campaign.id);
        projectsArray[existingProjectIndex].campaigns.splice(matchingOldIndex);
        campaignsArray = [...projectsArray[elementsIndex].campaigns];
        projectsArray[elementsIndex] = {
            ...projectsArray[elementsIndex],
            campaigns: [
                ...campaignsArray,
                {
                    ...action.campaign,
                    campaignId: action.campaign.id,
                    baseLinePerMin: {
                        value:  action.campaign.minimumbaseLineValue
                    },
                    hoursOfOperation: {
                        timezone: action.campaign.timezone
                    }
                }                
            ]
        }
    }
    return {
        ...state,
        projectCampaigns: {
            ...state.projectCampaigns,
            projects:[
                ...projectsArray
            ]            
        },
        state: action.type,
        error: null,
        modalCampaignState: false
    }
}

function updateCampaignError(state: ProjectCampaignState, action: UpdateCampaignError): ProjectCampaignState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        modalCampaignState: true
    }
}

const AdministationReducer =
    (state: ProjectCampaignState, action: ProjectCampaignActions): ProjectCampaignState => {

        const fnUpdateState = createReducer(initialState, {
            [SHOW_MODAL_PROJECT_OPEN]: showProjectModalOpen,
            [SHOW_MODAL_CAMPAIGN_OPEN]: showCampaignModalOpen,
            [SHOW_MODAL_PROJECT_CLOSE]: showProjectModalClose,
            [SHOW_MODAL_CAMPAIGN_CLOSE]: showCampaignModalClose,
            [LOAD_PROJECTS_CAMPAIGNS_START]: loadProjectsCampaigns,
            [LOAD_PROJECTS_CAMPAIGNS_SUCCESS]: loadProjectsCampaignsSuccess,
            [LOAD_PROJECTS_CAMPAIGNS_ERROR]: loadProjectsCampaignsError,
            [SAVE_PROJECTS_START]: saveProjectStart,
            [SAVE_PROJECTS_SUCCESS]: saveProjectSuccess,
            [SAVE_PROJECTS_ERROR]: saveProjectError,
            [UPDATE_PROJECTS_START]: updateProjectStart,
            [UPDATE_PROJECTS_SUCCESS]: updateProjectSuccess,
            [UPDATE_PROJECTS_ERROR]: updateProjectError,
            [SAVE_CAMPAIGNS_START]: saveCampaignStart,
            [SAVE_CAMPAIGNS_SUCCESS]: saveCampaignSuccess,
            [SAVE_CAMPAIGNS_ERROR]: saveCampaignError,
            [UPDATE_CAMPAIGNS_START]: updateCampaignStart,
            [UPDATE_CAMPAIGNS_SUCCESS]: updateCampaignSuccess,
            [UPDATE_CAMPAIGNS_ERROR]: updateCampaignError
        });

        return fnUpdateState(state, action);
    }

export default AdministationReducer;

