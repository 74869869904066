import createReducer from "./createReducer";
import {
    ASSIGN_FORM_FLOW_START,
    ASSIGN_FORM_FLOW_SUCCESS,
    ASSIGN_FORM_FLOW_ERROR,
    formFlowActions,
    AssignFormFlowStartAction,
    AssignFormFlowSuccessAction,
    AssignFormFlowErrorAction,
    SaveFormStart,
    SaveFormSuccess,
    SaveFormError,
    SAVE_SELECTED_FORM_START,
    SAVE_SELECTED_FORM_ERROR,
    SAVE_SELECTED_FORM_SUCCESS
} from '../actions/formsWF.action'

export interface FormFlowState {
    state: string;
    error: string;
    data: any;
}

const initialState: FormFlowState = {
    state: null,
    error: null,
    data: []
}

function assignFormFlowStart(state:FormFlowState, action: AssignFormFlowStartAction): FormFlowState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function assignFormFlowSuccess(state:FormFlowState, action: AssignFormFlowSuccessAction): FormFlowState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function assignFormFlowError(state:FormFlowState, action: AssignFormFlowErrorAction): FormFlowState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function saveFormStart(state:FormFlowState, action: SaveFormStart): FormFlowState {    
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function saveFormSuccess(state:FormFlowState, action: SaveFormSuccess): FormFlowState {
    const elementsIndex = state.data.findIndex(gs => gs.id == action.data.id);
    const data = [...state.data];
    if (elementsIndex >= 0) {
        data[elementsIndex] = {        
            ...action.data
        }
    } else {
        data.push(action.data);
    }
    
    return {
        ...state,
        data: [
            ...data
        ],
        state: action.type,
        error: null,        
    }
}

function saveFormError(state:FormFlowState, action: SaveFormError): FormFlowState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const FormFlowReducer =
    (state: FormFlowState, action: formFlowActions): FormFlowState => {

        const fnUpdateState = createReducer(initialState, {
            [ASSIGN_FORM_FLOW_START]: assignFormFlowStart,
            [ASSIGN_FORM_FLOW_SUCCESS]: assignFormFlowSuccess,
            [ASSIGN_FORM_FLOW_ERROR]: assignFormFlowError,
            [SAVE_SELECTED_FORM_START] : saveFormStart,
            [SAVE_SELECTED_FORM_SUCCESS]: saveFormSuccess,
            [SAVE_SELECTED_FORM_ERROR]: saveFormError
        });

        return fnUpdateState(state, action);
    }

export default FormFlowReducer;