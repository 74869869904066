//@ts-nocheck
import Axios from "axios";
import moment from "moment";
import { AppSettings } from "../../appSettings";

const actionNameInitalPrefix = "CALL";
export const CALL_INCOMING = `${actionNameInitalPrefix}_INCOMING`;
export const CALL_ONCALL = `${actionNameInitalPrefix}_ONCALL`;
// export const CALL_STARTED_SUCCESS = `${actionNameLoadPrefix}_SUCCESS`;
// export const CALL_STARTED_ERROR = `${actionNameLoadPrefix}_ERROR`;


const actionNameLoadPrefix = "CALL_STARTED";
export const CALL_STARTED_START = `${actionNameLoadPrefix}_START`;
export const CALL_STARTED_SUCCESS = `${actionNameLoadPrefix}_SUCCESS`;
export const CALL_STARTED_ERROR = `${actionNameLoadPrefix}_ERROR`;

const actionNameLoadPrefix1 = "CALL_END";
export const CALL_END_START = `${actionNameLoadPrefix1}_START`;
export const CALL_END_SUCCESS = `${actionNameLoadPrefix1}_SUCCESS`;
export const CALL_END_ERROR = `${actionNameLoadPrefix1}_ERROR`;

const actionName = "WRAPUP";
export const WRAPUP_SUCCESS = `${actionName}_SUCCESS`;

const actionNameLoadPrefix2 = "CONF_CALL_STARTED";
export const CONF_CALL_STARTED_START = `${actionNameLoadPrefix2}_START`;
export const CONF_CALL_STARTED_END = `${actionNameLoadPrefix2}_END`;
export const CONF_CALL_STARTED_SUCCESS = `${actionNameLoadPrefix2}_SUCCESS`;
export const CONF_CALL_STARTED_ERROR = `${actionNameLoadPrefix2}_ERROR`;
export const CONF_CALL_NUMBER_UPDATE = `CONF_CALL_NUMBER_UPDATE`;

const actionNameLoadPrefix3 = "CONF_STATUS";
export const CONF_STATUS_START = `${actionNameLoadPrefix3}_START`;
export const CONF_STATUS_SUCCESS = `${actionNameLoadPrefix3}_SUCCESS`;
export const CONF_STATUS_ERROR = `${actionNameLoadPrefix3}_ERROR`;

const actionNameOutbound = "OUTBOUND_CALL";
export const OUTBOUND_CALL_START = `${actionNameOutbound}_START`;
export const OUTBOUND_CALL_SUCCESS = `${actionNameOutbound}_SUCCESS`;
export const OUTBOUND_CALL_ERROR = `${actionNameOutbound}_ERROR`;

export interface CallStartedAction {
    type: typeof CALL_STARTED_SUCCESS,
    data: string
}

export interface CallEndAction {
    type: typeof CALL_END_SUCCESS,
    data: string
}

export interface CompleteWrapUpAction {
    type: typeof WRAPUP_SUCCESS,
    data: string
}
export interface OutboundErrorAction {
    type: typeof OUTBOUND_CALL_ERROR,
    data: string
}
export type ActiveCallActions = CallStartedAction | CallEndAction | CompleteWrapUpAction | OutboundErrorAction;

export const acceptCall = () => (dispatch) => {
    dispatch({
        type: CALL_STARTED_SUCCESS,
        data: moment(Date.now()).utc().format()
    })
}

export const conferenceCall = (confNumbers) => (dispatch) => {
    dispatch({
        type: CONF_CALL_STARTED_START,
        data: confNumbers
    })
}
export const updateConfCallNumbers = (confNumbers) => (dispatch) => {
    dispatch({
        type: CONF_CALL_NUMBER_UPDATE,
        data: confNumbers
    })
}
export const endConferenceCall = (confNumbers) => (dispatch) => {
    dispatch({
        type: CONF_CALL_STARTED_END
    })
}

export const endCall = () => (dispatch) => {
    dispatch({
        type: CALL_END_SUCCESS,
        data: moment(Date.now()).utc().format()
    })
}

export const wrapupCall = () => (dispatch) => {
    dispatch({
        type: WRAPUP_SUCCESS,
        data: moment(Date.now()).utc().format()
    })
}

export const statusConference = () => (dispatch) => {
    dispatch({
        type: WRAPUP_SUCCESS,
        data: moment(Date.now()).utc().format()
    })
}

export const outboundCallHandler = (request) => async (dispatch) => {
    dispatch({
        type: OUTBOUND_CALL_START
    });
    try {
        const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/OutboundCall`, request);
        if (response) {
            dispatch({
                type: OUTBOUND_CALL_SUCCESS
            });     
        }
    } catch (e) {
        dispatch({
            type: OUTBOUND_CALL_ERROR,
            data: JSON.stringify(error)
        });     
    }
}