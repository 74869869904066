
import createReducer from "./createReducer";
import {
    LOAD_SCHEDULE_START,
    LOAD_SCHEDULE_SUCCESS,
    LOAD_SCHEDULE_ERROR
} from "../actions/projectAgentSchedule.action";

const initialState = {
    data: [],
    state: null,
    error: null
};

function loaded(state, action) {
    return {
        ...state,
        data: action.data,
        error: null,
        state: action.type
    }
}

function loadStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

function loadError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}

export default createReducer(initialState, {
    [LOAD_SCHEDULE_START]: loadStarted,
    [LOAD_SCHEDULE_SUCCESS]: loaded,
    [LOAD_SCHEDULE_ERROR]: loadError
}); 