//@ts-nocheck
import createReducer from "./createReducer";
import {
    UPLOAD_ACTUAL_INTAKE_START, UPLOAD_ACTUAL_INTAKE_SUCCESS, UPLOAD_ACTUAL_INTAKE_ERROR, CLEAN_UP_ACTUAL_INTAKE_FORECAST
} from "../actions/actualIntake.action";

const initialState = {
    data: {},
    state: null,
    error: null
};

function uploaded(state, action) {
    return {
        data: action.response,
        error: null,
        state: action.type
    }
}

function uploadStarted(state, action) {
   
    return {
        data: null,
        error: null,
        state: action.type
    }
}

function uploadError(state, action) {
    return {
        data: null,
        error: action.error,
        state: action.type
    }
}

function cleanUp(state, action) {
    return {
        ...state,
        data: null,
        error: null,
        state: null
    }
}


export default createReducer(initialState, {
    [UPLOAD_ACTUAL_INTAKE_START]: uploadStarted,
    [UPLOAD_ACTUAL_INTAKE_SUCCESS]: uploaded,
    [UPLOAD_ACTUAL_INTAKE_ERROR]: uploadError,
    [CLEAN_UP_ACTUAL_INTAKE_FORECAST]: cleanUp
}); 
