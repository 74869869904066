//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "LOAD_MY_PROJECTS";
export const LOAD_MY_PROJECTS_START = `${actionNamePrefix}_START`;
export const LOAD_MY_PROJECTS_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_MY_PROJECTS_ERROR = `${actionNamePrefix}_ERROR`;

function loadStart() {
    return {
        type: LOAD_MY_PROJECTS_START
    };
}

function loadSuccess(projects) {
    return {
        type: LOAD_MY_PROJECTS_SUCCESS,
        data: projects
    };
}

function loadError(error) {
    return {
        type: LOAD_MY_PROJECTS_ERROR,
        error
    }
}

export const getMyProjects = () => (dispatch) => {
    dispatch(loadStart);
    Axios.get(`${AppSettings.ApiBaseUrl}/Projects/AllProjects`)
        .then(response => {
            dispatch(loadSuccess(response.data));
        })
        .catch(error => {
            dispatch(loadError(error));
        });
}