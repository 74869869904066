/**WORKER ACTIONS */
export const WORKER_READY = "WORKER_READY";
export const WORKER_ERROR = "WORKER_ERROR";
export const WORKER_DISCONNECTED = "WORKER_DISCONNECTED";
export const WORKER_TOKEN_EXPIRED="WORKER_TOKEN_EXPIRED";
export const WORKER_TOKEN_UPDATE="WORKER_TOKEN_UPDATE";
export const WORKER_ACTIVITY_UPDATED="WORKER_ACTIVITY_UPDATED";
export const WORKER_ACTIVITY_MISMATCH="WORKER_ACTIVITY_MISMATCH";

/**DEVICE ACTIONS */
export const DEVICE_INIT_START = "DEVICE_INIT_START";
export const DEVICE_INIT_SUCCESS = "DEVICE_INIT_SUCCESS";
export const DEVICE_INIT_ERROR = "DEVICE_INIT_ERROR";
export const DEVICE_REGISTERD = "DEVICE_REGISTERD";
export const DEVICE_OFFLINE = "DEVICE_OFFLINE";
export const DEVICE_CONNECT = "DEVICE_CONNECT";
export const DEVICE_DISCONNECT = "DEVICE_DISCONNECT";
export const DEVICE_INCOMING = "DEVICE_INCOMING";
export const DEVICE_CALL_DISCONNECTED = "DEVICE_CALL_DISCONNECTED";
export const DEVICE_CALL_CONNECTED = "DEVICE_CALL_CONNECTED";
export const DEVICE_REJECT = "DEVICE_REJECT";
export const DEVICE_CANCEL = "DEVICE_CANCEL";
export const DEVICE_HANGUP = "DEVICE_HANGUP";
export const DEVICE_ERROR = "DEVICE_ERROR";

/* RESERVATION ACTIONS * */
export const FETCH_RESERVATIONS     ="FETCH_RESERVATIONS";
export const RESERVATION_CREATED    = "RESERVATION_CREATED";
export const RESERVATION_ACCEPTED   = "RESERVATION_ACCEPTED";
export const RESERVATION_COMPLETED  = "RESERVATION_COMPLETED";
export const RESERVATION_CANCELLED  ="RESERVATION_CANCELLED";
export const RESERVATION_WRAPUP     ="RESERVATION_WRAPUP";
export const RESERVATION_REJECTED   ="RESERVATION_REJECTED";
export const RESERVATION_TIMEOUT    ="RESERVATION_TIMEOUT";

/* CONVERSATION CLIENT */
export const CONVERSATION_CLIENT_SUCCESS  ="CONVERSATION_CLIENT_SUCCESS";
export const CONVERSATION_CLIENT_ERROR  ="CONVERSATION_CLIENT_ERROR";
export const CONVERSATION_CLIENT_UPDATE  ="CONVERSATION_CLIENT_UPDATE";
export const CONVERSATION_CLIENT_READY  ="CONVERSATION_CLIENT_READY";

/* CONVERSATION ACTIONS */
export const CONVERSATION_JOINED     ="CONVERSATION_JOINED";
export const CONVERSATION_LEFT       = "CONVERSATION_LEFT";

/* MESSAGE ACTIONS */
export const ACTION_ADD_MESSAGE     ="ACTION_ADD_MESSAGE";
export const ACTION_REMOVE_MESSAGE    = "ACTION_REMOVE_MESSAGE";
export const ACTION_UPDATE_MESSAGE   = "ACTION_UPDATE_MESSAGE";