import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import NatureIcon from "@mui/icons-material/Nature";
import EqualizerSharpIcon from "@mui/icons-material/EqualizerSharp";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
// import MessageIcon from "@mui/icons-material/Message";
// import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CancelScheduleSendSharpIcon from "@mui/icons-material/CancelScheduleSendSharp";
import EventBusySharpIcon from "@mui/icons-material/EventBusySharp";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import BlurLinearIcon from "@mui/icons-material/BlurLinear";
import { Assessment } from "@mui/icons-material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ListAltIcon from "@mui/icons-material/ListAlt";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PanToolIcon from "@mui/icons-material/PanTool";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TimelineIcon from "@mui/icons-material/Timeline";
import WorkIcon from "@mui/icons-material/Work";
import DetailsIcon from "@mui/icons-material/Details";
import NotificationIcon from "@mui/icons-material/Notifications";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

export const getRoutes = () => {
	const menuItems = [
		{
			path: "/dashboard",
			text: "Dashboard",
			componentName: "dashboard",
			icon: <DashboardIcon />,
			active: true,
			permission: "tasks",
		},
		{
			path: "/notifications",
			text: "Notifications",
			componentName: "notifications",
			icon: <NotificationIcon />,
			active: true,
			permission: "notifications",
		},
		{
			path: "/tasks",
			text: "Tasks",
			componentName: "dashboard",
			icon: <ListAltIcon />,
			active: true,
			permission: "tasks",
		},
		{
			path: "/agent-market-place",
			text: "Agent market place",
			componentName: "dashboard",
			icon: <ListAltIcon />,
			active: true,
			permission: "tasks",
		},
		{
			path: "/market-place",
			text: "Talent Acquistion",
			componentName: "dashboard",
			icon: <GroupAddIcon />,
			active: true,
			permission: "talentAcquistion",
		},
		{
			path: "#", ///market-place
			text: "ID Management",
			componentName: "dashboard",
			icon: <PanToolIcon />,
			active: false,
			permission: "idManagement",
		},
		{
			path: "#",
			text: "Training",
			componentName: "dashboard",
			icon: <KeyboardIcon />,
			active: false,
			permission: "training",
		},
		{
			path: "/my-work",
			text: "My Work",
			icon: <AccessibilityIcon />,
			active: true,
			permission: "myWork",
			routes: [
				{
					path: "/my-work",
					text: "Dashboard",
					icon: <DashboardIcon />,
					active: true,
					permission: "myWork",
				},
			],
		},
		{
			path: "/states",
			text: "States",
			icon: <DetailsIcon />,
			active: true,
			permission: "myWork",
			routes: [
				{
					path: "/states",
					text: "Dashboard",
					icon: <DashboardIcon />,
					active: true,
					permission: "myWork",
				},
				{
					path: "/camapign-entries",
					text: "Campaign Entries",
					//icon: <DashboardIcon />,
					active: true,
					permission: "myWork",
				}
			],
		},
		{
			path: "/shadow",
			text: "Worker Shadow",
			icon: <AccessibilityIcon />,
			active: true,
			permission: "shadow",
			routes: [
				{
					path: "/shadow",
					text: "Dashboard",
					icon: <DashboardIcon />,
					active: true,
					permission: "shadow",
				}
			],
		},
		{
			path: "/vendor-dashboard",
			text: "WorkForce Management",
			icon: <AccessibilityIcon />,
			active: true,
			permission: "wfm",
			routes: [
				{
					path: "/vendor-dashboard",
					text: "Dashboard",
					icon: <DashboardIcon />,
					active: true,
					permission: "wfm",
				},
				{
					path: "/vendor-planning",
					text: "Planning",
					icon: "",
					active: true,
					permission: "wfmPlanning",
					routes: [
						{
							path: "/wfm-planning",
							text: "WFM Planning",
							icon: <NatureIcon />,
							active: false,
							permission: "wfmPlanning",
						},
						{
							path: "/staff",
							text: "Staff",
							icon: <GroupWorkIcon />,
							active: true,
							permission: "staff",
							routes: [
								{
									path: "/staff",
									text: "Dashboard",
									icon: <DashboardIcon />,
									active: true,
									permission: "staff",
								},
								{
									path: "/staff-import-schedules",
									text: "Import Schedules",
									icon: <ScheduleIcon />,
									active: true,
									permission: "staffImportSchedules",
								},
								{
									path: "/agent-skill-matrix",
									text: "Roster",
									icon: <BlurLinearIcon />,
									active: true,
									permission: "staffRoster",
								},
								{
									path: "/agent-assignment-matrix",
									text: "Assignments",
									icon: <BlurLinearIcon />,
									active: true,
									permission: "staffAssignments",
								},
								{
									path: "/staff-attributes",
									text: "Attributes",
									icon: <NatureIcon />,
									active: true,
									permission: "staffAttributes",
								},
								{
									path: "/vendor-agent-schedules",
									text: "Agent Schedules",
									icon: <EventBusySharpIcon />,
									active: true,
									permission: "staffAgentSchedules",
								},
								{
									path: "/project-agent-schedule",
									text: "Project Agent Schedule",
									icon: <AssignmentRoundedIcon />,
									active: true,
									permission: "staffProjectSchedules",
								},
							],
						},
						{
							path: "/forecasting",
							text: "Forecasting",
							icon: <NatureIcon />,
							active: true,
							permission: "forecasting",
							routes: [
								{
									path: "/forecasting",
									text: "Dashboard",
									icon: <DashboardIcon />,
									active: true,
									permission: "forecasting",
								},
								{
									path: "/daily-forecast",
									text: "Daily Forecast",
									icon: <TimelineIcon />,
									active: true,
									permission: "forecastingDaily",
								},
							],
						},
						{
							path: "/staff-modelling",
							text: "Staff Modelling",
							icon: <CancelScheduleSendSharpIcon />,
							active: true,
							permission: "staffModelling",
							routes: [
								{
									path: "/staff-modelling",
									text: "Dashboard",
									icon: <DashboardIcon />,
									active: true,
									permission: "staffModelling",
								},
								{
									path: "/vendor-adjust-forecast",
									text: "Daily",
									icon: <EqualizerSharpIcon />,
									active: true,
									permission: "staffModellingDaily",
								},
								{
									path: "/vendor-weekly-forecast",
									text: "Weekly",
									icon: <DateRangeIcon />,
									active: true,
									permission: "staffModellingWeekly",
								},
								{
									path: "/cabca",
									text: "Training Models",
									icon: <CancelScheduleSendSharpIcon />,
									active: false,
									permission: "staffModellingTraining",
								},
								{
									path: "/actual-records",
									text: "Actual Uploads",
									icon: <CancelScheduleSendSharpIcon />,
									active: false,
									permission: "staffModellingActuals",
								},
							],
						},
						{
							path: "/project-forecast-report",
							text: "Reporting",
							icon: <AssignmentRoundedIcon />,
							active: true,
							permission: "wfmReporting",
							routes: [
								{
									path: "/project-forecast-report",
									text: "Dashboard",
									icon: <DashboardIcon />,
									active: true,
									permission: "wfmReporting",
								},
							],
						},
						{
							path: "/vendor-remove-agent-schedules",
							text: "Administration",
							icon: <CancelScheduleSendSharpIcon />,
							active: true,
							permission: "wfmAdministration",
							routes: [
								{
									path: "/vendor-remove-agent-schedules",
									text: "Dashboard",
									icon: <DashboardIcon />,
									active: true,
									permission: "wfmAdministration",
								},
								{
									path: "/vendor-conflict-manager",
									text: "Conflict Schedules Manager",
									icon: <CompareArrowsIcon />,
									active: true,
									permission: "wfmAdministrationConflict",
								},
								{
									path: "/upload-agent-users",
									text: "Import New Agents",
									icon: <PersonAddIcon />,
									active: true,
									permission: "wfmAdministrationNewAgents",
								},
								{
									path: "/user-management",
									text: "User Management",
									icon: <PersonAddIcon />,
									active: true,
									permission: "wfmAdministrationUserManagement",
								},
							],
						},
						{
							path: "/abcas",
							text: "Tasks",
							icon: <AssignmentRoundedIcon />,
							active: false,
							permission: "wfmtasks",
						},
						{
							path: "/abcaf",
							text: "Support",
							icon: <ContactSupportIcon />,
							active: false,
							permission: "support",
						},
					],
				},
				{
					path: "/vendor-performance",
					text: "Real-Time",
					icon: "",
					active: true,
					permission: "wfmRealtime",
					routes: [
						{
							path: "/vendor-performance",
							text: "Performance",
							icon: <TrendingUpIcon />,
							active: true,
							permission: "wfmRealTimePerformance",
						},
						{
							path: "#",
							text: "Reporting",
							icon: <Assessment />,
							active: false,
							permission: "wfmRealtimeReporting",
						},
						{
							path: "/agent-states",
							text: "Agent States",
							icon: <Assessment />,
							active: true,
							permission: "wfmRealtimeReporting",
						},
					],
				},
			],
		},
		{
			path: "#", ///contractor-work
			text: "Staff Engagement",
			componentName: "contractorWork",
			icon: <HeadsetMicIcon />,
			active: false,
			permission: "staffEngagement",
		},
		{
			path: "/contractor-performance",
			text: "Performance",
			componentName: "contractPerformace",
			icon: <TrendingUpIcon />,
			active: true,
			permission: "performance",
			routes:[
				{
					path: "/contractor-performance",
					text: "Dashboard",
					icon: <DashboardIcon />,
					active: true,
					permission: "performance",
				},
				{
					path: "/call-recordings",
					text: "Recordings",
					icon: <RecordVoiceOverIcon />,
					active: true,
					permission: "performance",
				}
			]
		},
		{
			path: "/report-builder",
			text: "Reporting",
			icon: <AssessmentIcon />,
			active: true,
			permission: "reporting",
			routes: [
				{
					path: "/report-builder",
					text: "Dashboard",
					icon: <DashboardIcon />,
					active: true,
					permission: "reporting",
				},
				{
					path: "/workforce",
					text: "Workforce",
					icon: <WorkIcon />,
					active: true,
					permission: "reporting",
				},
			],
		},
		{
			path: "#",
			text: "Finance",
			icon: <MonetizationOnIcon />,
			active: false,
			permission: "finance",
		},
		{
			path: "/administration",
			text: "Administration",
			icon: <CancelScheduleSendSharpIcon />,
			permission: "administration",
			routes: [
				{
					path: "/administration",
					text: "Dashboard",
					icon: <DashboardIcon />,
					active: true,
					permission: "administration",
				},
				{
					path: "/projects-campaigns",
					text: "Projects & Campaigns",
					icon: <CancelScheduleSendSharpIcon />,
					active: true,
					permission: "projectCampaings",
				},
				{
					path: "/slot-types",
					text: "Schedule Slot Types",
					icon: <CancelScheduleSendSharpIcon />,
					active: true,
					permission: "scheduleSlots",
				},
				{
					path: "/workflow",
					text: "Workflow Administration",
					icon: <CancelScheduleSendSharpIcon />,
					active: true,
					permission: "scheduleSlots",
				},
				{
					path: "/leave-config",
					text: "Leave Configuration",
					icon: <CancelScheduleSendSharpIcon />,
					active: true,
					permission: "scheduleSlots",
				},
				{
					path: "/targets",
					text: "Targets",
					icon: <CancelScheduleSendSharpIcon />,
					active: true,
					permission: "scheduleSlots",
				},
				{
					path: "/contact-center",
					text: "Contact Center",
					icon: <ContactSupportIcon />,
					active: true,
					permission: "contactCenter",
				},
				{
					path: "/data-collection",
					text: "Data Collection",
					icon: <ContactSupportIcon />,
					active: true,
					permission: "scheduleSlots",
				},
				// {
				// 	path: "/studioWorkflow",
				// 	text: "React WorkFlow",
				// 	icon: <ContactSupportIcon />,
				// 	active: true,
				// 	permission: "scheduleSlots",
				// },
			],
			active: true,
		},
	];

	return menuItems;
};
