//@ts-nocheck
import createReducer from "../createReducer";
import {
    LOAD_FEEDS_START,
    LOAD_FEEDS_SUCCESS,
    UPDATE_ALERT_START,
    UPDATE_ALERT_SUCCESS,
    UPDATE_ALERT_ERROR,
} from "../../actions/applicationFeeds.action";

const initialState = {
    data: [],
    state: null,
    updateState: null,
    updatedId:null
};

function loaded(state, action) {    
    return {
        ...state,
        data: action.data,
        state: action.type,
    }
}

function loadStarted(state, action) {
    return {
        ...state,
        data: [],
        state: action.type
    }
}

function updateStarted(state, action) {
    return {
        ...state,
        updateState: action.type
    }
}

function updateError(state, action) {
    return {
        ...state,
        updateState: action.type
    }
}

function updated(state, action) {
    const cloneOfData = Object.assign([], state.data);
    const alertUserIndex = cloneOfData.findIndex(eachAlert => eachAlert.alertId === action.updatedId);

    if (alertUserIndex !== -1) {        
        cloneOfData[alertUserIndex].reviewedOn = new Date();
        return {
            ...state,
            updateState: action.type
        };
    }

    return state;
}

export default createReducer(initialState, {
    [LOAD_FEEDS_START]: loadStarted,
    [LOAD_FEEDS_SUCCESS]: loaded,
    [UPDATE_ALERT_START]: updateStarted,
    [UPDATE_ALERT_SUCCESS]: updated,
    [UPDATE_ALERT_ERROR]: updateError

}); 