import createReducer from "./createReducer";
import {
    LOAD_DASHBOARD_START,
    LOAD_DASHBOARD_SUCCESS,
    LOAD_DASHBOARD_ERROR,
    UPDATE_DRAG_SUCCESS,
    ADD_DASHBOARD_SUCCESS,
    DELETE_DASHBOARD_SUCCESS,
    DELETE_DASHBOARD_ERROR,
    dashboardActions,
    AddDashboardSuccessAction,
    LoadDashboardStartAction,
    LoadDashboardSuccessAction,
    LoadDashboardErrorAction,
    SaveDashboardStart,
    SaveDashboardSuccess,
    SaveDashboardError,
    UpdateDashboardStart,
    UpdateDashboardSuccess,
    UpdateDashboardError,
    SAVE_SELECTED_DASHBOARD_START,
    SAVE_SELECTED_DASHBOARD_ERROR,
    SAVE_SELECTED_DASHBOARD_SUCCESS,
    UPDATE_DASHBOARDS_START,
    UPDATE_DASHBOARDS_SUCCESS,
    UPDATE_DASHBOARDS_ERROR,
    UPDATE_SINGLE_DASHBOARDS_START,
    UPDATE_SINGLE_DASHBOARDS_SUCCESS,
    UPDATE_SINGLE_DASHBOARDS_ERROR,
    UpdateSingleDashboardStart,
    UpdateSingleDashboardSuccess,
    UpdateSingleDashboardError,
    DeleteDashboardSuccessAction,
    DeleteDashboardErrorAction,
    UpdateDragSuccessAction,
    UPDATE_DASHBOARD_GRID_LAYOUT_SUCCESS,
    UpdateDashboardGridLayoutSuccessAction,
    UpdateDashboardWidgetParamsSuccessAction,
    UPDATE_DASHBOARD_WIDGET_PARAMS_SUCCESS,
    IMPORT_DASHBOARD_SUCCESS,
    ImportDashbaordConfigSuccess,
} from '../actions/dashboardConfig.action'
import { IDashboardTabConfig } from "../data/dashboardConfig.data";

export interface DashboardState {
    state: string;
    error: string;
    data: IDashboardTabConfig[];
}

const initialState: DashboardState = {
    state: null,
    error: null,
    data: []
}

function addDashboardSuccess(state: DashboardState, action: AddDashboardSuccessAction): DashboardState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: [
            ...state.data,
            action.data
        ]
    }
}

function deleteDashboardSuccess(state: DashboardState, action: DeleteDashboardSuccessAction): DashboardState {
    return {
        ...state,
        error: null,
        data: [...state.data].filter((item) => item.id !== action.data)
    }
}

function deleteDashboardError(state: DashboardState, action: DeleteDashboardErrorAction): DashboardState {
    return {
        ...state,
        error: action.error
    }
}


function loadDashboardStart(state: DashboardState, action: LoadDashboardStartAction): DashboardState {
    return {
        ...state,
        state: action.type,
        error: null,
    }
}

function loadDashboardSuccess(state: DashboardState, action: LoadDashboardSuccessAction): DashboardState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadDashboardError(state: DashboardState, action: LoadDashboardErrorAction): DashboardState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function importDashboardSuccess(state: DashboardState, action: ImportDashbaordConfigSuccess): DashboardState {    
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function saveDashboardStart(state: DashboardState, action: SaveDashboardStart): DashboardState {
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function saveDashboardSuccess(state: DashboardState, action: SaveDashboardSuccess): DashboardState {
    let data = [...state.data];

    return {
        ...state,
        data: [
            ...data
        ],
        state: action.type,
        error: null,
    }
}

function saveDashboardError(state: DashboardState, action: SaveDashboardError): DashboardState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}
function updateDashboardStart(state: DashboardState, action: UpdateDashboardStart): DashboardState {
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function updateDashboardSuccess(state: DashboardState, action: UpdateDashboardSuccess): DashboardState {
    const updatedConfig = [...state.data];
    const matchingIndex = updatedConfig.findIndex(x => x.id === action.data.id);
    updatedConfig[matchingIndex] = action.data;
    return {
        ...state,
        data: [
            ...updatedConfig
        ],
        state: action.type,
        error: null,
    }
}

function updateDashboardError(state: DashboardState, action: UpdateDashboardError): DashboardState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function updateSingleDashboardStart(state: DashboardState, action: UpdateSingleDashboardStart): DashboardState {
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function updateSingleDashboardSuccess(state: DashboardState, action: UpdateSingleDashboardSuccess): DashboardState {
    const elementKey = action.widgetKey;
    const params = action.widgetParams;
    const data = [...state.data];
    // const matchingElementIndex = data.findIndex(x => x.i === elementKey);
    // data[matchingElementIndex].selectedParams = params;
    return {
        ...state,
        data: [
            ...data
        ],
        state: action.type,
        error: null,
    }
}

function updateSingleDashboardError(state: DashboardState, action: UpdateSingleDashboardError): DashboardState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function updateDragSuccess(state: DashboardState, action: UpdateDragSuccessAction) {
    return {
        ...state,
        data: [...state.data].map((item) => item.id !== action.data.id ? item : ({ ...item, drag: action.data.val }))
    }
}

function updateDashboardGridLayoutSuccess(state: DashboardState, action: UpdateDashboardGridLayoutSuccessAction) {
    return {
        ...state,
        data: [...state.data].map((item) => item.id !== action.data.id ? item : ({
            ...item,
            gridLayoutConfig: { ...item.gridLayoutConfig, ...action.data.layout}
        }))
    }
}

function updateDashboardWidgetParamsSuccess(state: DashboardState, action: UpdateDashboardWidgetParamsSuccessAction) {
    const { dashboardId, widgetId, params, config = {} } = action.data;
    return {
        ...state,
        data: [...state.data].map((db) => db.id !== dashboardId ? db : ({
            ...db,
            widgetsData: [...(db.widgetsData || [])].find(wg => wg.id === widgetId) ? [...(db.widgetsData || [])].map((wg) => wg.id !== widgetId ? wg : ({
                ...wg,
                ...config,
                params: {
                    ...wg.params,
                    ...params
                }
            })) : [...(db.widgetsData || []), {
                id: widgetId,
                ...config,
                params
            }]
        }))
    }
}

const DashboardConfigReducer =
    (state: DashboardState, action: dashboardActions): DashboardState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_DASHBOARD_START]: loadDashboardStart,
            [LOAD_DASHBOARD_SUCCESS]: loadDashboardSuccess,
            [LOAD_DASHBOARD_ERROR]: loadDashboardError,
            [SAVE_SELECTED_DASHBOARD_START]: saveDashboardStart,
            [SAVE_SELECTED_DASHBOARD_SUCCESS]: saveDashboardSuccess,
            [SAVE_SELECTED_DASHBOARD_ERROR]: saveDashboardError,
            [UPDATE_DASHBOARDS_START]: updateDashboardStart,
            [UPDATE_DASHBOARDS_SUCCESS]: updateDashboardSuccess,
            [UPDATE_DASHBOARDS_ERROR]: updateDashboardError,
            [UPDATE_SINGLE_DASHBOARDS_START]: updateSingleDashboardStart,
            [UPDATE_SINGLE_DASHBOARDS_SUCCESS]: updateSingleDashboardSuccess,
            [UPDATE_SINGLE_DASHBOARDS_ERROR]: updateSingleDashboardError,
            [ADD_DASHBOARD_SUCCESS]: addDashboardSuccess,
            [DELETE_DASHBOARD_SUCCESS]: deleteDashboardSuccess,
            [DELETE_DASHBOARD_ERROR]: deleteDashboardError,
            [UPDATE_DRAG_SUCCESS]: updateDragSuccess,
            [UPDATE_DASHBOARD_GRID_LAYOUT_SUCCESS]: updateDashboardGridLayoutSuccess,
            [UPDATE_DASHBOARD_WIDGET_PARAMS_SUCCESS]: updateDashboardWidgetParamsSuccess,
            [IMPORT_DASHBOARD_SUCCESS]: importDashboardSuccess
        });

        return fnUpdateState(state, action);
    }

export default DashboardConfigReducer;