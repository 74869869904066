//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "LOAD_MY_SCHEDULE";
export const LOAD_MY_SCHEDULE_START = `${actionNamePrefix}_START`;
export const LOAD_MY_SCHEDULE_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_MY_SCHEDULE_ERROR = `${actionNamePrefix}_ERROR`;

function loadStart() {
    return {
        type: LOAD_MY_SCHEDULE_START
    };
}

function loadSuccess(schedule) {
    return {
        type: LOAD_MY_SCHEDULE_SUCCESS,
        data: schedule
    };
}

function loadError(error) {
    return {
        type: LOAD_MY_SCHEDULE_ERROR,
        error
    }
}

export const getMySchedule = (startDateStr, endDateStr) => (dispatch) => {
    dispatch(loadStart);
    Axios.get(`${AppSettings.ApiBaseUrl}/startDate/${startDateStr}/endDate/${endDateStr}/AgentSchedule`)
        .then(response => {
            dispatch(loadSuccess(response.data));
        })
        .catch(error => {
            dispatch(loadError(error));
        });
}

export const ADD_TO_MY_SCHEDULE_START = "ADD_TO_MY_SCHEDULE_START";
export const ADD_TO_MY_SCHEDULE_SUCCESS = "ADD_TO_MY_SCHEDULE_SUCCESS";
export const ADD_TO_MY_SCHEDULE_ERROR = "ADD_TO_MY_SCHEDULE_ERROR";

function addToMyScheduleStart(forecastId) {
    return {
        type: ADD_TO_MY_SCHEDULE_START,
        data: forecastId
    }
}

function addToMyScheduleSuccess(agentSchedule){
    return {
        type: ADD_TO_MY_SCHEDULE_SUCCESS,
        data: agentSchedule
    }
}

function addToMyScheduleError(forecastId, error) {
    return {
        type: ADD_TO_MY_SCHEDULE_ERROR,
        error
    }
}

export const addToMySchedule = (forecastId) => (dispatch) => {
    addToMyScheduleStart(forecastId);
    Axios.post(`${AppSettings.ApiBaseUrl}/AgentSchedule`, { forecastId })
        .then(response => {
            dispatch(addToMyScheduleSuccess(response.data));
        })
        .catch(error => {
            dispatch(addToMyScheduleError(forecastId, error));
        });
}
