import createReducer from "./createReducer";
import {
    LOAD_WIDGET_START,
    LOAD_WIDGET_SUCCESS,
    LOAD_WIDGET_ERROR,
    ADD_WIDGET_SUCCESS,
    widgetActions,
    AddWidgetSuccessAction,
    LoadWidgetStartAction,
    LoadWidgetSuccessAction,
    LoadWidgetErrorAction,
    SaveWidgetStart,
    SaveWidgetSuccess,
    SaveWidgetError,
    UpdateWidgetStart,
    UpdateWidgetSuccess,
    UpdateWidgetError,
    SAVE_SELECTED_WIDGET_START,
    SAVE_SELECTED_WIDGET_ERROR,
    SAVE_SELECTED_WIDGET_SUCCESS,
    UPDATE_WIDGETS_START,
    UPDATE_WIDGETS_SUCCESS,
    UPDATE_WIDGETS_ERROR,
    UPDATE_SINGLE_WIDGET_START,
    UPDATE_SINGLE_WIDGET_SUCCESS,
    UPDATE_SINGLE_WIDGET_ERROR,
    UpdateSingleWidgetStart,
    UpdateSingleWidgetSuccess,
    UpdateSingleWidgetError,
    REMOVE_WIDGET_START,
    REMOVE_WIDGET_SUCCESS,
    REMOVE_WIDGET_ERROR,
    RemoveWidgetStart,
    RemoveWidgetSuccess,
    RemoveWidgetError
} from '../actions/widgetConfig.action'
import { DashboardTypes } from "../data/dashboardConfig.data";

interface widgetConfig {
    id: string
	type: string
	layoutParams: {
        w: number,
        h: number
    },
	configParams: any
    dashboardType?: DashboardTypes
}


export interface WidgetState {
    state: string;
    error: string;
    data: Array<widgetConfig>;
    cols: any;
}

const initialState: WidgetState = {
    state: null,
    error: null,
    data: [],
    cols: 12,
}

function addWidgetSuccess(state:WidgetState, action: AddWidgetSuccessAction): WidgetState {    
    return {
        ...state,
        state: action.type,
        error: null,
        data: [            
            ...state.data,
            action.data
        ]
    }
}


function loadWidgetStart(state:WidgetState, action: LoadWidgetStartAction): WidgetState {
    return {
        ...state,
        state: action.type,
        error: null,
    }
}

function loadWidgetSuccess(state:WidgetState, action: LoadWidgetSuccessAction): WidgetState {
    const data = [...state.data];    
    return {
        ...state,
        state: action.type,
        error: null,
        data: [
            ...data
        ]
    }
}

function loadWidgetError(state:WidgetState, action: LoadWidgetErrorAction): WidgetState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function saveWidgetStart(state:WidgetState, action: SaveWidgetStart): WidgetState {    
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function saveWidgetSuccess(state:WidgetState, action: SaveWidgetSuccess): WidgetState {
    // let data = [...state.data];
    // const item = action.selectedParams;
    // const date =  Math.round(+new Date()/1000);
    // data = data.concat({
    //     id:item.attrName+item.id+(data.length -1)+date+'.'+item.type,
    //     type: item.type,
    //     layoutParams: {
    //         i: item.attrName+item.id+(data.length -1)+date+'.'+item.type,
    //         x: (data.length * 2) % (state.cols || 12),
    //         y: Infinity, // puts it at the bottom
    //         w: item.w,
    //         h: item.h,    
    //     },
    //     configParams:{}
    // })
    return {
        ...state,
        data: [
            ...state.data,
            {
                ...action.selectedParams,
                layoutParams: {
                    w: action.selectedParams.w,
                    h: action.selectedParams.h
                }
            }
        ],
        state: action.type,
        error: null,        
    }
}

function saveWidgetError(state:WidgetState, action: SaveWidgetError): WidgetState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}
function updateWidgetStart(state:WidgetState, action: UpdateWidgetStart): WidgetState {    
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function updateWidgetSuccess(state:WidgetState, action: UpdateWidgetSuccess): WidgetState { 
    const data = [...state.data];
    data.forEach(element => {
        const matchingElementIndex = action.widgets.findIndex(x => x.i === element.id);    
        element.layoutParams = action.widgets[matchingElementIndex];
    });
    
    return {
        ...state,
        data: [
            ...data
        ],
        state: action.type,
        cols: action.cols,
        error: null,        
    }
}

function updateWidgetError(state:WidgetState, action: UpdateWidgetError): WidgetState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function updateSingleWidgetStart(state:WidgetState, action: UpdateSingleWidgetStart): WidgetState {    
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function updateSingleWidgetSuccess(state:WidgetState, action: UpdateSingleWidgetSuccess): WidgetState {    
    const elementKey = action.widgetKey;
    const params = action.widgetParams;
    const data = [...state.data];
    const matchingElementIndex = data.findIndex(x => x.id === elementKey);
    data[matchingElementIndex].configParams = params;
    return {
        ...state,        
        data: [
            ...data
        ],
        state: action.type,        
        error: null,        
    }
}

function updateSingleWidgetError(state:WidgetState, action: UpdateSingleWidgetError): WidgetState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function removeWidgetStart(state:WidgetState, action: RemoveWidgetStart): WidgetState {    
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function removeWidgetSuccess(state:WidgetState, action: RemoveWidgetSuccess): WidgetState {        
    const data = [...state.data];
    const items = data.filter(x => x.id !== action.key);
    return {
        ...state,        
        data: [
            ...items
        ],
        state: action.type,        
        error: null,        
    }
}

function removeWidgetError(state:WidgetState, action: RemoveWidgetError): WidgetState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const WidgetConfigReducer =
    (state: WidgetState, action: widgetActions): WidgetState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_WIDGET_START]: loadWidgetStart,
            [LOAD_WIDGET_SUCCESS]: loadWidgetSuccess,
            [LOAD_WIDGET_ERROR]: loadWidgetError,
            [SAVE_SELECTED_WIDGET_START] : saveWidgetStart,
            [SAVE_SELECTED_WIDGET_SUCCESS]: saveWidgetSuccess,
            [SAVE_SELECTED_WIDGET_ERROR]: saveWidgetError,
            [UPDATE_WIDGETS_START] : updateWidgetStart,
            [UPDATE_WIDGETS_SUCCESS]: updateWidgetSuccess,
            [UPDATE_WIDGETS_ERROR]: updateWidgetError,
            [UPDATE_SINGLE_WIDGET_START] : updateSingleWidgetStart,
            [UPDATE_SINGLE_WIDGET_SUCCESS]: updateSingleWidgetSuccess,
            [UPDATE_SINGLE_WIDGET_ERROR]: updateSingleWidgetError,
            [REMOVE_WIDGET_START] : removeWidgetStart,
            [REMOVE_WIDGET_SUCCESS]: removeWidgetSuccess,
            [REMOVE_WIDGET_ERROR]: removeWidgetError,
            [ADD_WIDGET_SUCCESS]: addWidgetSuccess 
        });

        return fnUpdateState(state, action);
    }

export default WidgetConfigReducer;