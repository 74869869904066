import { getRoutes } from './getRoutes';

/**
 * Combine paths
 *
 * @param {string} parent
 * @param {string} child
 * @returns {string}
 */
export const combinePaths = (parent, child) =>
	`${parent.replace(/\/$/, "")}/${child.replace(/^\//, "")}`;

/**
 * Recursively build paths for each navigation item
 *
 * @param routes
 * @param {string} parentPath
 * @returns {*}
 */
export const buildPaths = (routes, parentPath = "") =>
	routes.map(route => {
		//const path = combinePaths(parentPath, route.path);
		const path = route.path
		return {
			...route,
			path,
			...(route.routes && { routes: buildPaths(route.routes, path) })
		};
	});

/**
 * Recursively provide parent reference for each navigation item
 *
 * @param routes
 * @param parentRoute
 * @returns {*}
 */
export const setupParents = (routes, parentRoute = null) =>
	routes.map(route => {
		const withParent = {
			...route,
			...(parentRoute && { parent: parentRoute })
		};

		return {
			...withParent,
			...(withParent.routes && {
				routes: setupParents(withParent.routes, withParent)
			})
		};
	});

/**
 * Convert navigation tree into flat array
 *
 * @param routes
 * @returns {any[]}
 */
export const flattenRoutes = routes =>
	routes
		.map(route => [route.routes ? flattenRoutes(route.routes) : [], route])
		.flat(Infinity);

/**
 * Combine all the above functions together
 *
 * @param routes
 * @returns {any[]}
 */
export const generateAppRoutes = routes => {
	return flattenRoutes(setupParents(buildPaths(routes)));
};

/**
 * Provides path from root to the element
 *
 * @param route
 * @returns {any[]}
 */
export const pathTo = route => {
	if (!route.parent) {
		return [route];
	}
	return [...pathTo(route.parent), route];
};

export const getParentPath = route => {
	const routesList = getRoutes();
	const appRoutes = generateAppRoutes(routesList);
	const currentPageRoutes = appRoutes.find(rL => rL.path.match(route));	
	if (currentPageRoutes) {
		const newPath = getParentObject(currentPageRoutes);
		return newPath;
	}	
}

export const getTheCurrentParent = route => {
	const routesList = getRoutes();
	const appRoutes = generateAppRoutes(routesList);
	const currentPageRoutes = appRoutes.find(rL => rL.path.match(route));
	const parentPath =  currentPageRoutes.parent;
	return parentPath;
}

function getParentObject(route) {
	let result = {};
	if (route.parent) {
		result = getParentObject(route.parent);
	} 	
	if (!route.parent){		
		result = route;
	}

	return result;
}
