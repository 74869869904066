//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";
import { TaskEntry, TaskGroup } from "../../StatesLog/StatesLoggingContainer";

const actionNameLoadPrefix = "STATES_LOAD";
export const STATES_LOAD_START = `${actionNameLoadPrefix}_START`;
export const STATES_LOAD_SUCCESS = `${actionNameLoadPrefix}_SUCCESS`;
export const STATES_LOAD_ERROR = `${actionNameLoadPrefix}_ERROR`;

const actionNameClockInPrefix = "STATE_CLOCKIN";
export const STATE_CLOCKIN_START = `${actionNameClockInPrefix}_START`;
export const STATE_CLOCKIN_SUCCESS = `${actionNameClockInPrefix}_SUCCESS`;
export const STATE_CLOCKIN_ERROR = `${actionNameClockInPrefix}_ERROR`;

const actionNameClockOutPrefix = "STATE_CLOCKOUT";
export const STATE_CLOCKOUT_START = `${actionNameClockOutPrefix}_START`;
export const STATE_CLOCKOUT_SUCCESS = `${actionNameClockOutPrefix}_SUCCESS`;
export const STATE_CLOCKOUT_ERROR = `${actionNameClockOutPrefix}_ERROR`;

const actionNameStartPrefix = "STATE_START_LOG";
export const STATE_START_LOG_START = `${actionNameStartPrefix}_START`;
export const STATE_START_LOG_SUCCESS = `${actionNameStartPrefix}_SUCCESS`;
export const STATE_START_LOG_ERROR = `${actionNameStartPrefix}_ERROR`;

const actionNamePhonePrefix = "STATE_START_PHONE_LOG";
export const STATE_START_PHONE_LOG_START = `${actionNamePhonePrefix}_START`;
export const STATE_START_PHONE_LOG_SUCCESS = `${actionNamePhonePrefix}_SUCCESS`;
export const STATE_START_PHONE_LOG_ERROR = `${actionNamePhonePrefix}_ERROR`;

const actionNamePhoneStopPrefix = "STATE_STOP_PHONE_LOG";
export const STATE_STOP_PHONE_LOG_START = `${actionNamePhoneStopPrefix}_START`;
export const STATE_STOP_PHONE_LOG_SUCCESS = `${actionNamePhoneStopPrefix}_SUCCESS`;
export const STATE_STOP_PHONE_LOG_ERROR = `${actionNamePhoneStopPrefix}_ERROR`;


const actionNameUpdatePrefix = "STATE_UPDATE_LOG";
export const STATE_UPDATE_LOG_START = `${actionNameUpdatePrefix}_START`;
export const STATE_UPDATE_LOG_SUCCESS = `${actionNameUpdatePrefix}_SUCCESS`;
export const STATE_UPDATE_LOG_ERROR = `${actionNameUpdatePrefix}_ERROR`;

const actionNameStopPrefix = "STATE_STOP_LOG";
export const STATE_STOP_LOG_START = `${actionNameStopPrefix}_START`;
export const STATE_STOP_LOG_SUCCESS = `${actionNameStopPrefix}_SUCCESS`;
export const STATE_STOP_LOG_ERROR = `${actionNameStopPrefix}_ERROR`;

export interface StatesLoadStartAction {
    type: typeof STATES_LOAD_START;
}
export interface StatesLoadSuccessAction {
    type: typeof STATES_LOAD_SUCCESS;
    data: Array<TaskGroup>
}
export interface StatesLoadErrorAction {
    type: typeof STATES_LOAD_ERROR;
    error: string
}


export interface StateClockInStartAction {
    type: typeof STATE_CLOCKIN_START;
}
export interface StateClockInSuccessAction {
    type: typeof STATE_CLOCKIN_SUCCESS;
    data: any
}
export interface StateClockInErrorAction {
    type: typeof STATE_CLOCKIN_ERROR;
    error: string
}

export interface StateClockOutStartAction {
    type: typeof STATE_CLOCKOUT_START;
}

export interface StateClockOutSuccessAction {
    type: typeof STATE_CLOCKOUT_SUCCESS;
    data: any
}

export interface StateClockOutErrorAction {
    type: typeof STATE_CLOCKOUT_START;
    error: string
}

export interface StartLoggingStartAction {
    type: typeof STATE_START_LOG_START;
}

export interface StartLoggingSuccessAction {
    type: typeof STATE_START_LOG_SUCCESS;
    data: TaskEntry
}

export interface StartLoggingErrorAction {
    type: typeof STATE_START_LOG_ERROR;
    error: string
}

export interface UpdateLoggingStartAction {
    type: typeof STATE_UPDATE_LOG_START;
}

export interface UpdateLoggingSuccessAction {
    type: typeof STATE_UPDATE_LOG_SUCCESS;
    data: TaskEntry
}

export interface UpdateLoggingErrorAction {
    type: typeof STATE_UPDATE_LOG_ERROR;
    error: string
}

export interface StopLoggingStartAction {
    type: typeof STATE_STOP_LOG_START;
}

export interface StopLoggingSuccessAction {
    type: typeof STATE_STOP_LOG_SUCCESS;
    data: TaskEntry
}

export interface StopLoggingErrorAction {
    type: typeof STATE_STOP_LOG_ERROR;
    error: string
}

export type StateLogActions = StatesLoadStartAction | StatesLoadSuccessAction | StatesLoadErrorAction |
    StateClockInStartAction | StateClockInSuccessAction | StateClockInErrorAction |
    StateClockOutStartAction | StateClockOutSuccessAction | StateClockOutErrorAction |
    StartLoggingStartAction | StartLoggingSuccessAction | StartLoggingErrorAction |
    UpdateLoggingStartAction | UpdateLoggingSuccessAction | UpdateLoggingErrorAction |
    StopLoggingStartAction | StopLoggingSuccessAction | StopLoggingErrorAction;


export const loadStatesLog = () => async (dispatch) => {
    try {
        dispatch({ type: STATES_LOAD_START })
        const response = await Axios.get(`${AppSettings.ApiSchedulerUrl}/api/TaskGroups`);
        dispatch({
            type: STATES_LOAD_SUCCESS,
            data: response.data
        })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: STATES_LOAD_ERROR,
                error: errorMessage
            })
        }
    }
}

export const startClockInState = (saveClockIn: TaskGroup) => async (dispatch) => {
    try {
        dispatch({ type: STATE_CLOCKIN_START })
        const response = await Axios.post(`${AppSettings.ApiSchedulerUrl}/api/TaskGroups`, saveClockIn);
        dispatch({
            type: STATE_CLOCKIN_SUCCESS,
            data: response.data
        })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: STATE_CLOCKIN_ERROR,
                error: errorMessage
            })
        }
    }
}

export const endClockOutState = (saveClockOut: TaskGroup) => async (dispatch) => {
    try {
        dispatch({ type: STATE_CLOCKOUT_START })
        await Axios.patch(`${AppSettings.ApiSchedulerUrl}/api/TaskGroups/${saveClockOut.id}`, saveClockOut);
        dispatch({
            type: STATE_CLOCKOUT_SUCCESS,
            data: saveClockOut
        })
        dispatch({
            type: STATE_STOP_PHONE_LOG_SUCCESS
        })

    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: STATE_CLOCKOUT_ERROR,
                error: errorMessage
            })
        }
    }
}

export const startLoggingState = (startLogging: TaskEntry) => async (dispatch) => {
    dispatch({ type: STATE_START_LOG_START })
    try {
        const response = await Axios.post(`${AppSettings.ApiSchedulerUrl}/api/TaskEntries`, startLogging);                 
        dispatch({
            type: STATE_START_LOG_SUCCESS,
            data: response.data
        })
    }
    catch (err) {        
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: STATE_START_LOG_ERROR,
                error: errorMessage
            })
        }
    }
}

export const updateLoggingState = (startLogging: TaskEntry) => async (dispatch) => {
    dispatch({ type: STATE_UPDATE_LOG_START })
    try {
        const response = await Axios.put(`${AppSettings.ApiSchedulerUrl}/api/TaskEntries/${startLogging.id}`, startLogging);         
        dispatch({
            type: STATE_UPDATE_LOG_SUCCESS,
            data: response.data
        })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: STATE_UPDATE_LOG_ERROR,
                error: errorMessage
            })
        }
    }
}

export const stopLoggingState = (startLogging: TaskEntry) => async (dispatch) => {
    dispatch({ type: STATE_STOP_LOG_START })
    try {
        const response = await Axios.put(`${AppSettings.ApiSchedulerUrl}/api/TaskEntries/${startLogging.id}`, startLogging);                 
        dispatch({
            type: STATE_STOP_LOG_SUCCESS,
            data: response.data
        })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.data.message}`;
            dispatch({
                type: STATE_STOP_LOG_ERROR,
                error: errorMessage
            })
        }
    }
}

export const startPhoneLogging = (timezoneId: string, startTimeInUtc: string) => async (dispatch) => {
    dispatch({ type: STATE_START_PHONE_LOG_START })
    try {
        var request = {
            timezone: timezoneId,
            startTimeInUtc: startTimeInUtc
        }
        const response = await Axios.post(`${AppSettings.ApiSchedulerUrl}/api/TaskEntries/phone-sign-in`, request);
        
        dispatch({
            type: STATE_START_PHONE_LOG_SUCCESS,
            data: response.data
        })
    }
    catch (err) {
        console.log(err);
        
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.data.message}`;
            dispatch({
                type: STATE_START_PHONE_LOG_ERROR,
                error: "Error"
            })
        }
    }
}

export const stopPhoneLogging = (timezoneId: string, signoutCause, isForceSignOut: boolean, preflight?:boolean, isFriendly?:boolean) => async (dispatch) => {
    if (!preflight) {
        dispatch({ type: STATE_STOP_PHONE_LOG_START })
    }    
    try {
        var request = {
            timezone: timezoneId,
            signoutCause: signoutCause,
            isForceSignOut: isForceSignOut,
            ...(preflight && {forCachingPreFlightResponse: preflight })
        }
        if (!isFriendly) {
            const response = await Axios.post(`${AppSettings.ApiSchedulerUrl}/api/TaskEntries/phone-sign-out`, request);        
        } else {
            const response = await Axios.post(`${AppSettings.ApiSchedulerUrl}/api/TaskEntries/friendly-logout`, request);        
        }
        if (!preflight) {
            dispatch({
                type: STATE_STOP_PHONE_LOG_SUCCESS,
                isForceSignout: isForceSignOut
            })
        }
    }
    catch (err) {
        console.log(err);
        
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.data.message}`;
            dispatch({
                type: STATE_STOP_PHONE_LOG_ERROR,
                error: "Error"
            })
        }
    }
}

