import { GetAttributesResponse } from "../../../../store/actions/attributes.action";
import { RolesResponse } from "../../../../store/actions/roles.action";
import { AttributeRowDef } from "./convertToMapAttributes";

export interface SaveAttributeRequest {
    systemUserId: string,
    roleId: number;
    managerUserId: string;
    supervisorUserId: string;
}

export function convertToSaveAttributes(
    data: AttributeRowDef[],
    roles: RolesResponse[],
    attributes: GetAttributesResponse[]): SaveAttributeRequest[] {

    const saveAttributeDate: Array<SaveAttributeRequest> = new Array<SaveAttributeRequest>();
    const mapOfRoleIdAndName = getMapOfRoleIdAndName(roles);

    data.forEach((eachAttribute) => {
        if (eachAttribute.role) {
            const isNoSupervisorUser = eachAttribute.supervisorUserName === null || eachAttribute.supervisorUserName === '';
            const isNoManager = eachAttribute.managerUserName === null || eachAttribute.managerUserName === '';
            const eachSaveAttributeDate: SaveAttributeRequest = {
                systemUserId: eachAttribute.systemUserId,
                roleId: eachAttribute.role === null ? 0 : mapOfRoleIdAndName.get(eachAttribute.role),
                supervisorUserId: isNoSupervisorUser ? '' : getUserId(eachAttribute.supervisorUserName, attributes),
                managerUserId: isNoManager ? '' : getUserId(eachAttribute.managerUserName, attributes)
            }
            saveAttributeDate.push(eachSaveAttributeDate);
        }
    })

    return saveAttributeDate;

}

export function getMapOfRoleIdAndName(roles: RolesResponse[]): Map<string, number> {
    const mapOfRoleIdAndName = new Map<string, number>();

    roles.forEach(eachRole => {
        mapOfRoleIdAndName.set(eachRole.name, eachRole.id);
    });

    return mapOfRoleIdAndName;
}

function getUserId(name: string, attributes: GetAttributesResponse[]) {
    const matchingUser = attributes.find(at => at.systemUser.userFullName === name);
    return matchingUser && matchingUser.systemUser.id;
}


export function convertToSaveCustomAttributes(rowData: AttributeRowDef[], customAttributes) {
    const saveAttributeData: Array<any> = new Array<any>();
    rowData.forEach((eachRow) => {
        customAttributes.forEach(eachAttr => {
            if (eachRow.hasOwnProperty(eachAttr.id)) {
               const eachAttrValue =  {
                    userId: eachRow.systemUserId,
                    attributeId: eachAttr.id
               };
                
                if (eachAttr.attributeTypeName === 'String') {
                    eachAttrValue['stringValue'] = eachRow[eachAttr.id];
                }
                if (eachAttr.attributeTypeName === 'Number') {
                    eachAttrValue['numberValue'] = eachRow[eachAttr.id];
                }
                if (eachAttr.attributeTypeName === 'DateTime') {
                    eachAttrValue['dateTimeValue'] = eachRow[eachAttr.id];
                }
                if (eachAttr.attributeTypeName === 'Date') {
                    eachAttrValue['dateValue'] = eachRow[eachAttr.id];
                }
                if (eachAttr.attributeTypeName === 'Boolean') {
                    eachAttrValue['booleanValue'] = eachRow[eachAttr.id] === null || eachRow[eachAttr.id] === false ? false : true;
                }
                saveAttributeData.push(eachAttrValue);
            }
        });
    });
    return saveAttributeData;
}