//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_ASSIGNMENT_MATRIX_DATA";
export const LOAD_ASSIGNMENT_MATRIX_DATA_START = `${actionNamePrefix}_START`;
export const LOAD_ASSIGNMENT_MATRIX_DATA_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_ASSIGNMENT_MATRIX_DATA_ERROR = `${actionNamePrefix}_ERROR`;

const actionPrefixNameSave = "SAVE_ASSIGNMENT_MATRIX_DATA";
export const SAVE_ASSIGNMENT_MATRIX_DATA_START = `${actionPrefixNameSave}_START`;
export const SAVE_ASSIGNMENT_MATRIX_DATA_SUCCESS = `${actionPrefixNameSave}_SUCCESS`;
export const SAVE_ASSIGNMENT_MATRIX_DATA_ERROR = `${actionPrefixNameSave}_ERROR`;

export interface IUpdateAgentAssignmentMatrix {
    projectId: number,
    agentId: string,
    campaignIds: any[]
}

function loadStart() {
    return {
        type: LOAD_ASSIGNMENT_MATRIX_DATA_START
    };
}

function loadSuccess(data) {
    return {
        type: LOAD_ASSIGNMENT_MATRIX_DATA_SUCCESS,
        data: data
    };
}

function loadError(error) {
    return {
        type: LOAD_ASSIGNMENT_MATRIX_DATA_ERROR,
        error
    }
}

export const loadAssignmentData = () => async (dispatch) => {
    dispatch(loadStart())
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/Assignments`);        
        dispatch(loadSuccess(response.data))
    }
    catch (e) {
        dispatch(loadError(e.response.data.Message || e.message));
    }
}

export const cleanAssignmentData = () => async (dispatch) => {
    dispatch(loadStart());
}


function saveStart() {
    return {
        type: SAVE_ASSIGNMENT_MATRIX_DATA_START
    };
}

function saveSuccess() {
    return {
        type: SAVE_ASSIGNMENT_MATRIX_DATA_SUCCESS
    };
}

function saveError(error) {
    return {
        type: SAVE_ASSIGNMENT_MATRIX_DATA_ERROR,
        error
    }
}

export const updateAgentAssignment = ( updatedValue: Array<IUpdateAgentAssignmentMatrix>) => async (dispatch) => {
    
    dispatch(saveStart())    

    try {
        await Axios.post(`${AppSettings.ApiBaseUrl}/api/Assignments`, updatedValue);
        dispatch(saveSuccess())
    } 
    catch (e) {
        dispatch(saveError(e.response.data.Message || e.message));
    }
}