//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "LOAD_INVENTORY_AGENT_SCHEDULES";
export const LOAD_INVENTORY_AGENT_SCHEDULES_START = `${actionNamePrefix}_START`;
export const LOAD_INVENTORY_AGENT_SCHEDULES_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_INVENTORY_AGENT_SCHEDULES_ERROR = `${actionNamePrefix}_ERROR`;

export interface LoadInventoryAgentScheduleRequest {
    campaignId: number;
    startDateInUtc: string;
    endDateInUtc: string;
}

function loadStart() {
    return {
        type: LOAD_INVENTORY_AGENT_SCHEDULES_START
    };
}

function loadSuccess(result) {
    return {
        type: LOAD_INVENTORY_AGENT_SCHEDULES_SUCCESS,
        data: result
    };
}

function loadError(error) {
    return {
        type: LOAD_INVENTORY_AGENT_SCHEDULES_ERROR,
        error
    }
}

export const loadInventoryAgentSchedules = (request: LoadInventoryAgentScheduleRequest) => async (dispatch) => {
    dispatch(loadStart());
    try {
        const response =
            await Axios.get(`${AppSettings.ApiBaseUrl}/api/campaign/${request.campaignId}/fromDateTime/${request.startDateInUtc}/toDateTime/${request.endDateInUtc}/InventoryAgentSchedules`);
        dispatch(loadSuccess(response.data));
    } catch (e) {
        dispatch(loadError(e.response.data.Message || e.message));
    }
}


const actionNamePrefixUpdate = "UPDATE_INVENTORY_AGENT_SCHEDULES";
export const UPDATE_INVENTORY_AGENT_SCHEDULES_START = `${actionNamePrefixUpdate}_START`;
export const UPDATE_INVENTORY_AGENT_SCHEDULES_SUCCESS = `${actionNamePrefixUpdate}_SUCCESS`;
export const UPDATE_INVENTORY_AGENT_SCHEDULES_ERROR = `${actionNamePrefixUpdate}_ERROR`;

export interface UpdateInventoryAgentScheduleRequest {
    id: number;
    startDateInUtc: string;
    endDateInUtc: string;
}

function updateStart() {
    return {
        type: UPDATE_INVENTORY_AGENT_SCHEDULES_START
    };
}

function updateSuccess(result) {
    return {
        type: UPDATE_INVENTORY_AGENT_SCHEDULES_SUCCESS,
        data: result
    };
}

function updateError(error) {
    return {
        type: UPDATE_INVENTORY_AGENT_SCHEDULES_ERROR,
        error
    }
}

export const updateInventoryAgentSchedule = (request: UpdateInventoryAgentScheduleRequest) => async (dispatch) => {
    dispatch(updateStart());
    try {

        const response = await Axios.put(`${AppSettings.ApiBaseUrl}/api/InventoryAgentSchedules/${request.id}`,
            {
                startTimeInUtc: request.startDateInUtc,
                endTimeInUtc: request.endDateInUtc
            });

        dispatch(updateSuccess(response.data));
    } catch (e) {
        dispatch(updateError(e.response.data.Message || e.message));
    }
}

const actionNamePrefixCreate = "CREATE_INVENTORY_AGENT_SCHEDULES";
export const CREATE_INVENTORY_AGENT_SCHEDULES_START = `${actionNamePrefixCreate}_START`;
export const CREATE_INVENTORY_AGENT_SCHEDULES_SUCCESS = `${actionNamePrefixCreate}_SUCCESS`;
export const CREATE_INVENTORY_AGENT_SCHEDULES_ERROR = `${actionNamePrefixCreate}_ERROR`;

export interface CreateInventoryAgentScheduleRequest {
    agentIds: string[];
    campaignId: number;
    startDateInUtc: string;
    endDateInUtc: string;
    clientId: string;
    scheduleStateId: number;
}

function createStart(clientId: string) {
    return {
        type: CREATE_INVENTORY_AGENT_SCHEDULES_START,
        clientId
    };
}

function createSuccess(clientId: string, result) {
    return {
        type: CREATE_INVENTORY_AGENT_SCHEDULES_SUCCESS,
        data: result,
        clientId
    };
}

function createError(clientId: string, error) {
    return {
        type: CREATE_INVENTORY_AGENT_SCHEDULES_ERROR,
        clientId,
        error
    }
}

export const createInventoryAgentSchedule = (request: CreateInventoryAgentScheduleRequest) => async (dispatch) => {
    dispatch(createStart(request.clientId));
    try {

        const response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/campaign/${request.campaignId}/InventoryAgentSchedules`,
            {
                agentUserIds: request.agentIds,
                startTimeInUtc: request.startDateInUtc,
                endTimeInUtc: request.endDateInUtc,
                scheduleStateId: request.scheduleStateId
            });

        dispatch(createSuccess(request.clientId, response.data));
    } catch (e) {
        dispatch(createError(request.clientId, e.response.data.Message || e.message));
    }
}

const actionNamePrefixDelete = "DELETE_INVENTORY_AGENT_SCHEDULE";
export const DELETE_INVENTORY_AGENT_SCHEDULE_START = `${actionNamePrefixDelete}_START`;
export const DELETE_INVENTORY_AGENT_SCHEDULE_SUCCESS = `${actionNamePrefixDelete}_SUCCESS`;
export const DELETE_INVENTORY_AGENT_SCHEDULE_ERROR = `${actionNamePrefixDelete}_ERROR`;

export interface DeleteInventoryAgentScheduleRequest {
    agentId: string;
    clientId: string;
    serverId: number;
}

function deleteStart(clientId: string) {
    return {
        type: DELETE_INVENTORY_AGENT_SCHEDULE_START,
        clientId
    };
}

function deleteSuccess(clientId: string, result) {
    return {
        type: DELETE_INVENTORY_AGENT_SCHEDULE_SUCCESS,
        data: result,
        clientId
    };
}

function deleteError(clientId: string, error) {
    return {
        type: DELETE_INVENTORY_AGENT_SCHEDULE_ERROR,
        clientId,
        error
    }
}

export const deleteInventoryAgentSchedule = (request: DeleteInventoryAgentScheduleRequest) => async (dispatch) => {
    dispatch(deleteStart(request.clientId));
    try {
        const response = await Axios.delete(`${AppSettings.ApiBaseUrl}/api/InventoryAgentSchedules/${request.serverId}`);

        dispatch(deleteSuccess(request.clientId, response.data));
    } catch (e) {
        dispatch(deleteError(request.clientId, e.response.data.Message || e.message));
    }
}