//@ts-nocheck
import createReducer from "../createReducer";
import {
    LOAD_JOBS_START,
    LOAD_JOBS_SUCCESS,
    LOAD_JOB_DETAILS_START
} from "../../actions/companyJob.action"

const initialState = {
    data: [],
    state: null,
    jobDetails: null
};

function loaded(state, action) {
    return {
        ...state,
        data: action.data,
        state: action.type,
        jobDetails: null
    }
}

function loadStarted(state, action) {
    return {
        ...state,
        data: [],
        state: action.type
    }
}

function loadJobDetails(state, action) {
    const matchingJob = state.data.find(j => j.id === action.data);
    if (matchingJob) {
        const copyOfMatchingJob = { ...matchingJob };
        state = { ...state, jobDetails: copyOfMatchingJob };
    } else {
        state = { ...state, jobDetails: null };
    }
    return state;
}

export default createReducer(initialState, {
    [LOAD_JOBS_START]: loadStarted,
    [LOAD_JOBS_SUCCESS]: loaded,
    [LOAD_JOB_DETAILS_START]: loadJobDetails
}); 