import React from 'react';
import { NavLink } from "react-router-dom";
import { pathTo, generateAppRoutes } from './util/routerUtil';
import makeStyles from '@mui/styles/makeStyles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import NestedMenu from './NestedMenu';


const useStyles = makeStyles({
    breadcrumbs: {
        padding: '20px',
    },
    navigation: {
        backgroundColor: '#E8E8E8',
        padding:'7px',
        borderRadius:'15px',
        color:'#686868',
        textDecoration: 'none'
    },
    iconAlign: {
        verticalAlign:'text-bottom'
    }
});

export const Breadcrumb = (props) => {

    const classes = useStyles({});
    const { routesList, currentPath } = props;
    const appRoutes = generateAppRoutes(routesList);
    const currentPageRoutes = appRoutes.find(rL => rL.path.match(currentPath));
    const isDashboard = currentPath === '/vendor-dashboard';
    return (
        <>
            <NestedMenu route={currentPageRoutes} />
            { isDashboard ? null :
            <nav className={classes.breadcrumbs}>                
                <Breadcrumbs aria-label="breadcrumb">
                    <NavLink to="/" className={classes.navigation}><HomeIcon className={classes.iconAlign} />Home</NavLink>
                    {currentPageRoutes && pathTo(currentPageRoutes).map((crumb, index, breadcrumbs) => (
                        <div key={index} className="item">
                            {index < breadcrumbs.length - 1 && (
                                <NavLink to={crumb.path} className={classes.navigation}>{crumb.text}</NavLink>
                            )}
                            {index === breadcrumbs.length - 1 && <Typography color="textPrimary">{crumb.text}</Typography>}
                                
                        </div>
                    ))}
                </Breadcrumbs>                
            </nav>
            }
        </>
    )

}



