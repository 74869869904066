import React, { useState } from 'react';
import { saveAttributes, SystemUserResponse } from '../../../store/actions/attributes.action';
import EditorDialogContainer from '../manageAgentSchedule/EditorDialogContainer';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormControl,
    FormGroup,
    Grid,
    MenuItem,
    TextField,
    Select,
    InputLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import './attribute.css';
import Axios from 'axios';
import { AppSettings } from '../../../appSettings';
import { useDispatch, useSelector } from 'react-redux';
import { editCustomAttributes, loadAfterSave, loadCustomAttributes, saveCustomAttributes } from '../../../store/actions/customAttributes.action';
import { useEffect } from 'react';
import { AppState } from '../../../store/reducers';

interface DialogProps {
    handleCancel: () => void;
    title: any;
    type: string;
    onSave: (a) => void;
    editAttribute?: any;
}


const useStyles = makeStyles(theme => ({
    checkbox: {
        paddingLeft: '10px'
    }
}));

export default function AttributeDialog(props: DialogProps) {
    const classes = useStyles({});

    const fieldTypes = [
        {
            value: 'String',
            label: 'Text',
        },
        {
            value: 'Number',
            label: 'Number',
        },
        {
            value: 'Boolean',
            label: 'True/False',
        },
        {
            value: 'DateTime',
            label: 'Date & Time',
        }, {
            value: 'Date',
            label: 'Date',
        },
        {
            value: 'Image',
            label: 'Image',
        },
        {
            value: 'File',
            label: 'File',
        }
    ];

    const intialState = {
        name: '',
        defaultValueCheck: true,
        defaultValue: '',
        maxLength: '',
        description: '',
        showHoverText: true,
        hoverText: '',
        decimalPlaces: 2,
        useSeparator: true,
        availableForReporting: false,
        isActive: false,
        systemGenerated: false,
        localTimeZone: true,
        negativeNumber: '-1234.10'
    }
    const [editId, setEditId] = useState(0);
    const [state, setState] = useState(intialState);
    const updateState = data => setState(prevState => ({ ...prevState, ...data }));
    const dispatch = useDispatch();
    const customAttributeState = useSelector((state: AppState) => state.customAttributes);
    const [error, setError] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (props.type) {
			if (props.type === 'Boolean') {
				setTitle("TRUE/FALSE");
			} else if(props.type === 'DateTime') {
				setTitle('DATE & TIME');
			} else {
				setTitle(props.type.toUpperCase());
			}
		}
    },[props.type])

    useEffect(() => {
        if (props.editAttribute) {
            const editAttr = props.editAttribute; 
            updateState({
                ...editAttr,
                ...(editAttr.attributeTypeName === 'Number' && { defaultValue: editAttr.numberDefaultValue }),
                ...(editAttr.attributeTypeName === 'String' && { defaultValue: editAttr.stringDefaultValue }),
                ...(editAttr.attributeTypeName === 'Boolean' && { defaultValue: editAttr.booleanDefaultValue }),
                ...(editAttr.attributeTypeName === 'Date' && {defaultValue: editAttr.dateDefaultValue }),
                ...(editAttr.attributeTypeName === 'DateTime' && { defaultValue: editAttr.dateTimeDefaultValue }),                        
                ...(editAttr.attributeTypeName === 'Number' && {negativeNumber: editAttr.negativeNumberFormat }),
                
            })
            setEditId(editAttr.id);
        }
    },[props.editAttribute])

    useEffect(() => {
        if (customAttributeState) {
            if ( customAttributeState.type === 'SAVE_CUSTOM_ATTRIBUTES_ERROR' || customAttributeState.type === 'EDIT_CUSTOM_ATTRIBUTES_ERROR')  {
                setError(customAttributeState.error);
            } else if (customAttributeState.type === 'SAVE_CUSTOM_ATTRIBUTES_SUCCESS' || customAttributeState.type === 'EDIT_CUSTOM_ATTRIBUTES_SUCCESS') {
                props.handleCancel();  
                dispatch(loadAfterSave());
            }
        }
    },[customAttributeState]);

    const cancelDialog = () => {
        props.handleCancel();  
        dispatch(loadAfterSave());
    }

    const saveClickHandler = async () => {        
        console.log(editId);
        const saveUpdateAttribute = {
            name: state.name,
            attributeType: props.type,
            maxLength: parseInt(state.maxLength),
            ...state,
            ...(props.type === 'Number' && { numberDefaultValue: parseFloat(state.defaultValue) }),
            ...(props.type === 'String' && { stringDefaultValue: state.defaultValue }),
            ...(props.type === 'Boolean' && { booleanDefaultValue: state.defaultValueCheck }),
            ...(props.type === 'Date' && { dateDefaultValue: state.defaultValue }),
            ...(props.type === 'DateTime' && { dateTimeDefaultValue: state.defaultValue }),
            ...(props.type === 'Number' && { negativeNumberFormat: state.negativeNumber }),
        }
        if (editId > 0) {
            //@ts-ignore
            saveUpdateAttribute.id = editId;
            dispatch(editCustomAttributes(saveUpdateAttribute));
        } else {
            dispatch(saveCustomAttributes(saveUpdateAttribute));             
        }
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            updateState({
                [e.target.name]: e.target.checked
            })
        } else {
            updateState({
                [e.target.name]: e.target.value
            })
        }

    }

    // const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     const { options } = event.target as HTMLSelectElement;
    //     const value: string[] = [];
    //     for (let i = 0, l = options.length; i < l; i += 1) {
    //         if (options[i].selected) {
    //             value.push(options[i].value);
    //         }
    //     }
    //     updateState({ negativeNumber: value });
    // };


    const renderTrueFalse = () => {
        return (
            <Grid item md={6} sm={6}>
                <FormControlLabel className={classes.checkbox} control={<Checkbox name="defaultValueCheck" checked={state.defaultValueCheck} onChange={handleChange} />} label="True by default(checked)" />
            </Grid>

        )
    }

    const renderDateDefault = () => {
        let type = props.type;
        let defaultValue = '';
        if (type === 'DateTime') {
            type = "datetime-local";
            const datenow = new Date();
            defaultValue = datenow.toString()
        }
        if (type === 'Date') {
            const datenow = new Date();
            defaultValue = datenow.toString()
        }
        return (
            <>
                <Grid item md={6} sm={6}>
                    <TextField
                        variant="outlined"
                        label="Default Value"
                        name='defaultValue'
                        type={type}
                        onChange={handleChange}
                        value={state.defaultValue}
                        defaultValue={defaultValue}
                        helperText="Enter the default value when new record is added."
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item md={6} sm={6}>
                    <FormControlLabel className={classes.checkbox} control={<Checkbox name="localTimeZone" value={state.localTimeZone}  onChange={handleChange} />} label="Use Users Local Timezone" />
                </Grid>
            </>
        )

    }

    const renderDefaultValueField = () => {
        let type = props.type;
        let defaultValue = '';
        if (type === 'Image') {
            type = 'file';
        }

            return (
                <Grid item md={6}>
                    <TextField
                        variant="outlined"
                        label="Default Value"
                        name='defaultValue'
                        type={type}
                        onChange={handleChange}
                        value={state.defaultValue}
                        defaultValue={defaultValue}
                        helperText="Enter the default value when new record is added."
                    />
                </Grid>

            )
        
    }

    const renderNumberRows = () => {
        return (
            <>
                <Grid item md={4} sm={4}>
                    <TextField
                        variant="outlined"
                        label="Decimal Places"
                        name='decimalPlaces'
                        type='number'
                        onChange={handleChange}
                        value={state.decimalPlaces}
                        defaultValue={intialState.decimalPlaces}
                        helperText="Decimal Places"
                    />
                </Grid>
                <Grid item md={4} sm={4}>
                    <FormControlLabel className={classes.checkbox} control={<Checkbox name="useSeparator" checked={state.useSeparator}  onChange={handleChange} />} label="Use 1000 Separator(,)" />
                </Grid>
                <Grid item md={4} sm={4}>
                    <FormControl>
                        <InputLabel shrink htmlFor="select-multiple-native">
                            Negative Number formatting
                        </InputLabel>
                        <Select
                            fullWidth
                            native
                            name="negativeNumber"
                            value={state.negativeNumber}
                            onChange={handleChange}
                        >
                            <option key={1} value={'-1,234.10'}>
                                -1,234.10
                            </option>
                            <option key={1} value={'1,234.10'} style={{color:'red'}}>
                            1,234.10
                            </option>
                            <option key={2} value={'(1,234.10)'}>
                                (1,234.10)
                            </option>
                            <option key={3} value={'(1,234.10)'} style={{color:'red'}}>
                                (1,234.10)
                            </option>
                        </Select>
                    </FormControl>
                </Grid>
            </>

        )
    }

    const renderTextCharLimit = () => {
        return (
            <Grid item md={12} sm={6}>
                <TextField
                    variant="outlined"
                    label="Character limit"
                    onChange={handleChange}
                    value={state.maxLength}
                    name='maxLength'
                    helperText="Enter the maximum number of characters allowed."
                />
            </Grid>

        )
    }

    const renderDialogContent = () => {
        return (
            <Grid container spacing={1} id="att-form">
                {error && <Grid item md={12} sm={12}><p style={{fontSize:'10px', color:'red'}}>{error}</p></Grid> }
                <Grid item md={6} sm={6}>
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Type"
                        value={props.type}
                        onChange={handleChange}
                        helperText={`This will create a new column with ${props.type} type attribute.`}
                        variant="outlined"
                        disabled={true}
                    >
                        {fieldTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item md={6} sm={6}>
                    <FormGroup>
                        <FormControlLabel className={classes.checkbox} control={<Checkbox name="isActive" checked={state.isActive} onChange={handleChange} />} label="Attribute Active" />
                        <FormControlLabel className={classes.checkbox} control={<Checkbox name="availableForReporting" checked={state.availableForReporting}  onChange={handleChange} />} label="Available for reporting" />
                        <FormControlLabel className={classes.checkbox} control={<Checkbox name="systemGenerated" checked={state.systemGenerated}  onChange={handleChange} disabled />} label="System Generated Attribute" />
                    </FormGroup>
                </Grid>
                {(props.type === 'DateTime' || props.type === 'Date') && renderDateDefault()}
                <Grid item md={6} sm={6}>
                    <TextField
                        id="outlined-helperText"
                        value={state.name}
                        onChange={handleChange}
                        name='name'
                        label="Name"
                        defaultValue="New Property Name"
                        helperText="Enter the name of this new attribute."
                        variant="outlined"
                    />
                </Grid>
                {props.type === 'Boolean' && renderTrueFalse()}
                {//@ts-ignore
                    (props.type === "Number" || props.type === "String") && renderDefaultValueField()}
                {props.type === 'Number' && renderNumberRows()}
                {props.type === 'String' && renderTextCharLimit()}
                <Grid item md={12} sm={12}><FormControlLabel className={classes.checkbox} control={<Checkbox name="showHoverText" checked={state.showHoverText}  onChange={handleChange} />} label="Display column help text on hover." /></Grid>
                <Grid item md={12} sm={12}>                                        
                    <TextField
                        id="outlined-multiline-static"
                        label="Hover Helper Text"
                        multiline
                        rows={4}
                        fullWidth
                        onChange={handleChange}
                        value={state.hoverText}
                        name='hoverText'
                        defaultValue="Text"
                        variant="outlined"
                        helperText="Enter a brief description of this attribute. This will be externally visible to all users."
                    />
                </Grid>
                <Grid item md={12} sm={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Attribute Description"
                        multiline
                        rows={4}
                        fullWidth
                        onChange={handleChange}
                        value={state.description}
                        name='description'
                        defaultValue="Text"
                        variant="outlined"
                        helperText="Enter a brief description of this attribute. This is not externally visible."
                    />
                </Grid>
            </Grid>
        )
    }

    const renderButtons = () => {
        return (
            <>
                <Button variant="contained" onClick={cancelDialog} color="primary">Cancel</Button>
                <Button variant="contained" onClick={saveClickHandler} color="primary">Save</Button>
            </>
        )
    }

    const renderDialog = () => {
        return (
            <EditorDialogContainer
                isCustomTitle={true}
                title={props.title +" Attribute - " + title}
                content={renderDialogContent()}
                onClose={cancelDialog}
                buttons={renderButtons()}
                id="flow"
            />
        )
    }

    return (renderDialog())
}