import createReducer from "./createReducer";
import {
    LOAD_GRID_PARAMS_START,
    LOAD_GRID_PARAMS_SUCCESS,
    LOAD_GRID_PARAMS_ERROR,
    gridParamsActions,
    LoadGridParamsStartAction,
    LoadGridParamsSuccessAction,
    LoadGridParamsErrorAction,
    SaveGridParamsStart,
    SaveGridParamsSuccess,
    SaveGridParamsError,
    SAVE_SELECTED_START,
    SAVE_SELECTED_ERROR,
    SAVE_SELECTED_SUCCESS
} from '../actions/gridParametersSelected.action'

export interface GridParamsState {
    state: string;
    error: string;
    data: any;
}

const initialState: GridParamsState = {
    state: null,
    error: null,
    data: []
}

function loadGridParamsStart(state:GridParamsState, action: LoadGridParamsStartAction): GridParamsState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadGridParamsSuccess(state:GridParamsState, action: LoadGridParamsSuccessAction): GridParamsState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadGridParamsError(state:GridParamsState, action: LoadGridParamsErrorAction): GridParamsState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function saveGridParamsStart(state:GridParamsState, action: SaveGridParamsStart): GridParamsState {    
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function saveGridParamsSuccess(state:GridParamsState, action: SaveGridParamsSuccess): GridParamsState {
    const elementsIndex = state.data.findIndex(gs => gs.id == action.selectedParams.id);
    const data = [...state.data];
    if (elementsIndex >= 0) {
        data[elementsIndex] = {        
            ...action.selectedParams
        }
    } else {
        data.push(action.selectedParams);
    }
    
    return {
        ...state,
        data: [
            ...data
        ],
        state: action.type,
        error: null,        
    }
}

function saveGridParamsError(state:GridParamsState, action: SaveGridParamsError): GridParamsState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const GridParamsSelectedReducer =
    (state: GridParamsState, action: gridParamsActions): GridParamsState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_GRID_PARAMS_START]: loadGridParamsStart,
            [LOAD_GRID_PARAMS_SUCCESS]: loadGridParamsSuccess,
            [LOAD_GRID_PARAMS_ERROR]: loadGridParamsError,
            [SAVE_SELECTED_START] : saveGridParamsStart,
            [SAVE_SELECTED_SUCCESS]: saveGridParamsSuccess,
            [SAVE_SELECTED_ERROR]: saveGridParamsError
        });

        return fnUpdateState(state, action);
    }

export default GridParamsSelectedReducer;