//@ts-nocheck
import React from "react";

const BasePage = props =>(
    <div className="wrapper">
        { props.children }
    </div>
)

export default BasePage;
