import React, { useEffect, useRef, useState } from 'react';
import { Theme, Button, Tabs, Tab, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ReactComponent as OnGoing } from './Icons/OnGoing.svg';
import { ReactComponent as HangUp } from './Icons/HangUp.svg';
import { ReactComponent as Hold } from './Icons/HoldNoActive.svg';
import { ReactComponent as HoldActive } from './Icons/HoldActive.svg';
import { ReactComponent as Mike } from './Icons/Mike.svg';
import { ReactComponent as MikeMute } from './Icons/MuteActive.svg';
import { ReactComponent as Transfer } from './Icons/Transfer.svg';
import { ReactComponent as Answer } from './Icons/Answer.svg';
import { ReactComponent as Decline } from './Icons/Decline.svg';
import { ReactComponent as UserAvatar } from './Icons/user_avatar.svg';
import { ReactComponent as PhoneOutline } from './Icons/phone-outline.svg';
import { ReactComponent as ConferenceActive } from './Icons/ConferenceActive.svg';
import { ReactComponent as ConferenceNoActive } from './Icons/ConferenceNoActive.svg';
import { ReactComponent as ConferenceTabActive } from './Icons/ConferenceTabActive.svg';
import { ReactComponent as CallForwardNoActive } from './Icons/CallForwardNoActive.svg';
import { ReactComponent as CallForwardActive } from './Icons/CallForwardActive.svg';
import { ReactComponent as DialPadIcon } from './Icons/DialPadIcon.svg';
import { ReactComponent as DialPadActiveIcon } from './Icons/DialPadActiveIcon.svg';
import { ReactComponent as Camera } from './Icons/Camera.svg';
import { ReactComponent as Info } from './Icons/Info.svg';
import { ReactComponent as PhoneFill } from './Icons/PhoneFill.svg';
import { ReactComponent as PhoneEnd } from './Icons/PhoneEnd.svg';
import WebIcon from '@mui/icons-material/Web';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CallStates from './CallStates';
import Axios from 'axios';
import useLoudness from '../hooks/useLoudness';
import useMuteWarning from '../hooks/useMuteWarning';
import { AppSettings } from '../appSettings';
import { callerDetails } from './PhoneListContainer';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { acceptCall, conferenceCall, endCall, updateConfCallNumbers, wrapupCall } from '../store/actions/activeCalls.action';
import { AppState } from '../store/reducers';
import CallTimer from './CallTimer';
import ConferenceCallContainer from './ConferenceCallContainer';
import { useAuth0 } from '../auth0/react-auth0-wrapper';
import KeyPad from './KeyPad';
import { handleCallTimers, handleHangUpApi, handleReject } from './utils';
import { RESERVATION_TIMEOUT, RESERVATION_WRAPUP } from '../store/actions/softPhone/actionTypes';
import ChatForm from './ChatComponent/ChatForm';

export interface IConferenceCall {
    phoneNumber: string,
    status: string,
    type: string,
    hold: boolean,
    mute: boolean,
    name: string
}

interface CallDetailProps {
    callState: string,
    changeCallState: (callState: string) => void;
    handleTaskWrapup: () => void;
    agentSecondaryId: string,
    agentName: string,
    workspaceId: string,
    callerInfo: Partial<callerDetails>,
    queueName: string,
    phoneType: string,
    attributes: any,
    conn: any,
    selectedTask: any
    handleCoachDone: () => void;
    scheduleId: number,
    userReasons: any,
    handleIncoming: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        taskHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: 10
        },
        containerDiv: {
            background: '#1D1F28',
            color: 'white',
            minWidth: '300px',
            paddingTop: 0,
            position: 'relative',
            zIndex: 100,
            height: '100%'
        },
        contentDiv: {
            padding: 10,
            //height: '40vh',
            height: '65%',
            position: 'relative',
            textAlign: 'center'
        },
        btnsDiv: {
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 60
        },
        btnsAcceptDiv: {
            position: 'absolute',
            bottom: 0,
            left: '50%',
            //top: '50%',
            transform: 'translate(-50%,-50%)'
        },
        callIcons: {
            marginLeft: 10,
            cursor: 'pointer'
        },
        btnContainerDiv: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            height: '10%'
        },
        tabWidth: {
            minWidth: '130px'
        },
        statusDiv: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline'
        }
    })
);


export default function CallDetailContainer(props: CallDetailProps) {
    const classes = useStyles();
    const [callState, setCallState] = useState(CallStates.READY);
    const [tabValue, setTabValue] = useState('info');
    const tiRef = useRef(null);
    const [muted, setMuted] = useState(false);
    const [isTransfer, setIsTransfer] = useState(false);
    const [isHangClicked, setIsHangClicked] = useState(false);
    const [isWrapClicked, setIsWrapClicked] = useState(false);
    const [isHold, setIsHold] = useState(false);
    const [running, setRunning, loudness] = useLoudness();
    const [showMuteWarning] = useMuteWarning(loudness, running);
    const [showConf, setShowConf] = useState(false);
    const [showDailpad, setShowDailpad] = useState(false);
    const [confNumbers, setConfNumbers] = useState([]);
    const [number, setNumber] = useState("");
    const [timer, setTimer] = useState(0);
    const [isPeerCall, setIsPeerCall] = useState(false);
    const dispatch = useDispatch();
    const activeCallsState = useSelector((state: AppState) => state.activeCalls);
    const taskRouterWorker = useSelector((state: AppState) => state.taskRouterWorker);
    const deviceState = useSelector((state: AppState) => state.deviceState);
    const phoneForms = useSelector((state: AppState) => state.workflowForm);
    //@ts-ignore
    const { user } = useAuth0();

    useEffect(() => {
        if (taskRouterWorker.state === RESERVATION_WRAPUP) {
            if (tabValue === 'conference' && showConf === true) {
                props.changeCallState(CallStates.WRAPUP);
                setTabValue('info');
                setShowConf(false);
            }
        }
    }, [taskRouterWorker.state])

    useEffect(() => {
        const isPeerCall = props.attributes && props.attributes.type === 'outbound agent to agent call';
        setIsPeerCall(isPeerCall);
    }, [props.attributes])

    useEffect(() => {
        if (props.callState === CallStates.ON_CALL) {
            setTabValue('ongoing');
        }
    }, [props.callState])

    useEffect(() => {
        if (props.callState === CallStates.INCOMING && props.phoneType === 'outbound') {
            handleAnswer();
        }
    }, [props.callState, props.phoneType])

    useEffect(() => {
        if (tabValue === 'ongoing' && props.callState === CallStates.ON_CALL) {
            const startTimer = handleCallTimers(activeCallsState);
            setTimer(startTimer);
        }
        if (tabValue === 'ongoing' && props.callState === CallStates.WRAPUP) {
            const endTimer = handleCallTimers(activeCallsState);
            setTimer(endTimer);
        }
    }, [tabValue, activeCallsState.state, showDailpad])

    useEffect(() => {
        if (tabValue === 'conference') {
            const updatedConf = [...activeCallsState.conferenceNumbers];
            const isCustomer = updatedConf.findIndex(x => x.type === 'Customer');
            if (isCustomer < 0) {
                const customer: IConferenceCall = {
                    phoneNumber: props.attributes.from,
                    status: 'Connected',
                    type: 'Customer',
                    hold: false,
                    mute: false,
                    name: props.attributes.from
                }
                updatedConf.push(customer);
            }
            setConfNumbers(updatedConf);
            dispatch(updateConfCallNumbers(updatedConf));
        }

    }, [tabValue])

    const handleChange = (event, newValue: string) => {
        setTabValue(newValue);
    }

    const handleMuteHoldBeforeTransfer = async () => {
        var conf = [...confNumbers];
        var isCustomer = conf && conf.filter(x => x.type === "Customer");
        if (isCustomer) {
            var holdRequest = {
                isHold: "false",
                holdparty: "Customer"
            }
            var muteRequest = {
                isMute: "false",
                muteParty: "Customer"
            }
            holdMuteRequest(holdRequest, muteRequest);
        }
        var isThirdParty = conf && conf.filter(x => x.type === "ThirdParty");
        if (isThirdParty) {
            var holdRequest = {
                isHold: "false",
                holdparty: "ThirdParty"
            }
            var muteRequest = {
                isMute: "false",
                muteParty: "ThirdParty"
            }
            holdMuteRequest(holdRequest, muteRequest)
        }
    }

    const holdMuteRequest = async (holdRequest, muteRequest) => {
        await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/Hold`, holdRequest);
        await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/Mute`, muteRequest);
    }

    const handleForwardTransfer = async () => {
        setIsTransfer(true);
        handleMuteHoldBeforeTransfer();
        var hangRequest = {
            "deleteParty": "Agent",
            "transfer": true
        }
        try {
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/DeleteConference`, hangRequest);
            if (response && response.data) {

            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleAnswer = () => {
        // setCallState(CallStates.ACCEPTED);
        if (props.phoneType !== 'outbound') {
            props.handleIncoming();
        }
        props.changeCallState(CallStates.ACCEPTED);
        dispatch(acceptCall());
    }

    const handleAgentHold = (isHold) => {
        if (isHold === 'hold') {
            //   setCallState(CallStates.ON_HOLD);
            props.changeCallState(CallStates.ON_HOLD);
        } else {
            // setCallState(CallStates.ON_CALL);
            props.changeCallState(CallStates.ON_CALL);
        }
    }

    const handleAddParty = async (addedParty) => {
        setConfNumbers([...addedParty]);
    }

    const handleDailPad = () => setShowDailpad(!showDailpad);

    const handleConf = () => {
        setShowConf(!showConf);
        if (!showConf === true) {
            setTabValue('conference');
            const updatedConf = [...activeCallsState.conferenceNumbers];
            const isAgent = updatedConf.findIndex(x => x.type === 'Agent');
            if (isAgent < 0) {
                const agentUser: IConferenceCall = {
                    phoneNumber: user.name,
                    status: 'Connected',
                    type: 'Agent',
                    hold: false,
                    mute: false,
                    name: props.agentName
                }
                updatedConf.push(agentUser);
                setConfNumbers(updatedConf);
                dispatch(conferenceCall(updatedConf));
            }
        }
        else
            setTabValue('ongoing');
    }

    const handleMute = async (isMute: string) => {
        setMuted(isMute === 'mute' ? true : false);
        setRunning(!muted);
        var muteRequest = {
            isMute: isMute === 'mute' ? "true" : "false",
            muteParty: "Agent"
        }
        try {
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/Mute`, muteRequest);
            if (response && response.data) {
                const entries = response.data.entries;
                if (entries[0].eventId != null) {
                    //setMuted(true);
                } else {
                    setMuted(false);
                }
            }
        } catch (err) {
            console.log(err);
            setMuted(false);
        }

    }

    const handleHold = async (isHold: string) => {
        var holdRequest = {
            isHold: isHold === 'hold' ? "true" : "false",
            holdparty: "Customer"
        }
        try {
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/Hold`, holdRequest);
            if (response && response.data) {
                const entries = response.data.entries;
                if (entries[0].eventId != null) {
                    setIsHold(isHold === 'hold' ? true : false);
                    if (isHold === 'hold') {
                        props.changeCallState(CallStates.ON_HOLD);
                    } else {
                        props.changeCallState(CallStates.ON_CALL);
                    }

                } else {
                    setIsHold(false);
                    props.changeCallState(CallStates.ON_CALL);
                }
            }
        } catch (err) {
            console.log(err);
            setIsHold(false);
            props.changeCallState(CallStates.ON_CALL);
        }

    }

    const handleDecline = async () => {
        const currentReservation = taskRouterWorker.currentReservation;
        const reservationId = currentReservation.sid;
        await handleReject(props.workspaceId, reservationId, props.selectedTask);
        props.changeCallState(CallStates.DECLINE);
    }

    const handleHangUp = async () => {
        setIsHangClicked(true);
        tiRef.current && tiRef.current.stop();
        deviceState && deviceState.connection && deviceState.connection.disconnect();
        deviceState && deviceState.device && deviceState.device.disconnectAll();
        console.log("agent hangup called");
        if (Object.keys(props.callerInfo).length === 0 && props.phoneType === null && props.attributes === null) {
            props.handleCoachDone();
            await handleHangUpApi(props.workspaceId, props.agentName, taskRouterWorker.worker, props.selectedTask);
            const selectedState = props.userReasons.find(x => x.id == props.scheduleId);
            if (selectedState && selectedState.isProductive) {
                props.changeCallState(CallStates.READY);
            } else {
                props.changeCallState(CallStates.IDLE);
            }
        } else {
            // setCallState(CallStates.WRAPUP);
            setShowConf(false);
            setTabValue('info');
            await handleHangUpApi(props.workspaceId, props.agentName, taskRouterWorker.worker, props.selectedTask);
            // props.changeCallState(CallStates.WRAPUP);
            dispatch(endCall());
        }
        setIsHangClicked(false);
    }

    const handleWrapup = async () => {
        setIsWrapClicked(true);
        if (props.callState === CallStates.WRAPUP) {
            setCallState(CallStates.COMPLETEWRAPUP);
            props.changeCallState(CallStates.COMPLETEWRAPUP);
        }
        const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/WrapUp`);
        props.handleTaskWrapup();
        if (response && response.data) {
            const entries = response.data.entries;
            if (entries[0].eventId != null)
                console.log('Success')

        }
        setTimer(0);
        setTabValue('info')
        dispatch(wrapupCall());
        //setIsWrapClicked(false);
    }

    const handleOutbound = async () => {
        const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/CompleteOutbound`);
        if (response && response.data) {
            const entries = response.data.entries;
            if (entries[0].eventId != null)
                console.log('Success')

        }
    }

    const renderCallerInfo = (callerInfo) => {
        const keys = Object.keys(callerInfo);
        keys && keys.forEach((key, index) => {
            if ((typeof callerInfo[key] !== 'object') && callerInfo[key].endsWith("}}"))
                callerInfo[key] = "No information"
        });
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {callerInfo.hasOwnProperty("ruleInfo") && callerInfo.ruleInfo !== "" && <span><div dangerouslySetInnerHTML={{__html: callerInfo.ruleInfo}} /></span>}
                {callerInfo.hasOwnProperty("MemberNamePreference") && (callerInfo.MemberNamePreference !== "" || callerInfo.MemberNamePreference !== "No information") && <span>Name: {callerInfo.MemberNamePreference}</span>}
                {callerInfo.hasOwnProperty("MemberAge") && (callerInfo.MemberAge !== "" || callerInfo.MemberAge !== "No information") && <span>Age: {callerInfo.MemberAge}</span>}
                {callerInfo.hasOwnProperty("from") && (callerInfo.from !== "" || callerInfo.from !== "No information") && <span>Phone Number: {callerInfo.from}</span>}
                {callerInfo.hasOwnProperty("MemberPlan") && (callerInfo.MemberPlan !== "" || callerInfo.MemberPlan !== "No information") && <span>Plan: {callerInfo.MemberPlan}</span>}
                {callerInfo.hasOwnProperty("Status") && (callerInfo.Status !== "" || callerInfo.Status !== "No information") && <span>Status: {callerInfo.Status}</span>}
                {callerInfo.hasOwnProperty("Vehicle1") && (callerInfo.Vehicle1 !== "" || callerInfo.Vehicle1 !== "No information") && <span>Vechile: {callerInfo.Vehicle1}</span>}
                {callerInfo.hasOwnProperty("Color") && (callerInfo.Color !== "" || callerInfo.Color !== "No information") && <span>Color: {callerInfo.Color}</span>}
                {callerInfo.hasOwnProperty("LicensePlate") && (callerInfo.LicensePlate !== "" || callerInfo.LicensePlate !== "No information") && <span>License plate: {callerInfo.LicensePlate}</span>}
                {callerInfo.hasOwnProperty("Caller_Reason") && <span>Caller Reason: {callerInfo.Caller_Reason}</span>}
            </div>
        )
    }

    const renderInfoValue = () => {
        return (
            <>
                {
                    !showDailpad &&
                    <>
                        <p>Caller Info</p>
                        {
                            !isPeerCall ?
                                props.callerInfo && Object.keys(props.callerInfo).length > 0 ?
                                    <>
                                        {renderCallerInfo(props.callerInfo)}
                                    </>
                                    : <div>Loading...</div>
                                : <div>Agent to agent call.</div>
                        }
                    </>
                }
            </>
        )
    }

    const renderIncoming = () => {
        return (
            <>
                <div>
                    <PhoneOutline />
                    <p>{props.callerInfo.PhoneNumber}</p>
                    <p>Incoming call from queue {props.queueName}</p>
                </div>
            </>

        )
    }

    const renderConferenceCall = () => {
        return (
            !showDailpad && <ConferenceCallContainer onConference={activeCallsState.conferenceNumbers} handleAgentHold={handleAgentHold} loggedInAgentName={props.agentName} workspaceSid={props.workspaceId} agentCallState={callState} />
        )
    }

    const handlePress = (num) => {
        const numb = number + num;
        setNumber(numb);
        props.conn.sendDigits(num)
    }

    const renderShowDailPad = () => {
        return (
            <KeyPad handlePress={handlePress} number={number} />
        )
    }

    const renderIframe = () => {
        let attributes = props.attributes ? props.attributes : props.selectedTask.attributes;
        let url = attributes.iframeURL;
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <iframe src={url} width="100%" height="100%" />
            </div>

        )
    }

    const renderOnCall = () => {
        let from = '';
        if (props.attributes) {
            from = props.attributes.direction === 'outbound' ? props.attributes.CustomerPhone : props.attributes.from;
        }
        let reservationId
        const currentReservation = taskRouterWorker.currentReservation;
        if (currentReservation !== null) {
            reservationId = currentReservation.sid;
        }
        return (
            <div>
                {
                    (props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD) &&
                    <>
                        {
                            props.conn === null && taskRouterWorker.state !== RESERVATION_TIMEOUT && <p>Please wait for the call to connect...</p>
                        }

                        {
                            taskRouterWorker.state === RESERVATION_TIMEOUT && props.conn === null && taskRouterWorker.reservationSid === reservationId
                            && <p>Call has been forwarded to the next agent...</p>
                        }

                        {!showDailpad &&
                            <>
                                <OnGoing />
                                <p>{from}</p>
                                <div className={classes.statusDiv} key={timer}>Live | {<CallTimer timer={timer} />}</div>
                            </>}

                    </>
                }
                {
                    props.callState === CallStates.WRAPUP &&
                    <>
                        <PhoneEnd />
                        <p>{from}</p>
                        <div className={classes.statusDiv} key={timer}>WrapUp | {<CallTimer timer={timer} />}</div>
                    </>
                }

            </div>
        )
    }

    const muteWarning = (
        <p className="warning">Are you speaking? You are on mute!</p>
    );

    const renderForms = () => {
        let phoneForm;
        let attributes = props.attributes ? props.attributes : props.selectedTask.attributes
        if (attributes && attributes.hasOwnProperty("LoadForm")) {
            const formName = attributes.LoadForm;
            phoneForm = phoneForms.data && phoneForms.data.filter(x => x.formName === formName)[0];
        } 
        // else {
        //     phoneForm = phoneForms.data && phoneForms.data[0];
        // }
       // console.log(attributes);
       // phoneForm = phoneForms.data && phoneForms.data.filter(x => x.formName === "Phone Form 2")[0];
        return (
            <div style={{ padding: '10px' }}>
                <ChatForm formDef={phoneForm} taskAttributes={attributes} isPhone={true} />
            </div>
        )
    }

    const checkIfIframePresent = () => {
        let attributes = props.attributes ? props.attributes : props.selectedTask.attributes;
        if (attributes && attributes.hasOwnProperty('iframeURL')) {
            if (attributes.iframeURL !== '')
                return attributes.iframeURL;
        } else {
            return '';
        }               
    }

    return (
        <div className={classes.containerDiv}>
            <div className={classes.taskHeader}>
                {
                    props.selectedTask.attributes &&
                    <>
                        <UserAvatar />
                        <span style={{ marginLeft: '10px' }}> {props.selectedTask.attributes.direction === 'outbound' ? props.selectedTask.attributes.campaign : props.selectedTask.attributes.Campaigns} - {props.selectedTask.attributes.direction === 'outbound' ? props.selectedTask.attributes.CustomerPhone : props.selectedTask.attributes.from}</span>
                    </>

                }

            </div>
            <div>
                <Tabs value={tabValue}
                    indicatorColor="secondary"
                    textColor="primary"
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab className={classes.tabWidth} value="info" icon={<Info />} aria-label="phone" />
                    {props.callState === CallStates.INCOMING && <Tab className={classes.tabWidth} style={{ color: '#ffffff' }} value="incoming" label="INCOMING" />}
                    {(props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD || props.callState === CallStates.WRAPUP) && <Tab className={classes.tabWidth} value='ongoing' icon={<PhoneFill />} aria-label="favorite" />}
                    {((props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD) && showConf) && <Tab className={classes.tabWidth} value='conference' icon={<ConferenceTabActive />} aria-label="favorite" />}
                    {/* {(props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD) && <Tab className={classes.tabWidth} value='camera' icon={<Camera />} aria-label="person" />} */}
                    { checkIfIframePresent() !== '' &&  <Tab className={classes.tabWidth} value='iframeURL' icon={<WebIcon sx={{ color: '#ffff' }} />} aria-label="iframeURL" /> } 
                    {/* <Tab className={classes.tabWidth} value='iframeURL' icon={<WebIcon sx={{ color: '#ffff' }} />} aria-label="iframeURL" /> */}
                    <Tab style={{ minWidth: '130px' }} value="forms" icon={<ListAltIcon sx={{ color: '#ffff' }} />} aria-label="phone forms" />
                </Tabs>
            </div>
            <div className={classes.contentDiv}>
                {tabValue === 'info' && renderInfoValue()}
                {tabValue === 'incoming' && renderIncoming()}
                {(tabValue === 'ongoing' || tabValue === 'camera') && renderOnCall()}
                {(tabValue === 'conference' && showConf && (props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD)) && renderConferenceCall()}
                {showMuteWarning && muteWarning}
                {props.phoneType === 'outbound' && showDailpad && renderShowDailPad()}
                {tabValue === 'iframeURL' && renderIframe()}
                {tabValue === 'forms' && renderForms()}
            </div>
            {(tabValue === 'incoming' || tabValue === 'info' || tabValue === 'ongoing'  )  &&
                <div className={classes.btnContainerDiv}>
                    {(props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD) && (props.selectedTask.assignmentStatus !== "wrapping") &&
                        <div className={classes.btnsAcceptDiv}>
                            <div style={{ display: 'flex' }}>
                                {(showConf && (activeCallsState.conferenceNumbers && activeCallsState.conferenceNumbers.length === 3 && activeCallsState.conferenceNumbers[2].status === "Connected")) && <div className={classes.callIcons}>{!isTransfer ? <CallForwardNoActive onClick={() => handleForwardTransfer()} /> : <CallForwardActive onClick={() => setIsTransfer(false)} />}</div>}
                                <div className={classes.callIcons}>{!showConf ? <ConferenceNoActive onClick={handleConf} /> : <ConferenceActive onClick={handleConf} />}</div>
                                <div className={classes.callIcons}>{props.callState !== CallStates.ON_HOLD ? <Hold onClick={() => handleHold('hold')} /> : <HoldActive onClick={() => handleHold('noHold')} />}</div>
                                <div className={classes.callIcons}> {!muted ? <Mike onClick={() => handleMute('mute')} /> : <MikeMute onClick={() => handleMute('noMute')} />} </div>
                                {props.phoneType === 'outbound' && <div className={classes.callIcons}>{!showDailpad ? <DialPadIcon onClick={handleDailPad} /> : <DialPadActiveIcon onClick={handleDailPad} />}</div>}
                                <div className={classes.callIcons}><Transfer onClick={handleAnswer} /></div>
                                <div className={classes.callIcons}>{isHangClicked ? <CircularProgress style={{ color: '#ffffff' }} /> : <HangUp onClick={handleHangUp} />}</div>
                            </div>
                        </div>
                    }
                    {
                        props.callState === CallStates.INCOMING &&
                        <div className={classes.btnsAcceptDiv}>
                            <div style={{ display: 'flex' }}>
                                {
                                    props.phoneType !== 'outbound' ?
                                        <>
                                            <div className={classes.callIcons}><Answer onClick={handleAnswer} /></div>
                                            <div className={classes.callIcons}><Decline onClick={handleDecline} /></div>
                                        </>
                                        :
                                        <></>
                                }

                            </div>
                        </div>
                    }
                    {
                        props.selectedTask && props.selectedTask.assignmentStatus === "wrapping" &&
                        <div className={classes.btnsAcceptDiv}>
                            <div style={{ display: 'flex' }}>
                                <div className={classes.callIcons}>
                                    {isWrapClicked ? <CircularProgress style={{ color: '#ffffff' }} /> : <Button variant="contained" color="primary" onClick={handleWrapup}>{(props.selectedTask.attributes.direction === 'inbound' || props.selectedTask.attributes.direction === "inbound-transfer" || props.selectedTask.attributes.direction === "inbound-conference") ? 'End Wrapup' : 'End Outbound Wrapup'}</Button>}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}