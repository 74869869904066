//@ts-nocheck
import createReducer from "./createReducer";
import { 
    LOAD_MY_DEFAULT_CAMPAIGN_START,
    LOAD_MY_DEFAULT_CAMPAIGN_SUCCESS,
    LOAD_MY_DEFAULT_CAMPAIGN_ERROR,
    myDefaultCampaignActions,
    LoadMyDefaultCampaignStartedAction,
    LoadMyDefaultCampaignErrorAction,
    LoadMyDefaultCampaignSuccessAction
} from '../actions/myDefaultCampaign.action'

export interface MyDefaultCampaignState {
    state: string;
    error: string;
    data: {};
}

const initialState: MyDefaultCampaignState = {
    state: null,
    error: null,
    data: {}
}

function loadMyDefaultCampaign(state:MyDefaultCampaignState, action: LoadMyDefaultCampaignStartedAction): MyDefaultCampaignState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadMyDefaultCampaignSuccess(state:MyDefaultCampaignState, action: LoadMyDefaultCampaignSuccessAction): MyDefaultCampaignState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadMyDefaultCampaignError(state:MyDefaultCampaignState, action: LoadMyDefaultCampaignErrorAction): MyDefaultCampaignState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const MyDefaultCampaignReducer =
    (state: MyDefaultCampaignState, action: myDefaultCampaignActions): MyDefaultCampaignState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_MY_DEFAULT_CAMPAIGN_START]: loadMyDefaultCampaign,
            [LOAD_MY_DEFAULT_CAMPAIGN_SUCCESS]: loadMyDefaultCampaignSuccess,
            [LOAD_MY_DEFAULT_CAMPAIGN_ERROR]: loadMyDefaultCampaignError
        });

        return fnUpdateState(state, action);
    }

export default MyDefaultCampaignReducer;