import { combineReducers } from "redux";

import companyJobReducer from "./reducers/companyJob/companyJob.reducer";
import applicationFeedReducer from "./reducers/applicationFeed/applicationFeeds.reducer";
import myContractsReducer from "./reducers/myContracts.reducer";
import myProjectsReducer from "./reducers/myProjects.reducer";
import myCampaignReducer from "./reducers/myCampaign.reducer";
import myScheduleReducer from "./reducers/mySchedule.reducer";
import forecastReducer from "./reducers/forecast.reducer";
import forecastUploadReducer from "./reducers/forecastUpload.reducer";
import phoneIntervalStaffingReducer from "./reducers/phoneIntervalStaffing.reducer";
import preAdjustForecastReducer from "./reducers/forecastPreAdjust.reducer";
import weeklyForecast from "./reducers/weeklyForecast.reducer";
import weeklyForecastUploadReducer from "./reducers/weeklyForecastUpload.reducer";
import uploadSchedulerReducer from "./reducers/forecastScheduler.reducer";
import inventoryAdjustedForecast from "./reducers/inventoryAdjustedForecast.reducer";
import systemUserReducer from "./reducers/systemUser.reducer";
import agentScheduleReducer from "./reducers/agentSchedule.reducer";
import actualIntakeReducer from './reducers/actualIntake.reducer';
import agentsForCampaignsReducer from "./reducers/agentsForCampaigns.reducer";
import inventoryAgentSchedule from "./reducers/inventoryAgentSchedule";
import recalculateStaffedCountReducer from "./reducers/recalculateStaffedCount.reducer";
import projectInventoryForecastReducer from "./reducers/projectInventoryForecast.reducer";
import agentRosterMatrixReducer from "./reducers/agentRosterMatrix.reducer";
import agentAssignmentMatrixReducer from "./reducers/agentAssignmentMatrix.reducer";
import projectAgentScheduleReducer from "./reducers/projectAgentSchedule.reducer";
import filterParametersSelectedReducer from "./reducers/filterParametersSelected.reducer";
import selectedTimerReducer from "./reducers/selectedTimer.reducer";
import AttributesReducer, { AttributesState } from "./reducers/attributes.reducer";
import MarketPlaceFiltersReducer, { FiltersState } from "./reducers/marketPlaceFilters.reducer";
import CustomAttributesReducer, { CustomAttributesState } from "./reducers/customAttributes.reducer";
import CustomAttributesValueReducer, {CustomAttributesValueState} from "./reducers/customAttributesValue.reducer";
import RolesReducer, { RolesState } from "./reducers/roles.reducer";
import AdministationReducer, {ProjectCampaignState } from './reducers/administration.reducer';
import ScheduleSlotTypeReducer, {ScheduleTypesState} from './reducers/scheduleSlotTypes.reducer';
import MyAssignmentsReducer, {MyAssignmentState} from './reducers/myAssignments.reducer';
import MyProfileReducer, {MyProfileState} from "./reducers/myProfile.reducer";
import ActualOutputReducer, {ActualOutputState} from "./reducers/actualOutput.reducer";
import GridParamsSelectedReducer, {GridParamsState} from "./reducers/gridParametersSelected.reducer";
import OutOfOfficeReducer, {OOTState} from "./reducers/outOfOffice.reducer";
import MyBaseScheduleReducer, {MyBaseScheduleState} from "./reducers/myBaseSchedule.reducer";
import MyAvailableHoursReducer, {MyAvailableHoursState} from "./reducers/myAvailableHours.reducer";
import MyAppliedHoursReducer, {MyAppliedHoursState} from "./reducers/myAppliedHours.reducer";
import MyLeaveManagementReducer, {MyLeaveState} from "./reducers/myLeavesManagement.reducer";
import ShiftReducer,{ShiftState} from "./reducers/shift.reducer";
import ScheduleTemplateReducer, {ScheduleTemplateState } from "./reducers/scheduleTemplate.reducer";
import StopwatchReducer, {StopwatchState} from './reducers/stopwatch.reducer';
import DashboardReportReducer, {DashboardReportState} from "./reducers/dashboardReport.reducer";
import DashboardPeopleReportReducer, {DashboardPeopleReportState} from "./reducers/dashboardPeopleReport.reducer";
import WorkflowReducer, {WorkflowState} from "./reducers/workflow.reducer";
import FormReducer, {FormState} from "./reducers/forms.reducer";
import FormFlowReducer, {FormFlowState} from "./reducers/formsWF.reducer";
import FormSubmitReducer, {FormDataState} from "./reducers/formSubmit.reducer";
import WidgetConfigReducer, {WidgetState} from "./reducers/widgetConfig.reducer";
import myRosterCampaignReducer from "./reducers/myRosterCampaign.reducer";
import TargetReducer,{TargetsTypesState} from "./reducers/targets.reducer";
import StateTimerReducer, { StateTimerState } from "./reducers/stateTimer.reducer";
import MyDefaultCampaignReducer, {MyDefaultCampaignState} from "./reducers/myDefaultCampaign.reducer";
import StateLogReducer, { StateLogState } from "./reducers/statesLog.reducer";
import PhoneTokenReducer, { PhoneTokenState } from "./reducers/phoneToken.reducer";
import ActiveCallsReducer, {ActiveCallState} from "./reducers/activeCalls.reducer";
import DashboardConfigReducer, {DashboardState} from "./reducers/dashboardConfig.reducer"
import CurrentActivityReducer, { CurrentActivityState } from "./reducers/currentActivity.reducer";
import WorkerInitReducer, { TaskRouterWorkerState } from "./reducers/softPhone/worker.reducer";
import DeviceReducer, { DeviceState } from "./reducers/softPhone/device.reducer";
import { ChatReducer, ChatState } from "./reducers/softPhone/chat.reducer";
import LoggedInBusinessReducer, { BusinessState } from "./reducers/loggedInBusiness.reducer";
// import phoneMenuReducer, { PhoneMenu } from "./reducers/phoneMenu.reducer";

export interface RootState {
  companyJob: any;
  applicationFeed: any;
  myContracts: any;
  myProjects: any;
  myCampaigns: any;
  myRosterCampaign: any;
  mySchedule: any;
  forecast: any;
  phoneIntervalStaffing: any;
  forecastUpload: any;
  preAdjustForecast: any;
  weeklyForecast: any;
  weeklyForecastUpload: any;
  uploadScheduler: any;
  inventoryAdjustedForecast: any;
  agentUser: any;
  agentSchedule: any;
  actualIntake: any;
  agentsForCampaigns: any;
  inventoryAgentSchedule: any;
  recalculateStaffedCount: any;
  projectInventoryForecast: any;
  agentRosterMatrix: any;
  agentAssignmentMatrix:any;
  projectAgentSchedule: any;
  filterParametersSelected: any;
  selectedTimer: any;
  gridParametersSelected: GridParamsState;
  attributes: AttributesState;
  filters:FiltersState;
  customAttributes: CustomAttributesState;
  customAttributesValue:CustomAttributesValueState;
  roles: RolesState;
  admin: ProjectCampaignState;
  scheduleTypes: ScheduleTypesState;
  myAssignments: MyAssignmentState;
  myProfile: MyProfileState;
  actualOutput: ActualOutputState;
  outOfOffice: OOTState;
  myBaseSchedule: MyBaseScheduleState,
  myAvailableHours: MyAvailableHoursState,
  myAppliedHours: MyAppliedHoursState,
  myLeave: MyLeaveState,
  shift: ShiftState,
  scheduleTemplate: ScheduleTemplateState
  stopwatch: StopwatchState,
  dashboardReport: DashboardReportState,
  dashboardPeopleReport: DashboardPeopleReportState,
  workflow: WorkflowState,
  workflowForm: FormState,
  flowForm: FormFlowState,
  formData: FormDataState,
  widgetConfig: WidgetState,
  targets: TargetsTypesState,
  stateTimer: StateTimerState,
  myDefaultCampaign: MyDefaultCampaignState,
  statesLog: StateLogState,
  phoneToken: PhoneTokenState,
  activeCalls:ActiveCallState,
  dashboardConfig: DashboardState,
  currentActivity: CurrentActivityState,
  taskRouterWorker:TaskRouterWorkerState,
  deviceState:DeviceState,
  chatState:ChatState,
  loggedInBusiness: BusinessState
  // phoneMenu: PhoneMenu,
}

const rootReducer = combineReducers<RootState>({
  companyJob: companyJobReducer,
  applicationFeed: applicationFeedReducer,
  myContracts: myContractsReducer,
  myProjects: myProjectsReducer,
  myCampaigns: myCampaignReducer,
  myRosterCampaign: myRosterCampaignReducer,
  mySchedule: myScheduleReducer,
  forecast: forecastReducer,
  phoneIntervalStaffing: phoneIntervalStaffingReducer,
  forecastUpload: forecastUploadReducer,
  preAdjustForecast: preAdjustForecastReducer,
  weeklyForecast: weeklyForecast,
  weeklyForecastUpload: weeklyForecastUploadReducer,
  uploadScheduler: uploadSchedulerReducer,
  inventoryAdjustedForecast: inventoryAdjustedForecast,
  agentUser: systemUserReducer,
  agentSchedule: agentScheduleReducer,
  actualIntake: actualIntakeReducer,
  agentsForCampaigns: agentsForCampaignsReducer,
  inventoryAgentSchedule: inventoryAgentSchedule,
  recalculateStaffedCount: recalculateStaffedCountReducer,
  projectInventoryForecast: projectInventoryForecastReducer,
  agentRosterMatrix: agentRosterMatrixReducer,
  agentAssignmentMatrix: agentAssignmentMatrixReducer,
  projectAgentSchedule: projectAgentScheduleReducer,
  filterParametersSelected: filterParametersSelectedReducer,
  selectedTimer: selectedTimerReducer,
  gridParametersSelected: GridParamsSelectedReducer,
  attributes: AttributesReducer,
  filters:MarketPlaceFiltersReducer,
  customAttributes: CustomAttributesReducer,
  customAttributesValue:CustomAttributesValueReducer,
  roles: RolesReducer,
  admin: AdministationReducer,
  scheduleTypes:ScheduleSlotTypeReducer,
  myAssignments: MyAssignmentsReducer,
  myProfile: MyProfileReducer,
  actualOutput: ActualOutputReducer,
  outOfOffice: OutOfOfficeReducer,
  myBaseSchedule: MyBaseScheduleReducer,
  myAvailableHours: MyAvailableHoursReducer,
  myAppliedHours:MyAppliedHoursReducer,
  myLeave: MyLeaveManagementReducer,
  shift: ShiftReducer,
  scheduleTemplate: ScheduleTemplateReducer,
  stopwatch: StopwatchReducer,
  dashboardReport: DashboardReportReducer,
  dashboardPeopleReport: DashboardPeopleReportReducer,
  workflow: WorkflowReducer,
  workflowForm: FormReducer,
  flowForm: FormFlowReducer,
  formData: FormSubmitReducer,
  widgetConfig: WidgetConfigReducer,
  targets: TargetReducer,
  stateTimer: StateTimerReducer,
  myDefaultCampaign: MyDefaultCampaignReducer,
  statesLog: StateLogReducer,
  phoneToken: PhoneTokenReducer,
  activeCalls: ActiveCallsReducer,
  dashboardConfig: DashboardConfigReducer,
  currentActivity: CurrentActivityReducer,
  taskRouterWorker: WorkerInitReducer,
  deviceState: DeviceReducer,
  chatState:ChatReducer,
  loggedInBusiness: LoggedInBusinessReducer
  // phoneMenu: phoneMenuReducer,

});

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer;