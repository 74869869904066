//@ts-nocheck
import createReducer from "./createReducer";
import { 
    LOAD_SKILL_MATRIX_DATA_START,
    LOAD_SKILL_MATRIX_DATA_SUCCESS,
    LOAD_SKILL_MATRIX_DATA_ERROR,
    SAVE_SKILL_MATRIX_DATA_ERROR,
    SAVE_SKILL_MATRIX_DATA_START,
    SAVE_SKILL_MATRIX_DATA_SUCCESS
} from '../actions/agentRosterMatrix.action'

const initialState = {
    data: [],
    type: null,
    error: null,
    saveState: null,
    saveError: null
};

function loaded(state, action) {    
    return {
        ...state,
        data: action.data,
        error: null,
        type: action.type,
        saveState: null
    }
}

function loadStarted(state, action) {
    return {
        ...state,
        state: action.type,
        data:[]
    }
}

function loadError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}

function saveStart(state, action) {
    return {
        ...state,
        saveState: action.type,
        saveError: null
    }
}

function saveSuccess(state, action) {
    return {
        ...state,
        saveState: action.type,
        saveError: null,
    }
}

function saveError(state, action) {
    return {
        ...state,
        saveState: action.type,
        saveError: action.error
    }
}

export default createReducer(initialState, {
    [LOAD_SKILL_MATRIX_DATA_START]: loadStarted,
    [LOAD_SKILL_MATRIX_DATA_SUCCESS]: loaded,
    [LOAD_SKILL_MATRIX_DATA_ERROR]: loadError,

    [SAVE_SKILL_MATRIX_DATA_START]: saveStart,
    [SAVE_SKILL_MATRIX_DATA_SUCCESS]: saveSuccess,
    [SAVE_SKILL_MATRIX_DATA_ERROR]: saveError,
}); 