import React, { useEffect, useRef, useState } from 'react';
import Timer from 'react-compound-timer/build';

export default function CallTimer(props) {
    const tiRef = useRef(null);    
    useEffect(() => {
        tiRef.current && tiRef.current.stop();
        tiRef.current && tiRef.current.reset();
        tiRef.current && tiRef.current.start();
    }, [props.timer])

    return (
        <Timer initialTime={props.timer}
            lastUnit='h'
            ref={tiRef} 
            formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
        >
            {({ start, resume, pause, stop, reset, timerState }) => (
                <div><p style={{ fontSize: '10px', padding: 0, margin: 0 }}><Timer.Hours />:<Timer.Minutes />:<Timer.Seconds /></p></div>
            )}
        </Timer>
    )

}