import createReducer from "./createReducer";
import { 
    LOAD_DASHBOARD_REPORT_START, 
    LOAD_DASHBOARD_REPORT_SUCCESS, 
    LOAD_DASHBOARD_REPORT_ERROR,
    LOAD_DASHBOARD_VERTICAL_REPORT_START,
    LOAD_DASHBOARD_VERTICAL_REPORT_SUCCESS,
    LOAD_DASHBOARD_VERTICAL_REPORT_ERROR,
    dashboardReportActions,
    LoadDashboardReportStartAction,
    LoadDashboardReportSuccessAction,
    LoadDashboardReportErrorAction,
    LoadDashboardVerticalReportStartAction,
    LoadDashboardVerticalReportSuccessAction,
    LoadDashboardVerticalReportErrorAction
} from "../actions/dashboardReport.action";

export interface DashboardReportState {
    state: string;
    error: string;
    data: any;
    verticalData: any;
}

const initialState: DashboardReportState = {
    state: null,
    error: null,
    data: [],
    verticalData: []
}

function loadDashboardReportStart(state:DashboardReportState, action: LoadDashboardReportStartAction): DashboardReportState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadDashboardReportSuccess(state:DashboardReportState, action: LoadDashboardReportSuccessAction): DashboardReportState {    
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data,
    }
}

function loadDashboardReportError(state:DashboardReportState, action: LoadDashboardReportErrorAction): DashboardReportState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function loadDashboardVerticalReportStart(state:DashboardReportState, action: LoadDashboardVerticalReportStartAction): DashboardReportState {
    return {
        ...state,
        state: action.type,
        error: null,
        verticalData: null
    }
}

function loadDashboardVerticalReportSuccess(state:DashboardReportState, action: LoadDashboardVerticalReportSuccessAction): DashboardReportState {        
    return {
        ...state,
        state: action.type,
        error: null,
        verticalData: action.verticalData,
    }
}

function loadDashboardVerticalReportError(state:DashboardReportState, action: LoadDashboardVerticalReportErrorAction): DashboardReportState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const DashboardReportReducer =
    (state: DashboardReportState, action: dashboardReportActions): DashboardReportState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_DASHBOARD_REPORT_START]: loadDashboardReportStart,
            [LOAD_DASHBOARD_REPORT_SUCCESS]: loadDashboardReportSuccess,
            [LOAD_DASHBOARD_REPORT_ERROR]: loadDashboardReportError,
            [LOAD_DASHBOARD_VERTICAL_REPORT_START]: loadDashboardVerticalReportStart,
            [LOAD_DASHBOARD_VERTICAL_REPORT_SUCCESS]: loadDashboardVerticalReportSuccess,
            [LOAD_DASHBOARD_VERTICAL_REPORT_ERROR]: loadDashboardVerticalReportError,
           
        });

        return fnUpdateState(state, action);
    }

export default DashboardReportReducer;