import createReducer from "./createReducer";
import {
    UPLOAD_FORECAST_SCHEDULER_START, UPLOAD_FORECAST_SCHEDULER_SUCCESS, UPLOAD_FORECAST_SCHEDULER_ERROR, UPLOAD_FORECAST_SCHEDULER_RESET
} from "../actions/forecastSchedule.action";

const initialState = {
    stateType: null,
    error: null,
    isError: false,
    data: null,
    confilctOccured:false,
    message:null
};

function uploadScheduler(state, action) {
    return {
        ...state,
        stateType: action.type,        
        data: action.response,
        confilctOccured: action.response.coflictOccured,
        message:action.response.message
    }
}

function uploadSchedulerStarted(state, action) {
    return {
        ...state,        
        stateType: action.type
    }
}

function uploadSchedulerError(state, action) {
    return {
        ...state,        
        error: action.error,
        isError: true,
        stateType: action.type,
        message:action.error.message
    }
}

function uploadSchedulerReset () {
    return {
        ...initialState
    }
}

export default createReducer(initialState, {
    [UPLOAD_FORECAST_SCHEDULER_START]: uploadSchedulerStarted,
    [UPLOAD_FORECAST_SCHEDULER_SUCCESS]: uploadScheduler,
    [UPLOAD_FORECAST_SCHEDULER_ERROR]: uploadSchedulerError,
    [UPLOAD_FORECAST_SCHEDULER_RESET]: uploadSchedulerReset
}); 