import React,{useState} from 'react';
import { useTheme, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as NetworkSymbol } from './Icons/NetworkSymbol.svg';
import { ReactComponent as AudioDevice } from './Icons/Audio.svg';
import { ReactComponent as SignOut } from './Icons/SignOut.svg';
import MemoAudioDevices from './AudioDevices';
import MemoNetworkSettings from './NetworkSettings';

interface ISettingsProps {
    handleBackMenu: () => void;
    handleSignout:() => void;
    device:any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuContainer: {
            padding:'10px',
            height:'30px',
            '& h5' : {
                color: '#E0F6EF'
            },
        },
        itemContainer: {
            display:'flex',
            flexDirection:'row',
            height: '30px',
            padding: '25px',
            alignItems:'center',
            cursor:'pointer',
            '&:hover':{
                background:'#373F51'
            }
        },
        textContent: {
            marginLeft:'10px',
            display:'flex',
            flexDirection:'column'
        },
        headerText: {
            fontSize: '15px',
            color: '#fff'
        },
        smallDesc:{
            fontSize: '12px',
            color: '#A6A6A6'
        },
        iconContent:{
            padding:'10px'
        },
        icons:{
            color:"#F8F8F8",
            height:'20px'
        },
        subtitle:{
            textTransform:'uppercase',
            color:'#fff !important',
            marginLeft:'10px'
        },
        flexContainer: {
            display: 'flex',
        }
    })
);




function SettingsMenu(props: ISettingsProps) {
   const classes = useStyles();
   const [showMainMenu, setShowMainMenu] = useState(true)
   const [showAudio, setShowAudio] = useState(false);
   const [showNetwork, setShowNetwork] = useState(false);

   const showAudioDevices = () => {
       setShowMainMenu(false);
       setShowAudio(true);
   }

   const showNetworkDevices = () => {
       setShowMainMenu(false);
       setShowAudio(false);
       setShowNetwork(true);
   }

   const renderMainMenu = () => {
       return(
           <div className={classes.menuContainer}>
               <Typography variant="h5">Settings</Typography>
               <div>
                   <div className={classes.itemContainer} onClick={showAudioDevices}>
                       <div className={classes.iconContent}  onClick={showAudioDevices}><AudioDevice className={classes.icons} /></div>
                       <div className={classes.textContent}  onClick={showAudioDevices}>
                           <span className={classes.headerText}>Audio devices</span>
                           <span className={classes.smallDesc}>Change speaker, microphone, volume & testing</span>
                       </div>
                   </div>
                   <div className={classes.itemContainer} onClick={showNetworkDevices}>
                       <div className={classes.iconContent} onClick={showNetworkDevices}><NetworkSymbol className={classes.icons} /></div>
                       <div className={classes.textContent} onClick={showNetworkDevices}>
                           <span className={classes.headerText}>Network & Internet</span>
                           <span className={classes.smallDesc}>Network, wifi, latency, jitter, packet loss</span>
                       </div>                        
                   </div>
                   <div className={classes.itemContainer}>
                       <div className={classes.iconContent}>
                           <SignOut className={classes.icons} onClick={props.handleSignout} />
                       </div>
                       <div className={classes.textContent} onClick={props.handleSignout}>
                           <span className={classes.headerText}>Log Out</span>
                           <span className={classes.smallDesc}>Sign-out of phone</span>
                       </div>
                   </div>
               </div>
           </div>  
       )
   }

   const handleBackArrow = () => {
       if (!showMainMenu) {
           setShowMainMenu(true);
           setShowAudio(false)
           setShowNetwork(false);
       } else {
           props.handleBackMenu()
       }
   }

   return(
       <>
           <div className={classes.flexContainer}>
               <ArrowBackIcon style={{color:'#CECFD5'}} onClick={handleBackArrow} />
               {!showMainMenu && <Typography variant="subtitle1" className={classes.subtitle}>Settings</Typography> }
           </div>
           {showMainMenu && renderMainMenu()}
           {showAudio && <MemoAudioDevices device={props.device} />  }          
           {showNetwork && <MemoNetworkSettings device={props.device} />  }
       </>        
   )
}

const MemoSettingsMenu = React.memo(SettingsMenu);
export default MemoSettingsMenu;