import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EventNoteIcon from '@mui/icons-material/EventNote';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Filter7Icon from '@mui/icons-material/Filter7';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import ImageIcon from '@mui/icons-material/Image';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { makeStyles } from '@mui/styles';
import { SystemUserResponse } from '../../../store/actions/attributes.action';
import { useState } from 'react';
import AttributeDialog from './AttributeDialog';
import { useDispatch } from 'react-redux';
import { loadAfterSave } from '../../../store/actions/customAttributes.action';
import {ReactComponent as Tableadd} from '../../../marketPlace/BusinessMarketPlace/PipeLineTab/Icons/Tableadd.svg';
import { LocationOn } from '@mui/icons-material';

export interface AttributesOptionProps {
    resources: SystemUserResponse[],
	onSaveChange: (customAttribute) => void;
}


const useStyles = makeStyles(theme => ({
    iconStyle: {
        marginRight: '10px'
    }
}));

export default function AttributeOptionsList(props:AttributesOptionProps) {
	const classes = useStyles({});
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedType, setSelectedType] = useState('');
	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState('');
	const dispatch = useDispatch();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event) => {			
		setAnchorEl(null);
	};

	const hanldeOpenOption = (event) => {
		const {value} = event.currentTarget.dataset;		
		setSelectedType(value);	
		setOpen(true);
	}

	const handleDialogClose = () => {
		setOpen(false);
		setAnchorEl(null);
		dispatch(loadAfterSave());

	}

	const renderAttributeDialog = () => {
		return <AttributeDialog
					title={'New'}
					handleCancel={handleDialogClose}
					type={selectedType}
					onSave={props.onSaveChange}
				/>
	}

	return (
		<div>
			<>
			<Button variant="outlined" aria-controls="simple-menu" aria-haspopup="true" style={{ height: '34px'}} onClick={handleClick}>
				<Tableadd style={{marginRight:4}} />Add Attributes
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				// getContentAnchorEl={null}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        		transformOrigin={{ vertical: "top",horizontal: "center" }}	
				onClose={handleClose}
			>
				<MenuItem data-value='String' onClick={hanldeOpenOption}><TextFormatIcon fontSize="small" className={classes.iconStyle} /> Text</MenuItem>
				<MenuItem data-value='Number' onClick={hanldeOpenOption}><Filter7Icon fontSize="small"  className={classes.iconStyle} /> Number</MenuItem>
				<MenuItem data-value='Boolean' onClick={hanldeOpenOption}><CheckBoxIcon fontSize="small" className={classes.iconStyle} />True/False</MenuItem>
				<MenuItem data-value='DateTime' onClick={hanldeOpenOption}><EventNoteIcon fontSize="small" className={classes.iconStyle} /> Date & Time</MenuItem>
				<MenuItem data-value='Date' onClick={hanldeOpenOption}><DateRangeIcon fontSize="small" className={classes.iconStyle} /> Date</MenuItem>
				<MenuItem data-value='Image' onClick={hanldeOpenOption} disabled><ImageIcon fontSize="small" className={classes.iconStyle} />Image</MenuItem>
				<MenuItem data-value='File' onClick={hanldeOpenOption} disabled><AttachFileIcon fontSize="small" className={classes.iconStyle} />File</MenuItem>
			</Menu>
			{open && renderAttributeDialog()}
			</>
		</div>
	);
}
