const actionNamePrefix = "SAVE_TIMER_OPTION";
export const SAVE_TIMER_OPTION = `${actionNamePrefix}`;

function saveTimerOption(option) {
    return {
        type: SAVE_TIMER_OPTION,
        option: option
    };
}

export const setTimerOption = (selectedOption) => (dispatch) => {
    dispatch(saveTimerOption(selectedOption));
}
