import { Device } from "@twilio/voice-sdk"
import { Dispatch } from "redux";
import { DEVICE_INIT_START, DEVICE_INIT_SUCCESS,DEVICE_INIT_ERROR } from "./actionTypes";
import { initDeviceListener } from "./listeners/initDeviceListeners";

export interface DeviceStartedAction {
    type: typeof DEVICE_INIT_START,
    data: any
}

export interface DeviceEndAction {
    type: typeof DEVICE_INIT_SUCCESS,
    data: Device
}

export interface DeviceErrorAction {
    type: typeof DEVICE_INIT_ERROR,
    data: string
}

export type DeviceInitActions = DeviceStartedAction| DeviceEndAction | DeviceErrorAction;

export const initPhoneDevice = (phoneToken, location) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: DEVICE_INIT_START
        })
        let device;
        try {
            device = new Device(phoneToken, { logLevel: 0 });

            if (location.ashburn === true || location.umatilla === true || location.saoPaulo === true) {
                var edge = [];
                if (location.ashburn === true) edge.push('ashburn');
                if (location.umatilla === true) edge.push('umatilla');
                if (location.saoPaulo === true) edge.push('sao-paulo');
                const options = {
                    edge: edge,
                    maxCallSignalingTimeoutMs: 30000,
                }
                device.updateOptions(options);
            }
            device.register();

        } catch (e) {
            dispatch({
                type: DEVICE_INIT_ERROR,
                data: JSON.stringify(e)
            })
        }
       
        console.log(device);

        dispatch({
            type: DEVICE_INIT_SUCCESS,
            data: device
        })
        initDeviceListener(device, dispatch);
    }

}