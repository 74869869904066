//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "STOPWATCH";
export const STOPWATCH = `${actionNamePrefix}`;
export const STOPWATCH_GET = `${actionNamePrefix}_GET`;
export const STOPWATCH_START = `${actionNamePrefix}_START`;
export const STOPWATCH_STOP = `${actionNamePrefix}_STOP`;
export const STOPWATCH_ERROR = `${actionNamePrefix}_ERROR`;

export interface StopwatchAction {
    type: typeof STOPWATCH;
    data: any
}

export interface StopwatchGetAction {
    type: typeof STOPWATCH_GET;
    data: any
}

export interface StopwatchStartAction {
    type: typeof STOPWATCH_START;
    data: any
}

export interface StopwatchStopAction {
    type: typeof STOPWATCH_STOP;
    data: any
}

export interface StopwatchErrorAction {
    type: typeof STOPWATCH_ERROR;
    data: any
}

export type StopwatchActions = StopwatchGetAction |
                                StopwatchStartAction |
                                StopwatchStopAction |
                                StopwatchErrorAction;

export const getStopwatch = () => async (dispatch) => {
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/UserProfile/GetStopwatch`);
        dispatch({
            type: STOPWATCH_GET,
            data: response.data
            })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: STOPWATCH_ERROR,
                error: errorMessage
            })
        }
    }
}

export const startStopwatch = () => async (dispatch) => {
    dispatch({ type: STOPWATCH });
    try {
        const response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/UserProfile/StartStopwatch`);
        dispatch({
            type: STOPWATCH_START,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: STOPWATCH_ERROR,
                error: errorMessage
            })
        }
    }
}

export const stopStopwatch = () => async (dispatch) => {
    dispatch({ type: STOPWATCH });
    try {
        const response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/UserProfile/StopStopwatch`);
        dispatch({
            type: STOPWATCH_STOP,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: STOPWATCH_ERROR,
                error: errorMessage
            })
        }
    }
}