import { ValueGetterParams } from "@ag-grid-community/core";
import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";

export default function DateRenderer(params: ValueGetterParams) {

	let colId = params.column.getColId();
	const [dateOnly, setDateOnly] = useState(params.data[colId]);
	useEffect(() => {
		if (params.data[colId] && params.data[colId] !== null) {
			const value = params.data[colId];
			setDateOnly(value.split("T")[0]);
		}
	}, [params.data[colId]])

	const handleChange = (e) => {
		if (params.node.groupData) return (<div></div>);
		let dateValue = e.target.value;
		setDateOnly(e.target.value);
		params.node.setDataValue(colId, dateValue);
	}
	
	return (
		<TextField
			name="dateField"
			type="date"
			onChange={handleChange}
			value={dateOnly}
		/>
	);

}
