import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Close } from '@mui/icons-material';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });
export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

export const DialogTitleCustom = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    //disableTypography
    return (
        <DialogTitle component="h6" className={classes.root} {...other}>
            {children}
            { onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        //color: (theme) => theme.palette.grey[500],
                    }}
                    size="large">
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});



export default function EditorDialogContainer(props) {
    return (
        <Dialog
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth={true}
            aria-labelledby="confirmation-dialog-title"
            open={true}
            id={props.id}>
            {props.isCustomTitle ?
                <DialogTitleCustom id={props.id} onClose={props.onClose}>
                    {props.title}
                </DialogTitleCustom>
                :
                <DialogTitle>
                    {props.title}
                </DialogTitle>
            }

            <DialogContent dividers>
                {props.error? <p style={{fontSize: '11px', color:'red'}}> {props.error} </p> : null}
                {props.validationError? <p style={{fontSize: '11px', color:'red'}}> {props.validationError} </p> : null}
                {props.content}
            </DialogContent>
            <DialogActions>
                {props.buttons}
            </DialogActions>
        </Dialog>
    );
}