//@ts-nocheck
import createReducer from "./createReducer";
import { LOAD_PROJECT_INVENTORY_FORECAST_START, LOAD_PROJECT_INVENTORY_FORECAST_SUCCESS, LOAD_PROJECT_INVENTORY_FORECAST_ERROR, CLEANUP_PROJECT_INVENTORY_FORECAST } from "../actions/projectForecastInventory.action";

const initialState = {
    data: [],
    state: null,
    error: null   
};

function loaded(state, action) {
    return {
        ...state,
        data: action.data,
        error: null,
        state: action.type        
    }
}

function loadStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

function loadError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}

function cleanUp(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}


export default createReducer(initialState, {
    [LOAD_PROJECT_INVENTORY_FORECAST_START]: loadStarted,
    [LOAD_PROJECT_INVENTORY_FORECAST_SUCCESS]: loaded,
    [LOAD_PROJECT_INVENTORY_FORECAST_ERROR]: loadError,
    [CLEANUP_PROJECT_INVENTORY_FORECAST]: cleanUp
}); 