//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_WORKFLOW";
export const LOAD_WORKFLOW_START = `${actionNamePrefix}_START`;
export const LOAD_WORKFLOW_ERROR = `${actionNamePrefix}_ERROR`;
export const LOAD_WORKFLOW_SUCCESS = `${actionNamePrefix}_SUCCESS`;


const actionNamePrefixSave = "SAVE_SELECTED";
export const SAVE_SELECTED_START = `${actionNamePrefixSave}_START`;
export const SAVE_SELECTED_ERROR = `${actionNamePrefixSave}_ERROR`;
export const SAVE_SELECTED_SUCCESS = `${actionNamePrefixSave}_SUCCESS`;

export interface LoadWorkflowStartAction {
    type: typeof LOAD_WORKFLOW_START;
}

export interface LoadWorkflowSuccessAction {
    type: typeof LOAD_WORKFLOW_SUCCESS;
    data: any
}

export interface LoadWorkflowErrorAction {
    type: typeof LOAD_WORKFLOW_ERROR;
    error: string;
}

export interface SaveWorkflowStart {
    type: typeof SAVE_SELECTED_START,
}

export interface SaveWorkflowSuccess {
    type: typeof SAVE_SELECTED_SUCCESS,
    selectedParams: data
}

export interface SaveWorkflowError {
    type: typeof SAVE_SELECTED_ERROR,
    error: string
}

export type workflowActions = LoadWorkflowStartAction |
                                LoadWorkflowSuccessAction|
                                LoadWorkflowErrorAction | SaveWorkflowStart | SaveWorkflowSuccess | SaveWorkflowError;

export const getWorkflows = () => async (dispatch) => {
    dispatch({type: LOAD_WORKFLOW_START})
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/Workflow`);
        dispatch({
            type: LOAD_WORKFLOW_SUCCESS,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_WORKFLOW_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveSelectedSWorkflow = (selectedData) => async (dispatch) => {
    dispatch({type: SAVE_SELECTED_START})
    try {
       const response = await Axios.post(`${AppSettings.ApiBaseUrl}/Workflow`, selectedData);
        dispatch({
            type: SAVE_SELECTED_SUCCESS,
            selectedParams: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: SAVE_SELECTED_ERROR,
                error: errorMessage
            })
        }
    }
}

