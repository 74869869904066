import createReducer from "./createReducer";
import {
    UPLOAD_WEEKLY_FORECAST_START, 
    UPLOAD_WEEKLY_FORECAST_SUCCESS, 
    UPLOAD_WEEKLY_FORECAST_ERROR,
    CLEAN_UP_WEEKLY_FORECAST
} from "../actions/forecast.action";

const initialState = {
    state: null,
    error: null,
    isError: false,
    data: null
};

function uploadedWeekly(state, action) {
    return {
        ...state,
        state: action.type,        
        data: action.data
    }
}

function uploadWeeklyStarted(state, action) {
    return {
        ...state,        
        state: action.type
    }
}

function uploadWeeklyError(state, action) {
    return {
        ...state,        
        error: action.error,
        isError: true,
        state: action.type
    }
}

function cleanUp(state, action) {
    return {
        ...state,
        data: null,
        error: null,
        state: null
    }
}


export default createReducer(initialState, {
    [UPLOAD_WEEKLY_FORECAST_START]: uploadWeeklyStarted,
    [UPLOAD_WEEKLY_FORECAST_SUCCESS]: uploadedWeekly,
    [UPLOAD_WEEKLY_FORECAST_ERROR]: uploadWeeklyError,
    [CLEAN_UP_WEEKLY_FORECAST]: cleanUp
}); 