//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "LOAD_FEEDS";
export const LOAD_FEEDS_START = `${actionNamePrefix}_START`;
export const LOAD_FEEDS_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_FEEDS_ERROR = `${actionNamePrefix}_ERROR`;

const actionNamePrefix1 = "UPDATE_ALERT";
export const UPDATE_ALERT_START = `${actionNamePrefix1}_START`;
export const UPDATE_ALERT_SUCCESS = `${actionNamePrefix1}_SUCCESS`;
export const UPDATE_ALERT_ERROR = `${actionNamePrefix1}_ERROR`;


function loadStart() {
    return {
        type: LOAD_FEEDS_START,
        data: []
    };
}

function loadFeeds(feeds) {
    return {
        type: LOAD_FEEDS_SUCCESS,
        data: feeds
    };
}

function loadFeedsError(error) {
    return {
        type: LOAD_FEEDS_ERROR,
        error
    }
}

export const getApplicationFeeds = () => async (dispatch) => {
    dispatch(loadStart);
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/alert`);
        dispatch(loadFeeds(response.data));
    } 
    catch (e) {
        dispatch(loadFeedsError(e.response.data.Message || e.message));
    }
}


function updateStart() {
    return {
        type: UPDATE_ALERT_START,
    };
}

function updateError(error) {
    return {
        type: UPDATE_ALERT_ERROR,
        error
    };
}

function updated(alertId) {
    return {
        type: UPDATE_ALERT_SUCCESS,
        updatedId: alertId
    };
}

export const updateForReview = (id) => async (dispatch) => {
    dispatch(updateStart);
    try {
        const response = await Axios.put(`${AppSettings.ApiBaseUrl}/api/alert?alertId=${id}`);
        dispatch(updated(id));
    } 
    catch (e) {
        dispatch(updateError(e.response.data.Message || e.message));
    }
}

