import { ValueGetterParams } from "@ag-grid-community/core";
import { TextField } from "@mui/material";
import React, { useState,useEffect } from "react";

export default function DateTimeRenderer(params: ValueGetterParams) {

	let colId = params.column.getColId();
	const [date, setDate] = useState(params.data[colId]);
	useEffect(() => {
		if (params.data[colId] && params.data[colId] !== null) {
			setDate(params.data[colId]);
		}
	}, [params.data[colId]])

	const handleChange = (e) => {
		if (params.node.groupData) return (<div></div>);
		let dateValue = e.target.value;
		setDate(e.target.value);
		params.node.setDataValue(colId, dateValue);
	}


	return (
		<TextField
			name="dateTimeField"
			type="datetime-local"
			onChange={handleChange}
			value={date}
		/>
	);

}
