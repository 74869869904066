import Axios, { AxiosError } from "axios";
import { AppSettings } from "../../appSettings";
import { SaveAttributeRequest } from "../../vendor/staff/attributes/mappers/convertToSaveAttributes";

export const LOAD_FILTERS = "LOAD_FILTERS";
export const LOAD_FILTERS_SUCCESS = "LOAD_FILTERS_SUCCESS";
export const LOAD_FILTERS_ERROR = "LOAD_FILTERS_ERROR";

export const SAVE_FILTERS = "SAVE_FILTERS";
export const SAVE_FILTERS_SUCCESS = "SAVE_FILTERS_SUCCESS";
export const SAVE_FILTERS_ERROR = "SAVE_FILTERS_ERROR";

export const LOAD_FILTERS_AFTERSAVE = "LOAD_FILTERS_AFTERSAVE";

export interface LoadFiltersError {
    id: string;
    message: string;
}

export interface SaveFiltersError {
    id: string;
    message: string;
}

export interface LoadFiltersAction {
    type: typeof LOAD_FILTERS;
}

export interface LoadFiltersSuccessAction {
    type: typeof LOAD_FILTERS_SUCCESS;
    filters: Array<any>
}

export interface LoadFiltersError {
    type: typeof LOAD_FILTERS_ERROR;
    error: string;
}

export interface SaveFiltersAction {
    type: typeof SAVE_FILTERS
}

export interface SaveFiltersSuccessAction {
    type: typeof SAVE_FILTERS_SUCCESS;
    message: string;
    filters:Array<any>;
}

export interface SaveFiltersError {
    type: typeof SAVE_FILTERS_ERROR;
    error: string;
}
export interface LoadFiltersAfterSave {
    type: typeof LOAD_FILTERS_AFTERSAVE;
}


export type FiltersAction = 
    LoadFiltersAction |
    LoadFiltersSuccessAction |
    LoadFiltersError | 
    SaveFiltersAction |
    LoadFiltersAfterSave |
    SaveFiltersSuccessAction |
    SaveFiltersError;

export const loadFilters = (filtersArray) => async (dispatch: any) => {
    dispatch({ type: LOAD_FILTERS });

    try {
        // const response = 
        // await Axios.get<Array<any>>(`${AppSettings.ApiRoleManagerUrl}/api/Filters`);
        
        dispatch({
            type: LOAD_FILTERS_SUCCESS,
            filters:filtersArray
            // filters: [
            //     {
            //         id: 'industry',
            //         selectedOptions: [1, 3, 5]
            //     },
            //     {
            //         id: 'geographic',
            //         selectedOptions: [2,4]
            //     },
            // ]
        });
    } catch (err) {

        //@ts-ignore
        if (err && err.response) {
            //@ts-ignore
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: LOAD_FILTERS_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveFilters = (saveFiltersData) => async (dispatch: any) => {
    dispatch({ type: SAVE_FILTERS });
    // console.log('praveen and save filters')
    try {
        // const response = await Axios.post(`${AppSettings.ApiRoleManagerUrl}/api/filters/`, saveFiltersData);
        dispatch({
            type: SAVE_FILTERS_SUCCESS,
            filters: saveFiltersData
        });
    } catch (err) {
        //@ts-ignore
        if (err && err.response) {
            //@ts-ignore
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: SAVE_FILTERS_ERROR,
                error: errorMessage
            })
        }
    }
}
// export const loadAfterSave = () => (dispatch: any) => {
//     dispatch({ type: LOAD_FILTERS_AFTERSAVE}); 
// }