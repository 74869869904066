//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "LOAD_JOBS";
export const LOAD_JOBS_START = `${actionNamePrefix}_START`;
export const LOAD_JOBS_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_JOBS_ERROR = `${actionNamePrefix}_ERROR`;

const loadJobDetailsPrefix = "LOAD_JOB_DETAILS";
export const LOAD_JOB_DETAILS_START = `${loadJobDetailsPrefix}_START`;

function loadStart() {
    return {
        type: LOAD_JOBS_START
    };
}

function loadJobs(jobs) {
    return {
        type: LOAD_JOBS_SUCCESS,
        data: jobs
    };
}

export const getAllJobs = (filter) => (dispatch) => {
    dispatch(loadStart);
    Axios.post(`${AppSettings.ApiBaseUrl}/job/search`, filter)
        .then(response => {
            dispatch(loadJobs(response.data));
        })
        .catch(error => {
            // dispatch(loadError(error));
        });
}

function loadJobDetailsStart(jobId){
    return {
        type: LOAD_JOB_DETAILS_START,
        data: jobId
    };
}

export const loadJobDetails = (jobId) => (dispatch) => {
    dispatch(loadJobDetailsStart(jobId));
}