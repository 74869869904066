//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";
import { LoadReportRequest } from "./dashboardReport.action";

const actionNamePrefix = "LOAD_DASHBOARD_PEOPLE_REPORT";
export const LOAD_DASHBOARD_PEOPLE_REPORT_START = `${actionNamePrefix}_START`;
export const LOAD_DASHBOARD_PEOPLE_REPORT_ERROR = `${actionNamePrefix}_ERROR`;
export const LOAD_DASHBOARD_PEOPLE_REPORT_SUCCESS = `${actionNamePrefix}_SUCCESS`;

export interface LoadDashboardPeopleReportStartAction {
    type: typeof LOAD_DASHBOARD_PEOPLE_REPORT_START;
}

export interface LoadDashboardPeopleReportSuccessAction {
    type: typeof LOAD_DASHBOARD_PEOPLE_REPORT_SUCCESS;
    data: any
}

export interface LoadDashboardPeopleReportErrorAction {
    type: typeof LOAD_DASHBOARD_PEOPLE_REPORT_ERROR;
    error: string;
}

export type dashboardPeopleReportActions = LoadDashboardPeopleReportStartAction |
                                LoadDashboardPeopleReportSuccessAction|
                                LoadDashboardPeopleReportErrorAction;

export const getDashboardPeopleReport = (request: LoadReportRequest) => async (dispatch) => {
    dispatch({type: LOAD_DASHBOARD_PEOPLE_REPORT_START})
    try {
        
        const response = await Axios.get(`${AppSettings.ApiSchedulerUrl}/api/reports/startDateInBtz/${request.startDate}/endDateInBtz/${request.endDate}/peopleData`);        
        dispatch({
            type: LOAD_DASHBOARD_PEOPLE_REPORT_SUCCESS,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_DASHBOARD_PEOPLE_REPORT_ERROR,
                error: errorMessage
            })
        }
    }
}
