import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Dialog, DialogContent,DialogTitle, DialogActions, Button } from '@mui/material';

interface IImageProps {
    onSaveImage: (imgUrl: string) => void;
    imgServer: string,
    
}

export default function ImageUpload( props:IImageProps ) {
    
    const imageMaxSize = 1000000000 // bytes
    const acceptedFileTypes = 'image/jpg, image/jpeg, image/gif, image/png';
    const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });

    const initialState = {
        imgSrc: null,
        imgSrcExt: null,
        open: null,
        selectedFile: null,
        isConfirmDisabled: false,
        scale: 1,
        position: { x: 0.5, y: 0.5 },
        rotate: 0,
        image: null
    };

    let data = {};
    let imgeditor = null;
    const setEditorRef = (editor) => imgeditor = editor;

    const [state, updateState] = useState(initialState);
    const setState = (data) => updateState(prevState => ({ ...prevState, ...data }));

    const extractImageFileExtensionFromBase64 = (base64Data) => {
        return base64Data.substring("data:image/".length, base64Data.indexOf(";base64"))
    }

    const handleScale = event => {
        const scale = parseFloat(event.target.value)
        setState({ scale: scale });
    }

    const handlePositionChange = position => {
        setState({ position: position });
    }

    const rotateImgLeft = () => {
        setState({ rotate: state.rotate - 90 })
    }

    const rotateImgRight = () => {
        setState({ rotate: state.rotate + 90 })
    }

    const verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0]
            const currentFileType = currentFile.type;
            const currentFileSize = currentFile.size;
            if (currentFileSize > imageMaxSize) {
                alert("This file is not allowed. " + currentFileSize + " bytes is too large");
                return false;                
            }
            if (!acceptedFileTypesArray.includes(currentFileType)) {
                alert("This file is not allowed. Only images with jpg/png/gif extensions are allowed.");
                return false;
            }
            return true;
        } 
    }

    const changeInputvalue = event => {
        event.target.value = '';
        setState(initialState);
    }

    const fileChangeHandler = event => {
        const files = event.target.files; 
        if (files && files.length > 0) {
            const isVerified = verifyFile(files);
            if (isVerified) {
                // imageBase64Data 
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    const myResult = myFileItemReader.result;
                    setState({ imgSrc: myResult, imgSrcExt: extractImageFileExtensionFromBase64(myResult) })
                }, false)

                myFileItemReader.readAsDataURL(currentFile);
                setState({open: true});
            }
        } else {
            setState({open: false});
        }
    }

    const imageResize = (image, width, height) => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, width, height);
        return canvas.toDataURL("image/jpg");
    }

    const dataURItoBlob = dataURI => {
        const binary = atob(dataURI.split(',')[1]);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/jpg' });
    };

    const createFormData = (dataToUpload, awsCreds) => {
        const file = dataToUpload;
        const form = new FormData();
      
        return form;
    }

    const handleCancel = () => {
        setState({ open: false });         
    }

    const handleUpload = async () => {
        const img = imgeditor.getImageScaledToCanvas().toDataURL();                
        setState({ open: false, imgSrc: img});
        props.onSaveImage(img);
    }

    const renderImageRender = () => {
        return (
            <>
                <div id="image-editor">
                    <AvatarEditor
                        image={state.imgSrc}
                        width={200}
                        height={200}
                        border={50}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={state.scale}
                        onPositionChange={handlePositionChange}
                        position={state.position}
                        ref={setEditorRef}
                        rotate={state.rotate}
                    />
                </div>
                <div id="image-editor-controls">
                    <div>
                        <label>Scale</label>
                        <input
                            name="scale"
                            type="range"
                            onChange={handleScale}
                            min="1"
                            max="2"
                            step="0.01"
                            defaultValue="1"
                        />
                    </div>
                    <div>
                        <label>Rotate</label>
                        <RotateLeftIcon onClick={rotateImgLeft} />
                        <RotateRightIcon onClick={rotateImgRight} />
                    </div>
                </div>
            </>
        );
    }

    const openDialog = () => {
        return (
                <div>
                    <Dialog
                        open={state.open}
                        onClose={handleCancel}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                    >				
                        <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>Edit Picture</DialogTitle>
                        <DialogContent>
                            {renderImageRender()}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel} color="primary">
                                Cancel
                              </Button>
                            <Button onClick={handleUpload} color="primary" autoFocus>
                                Ok
                              </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );        
    }

    return (
        <>
            <div>                
                <img src={state.imgSrc ? state.imgSrc : props.imgServer} height={200} width={200} />
            </div>
            {/* <label htmlFor="file-upload" className="upload-photo">Upload Photo</label> */}
            <input id="file-upload" type='file' multiple={false} onChange={fileChangeHandler} onClick={changeInputvalue} />
            {state.open && openDialog()}
        </>
    );
}

