import { BusinessResponse } from "../actions/administration.action";
import {
    BusinessAction,
    LoadBusinessStart,
    LoadBusinessSuccessAction,
    LoadBusinessError,
    LOAD_BUSINESS_START,
    LOAD_BUSINESS_SUCCESS,
    LOAD_BUSINESS_ERROR
} from "../actions/loggedInBusiness.action";
import createReducer from "./createReducer";

export interface BusinessState {
    state: string;
    error: string;
    data: BusinessResponse
}

const initialState: BusinessState = {
    state: null,
    error: null,
    data: null
}

function loadBusiness(state:BusinessState, action: LoadBusinessStart): BusinessState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadBusinessSuccess(state:BusinessState, action: LoadBusinessSuccessAction): BusinessState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadBusinessError(state:BusinessState, action: LoadBusinessError): BusinessState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const LoggedInBusinessReducer =
    (state: BusinessState, action: BusinessAction): BusinessState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_BUSINESS_START]: loadBusiness,
            [LOAD_BUSINESS_SUCCESS]: loadBusinessSuccess,
            [LOAD_BUSINESS_ERROR]: loadBusinessError
        });

        return fnUpdateState(state, action);
    }

export default LoggedInBusinessReducer;
