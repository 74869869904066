import React, { useEffect, useRef, useState } from "react";
import { AgGridReact, AgGridColumn } from "@ag-grid-community/react";
import { Button, Grid } from "@mui/material";
import { RichSelectModule, RangeSelectionModule, GridApi, RowNode, ClientSideRowModelModule, AllModules, ColDef } from "@ag-grid-enterprise/all-modules";
import { GetAttributesResponse, SAVE_ATTRIBUTES, SAVE_ATTRIBUTES_SUCCESS, SAVE_ATTRIBUTES_ERROR } from "../../../store/actions/attributes.action";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { RolesResponse } from "../../../store/actions/roles.action";
import { AttributeRowDef, convertToMapAttributes } from './mappers/convertToMapAttributes';
import AttributeOptionsList from "./AttributeOptionsList";
import { convertToSaveAttributes, convertToSaveCustomAttributes } from "./mappers/convertToSaveAttributes";
import OperationStatusIndicator from "../../../OperationStatusIndicator";
import FileUploadComponent from "./FileUploadComponent";
import CheckboxRenderer from "./CheckboxRenderer";
import DateTimeRenderer from "./DateTimeRenderer";
import DateRenderer from "./DateRenderer";
import NumberInputRenderer from "./NumberInputRenderer";
import UploadBtn from "./UploadBtn";
import UploadDialogComponent from "./UploadDialogComponent";
import { convertToMapCustomAttributes } from "./mappers/convertToMapCustomAttributes";
import Axios from "axios";
import { AppSettings } from "../../../appSettings";
import Delete from "@mui/icons-material/Delete";
import { round } from "../../../utils/utilsForRounding";
import { useDispatch } from "react-redux";
import { deleteCustomAttributes } from "../../../store/actions/customAttributes.action";
import AttributeDialog from "./AttributeDialog";
import { customComparator } from "../customComparator";

export interface NewAttribute {
    field: string,
    headerName: string,
    defaultValue: string,
    type: string
}

export interface AttributesGridProps {
    attributes: GetAttributesResponse[],
    customAttributes: any[],
    customAttributesValues: any[],
    roles: RolesResponse[],
    saveAttributes: any,
    saveState: string,
    errorState: string
}

export default function AttributesGrid(props: AttributesGridProps) {
    const RoleNameSupervisor = "Supervisor";
    const RoleNameManager = "Manager";
    const RoleNameAgent = "Agent";

    const gridApiRef = useRef<GridApi | null>(null);
    const [rowData, setRowData] = useState<AttributeRowDef[]>([]);
    const defaultColDef = { resizable: true, width: 120 };
    const [roleNames, setRoleNames] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [type, setType] = useState('');
    const gridModules = [AllModules, RichSelectModule, RangeSelectionModule, ClientSideRowModelModule];
    const [columns, setColumns] = useState<ColDef[]>([]);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (props.customAttributes) {
            const updatedCols = convertToMapCustomAttributesColumns(props.customAttributes);
            setColumns(updatedCols);
        }
    }, [props.customAttributes])

    const statusBar = {
        statusPanels: [
            {
                statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'center',
            },
            { statusPanel: 'agFilteredRowCountComponent' },
            { statusPanel: 'agSelectedRowCountComponent' },
            { statusPanel: 'agAggregationComponent' },
        ],
    }

    useEffect(() => {

        const roles = props.roles?.map(r => r.name);
        setRoleNames(roles);
        const rowItems = convertToMapAttributes(props.roles, props.attributes, props.customAttributes, props.customAttributesValues);        
        setRowData(rowItems);

    }, [props.attributes, props.roles, props.customAttributes, props.customAttributesValues])

    const frameworkComponents = {
        "fileRenderer": FileUploadComponent,
        "checkboxRenderer": CheckboxRenderer,
        "dateTimeRenderer": DateTimeRenderer,
        "dateRenderer": DateRenderer,
        "numberRender": NumberInputRenderer,
        "imageBtn": UploadBtn
    };

    const onGridReady = (params: any) => {
        gridApiRef.current = params.api;
    }


    const convertToMapCustomAttributesColumns = (customAttributes: Array<any>) => {
        const mappedColumns = Array<ColDef>();
        customAttributes.forEach((eachAttribute) => {
            var eachMappedCol = colDefMapper(eachAttribute);
            mappedColumns.push(eachMappedCol.eachCol);
        })
        return mappedColumns;
    }

    const colDefMapper = (eachAttribute) => {
        const eachCol: ColDef = {
            field: eachAttribute.id.toString(),
            headerName: eachAttribute.name,
            editable: true,
            resizable: true,
            headerTooltip: eachAttribute.hoverText ? eachAttribute.hoverText : 'Helper Text comes here'           
        }
        let defaultValue;
        if (eachAttribute.attributeTypeName === 'String') {
            defaultValue = eachAttribute.stringDefaultValue;
        }
        if (eachAttribute.attributeTypeName === 'Number') {
            //eachCol.cellRenderer = 'numberRender'
            defaultValue = eachAttribute.numberDefaultValue;
            eachCol.valueSetter = function (params) {
                const parsedValue = parseFloat(params.newValue);
                if (isNaN(parsedValue) || parsedValue <= 0) {
                    return false;
                }
        
                params.data[eachAttribute.id.toString()] = parsedValue;
                return true;
            };
            
            
        }
        if (eachAttribute.attributeTypeName === 'DateTime') {
            eachCol.cellRenderer = 'dateTimeRenderer';
            eachCol.width = 250;
            defaultValue = eachAttribute.dateTimeDefaultValue;
        }
        if (eachAttribute.attributeTypeName === 'Date') {
            eachCol.cellRenderer = 'dateRenderer';
            eachCol.width = 250;
            defaultValue = eachAttribute.dateDefaultValue;
        }
        if (eachAttribute.attributeTypeName === 'Boolean') {
            //eachCol.headerCheckboxSelection = true;
            eachCol.cellRenderer = 'checkboxRenderer'
            defaultValue = eachAttribute.booleanDefaultValue;
        }
        if (eachAttribute.attributeTypeName === 'Image') {
            eachCol.cellRenderer = 'imageBtn';
            eachCol.cellRendererParams = {
                clicked: function (rowData) {
                    setSelectedRow(rowData);
                    setType('image');
                    setOpen(true);
                }
            }
        }
        if (eachAttribute.attributeTypeName === 'File') {
            eachCol.cellRenderer = 'imageBtn';
            eachCol.cellRendererParams = {
                clicked: function (rowData) {
                    setSelectedRow(rowData);
                    setType('file');
                    setOpen(true);
                }
            }
        }

        return { eachCol, defaultValue };
    }

    function getMainMenuItems(params) {
        // you don't need to switch, we switch below to just demonstrate some different options
        // you have on how to build up the menu to return
        switch (params.column.getId()) {
            // return the defaults, put add some extra items at the end
            case 'systemUserName':
            case 'systemEmailId':
            case 'role':
            case 'supervisorUserName':
            case 'managerUserName':                
                return params.defaultItems;
            default:
                var menuItems = params.defaultItems.slice(0);
                menuItems.push({
                    name: 'Edit Column',
                    action: function () {
                        setEditOpen(true);
                        setSelectedColumn(params.column);
                    },
                    // icon: function () {
                    //     return '<i class="fa fa-times"></i>'
                    // }
                    // '<img src="https://www.ag-grid.com/example-assets/lab.png" style="width: 14px;" />',
                });
                menuItems.push({
                    name: 'Delete Column',
                    action: function () {
                        const colId = params.column.getId();
                        const selectedAttribute = props.customAttributes.find(x => x.id == colId);
                        if (selectedAttribute) {
                            const value = window.confirm('Are you sure you want to delete column?');
                            if (value) {
                                console.log("call delete of column with id-",params.column.getId());
                                dispatch(deleteCustomAttributes(selectedAttribute));
                            }
                        }
                        
                    },
                    // icon:
                    // '<img src="https://www.ag-grid.com/example-assets/lab.png" style="width: 14px;" />',
                });
                return menuItems;
        }
    }

    const onEditSave = () => {
        console.log('on save click')
    }

    const openEditAttributeDialog = () => {
        //@ts-ignore
        if (selectedColumn && selectedColumn.colId) {
            const custom = props.customAttributes;
            //@ts-ignore
            const colId = selectedColumn.colId;
            const selectedAttribute = custom.find(x => x.id == colId);
        
        return <AttributeDialog
                    title={'Edit'}
                    handleCancel={cancelEditDialog}
                    type={selectedAttribute.attributeTypeName}
                    onSave={onEditSave}
                    editAttribute={selectedAttribute}
                />
        }
    }


    const getRoleNameCellEditorParams = () => {
        return {
            values: roleNames
        }
    }

    const getUsersByRole = (role: string): any => {
        const usersWithRole: string[] = [];
        if (gridApiRef.current) {
            gridApiRef.current.forEachNode((rowNode: RowNode) => {
                const rowData = rowNode.data as AttributeRowDef;
                if (rowData.role === role)
                    usersWithRole.push(rowData.systemUserName);
            });
        }
        return { values: usersWithRole };
    }

    const getSupervisorCellEditor = (params: any) => {
        const rowData = params.data as AttributeRowDef;

        if (rowData && rowData.role === RoleNameAgent) {
            return {
                component: "agRichSelectCellEditor"
            };
        }

        return null;
    }

    const getManagerCellEditor = (params: any) => {
        const rowData = params.data as AttributeRowDef;

        if (rowData && rowData.role === RoleNameSupervisor) {
            return {
                component: "agRichSelectCellEditor"
            };
        }

        return null;
    }

    const canHaveSupervisor = (params: any) => {
        const rowData = params.data as AttributeRowDef;
        return rowData && rowData.role === RoleNameAgent;
    }

    const canHaveManager = (params: any) => {
        const rowData = params.data as AttributeRowDef;
        return rowData && rowData.role === RoleNameSupervisor;
    }

    const getManagerForSupervisor = (supervisorUserName) => {
        let manager = '';
        if (gridApiRef.current) {
            gridApiRef.current.forEachNode((rowNode: RowNode) => {
                const rowData = rowNode.data as AttributeRowDef;
                if (rowData.systemUserName === supervisorUserName) {
                    manager = rowData.managerUserName;
                    return manager;
                }
            })
        }        
        return manager;
    }

    const onSelectedRoleChanged = (params: any) => {
        const newRole: string = params.newValue;
        const rowDataOfChangedRole = params.data as AttributeRowDef;
        const gridApi: GridApi = params.api as GridApi;
        const rowNodesToRefresh: Array<RowNode> = [];
        gridApi.forEachNode((rowNode: RowNode) => {
            const eachRowData = rowNode.data as AttributeRowDef;
            const currentRow = rowDataOfChangedRole.systemUserName === eachRowData.systemUserName;

            //Clear the values in supervisor or manager if the role is changed.
            if (currentRow &&
                ((newRole !== RoleNameSupervisor && rowDataOfChangedRole.supervisorUserName !== null)
                    || (newRole !== RoleNameManager && rowDataOfChangedRole.managerUserName !== null))) {

                eachRowData.managerUserName = null;
                eachRowData.supervisorUserName = null;
                rowNodesToRefresh.push(rowNode);
            }

            if (isUserSupervisor(rowDataOfChangedRole.systemUserName, eachRowData) &&
                newRole !== RoleNameSupervisor) {
                eachRowData.supervisorUserName = null;
                eachRowData.managerUserName = null;
                rowNodesToRefresh.push(rowNode);
            }

            else if (isUserManager(rowDataOfChangedRole.systemUserName, eachRowData) &&
                newRole !== RoleNameManager) {
                eachRowData.managerUserName = null;
                rowNodesToRefresh.push(rowNode);
            }
        });
        // console.log(rowNodesToRefresh);
        // console.info(`Refreshing ${rowNodesToRefresh.length} rows`);
        gridApi.refreshRows(rowNodesToRefresh);
    }

    const onSupervisorSelected = (params: any) => {
        const rowDataOfChangedSupervisor = params.data as AttributeRowDef;
        const rowNodesToRefresh: Array<RowNode> = [];
        const gridApi: GridApi = params.api as GridApi;
        if (gridApiRef.current) {
            gridApiRef.current.forEachNode((rowNode: RowNode) => {
                const rowData = rowNode.data as AttributeRowDef;
                if (rowData.systemUserName === rowDataOfChangedSupervisor.systemUserName) {
                    const managerName = getManagerForSupervisor(rowDataOfChangedSupervisor.supervisorUserName);
                    rowData.managerUserName = managerName;
                    rowNodesToRefresh.push(rowNode);
                }
            });
        }
        

        gridApi.refreshRows(rowNodesToRefresh);
    }

    const onAddAttribute = (custom: NewAttribute) => {
        const updatedCols = [...columns];

        if (custom !== null) {
            const newCustomAttribute = colDefMapper(custom);
            updatedCols.push(newCustomAttribute.eachCol);
            setColumns(updatedCols);

            const rowNodesToRefresh: Array<RowNode> = [];
            if (gridApiRef.current) {
                gridApiRef.current.forEachNode((rowNode: RowNode) => {
                    const rowData = rowNode.data;
                    rowData[newCustomAttribute.eachCol.field] = newCustomAttribute.defaultValue;
                    rowNodesToRefresh.push(rowData);
                });
                gridApiRef.current.refreshRows(rowNodesToRefresh);
            }            
        }
    }

    const isUserSupervisor = (userName: string, rowData: AttributeRowDef) =>
        rowData.supervisorUserName === userName;

    const isUserManager = (userName: string, rowData: AttributeRowDef) =>
        rowData.managerUserName === userName;

    const isAssigned = (userName: string) => {
        //Check if the userName is present in either manager or supervisor
        const isPresent = rowData.filter(rD => rD.supervisorUserName === userName || rD.managerUserName === userName);
        return isPresent;
    }

    const saveChanges = () => {
        const saveData = convertToSaveAttributes(rowData, props.roles, props.attributes);
        const saveAttributesData = convertToSaveCustomAttributes(rowData, props.customAttributes);
        props.saveAttributes(saveData, saveAttributesData);
    }

    const cancelDialog = () => setOpen(false);
    const cancelEditDialog = () => setEditOpen(false);

    const uploadDialogRender = () => {
        return (
            <UploadDialogComponent
                selected={selectedRow}
                cancelEditor={cancelDialog}
                type={type}
            />
        )
    }

    return (<div>
        <div>
            <Grid container spacing={3}>
                <Grid item md={12} sm={12}>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button variant="contained" color="primary" onClick={saveChanges}>
                                Save Changes
                            </Button>&nbsp;&nbsp;
                            <AttributeOptionsList
                                resources={props.attributes && props.attributes.map(a => a.systemUser)}
                                onSaveChange={onAddAttribute}
                            />
                            <OperationStatusIndicator
                                inProgress={props.saveState === SAVE_ATTRIBUTES}
                                isError={props.saveState === SAVE_ATTRIBUTES_ERROR}
                                isSuccess={props.saveState === SAVE_ATTRIBUTES_SUCCESS}
                                errorMessage={props.errorState}
                                successMessage="Attributes saved successfully" />
                        </div>
                    </div>
                </Grid>
                <Grid item md={12} sm={12}>
                    <div style={{ width: "auto", height: "100%" }}>
                        <div
                            id="gridAttributes"
                            style={{
                                height: "100%",
                                width: "auto",
                            }}
                            className="ag-theme-balham"
                        >
                            <AgGridReact
                                // @ts-ignore
                                modules={AllModules}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                                getMainMenuItems={getMainMenuItems}
                                rowData={rowData}
                                statusBar={statusBar}
                                enableFillHandle={true}
                                fillHandleDirection="y"
                                enableRangeSelection={true}
                                frameworkComponents={frameworkComponents}                                
                                domLayout='autoHeight'                                
                            >

                                <AgGridColumn
                                    field="systemUserName"
                                    headerName="User"
                                    sortable={true}
                                    filter='agTextColumnFilter'
                                    floatingFilter={true}
                                    comparator={customComparator}
                                />

                                <AgGridColumn
                                    field="systemEmailId"
                                    headerName="Email Id"
                                    sortable={true}
                                    filter='agTextColumnFilter'
                                    floatingFilter={true}
                                    comparator={customComparator}
                                />

                                <AgGridColumn
                                    field="role"
                                    headerName="Role"
                                    cellEditor="agRichSelectCellEditor"
                                    editable={true}
                                    resizable={true}
                                    //filter='agTextColumnFilter'
                                    valueSetter={(params) => {
                                        if (params.oldValue === RoleNameSupervisor || params.oldValue === RoleNameManager) {
                                            const isAssignedRole = isAssigned(params.data.systemUserName);
                                            if (isAssignedRole && isAssignedRole.length > 0) {
                                                const result = window.confirm("You have being assigned " + params.oldValue + " to users. If you continue, deletion of assignments will occur. Are you sure you want to change role?");
                                                if (result === true) {
                                                    params.data['role'] = params.newValue;
                                                }
                                            } else {
                                                params.data['role'] = params.newValue;
                                            }
                                        } else {
                                            params.data['role'] = params.newValue;
                                            return true;
                                        }

                                    }}
                                    onCellValueChanged={onSelectedRoleChanged}
                                    cellEditorParams={getRoleNameCellEditorParams()}
                                />

                                <AgGridColumn
                                    field="supervisorUserName"
                                    headerName="Supervisor"
                                    editable={canHaveSupervisor}
                                    resizable={true}
                                    //filter='agTextColumnFilter'                                    
                                    cellEditorParams={() => getUsersByRole(RoleNameSupervisor)}
                                    onCellValueChanged={onSupervisorSelected}
                                    cellEditorSelector={getSupervisorCellEditor} />

                                <AgGridColumn
                                    field="managerUserName"
                                    headerName="Manager"
                                    resizable={true}
                                   // filter='agTextColumnFilter'
                                    editable={canHaveManager}
                                    cellEditorParams={() => getUsersByRole(RoleNameManager)}
                                    cellEditorSelector={getManagerCellEditor} />

                                {columns && columns.map(column => (
                                    <AgGridColumn {...column} 
                                        key={column.field} 
                                        menuTabs={['generalMenuTab','filterMenuTab', 'columnsMenuTab']}                                         
                                        //headerComponentParams={{ template: 'arrow Icon'} }
                                    />
                                ))}

                            </AgGridReact>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {open && uploadDialogRender()}
            {editOpen && openEditAttributeDialog()}
        </div>
    </div>)
}