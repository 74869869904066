import { Mail } from '@mui/icons-material';
import { Badge, ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import * as signalR from "@microsoft/signalr";
import React, { useEffect, useState } from 'react';
import Popper from '@mui/material/Popper';
import { AppSettings } from '../../appSettings';
import { useAuth0 } from '../../auth0/react-auth0-wrapper';
import { Toast } from 'bryntum-scheduler';


function Messages(props) {
    const [messages, setMessages] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    //@ts-ignore
    const { userAccessToken } = useAuth0();

    useEffect(() => {
        const connectionSettings: signalR.IHttpConnectionOptions = {};
        connectionSettings.skipNegotiation = true;
        connectionSettings.transport = signalR.HttpTransportType.WebSockets;
        connectionSettings.accessTokenFactory = () => userAccessToken;

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${AppSettings.ForecastApiWSBaseUrl}/userMessages`,
                        connectionSettings,
                        )
            .configureLogging(signalR.LogLevel.Information)
            .build();
    
        connection
            .on("Message", (message) => {
                console.info(`Message received ${message.message}`);
                
                setMessages([...messages, message]);

                Toast.show({
                    html: `Message received ${message.message}`,
                    color: 'b-red',
                    style: 'color:white',
                    timeout: 10000
                });
            });        

        connection.start().then(() => {
            console.info("Connection established");
        });

        return function cleanup() {
            connection.stop().then(() => {
                console.info("Connection stopped");
            })
        };
    }, [])

    const getNumberOfNew = () => {
        return messages.filter(x => x.new).length;
    }

    const showMessages = () => {
        // TODO navigate to messages
    }

    const dismissAll = () => {
        const newArr = [...messages].map(x => { return {...x, new: false}} );
        setMessages(newArr);

        // TODO update on server?
    }
    const handleClickaway = () =>{
        if (showMenu){
            setShowMenu(false)
            setAnchorEl(null)
        }
    }

    const getMenu = () => {
        return (
            <Popper open={showMenu} anchorEl={anchorEl} transition
            disablePortal={true}
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClickaway}>
                                <MenuList>
                                    {messages.map(x => {
                                        return <MenuItem>{x.message}</MenuItem>
                                    })}
                                    {<Divider />}
                                    {<MenuItem onClick={showMessages}>Show all messages</MenuItem>}
                                    {<Divider />}
                                    {messages?.length > 0 && <MenuItem onClick={dismissAll}>Dismiss all</MenuItem>}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        )
    }

    const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {        
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setShowMenu(!showMenu);
    }

    return <>
            <IconButton
                onClick={toggleMenu}
                aria-label={"show " + getNumberOfNew() + " new messages"}
                color="inherit"
                size="large">
                <Badge badgeContent={getNumberOfNew()} color="secondary">
                    <Mail />
                </Badge>
            </IconButton>
            {showMenu && getMenu()}
        </>;
    
}

export default Messages;
