import React, { useEffect, useState } from 'react';
import {
    Theme,
    Table,
    TableRow,
    TableCell,
    TableContainer,
    Button,
    TableBody,
    CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Axios from 'axios';
import { AppSettings } from '../appSettings';
import { ReactComponent as PhoneEnd } from './Icons/PhoneEnd.svg';
import moment from 'moment';
import { getDuration } from '../vendor/staff/manageAgentSchedule/util';
import CallTimer from './CallTimer';
import CallStates from './CallStates';
import { useSelector } from 'react-redux';
import { AppState } from '../store/reducers';

interface PhoneWrapupTaskProps {
    callerInfo: any,
    handleWrapUp: () => void;
    attributes: any
    task: boolean,
    taskDetail?: any,
    handleSelectedTask: (taskId) => void;
    selectedTask?: any
    worker: any,
    isWrapUp: boolean,
    changeCallState: (callState: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableRoot: {
            padding: 0,
            border: '1px solid #373F51'
        },
        tableRow: {
            border: '1px solid #373F51'
        },
        tableIcon: {
            height: 30,
            background: '#949CB2',
            textAlign: 'center',
            width: 30,
            border: 'none'
        },
        tableContentCol: {
            display: 'flex !important',
            fontSize: 14,
            border: 'none',
            padding: 0,
            "&:hover": {
                backgroundColor: "#373F51",
            },
        },
        spanCol: {
            color: '#A6A6A6',
            display: 'flex',
            alignItems: 'baseline',
            fontSize: '10px'
        },
        callIcons: {
            marginLeft: 10,
            cursor: 'pointer',
            padding: '13px'
        }

    })
);

export default function PhoneWrapupTasks(props: PhoneWrapupTaskProps) {
    const [isClicked, setIsClicked] = useState(false);
    const classes = useStyles();
    const activeCallsState = useSelector((state: AppState) => state.activeCalls);
    
    useEffect(() => {
        if (props.selectedTask && props.selectedTask.hasOwnProperty("wrapupClicked")) {
            if (props.selectedTask.wrapupClicked === true) setIsClicked(true);
        }        
    }, [props.selectedTask])

    const handleClick = () => {
        if (props.taskDetail) {
            console.log("Selected task on click wrap", props.taskDetail)
            props.handleSelectedTask(props.taskDetail.sid);
        }
    }

    const handleTaskWrapup = async () => {
        setIsClicked(true);
        const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/WrapUp`);
        if (response && response.data) {
            const entries = response.data.entries;
            if (entries[0].eventId != null)
                console.log('Success')
        }
        props.changeCallState(CallStates.COMPLETEWRAPUP);
        props.handleWrapUp();
    }

    const handleOutboundWrapup = async () => {
        if (props.worker !== null) {
            const taskSid = props.taskDetail.sid;
            props.worker.completeTask(taskSid,
                function (error, completedTask) {
                    if (error) {
                        console.log(error.code);
                        console.log(error.message);
                        return;
                    }
                    console.log("Completed Task: " + completedTask.assignmentStatus);
                }
            );
        }
    }

    const renderTableRow = () => {
        let to = ''; let from = ''; let direction = ''; let queueName = ''; let directionText = ''; let agentName = '';
        let assignmentStatus = "";
        if (props.taskDetail) {
            assignmentStatus = props.taskDetail.assignmentStatus;
        }
        if (props.attributes) {
            agentName = props.attributes.Agent;
            to = props.attributes.direction === 'outbound' ? props.attributes.outbound_to : props.attributes.to
            from = props.attributes.direction === 'outbound' ? props.attributes.CustomerPhone : props.attributes.from
            direction = props.attributes.direction;
            queueName = props.attributes.direction === 'outbound' ? props.attributes.campaign : props.attributes.Campaigns;
            directionText = props.attributes.direction === 'outbound' ? "Oubound call from" : "Incoming call to";
        }

        const currentTime = moment(Date.now()).utc().format();
        const updatedTime = moment(props.taskDetail.dateUpdated).utc().format();
        const duration = getDuration(updatedTime, currentTime);
        let durationMS = duration.asMilliseconds();
        durationMS = durationMS < 0 ? 0 : durationMS;

        return (
            <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableIcon}>
                    <PhoneEnd />
                </TableCell>
                <TableCell className={classes.tableContentCol} {...(props.selectedTask && props.selectedTask.sid === props.taskDetail.sid && { style: { backgroundColor: "#373F51" } })}>
                    <>
                        <div style={{ width: '65%', paddingLeft: '5px' }} onClick={handleClick}>
                            {agentName !== ' ' && <span className={classes.spanCol} style={{ fontSize: '12px' }}> {agentName} <br /></span>}
                            <span className={classes.spanCol} style={{ fontSize: '12px' }}>
                                {from} <br />{direction}
                            </span>
                            <span className={classes.spanCol}><span onClick={handleClick}>WrapUp | <CallTimer timer={durationMS} />  </span></span>
                        </div>
                        <div style={{ alignSelf: 'center', marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                            <div className={classes.callIcons}>
                                {(isClicked) && <CircularProgress style={{ color: '#ffffff' }} />}
                                {(props.taskDetail && assignmentStatus === "wrapping" && (direction === "inbound" || direction === "inbound-transfer" || direction === "inbound-conference") && !props.isWrapUp && !isClicked) && <Button style={{ fontSize: '12px', padding: '5px' }} variant="contained" color="primary" onClick={handleTaskWrapup}>End Wrapup</Button>}
                                {(props.taskDetail && assignmentStatus === "wrapping" && direction === "outbound" && !props.isWrapUp && !isClicked) && <Button style={{ fontSize: '12px', padding: '5px' }} variant="contained" color="primary" onClick={handleOutboundWrapup}>End Outbound Wrapup</Button>}
                            </div>

                        </div>
                    </>
                </TableCell>
            </TableRow>
        )
    }

    return (
        props.taskDetail !== null && renderTableRow()
    );
}