//@ts-nocheck
import React, { useEffect, useState } from 'react';
import './App.css';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Route from './layout/Route';
import { BrowserRouter } from "react-router-dom";
import moment from "moment-timezone";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { AppSettings } from './appSettings';
import { Decimal } from "decimal.js";
import { useDispatch, useSelector } from 'react-redux';
import { stopPhoneLogging } from './store/actions/statesLog.action';
import { useAuth0 } from './auth0/react-auth0-wrapper';
import { AppState } from './store/reducers';
import { loadBusiness, LOAD_BUSINESS_SUCCESS } from './store/actions/loggedInBusiness.action';

LicenseManager.setLicenseKey(AppSettings.AGGridLicenseKey);

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: useTheme().spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	appBar: {
		position: "static",
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
	},
	content: {
		marginTop: "60px",
		marginLeft: `calc(${drawerWidth}px + 10px)`
	}
}));

const theme = createTheme({
	palette: {
		primary: {
			main: '#0A125D'
		},
		secondary: {
			main: '#276BF0'
		},
	},
	typography: {
		// Tell Material-UI what's the font-size on the html element is.
		htmlFontSize: 18
	}
});

function App() {

	const dispatch = useDispatch();	
	const { isAuthenticated } = useAuth0();
	const classes = useStyles();	
	Decimal.set({ rounding: 0, precision: 2 })
	const d = new Decimal(2.2167);
	console.log(d.toDecimalPlaces(2, Decimal.ROUND_UP).toNumber());
	const basename = process.env.NODE_ENV === 'development' ? '/' : ("" || '/');
	console.log(process.env.REACT_APP_API_BASE_URL);
	moment.tz.setDefault("America/New_York");
	const browserTimezone = moment.tz.guess();	
	const loggedInBusiness = useSelector((state:AppState) => state.loggedInBusiness);

	useEffect(() => {		
		const unloadCallback = (event) => {
		  event.preventDefault();
		  dispatch(stopPhoneLogging(browserTimezone, "Agent Disconnected", true, true, true), true)
		  return (event.returnValue = 'Closing');		
		};

		const handleUnload = () => {
			dispatch(stopPhoneLogging(browserTimezone, "Agent Disconnected", true, false, true))
			console.log("Unload was called")
		}	
	  
		window.addEventListener("beforeunload", unloadCallback,true);
		window.addEventListener("unload", handleUnload,true);
		return () => {
			window.removeEventListener("beforeunload", unloadCallback,true);
		 	window.removeEventListener("unload", handleUnload,true);
		}
	}, []);

	useEffect(() => {
		if (isAuthenticated && loggedInBusiness.state !== LOAD_BUSINESS_SUCCESS) {
			dispatch(loadBusiness());
		}
	},[isAuthenticated])
	
	useEffect(() => {
		if (loggedInBusiness.state === LOAD_BUSINESS_SUCCESS) {
			document.title =  loggedInBusiness.data.legalName;
		}
	},[loggedInBusiness.state])
	
	return (
        <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <CssBaseline />
                    <BrowserRouter basename={basename}>
                        <Route />
                    </BrowserRouter>
                </div>
        </ThemeProvider>
    );
}

export default App;
