import { createStore, applyMiddleware, compose } from "redux";
//import rootReducer from "./reducers/createReducer";
import reducers from './reducers';
import middlewares from './middleware/middleware';

const composeEnhancers = (process.env.NODE_ENV === 'development' ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : undefined) || compose;

const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(
        ...middlewares
    )),
);

export default store;