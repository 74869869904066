import createReducer from "./createReducer";
import {
    LOAD_FORM_START,
    LOAD_FORM_SUCCESS,
    LOAD_FORM_ERROR,
    formActions,
    LoadFormStartAction,
    LoadFormSuccessAction,
    LoadFormErrorAction,
    SaveFormStart,
    SaveFormSuccess,
    SaveFormError,
    SAVE_SELECTED_FORM_START,
    SAVE_SELECTED_FORM_ERROR,
    SAVE_SELECTED_FORM_SUCCESS
} from '../actions/forms.action'

export interface FormState {
    state: string;
    error: string;
    data: any;
}

const initialState: FormState = {
    state: null,
    error: null,
    data: []
}

function loadFormStart(state:FormState, action: LoadFormStartAction): FormState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadFormSuccess(state:FormState, action: LoadFormSuccessAction): FormState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadFormError(state:FormState, action: LoadFormErrorAction): FormState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function saveFormStart(state:FormState, action: SaveFormStart): FormState {    
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function saveFormSuccess(state:FormState, action: SaveFormSuccess): FormState {
    const elementsIndex = state.data.findIndex(gs => gs.id == action.selectedParams.id);
    const data = [...state.data];
    if (elementsIndex >= 0) {
        data[elementsIndex] = {        
            ...action.selectedParams
        }
    } else {
        data.push(action.selectedParams);
    }
    
    return {
        ...state,
        data: [
            ...data
        ],
        state: action.type,
        error: null,        
    }
}

function saveFormError(state:FormState, action: SaveFormError): FormState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const FormSelectedReducer =
    (state: FormState, action: formActions): FormState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_FORM_START]: loadFormStart,
            [LOAD_FORM_SUCCESS]: loadFormSuccess,
            [LOAD_FORM_ERROR]: loadFormError,
            [SAVE_SELECTED_FORM_START] : saveFormStart,
            [SAVE_SELECTED_FORM_SUCCESS]: saveFormSuccess,
            [SAVE_SELECTED_FORM_ERROR]: saveFormError
        });

        return fnUpdateState(state, action);
    }

export default FormSelectedReducer;