//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "LOAD_MY_CAMPAIGNS";
export const LOAD_MY_CAMPAIGNS_START = `${actionNamePrefix}_START`;
export const LOAD_MY_CAMPAIGNS_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_MY_CAMPAIGNS_ERROR = `${actionNamePrefix}_ERROR`;

function loadStart() {
    return {
        type: LOAD_MY_CAMPAIGNS_START
    };
}

function loadSuccess(campaigns) {
    return {
        type: LOAD_MY_CAMPAIGNS_SUCCESS,
        data: campaigns
    };
}

function loadError(error) {
    return {
        type: LOAD_MY_CAMPAIGNS_ERROR,
        error
    }
}

export const getMyCampaigns = () => (dispatch) => {
    dispatch(loadStart);
    Axios.get(`${AppSettings.ApiBaseUrl}/campaigns`)
        .then(response => {
            dispatch(loadSuccess(response.data));
        })
        .catch(error => {
            dispatch(loadError(error));
        });
}
