//@ts-nocheck
import createReducer from "./createReducer";
import {
    LOAD_INVENTORY_ADJUSTED_FORECAST_START,
    LOAD_INVENTORY_ADJUSTED_FORECAST_SUCCESS,
    LOAD_INVENTORY_ADJUSTED_FORECAST_ERROR,
    UPDATE_ADJUSTED_INVENTORY_FORECAST_START,
    UPDATE_ADJUSTED_INVENTORY_FORECAST_SUCCESS,
    UPDATE_ADJUSTED_INVENTORY_FORECAST_ERROR,
    CLEAN_UP_ADJUSTED_INVENTORY_FORECAST
}
from "../actions/inventoryAdjustedForecast.action";
import InventoryAdjustedForecast from "../../vendor/forecast/models/InventoryAdjustedForecast";

interface IState {
    data: Array<InventoryAdjustedForecast>;
    state: string,
    error: string,
    saveState: string,
    saveError: string
}

const initialState: IState = {
    data : [],
    state: null,
    error: null
};

function loaded(state, action) {
    return {
        ...state,
        data: action.data,
        error: null,
        state: action.type
    }
}

function loadStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

function loadError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}

function updateStarted(state, action) {
    return {
        ...state,
        saveState: action.type
    }
}

function updateSuccess(state, action) {
    return {
        ...state,
        saveState: action.type
    }
}

function updateError(state, action) {
    return {
        ...state,
        saveState: action.type,
        saveError: action.error
    }
}

function cleanUp(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

export default createReducer(initialState, {
    [LOAD_INVENTORY_ADJUSTED_FORECAST_START]: loadStarted,
    [LOAD_INVENTORY_ADJUSTED_FORECAST_SUCCESS]: loaded,
    [LOAD_INVENTORY_ADJUSTED_FORECAST_ERROR]: loadError,

    [UPDATE_ADJUSTED_INVENTORY_FORECAST_START]: updateStarted,
    [UPDATE_ADJUSTED_INVENTORY_FORECAST_SUCCESS]: updateSuccess,
    [UPDATE_ADJUSTED_INVENTORY_FORECAST_ERROR]: updateError,

    [CLEAN_UP_ADJUSTED_INVENTORY_FORECAST]: cleanUp,
}); 