import createReducer from "./createReducer";
import {
    LOAD_TARGETS_TYPES_START,
    LOAD_TARGETS_TYPES_SUCCESS,
    LOAD_TARGETS_TYPES_ERROR,
    LoadTargetsTypesStart,
    LoadTargetsTypesSuccess,
    LoadTargetsTypesError,
    SAVE_TARGETS_TYPES_START,
    SAVE_TARGETS_TYPES_SUCCESS,
    SAVE_TARGETS_TYPES_ERROR,
    SaveTargetsTypesStart,
    SaveTargetsTypesSuccess,
    SaveTargetsTypesError,
    targetsTypeActions
} from "../actions/targets.action";

export interface TargetsTypesState {
    type: string;
    error: string;
    targets: Array<any>;
}

const initialState: TargetsTypesState = {
    type: null,
    error: null,
    targets: []
}

function loadTargetsTypesStart(state: TargetsTypesState, action: LoadTargetsTypesStart): TargetsTypesState {
    return {
        ...state,
        type: action.type,
        error: null,
        targets: []
    }
}

function loadTargetsTypesSuccess(state: TargetsTypesState, action: LoadTargetsTypesSuccess): TargetsTypesState {
    return {
        ...state,
        type: action.type,
        error: null,
        targets: action.data
    }
}

function loadTargetsTypesError(state: TargetsTypesState, action: LoadTargetsTypesError): TargetsTypesState {
    return {
        ...state,
        type: action.type,
        error: action.message
    }
}

function saveTargetsStart(state: TargetsTypesState, action: SaveTargetsTypesStart): TargetsTypesState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function saveTargetsSuccess(state: TargetsTypesState, action: SaveTargetsTypesSuccess): TargetsTypesState {    
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function saveTargetsError(state: TargetsTypesState, action: SaveTargetsTypesError): TargetsTypesState {
    return {
        ...state,
        type: action.type,
        error: action.message,
    }
}

const TargetsReducer = (state: TargetsTypesState, action: targetsTypeActions): TargetsTypesState => {
    const fnUpdateState = createReducer(initialState, {
        [LOAD_TARGETS_TYPES_START]:loadTargetsTypesStart,
        [LOAD_TARGETS_TYPES_SUCCESS]: loadTargetsTypesSuccess,
        [LOAD_TARGETS_TYPES_ERROR]: loadTargetsTypesError,
        [SAVE_TARGETS_TYPES_START]: saveTargetsStart,
        [SAVE_TARGETS_TYPES_SUCCESS]: saveTargetsSuccess,
        [SAVE_TARGETS_TYPES_ERROR]: saveTargetsError
    });

    return fnUpdateState(state, action);
}

export default TargetsReducer;