//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";
import IWeeklyInventoryForecast from "../../models/IWeeklyInventoryForecast";
import { CAMPAIGN_TYPE_INVENTORY } from "../../vendor/forecast/models/CampaignTypes";

const actionNamePrefix = "LOAD_FORECAST";
export const LOAD_FORECAST_START = `${actionNamePrefix}_START`;
export const LOAD_FORECAST_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_FORECAST_ERROR = `${actionNamePrefix}_ERROR`;

const actionNamePrefix1 = "UPLOAD_FORECAST";
export const UPLOAD_FORECAST_START = `${actionNamePrefix1}_START`;
export const UPLOAD_FORECAST_SUCCESS = `${actionNamePrefix1}_SUCCESS`;
export const UPLOAD_FORECAST_ERROR = `${actionNamePrefix1}_ERROR`;
export const CLEAN_UP_UPLOAD_FORECAST   = `${actionNamePrefix1}_CLEANUP`;

const actionNamePrefix2 = "GET_PREFORECAST";
export const GET_PREFORECAST_START = `${actionNamePrefix2}_START`;
export const GET_PREFORECAST_SUCCESS = `${actionNamePrefix2}_SUCCESS`;
export const GET_PREFORECAST_ERROR = `${actionNamePrefix2}_ERROR`;

const actionNamePrefix3 = "UPLOAD_WEEKLY_FORECAST";
export const UPLOAD_WEEKLY_FORECAST_START = `${actionNamePrefix3}_START`;
export const UPLOAD_WEEKLY_FORECAST_SUCCESS = `${actionNamePrefix3}_SUCCESS`;
export const UPLOAD_WEEKLY_FORECAST_ERROR = `${actionNamePrefix3}_ERROR`;
export const CLEAN_UP_WEEKLY_FORECAST   = `${actionNamePrefix3}_CLEANUP`;

const actionNamePrefix4 = "LOAD_WEEKLY_FORECAST";
export const LOAD_WEEKLY_FORECAST_START = `${actionNamePrefix4}_START`;
export const LOAD_WEEKLY_FORECAST_SUCCESS = `${actionNamePrefix4}_SUCCESS`;
export const LOAD_WEEKLY_FORECAST_ERROR = `${actionNamePrefix4}_ERROR`;


function loadStart(dataStoreKey) {
    return {
        type: LOAD_FORECAST_START,
        dataStoreKey
    };
}

function loadSuccess(forecast, dataStoreKey) {
    return {
        type: LOAD_FORECAST_SUCCESS,
        data: forecast,
        dataStoreKey
    };
}

function loadError(error) {
    return {
        type: LOAD_FORECAST_ERROR,
        error
    }
}

export const loadForecast = (campaignId, dataStoreKey, fromDateString, toDateString) => async (dispatch) => {
    dispatch(loadStart(dataStoreKey));
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/campaign/${campaignId}/fromDateTime/${fromDateString}/toDateTime/${toDateString}/forecasts`);
        dispatch(loadSuccess(dataStoreKey, response.data));
    }
    catch (e) {
        dispatch(loadError(e.response.data.Message || e.message));
    }
}

function uploadStart() {
    return {
        type: UPLOAD_FORECAST_START
    };
}

function uploadSuccess(response) {
    return {
        type: UPLOAD_FORECAST_SUCCESS,
        response: response
    };
}

function uploadError(error) {
    return {
        type: UPLOAD_FORECAST_ERROR,
        error
    }
}

export const uploadForecastdata = (file, campaignType) => async (dispatch) => {
    dispatch(uploadStart());
    const formData = new FormData();
    formData.append('file', file);
    const config = { headers: { 'content-type': 'multipart/form-data', } };

    try {
        let response = null;
        if (campaignType.toUpperCase() === CAMPAIGN_TYPE_INVENTORY) {
           response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/InventoryForecast`, formData, config);
        }
        else {
           response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/PhoneForecast`, formData, config);
        }

        dispatch(uploadSuccess(response.data));
    }
    catch (e) {
        dispatch(uploadError(e.response.data.Message || e.message));
    }

}

function cleanUpUpload() {
    return {
        type: CLEAN_UP_UPLOAD_FORECAST
    };
}

export const cleanUpUploadForecast = () => (dispatch) => {
    dispatch(cleanUpUpload())
}


function loadPreAdjustForecastStart() {
    return {
        type: GET_PREFORECAST_START
    };
}

function loadPreAdjustForecastSuccess(forecast, campaignType) {
    return {
        type: GET_PREFORECAST_SUCCESS,
        data: forecast,
        campaignType
    };
}

function loadPreAdjustForecastError(error) {
    return {
        type: GET_PREFORECAST_ERROR,
        error
    }
}

export const loadPreAdjustForecast = (campaign, fromDateString, toDateString) => async (dispatch) => {
    dispatch(loadPreAdjustForecastStart());
    try {
        let response = null;
        if (campaign.type.toUpperCase() == CAMPAIGN_TYPE_INVENTORY)
            response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/campaign/${campaign.id}/fromDateTime/${fromDateString}/toDateTime/${toDateString}/InventoryForecast`);
        else
            response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/PhoneForecast/campaignId/${campaign.id}/startInUtc/${fromDateString}/endInUtc/${toDateString}`);
            

        dispatch(loadPreAdjustForecastSuccess(response.data, campaign.type));
    }
    catch (e) {
        dispatch(loadPreAdjustForecastError(e.response.data.Message || e.message));
    }
}

function uploadweeklyStart() {
    return {
        type: UPLOAD_WEEKLY_FORECAST_START
    };
}

function uploadWeeklySuccess(data) {
    return {
        type: UPLOAD_WEEKLY_FORECAST_SUCCESS,
        response: data
    };
}

function uploadWeeklyError(error) {
    return {
        type: UPLOAD_WEEKLY_FORECAST_ERROR,
        error
    }
}

export const uploadWeeklyForecastdata = (file, campaignType) => async (dispatch) => {
    dispatch(uploadweeklyStart());
    const formData = new FormData();
    formData.append('file', file);
    const config = { headers: { 'content-type': 'multipart/form-data', } };
    try {
        let response = null;

        if (campaignType.toUpperCase() === CAMPAIGN_TYPE_INVENTORY) {
            const response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/inventoryWeeklyForecast`, formData, config);
        }
        else {
            const response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/weekly-forecast`, formData, config);
        }
        dispatch(uploadWeeklySuccess(response));
    }
    catch (e) {
        dispatch(uploadWeeklyError(e.response.data.Message || e.message));
    }
}

function cleanUp() {
    return {
        type: CLEAN_UP_WEEKLY_FORECAST
    };
}

export const cleanUpWeeklyForecast = () => (dispatch) => {
    dispatch(cleanUp())
}

export interface LoadWeeklyForecastRequest {
    campaignId: number;
    startDate: string;
    endDate: string;
    campaignType: string;
}

function loadWeeklyForecastStart() {
    return {
        type: LOAD_WEEKLY_FORECAST_START
    };
}

function loadWeeklyForecastSuccess(forecast: Array<IWeeklyInventoryForecast>) {
    return {
        type: LOAD_WEEKLY_FORECAST_SUCCESS,
        data: forecast
    };
}

function loadWeeklyForecastError(error) {
    return {
        type: LOAD_WEEKLY_FORECAST_ERROR,
        error
    }
}

export const loadWeeklyForecast = (request: LoadWeeklyForecastRequest) => async (dispatch) => {
    dispatch(loadWeeklyForecastStart());
    try {
        let response = null;
        
        if (request.campaignType.toUpperCase() === CAMPAIGN_TYPE_INVENTORY) {
            response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/inventoryWeeklyForecast/campaign/${request.campaignId}/fromDateTime/${request.startDate}/toDateTime/${request.endDate}`);
        } else {

        }
        dispatch(loadWeeklyForecastSuccess(response.data));
    } catch (e) {        
        dispatch(loadWeeklyForecastError(e.response.data.Message || e.message));
    }
}
