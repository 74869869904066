import React, { useEffect, useRef, useState, UIEvent, FC, CSSProperties, useCallback } from 'react';
import { Box, Button, Text, Spinner } from "@twilio-paste/core";
import { Client, Conversation, Message, Participant, User } from "@twilio/conversations";
import { ReactComponent as Info } from '../Icons/Info.svg';
import { ReactComponent as ChatBubble } from '../Icons/ChatBubble.svg';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { CustomizationProvider, CustomizationProviderProps } from '@twilio-paste/core/customization';
import { ReactComponent as UserAvatar } from '../Icons/user_avatar.svg';
import throttle from "lodash.throttle";
import {
    conversationEventContainerStyles,
    conversationEventTitleStyles,
    conversationEventDateStyles,
    spinnerContainerStyles,
    messageListStyles,
    innerContainerStyles,
    outerContainerStyles,
    participantTypingStyles
} from "./styles/MessageList.styles";
import { Tabs, Tab } from '@mui/material';
import { MessageBubble } from './MessageBubble';
import { MessageListSeparator } from './MessageListSeparator';
import { MESSAGES_LOAD_COUNT, MESSAGES_SPINNER_BOX_HEIGHT } from './constants';
import { MessageInput } from './MessageInput';
import { ConversationEnded } from './ConversationEnded';
import Axios from 'axios';
import { AppSettings } from '../../appSettings';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/reducers';
import ChatForm from './ChatForm';

interface WebChatProps {
    conversation?: Conversation,
    conversationClient?: Client,
    callerInfo: any,
    attributes: any,
    worker: any,
    task: any,
    handleEndChat:() => void;
}

export const getDaysOld = (date: Date): number => {
    const messageDate = new Date(date.getTime());
    messageDate.setUTCHours(0);
    messageDate.setUTCMinutes(0);
    messageDate.setUTCSeconds(0, 0);

    const currentDate = new Date();
    currentDate.setUTCHours(0);
    currentDate.setUTCMinutes(0);
    currentDate.setUTCSeconds(0, 0);

    const timeDiff = currentDate.getTime() - messageDate.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
};
const AnyCustomizationProvider: FC<CustomizationProviderProps & { style: CSSProperties }> = CustomizationProvider;
export default function WebChatContainer(props: WebChatProps) {
    
    const messageListRef = useRef<HTMLDivElement>(null);
    const [tabValue, setTabValue] = useState('info');
    const [messages, setMessages] = useState<Array<Message>>([]);
    const [participants, setParticipants] = useState<Array<Participant>>([]);
    const [users, setUsers] = useState<Array<User>>([]);
    const [shouldFocusLatest, setShouldFocusLatest] = useState(false);
    const isLoadingMessages = useRef(false);
    const oldMessagesLength = useRef((messages || []).length);
    const [conversationState, setConversationState] = useState('');
    const phoneForms = useSelector((state: AppState) => state.workflowForm);

    useEffect(() => {
        if (props.conversation !== null) {
            (async function () {
                const { conversation } = props;
                setConversationState(conversation.state?.current);
                var participants = await conversation.getParticipants();
                setParticipants(participants);
                var messagesList = (await conversation.getMessages(100)).items;
                setMessages(messagesList);
                const chatParts = participants.filter(x => x.type === "chat");
                var users = await Promise.all(chatParts.map(async (p) => p.getUser()));
                setUsers(users);                
            })();
        }
    }, [props.conversation])

    useEffect(() => {
        if (props.conversation !== null) {
            props.conversation.on('messageAdded', m => {
                const updatedMessages = [...messages, m];   
                setMessages(updatedMessages);          
            });
        }
    },[props.conversation, messages])

    const [focusIndex, setFocusIndex] = useState(
        messages && messages.length ? messages[messages?.length - 1].index : -1
    );
    const [hasLoadedAllMessages, setHasLoadedAllMessages] = useState(true);

    const handleChange = (event, newValue: string) => {
        setTabValue(newValue);
    }

    const scrollToBottom = () => {
        if (!messageListRef.current) {
            return;
        }

        messageListRef.current.scrollTop = 0;
    };

    useEffect(() => {
        const messageListener = (message: Message) => {
            // Should focus latest message if one arrives while messages are not focused
            if (!document.activeElement?.hasAttribute("data-message-bubble")) {
                setShouldFocusLatest(true);
            }

            // Ensure that any new message sent by the current user is within scroll view.
            const belongsToCurrentUser = message.author === props.conversationClient?.user.identity;
            if (belongsToCurrentUser) {
                scrollToBottom();
            }
        };
        if (props.conversation) {
            props.conversation.addListener("messageAdded", messageListener);
        }
        
        return () => {
            if (props.conversation) {
                props.conversation.removeListener("messageAdded", messageListener);
            }            
        };
    }, [props.conversation, props.conversationClient]);

    useEffect(() => {
        const checkIfAllMessagesLoaded = async () => {
            const totalMessagesCount = await props.conversation?.getMessagesCount();
            if (totalMessagesCount) {
                setHasLoadedAllMessages(totalMessagesCount === messages?.length);
            }

            // if messages were added to state, loading is complete
            if (messages && oldMessagesLength.current < messages?.length) {
                isLoadingMessages.current = false;
                oldMessagesLength.current = messages.length;
            }
        };

        checkIfAllMessagesLoaded();
    }, [messages, props.conversation]);


    const handleFocus = () => {
        // Hand over focus to message bubbles once there is at least one
        if (messages && messages.length && focusIndex < 0) {
            setFocusIndex(messages[messages.length - 1].index);
        }
    }
    const isLastOfUserGroup = (message: Message, i: number, messages: Message[]) => {
        const nextMessage = messages[i + 1];

        // if there's no message afterwards, it's definitely the last of a group
        if (!nextMessage) {
            return true;
        }

        // if the author of the next message is different from current one's, then yes, this message is last of its group
        return nextMessage.author !== message.author;
    };

    const isFirstOfDateGroup = (message: Message, i: number, messages: Message[]) => {
        const prevMessage = messages[i - 1];

        // if the previous message has a date older than the current message, this message is the first for this date
        return getDaysOld(prevMessage.dateCreated) > getDaysOld(message.dateCreated);
    };

    const renderChatStarted = () =>
        hasLoadedAllMessages ? (
            <>
                <Box {...conversationEventContainerStyles}>
                    <Text as="h3" {...conversationEventTitleStyles} data-test="chat-started">
                        Chat started
                    </Text>
                    <Text as="p" {...conversationEventDateStyles}>
                        {props.conversation?.dateCreated.toLocaleString()}
                    </Text>
                </Box>
            </>
        ) : null;

    const renderSeparatorIfApplicable = (message: Message, i: number) => {
        const belongsToCurrentUser = message.author === props.conversationClient?.user.identity;
        const isFirstUnreadMessage = message.index === (props.conversation?.lastReadMessageIndex as number) + 1;

        /*
         * Render date separator above the first message which is the first of a certain date
         * (the first message of any chunk cannot compare itself with previous,
         * and the i = 0 date separator is rendered before "Chat started" section)
         */
        if (i > 0 && isFirstOfDateGroup(message, i, messages as Message[])) {
            return <MessageListSeparator message={message} separatorType="date" />;
        }

        /*
         * Render New separator above the first unread message
         * (messages sent by the current user should be treated as inherently read to avoid flicker)
         */
        if (isFirstUnreadMessage && !belongsToCurrentUser) {
            return <MessageListSeparator message={message} separatorType="new" />;
        }

        return null;
    };

    const updateFocus = (newFocus: number) => {
        if (newFocus < 0 || !messages || !messages.length || newFocus > messages[messages.length - 1].index) {
            return;
        }

        if (shouldFocusLatest) {
            setFocusIndex(messages[messages.length - 1].index);
            setShouldFocusLatest(false);
        } else {
            setFocusIndex(newFocus);
        }
    };

    const handleEndChat = async () => {
        if (props.worker !== null) {
            const taskSid = props.task.sid;            
            props.worker.completeTask(taskSid,
                function (error, completedTask) {
                    if (error) {
                        console.log(error.code);
                        console.log(error.message);
                        return;
                    }
                    console.log("Completed Task: " + completedTask.assignmentStatus);
                }
            );
            await handleLeaveConversation();
            props.handleEndChat();
        }
    }

    const handleLeaveConversation = async () => {

        try {
            const chatServiceSid = props.attributes && props.attributes.ChatServiceSid;
            const conversationSid = props.attributes && props.attributes.ConversationSid;

            var request = {
                chatServiceSid: chatServiceSid,
                participantSid: conversationSid
            }
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/ChatParticipant/LeaveConference`, request)
            if (response && response.data) {
                console.log(response.data);
            }            

        } catch (e) {
            console.log(e);
        }
    }

    const renderChatItems = useCallback(() => {
        if (!messages) {
            return null;
        }

        /*
         * We use a copy of the messages array where the first message is a placeholder for the loading spinner.
         * By assigning the loading spinner to the same index and key as the previous message,
         * we avoid a snappy scroll position change when loading spinner disappears.
         */
        const spinnerIndex = (messages[0]?.index || 0) - 1;
        const messagesWithSpinner = [
            {
                index: spinnerIndex
            } as Message,
            ...messages
        ];
        //setMessages(messagesWithSpinner);
        return messagesWithSpinner.map((message: Message, i: number) => {
            // First message in array represents the loading spinner
            if (message.index === spinnerIndex) {
                // Only render loading spinner if there are remaining messages to load
                return hasLoadedAllMessages ? null : (
                    <Box {...spinnerContainerStyles} key={message.index}>
                        <Spinner color="colorTextWeak" decorative={false} title="Loading" />
                    </Box>
                );
            }
            // Discount loading spinner from indices
            i -= 1;
            console.log("I value",i);
            console.log(messages.length);
            return (
                <Box data-test="all-message-bubbles" key={message.index}>
                    {renderSeparatorIfApplicable(message, i)}
                    <MessageBubble
                        message={message}
                        isLast={i === messages.length - 1}
                        isLastOfUserGroup={isLastOfUserGroup(message, i, messages)}
                        focusable={message.index === focusIndex}
                        conversationsClient={props.conversationClient}
                        participants={participants}
                        users={users}
                        updateFocus={updateFocus}
                    />
                </Box>
            );
        });
    },[messages, users, participants]);

    const renderCallerInfo = (callerInfo) => {
        const keys = Object.keys(callerInfo);
        keys && keys.forEach((key, index) => {
            if ((typeof callerInfo[key] !== 'object') && callerInfo[key].endsWith("}}"))
                callerInfo[key] = "No information"
        });
        return (
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <span>Name: {callerInfo.MemberNamePreference}</span>
                <span>Age: {callerInfo.MemberAge}</span>
                <span>Phone Number: {callerInfo.PhoneNumber}</span>
                <span>Plan: {callerInfo.MemberPlan}</span>
                <span>Status: {callerInfo.Status}</span>
                <span>Vechile: {callerInfo.Vehicle1}</span>
                <span>Color: {callerInfo.Color}</span>
                <span>License plate: {callerInfo.LicensePlate}</span>
            </div>
        )
    }

    const renderInfoValue = () => {
        return (
            renderCallerInfo(props.callerInfo)
        )
    }

    const getMoreMessages = async (anchor) => {
        console.log("anchor", anchor)
        const newMessages = (await props.conversation.getMessages(MESSAGES_LOAD_COUNT, anchor.anchor)).items;
        setMessages([...newMessages, ...(messages || [])])
        //updatedMessages.push(newMessages);
        //setMessages(updatedMessages);
    }

    const handleScroll = async (event: UIEvent<HTMLDivElement>) => {
        const element = event.target as HTMLDivElement;
        const hasReachedTop =
            element.scrollHeight + element.scrollTop - MESSAGES_SPINNER_BOX_HEIGHT <= element.clientHeight;

        // When reaching the top of all messages, load the next chunk
        if (hasReachedTop && props.conversation && messages && !hasLoadedAllMessages && !isLoadingMessages.current) {
            isLoadingMessages.current = true;
            oldMessagesLength.current = messages.length;
            const totalMessagesCount = await props.conversation?.getMessagesCount();

            if (totalMessagesCount && messages.length < totalMessagesCount) {
                getMoreMessages({ anchor: totalMessagesCount - messages.length - 1 });
            }
        }
    };
    console.log("Participants", participants);
    const renderChatBox = () => {
        return (

            <AnyCustomizationProvider
                baseTheme="default"
                theme={{
                    "textColors": {
                        "colorTextWeak": "rgb(128,128,128)"
                    }
                }}
                elements={{
                    MESSAGE_INPUT: {
                        boxShadow: "none!important" as "none"
                    },
                    MESSAGE_INPUT_BOX: {
                        display: "inline-block",
                        boxShadow: "none"
                    },
                    ALERT: {
                        paddingTop: "space30",
                        paddingBottom: "space30"
                    },
                    BUTTON: {
                        "&[aria-disabled='true'][color='colorTextLink']": {
                            color: "colorTextLinkWeak"
                        }
                    }
                }}
                style={{ height: "100%", minHeight: "100%", minWidth: "100%" }}
            >
                <div style={{ textAlign: 'right' }}>
                    {conversationState === "active" && <Button variant="primary" data-test="start-new-chat-button" onClick={handleEndChat}>End Chat</Button>}
                </div>
                <div style={{ height: '100%', display: 'flex', flexDirection:'column' }}>
                    <Box {...messageListStyles}>
                        {/* onScroll={throttle(handleScroll, 1000)}  */}
                        <Box {...outerContainerStyles} onScroll={throttle(handleScroll, 1000)} ref={messageListRef} role="main">
                            <Box
                                aria-label="Chat messages"
                                role="log"
                                aria-relevant="additions"
                                {...innerContainerStyles}
                                tabIndex={focusIndex >= 0 ? -1 : 0}
                                onFocus={handleFocus}
                            >
                                {renderChatStarted()}
                                {renderChatItems()}
                                {participants
                                    ?.filter((p) => p.isTyping && p.identity !== props.conversationClient?.user.identity)
                                    .map((p) => (
                                        <Text {...participantTypingStyles} as="p" key={p.identity}>
                                            {users?.find((u) => u.identity === p.identity)?.friendlyName} is typing...
                                        </Text>
                                    ))}
                            </Box>
                        </Box>
                    </Box>
                    {
                        conversationState === "active" && <MessageInput conversation={props.conversation} />
                    }
                     {/* : <ConversationEnded /> */}
                </div>

            </AnyCustomizationProvider>
        )
    }

    const renderForms = () => {
        const phoneForm = phoneForms.data && phoneForms.data[0];
        console.log(phoneForms.data);
        return (
            <div style={{padding:'10px'}}>
               <ChatForm formDef={phoneForm} taskAttributes={props.attributes} isPhone={false} />
            </div>
        )
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                {
                    props.attributes &&
                    <>
                        <UserAvatar />
                        <span style={{ marginLeft: '10px' }}> {props.attributes.Campaigns} - {props.attributes.Author}</span>
                    </>

                }

            </div>
            <div>
                <Tabs value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab style={{ minWidth: '130px' }} value="info" icon={<Info />} aria-label="phone" />
                    <Tab style={{ minWidth: '130px' }} value="chat" icon={<ChatBubble />} aria-label="phone" />
                    <Tab style={{ minWidth: '130px' }} value="forms" icon={<ListAltIcon sx={{color: '#ffff'}} />} aria-label="phone forms" />
                </Tabs>
            </div>
            <div style={{ height: '80%' }}>
                {tabValue === 'info' && renderInfoValue()}
                {tabValue === 'chat' && renderChatBox()}
                {tabValue === 'forms' && renderForms()}
            </div>
        </>
    );
}