//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "TIMER";
export const TIMER = `${actionNamePrefix}`;
export const TIMER_GET = `${actionNamePrefix}_GET`;
export const TIMER_START = `${actionNamePrefix}_START`;
export const TIMER_STOP = `${actionNamePrefix}_STOP`;
export const TIMER_ERROR = `${actionNamePrefix}_ERROR`;

export interface StateTimerAction {
    type: typeof TIMER;
}

export interface StateTimerGetAction {
    type: typeof TIMER_GET;
    data: any
}

export interface StateTimerStartAction {
    type: typeof TIMER_START;
    data: any
}

export interface StateTimerStopAction {
    type: typeof TIMER_STOP;
    data: any
}

export interface StateTimerErrorAction {
    type: typeof TIMER_ERROR;
    data: any
}

export type StateTimerActions = StateTimerGetAction |
                                StateTimerStartAction |
                                StateTimerStopAction |
                                StateTimerErrorAction;

export const getStateTimer = () => async (dispatch) => {
    try {
        //const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/UserProfile/GetStateTimer`);
        const response = {
            data: [
                {
                    id: 1,
                    stateId: 1,
                    startDateTimeInBtz: '2021-06-17 04:07:01',
                    endDateTimeInBtz: '2021-06-17 04:07:01'
                },
                {
                    id: 2,
                    stateId: 2,
                    startDateTimeInBtz: '2021-06-17 04:07:01',
                    endDateTimeInBtz: '2021-06-17 04:07:01'
                },
                {
                    id: 2,
                    stateId: 3,
                    startDateTimeInBtz: '2021-06-17 04:07:01',
                    endDateTimeInBtz: '2021-06-17 04:07:01'
                },
            ]
        }
        dispatch({
            type: TIMER_GET,
            data: response.data
            })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: TIMER_ERROR,
                error: errorMessage
            })
        }
    }
}

export const startStateTimer = (request) => async (dispatch) => {
    dispatch({ type: TIMER });
    try {
       
        dispatch({
            type: TIMER_START,
            data: request
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: TIMER_ERROR,
                error: errorMessage
            })
        }
    }
}
