import {
    FiltersAction,
    LoadFiltersAction,
    LoadFiltersError,
    // GetFiltersResponse,
    LoadFiltersSuccessAction,
    LOAD_FILTERS,
    LOAD_FILTERS_ERROR,
    LOAD_FILTERS_SUCCESS, 
    SAVE_FILTERS,
    SAVE_FILTERS_SUCCESS,
    SAVE_FILTERS_ERROR,
    SaveFiltersAction,
    SaveFiltersSuccessAction,
    SaveFiltersError,
    // LoadFiltersAfterSave,
    // LOAD_FILTERS_AFTERSAVE
} from "../actions/marketPlaceFilters.action";
import createReducer from "./createReducer";

export interface FiltersState {
    type: string;
    error: string;
    filters: Array<any>;
}

const initialState: FiltersState = {
    type: null,
    error: null,
    filters: []
}

function loadFilters(state:FiltersState, action: LoadFiltersAction): FiltersState {
    return {
        ...state,
        type: action.type,
        error: null,
    }
}

function loadFiltersSuccess(state:FiltersState, action: LoadFiltersSuccessAction): FiltersState {
    return {
        ...state,
        type: action.type,
        error: null,
        filters: action.filters
    }
}

function loadFiltersError(state:FiltersState, action: LoadFiltersError): FiltersState {
    return {
        ...state,
        type: action.type,
        error: action.error,
        filters: []
    }
}

function saveFilters(state:FiltersState, action: SaveFiltersAction): FiltersState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function saveFiltersSuccess(state:FiltersState, action: SaveFiltersSuccessAction): FiltersState {
    // const updated = [...state.filters];
    // updated.push(action.filters);
    let options=action
    // var selected = []
    // options.forEach(element => {
    //     // selected.push(element.options)
    console.log('ekdum inside',options)
    // });
    
    
    return {
        ...state,
        type: action.type,
        error: null,
        filters:action.filters
    }
}

function saveFiltersError(state:FiltersState, action: SaveFiltersError): FiltersState {
    return {
        ...state,
        type: action.type,
        error: action.error
    }
}


const MarketPlaceFiltersReducer =
    (state: FiltersState, action: FiltersAction): FiltersState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_FILTERS]: loadFilters,
            [LOAD_FILTERS_SUCCESS]: loadFiltersSuccess,
            [LOAD_FILTERS_ERROR]: loadFiltersError,
            [SAVE_FILTERS]: saveFilters,
            [SAVE_FILTERS_SUCCESS]: saveFiltersSuccess,
            [SAVE_FILTERS_ERROR]: saveFiltersError,
            // [LOAD_FILTERS_AFTERSAVE]: loadFiltersAfterSave
        });

        return fnUpdateState(state, action);
    }

export default MarketPlaceFiltersReducer;
