import { v4 as uuidv4 } from 'uuid';
import defaultRealtimeDashboardWidgetGridConfig from './realtimeDashboardWidgetGridConfig';
import defaultHistoricalDashboardWidgetGridConfig from './historicalDashboardWidgetGridConfig';
import defaultInventoryDashboardWidgetGridConfig from './inventoryDashboardWidgetGridConfig';

export enum DashboardTypes {
	Realtime = 'realtime', History = 'history', Inventory = 'inventory', AgentStates = 'agentStates', Custom = 'custom'
}

export enum WidgetTypes {
	ForecastReport = 'forecast-report', PeopleReport = 'people-report', PeopleDataChart = 'people-chart',
	ForecastChart = 'forecast-chart',PhoneForecastChart = 'phone-forecast-chart', DayMeasure = 'day-measure', PhoneReport = 'phone-report',
	AgentMetric = 'agent-metric', CampaignPerformance = 'campaign-performance', ServiceLevel = 'service-level',
	AgentCounter = 'agent-counter', QueueCounts = 'queue-counts', AverageSpeedOfAnswer = 'average-speed-answer',
	CurrentIntervalStaffing = 'staffing-planned', AgentActivity = 'actual-agent-activity', PhoneOverUnder = 'phone-over-under'
}

export const getDefaultDashboarTitleFromType = (type: DashboardTypes, index?: number): string => {
	switch (type) {
		case DashboardTypes.Realtime:
			return 'Real-time Dashboard';
		case DashboardTypes.History:
			return 'Historical Dashboard';
		case DashboardTypes.Inventory:
			return 'Inventory Dashboard';
		case DashboardTypes.AgentStates:
			return 'Agent States';
		case DashboardTypes.Custom:
			return `Custom Tab ${index}`;
	}
}

export const getWidgetNameFromType = (type: WidgetTypes) => {
	switch (type) {
		case WidgetTypes.AgentMetric:
			return 'Agent Metric';
		case WidgetTypes.CampaignPerformance:
			return 'Campaign Performance';
		case WidgetTypes.DayMeasure:
			return 'Day Measure';
		case WidgetTypes.ForecastChart:
			return 'Forecast Chart (Inventory)';
		case WidgetTypes.PhoneForecastChart:
			return 'Forecast Chart (Phone)';
		case WidgetTypes.ForecastReport:
			return 'Forecast Report (Inventory)';
		case WidgetTypes.PeopleDataChart:
			return 'People Data Chart';
		case WidgetTypes.PeopleReport:
			return 'People Report';
		case WidgetTypes.PhoneReport:
			return 'Forecast Report (Phone)';
		case WidgetTypes.PhoneOverUnder:
			return 'Phone Interval Staff Short Over Report';
		case WidgetTypes.AgentCounter:
			return 'Agent Counter - ALL Campaigns';
		case WidgetTypes.ServiceLevel:
			return 'Service Level';
		case WidgetTypes.QueueCounts:
			return 'Queue Counts - Voice';
		case WidgetTypes.AverageSpeedOfAnswer:
			return 'Average Speed of Answer - Channel - Voice';
		case WidgetTypes.CurrentIntervalStaffing:
			return 'Current Interval Staffing to Planned';			
		case WidgetTypes.AgentActivity:
			return 'Actual Agent Activity';
	}
}

export interface IWidgetGridConfig {
	x: number, y: number, w: number, h: number, i: string
}

export interface IDashboarLayout {
	[breakpoint: string]: IWidgetGridConfig[]
}

export interface IDashboardTabConfig {
	id: string,
	type: DashboardTypes,
	title: string,
	displayOrder: number,
	drag: boolean,
	isUserDefined: boolean,
	widgetsData?: any[],
	gridLayoutConfig?: IDashboarLayout
}

export const initRealtimeDashboard: IDashboardTabConfig = {
	id: uuidv4(),
	type: DashboardTypes.Realtime,
	title: getDefaultDashboarTitleFromType(DashboardTypes.Realtime),
	displayOrder: 0,
	drag: false,
	isUserDefined: false,
	widgetsData: [],
	gridLayoutConfig: defaultRealtimeDashboardWidgetGridConfig
};

export const initHistoricalDashboard: IDashboardTabConfig = {
	id: uuidv4(),
	type: DashboardTypes.History,
	title: getDefaultDashboarTitleFromType(DashboardTypes.History),
	displayOrder: 1,
	drag: false,
	isUserDefined: false,
	widgetsData: [],
	gridLayoutConfig: defaultHistoricalDashboardWidgetGridConfig
};

export const initInventoryDashboard: IDashboardTabConfig = {
	id: uuidv4(),
	type: DashboardTypes.Inventory,
	title: getDefaultDashboarTitleFromType(DashboardTypes.Inventory),
	displayOrder: 2,
	drag: false,
	isUserDefined: false,
	widgetsData: [],
	gridLayoutConfig: defaultInventoryDashboardWidgetGridConfig
};

export const initAgentStates: IDashboardTabConfig = {
	id: uuidv4(),
	type: DashboardTypes.AgentStates,
	title: getDefaultDashboarTitleFromType(DashboardTypes.AgentStates),
	displayOrder: 3,
	drag: false,
	isUserDefined: false,
	widgetsData: [],
};

export const datasourceList = [
	{
		id: 1,
		name: getWidgetNameFromType(WidgetTypes.ForecastReport),
		attrName: "Widget1",
		w: 5,
		h: 4,
		width: 3,
		type: WidgetTypes.ForecastReport
	},
	{
		id: 2,
		name: getWidgetNameFromType(WidgetTypes.ForecastChart),
		width: 2,
		attrName: "Widget4",
		w: 5,
		h: 4,
		type: WidgetTypes.ForecastChart
	},
	{
		id: 3,
		name: getWidgetNameFromType(WidgetTypes.PeopleReport),
		attrName: "Widget2",
		w: 5,
		h: 4,
		width: 3,
		type: WidgetTypes.PeopleReport
	},
	{
		id: 4,
		name: getWidgetNameFromType(WidgetTypes.PeopleDataChart),
		attrName: "Widget3",
		w: 4,
		h: 4,
		width: 1,
		type: WidgetTypes.PeopleDataChart
	},
	{
		id: 5,
		name: getWidgetNameFromType(WidgetTypes.DayMeasure),
		width: 0,
		type: WidgetTypes.DayMeasure,
		attrName: "Widget5",
		w: 2,
		h: 1,
	},
	{
		id: 6,
		name: getWidgetNameFromType(WidgetTypes.PhoneReport),
		width: 5,
		type: WidgetTypes.PhoneReport,
		attrName: "Widget6",
		w: 5,
		h: 4,
	},
	{
		id: 7,
		name: getWidgetNameFromType(WidgetTypes.AgentMetric),
		width: 5,
		type: WidgetTypes.AgentMetric,
		attrName: "Widget7",
		w: 5,
		h: 4,
	},
	{
		id: 8,
		name: getWidgetNameFromType(WidgetTypes.CampaignPerformance) + ` (${DashboardTypes.Realtime})`,
		width: 5,
		type: WidgetTypes.CampaignPerformance,
		attrName: "Widget8",
		w: 5,
		h: 4,
		dashboardType: DashboardTypes.Realtime
	},
	{
		id: 14,
		name: getWidgetNameFromType(WidgetTypes.CampaignPerformance) + ` (${DashboardTypes.History})`,
		width: 5,
		type: WidgetTypes.CampaignPerformance,
		attrName: "Widget8",
		w: 5,
		h: 4,
		dashboardType: DashboardTypes.History
	},
	{
		id: 9,
		name: getWidgetNameFromType(WidgetTypes.AgentCounter),
		width: 2,
		type: WidgetTypes.AgentCounter,
		attrName: "Widget9",
		w: 4,
		h: 2,
	},
	{
		id: 10,
		name: getWidgetNameFromType(WidgetTypes.ServiceLevel),
		width: 2,
		type: WidgetTypes.ServiceLevel,
		attrName: "Widget10",
		w: 2,
		h: 2,
	},
	{
		id: 11,
		name: getWidgetNameFromType(WidgetTypes.AverageSpeedOfAnswer),
		width: 2,
		type: WidgetTypes.AverageSpeedOfAnswer,
		attrName: "Widget11",
		w: 4,
		h: 2,
	},
	{
		id: 12,
		name: getWidgetNameFromType(WidgetTypes.CurrentIntervalStaffing),
		width: 2,
		type: WidgetTypes.CurrentIntervalStaffing,
		attrName: "Widget12",
		w: 4,
		h: 4,
	},
	{
		id: 13,
		name: getWidgetNameFromType(WidgetTypes.QueueCounts),
		width: 2,
		type: WidgetTypes.QueueCounts,
		attrName: "Widget13",
		w: 2,
		h: 2,
	},
	{
		id: 14,
		name: getWidgetNameFromType(WidgetTypes.AgentActivity),
		width: 5,
		type: WidgetTypes.AgentActivity,
		attrName: "Widget14",
		w: 5,
		h: 4,
	},
	{
		id: 15,
		name: getWidgetNameFromType(WidgetTypes.PhoneForecastChart),
		attrName: "Widget15",
		w: 5,
		h: 4,
		width: 3,
		type: WidgetTypes.PhoneForecastChart
	},
	{
		id: 16,
		name: getWidgetNameFromType(WidgetTypes.PhoneOverUnder),
		attrName: "Widget16",
		w: 5,
		h: 4,
		width: 3,
		type: WidgetTypes.PhoneOverUnder
	}
]
