//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_MY_DEFAULT_CAMPAIGN";
export const LOAD_MY_DEFAULT_CAMPAIGN_START = `${actionNamePrefix}_START`;
export const LOAD_MY_DEFAULT_CAMPAIGN_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_MY_DEFAULT_CAMPAIGN_ERROR = `${actionNamePrefix}_ERROR`;

export interface LoadMyDefaultCampaignStartedAction {
    type: typeof LOAD_MY_DEFAULT_CAMPAIGN_START;
}

export interface LoadMyDefaultCampaignSuccessAction {
    type: typeof LOAD_MY_DEFAULT_CAMPAIGN_SUCCESS;
    data: any
}

export interface LoadMyDefaultCampaignErrorAction {
    type: typeof LOAD_MY_DEFAULT_CAMPAIGN_ERROR;
    error: string;
}

export type myMyDefaultCampaignActions = LoadMyDefaultCampaignStartedAction |
                                LoadMyDefaultCampaignSuccessAction|
                                LoadMyDefaultCampaignErrorAction;

export const loadMyDefaultCampaign = () => async (dispatch) => {
    dispatch({type: LOAD_MY_DEFAULT_CAMPAIGN_START})
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/campaigns/MyPrimaryCampaign`);
        dispatch({
            type: LOAD_MY_DEFAULT_CAMPAIGN_SUCCESS,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_MY_DEFAULT_CAMPAIGN_ERROR,
                error: errorMessage
            })
        }
    }
}

