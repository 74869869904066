//@ts-nocheck
import createReducer from "./createReducer";
import {
    LOAD_MY_SCHEDULE_START,
    LOAD_MY_SCHEDULE_SUCCESS,
    LOAD_MY_SCHEDULE_ERROR,
    ADD_TO_MY_SCHEDULE_SUCCESS,
} from "../actions/mySchedule.action";

const initialState = {
    data: [],
    state: null,
    error: null
};

function loaded(state, action) {
    return {
        ...state,
        data: action.data,
        error: null,
        state: action.type
    }
}

function loadStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

function loadError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}

function addToMyScheduleSuccess(state, action) {
    const cloneOfSchedule = [...state.data];
    cloneOfSchedule.push(action.data);

    // Sort all schedule records by sort key
    cloneOfSchedule.sort((a, b) => {

        if (a.forecast.sortKey > b.forecast.sortKey) {
            return 1;
        }

        return -1;
    });

    return {
        ...state,
        data: cloneOfSchedule,
        error: null
    }
}

export default createReducer(initialState, {
    [LOAD_MY_SCHEDULE_START]: loadStarted,
    [LOAD_MY_SCHEDULE_SUCCESS]: loaded,
    [LOAD_MY_SCHEDULE_ERROR]: loadError,
    [ADD_TO_MY_SCHEDULE_SUCCESS]: addToMyScheduleSuccess
}); 