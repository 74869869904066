//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";
import {CAMPAIGN_TYPE_INVENTORY } from "../../vendor/forecast/models/CampaignTypes";

const actionNamePrefix = "UPLOAD_FORECAST_SCHEDULER";
export const UPLOAD_FORECAST_SCHEDULER_RESET = `${actionNamePrefix}_RESET`;
export const UPLOAD_FORECAST_SCHEDULER_START = `${actionNamePrefix}_START`;
export const UPLOAD_FORECAST_SCHEDULER_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const UPLOAD_FORECAST_SCHEDULER_ERROR = `${actionNamePrefix}_ERROR`;

function resetSchedulerState () {
    return {
        type: UPLOAD_FORECAST_SCHEDULER_RESET
    }
}

function uploadShedulerStart() {
    return {
        type: UPLOAD_FORECAST_SCHEDULER_START
    };
}

function uploadShedulerSuccess(data) {
    return {
        type: UPLOAD_FORECAST_SCHEDULER_SUCCESS,
        response: data
    };
}

function uploadShedulerError(error) {
    return {
        type: UPLOAD_FORECAST_SCHEDULER_ERROR,
        error
    }
}

export const uploadSheduler = (file, campaignType) => async (dispatch) => {
    dispatch(uploadShedulerStart());
    const formData = new FormData();
    formData.append('file', file);
    const config = { headers: { 'content-type': 'multipart/form-data', } };

    try {
        let response = null;
        response = await Axios.post(`${AppSettings.ApiSchedulerUrl}/bulkimport/InventoryAgentSchedule`, formData, config);
        dispatch(uploadShedulerSuccess(response.data));
    }
    catch (e) {
        dispatch(uploadShedulerError(e.response.data.message || e.message));
    }
}

export const resetScheduler = () => async (dispatch) => {
    dispatch(resetSchedulerState());
}