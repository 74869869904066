//@ts-nocheck
import Axios, { AxiosError } from "axios";
import { AppSettings } from "../../appSettings";
import { SaveActualsRequest } from "../../vendor/staffModelling/ActualUploadRecords/mapper/convertToSaveActuals";

export const LOAD_ACTUAL_OUTPUT = "LOAD_ACTUAL_OUTPUT";
export const LOAD_ACTUAL_OUTPUT_SUCCESS = "LOAD_ACTUAL_OUTPUT_SUCCESS";
export const LOAD_ACTUAL_OUTPUT_ERROR = "LOAD_ACTUAL_OUTPUT_ERROR";

export const SAVE_ACTUAL_OUTPUT = "SAVE_ACTUAL_OUTPUT";
export const SAVE_ACTUAL_OUTPUT_SUCCESS = "SAVE_ACTUAL_OUTPUT_SUCCESS";
export const SAVE_ACTUAL_OUTPUT_ERROR = "SAVE_ACTUAL_OUTPUT_ERROR";

export interface LoadActualOutputError {
    id: string;
    message: string;
}

export interface ActualOutputResponse {
        id: string,
        inventoryAdjustedForecastId: number,
        intakeSource: string,
        actionTaken: string,
        classification: string,
        mode: string,
        formattedRecievedDateInBtz: string,
        userId: string,
        formattedProcessedDateInBtz: string,
        formattedStartedDateInBtz: string,
        campaignId: number,
        campaignName: string,
        projectName: string
}

export interface GetActualOutputResponse {
    inventoryActualOutputs: Array<ActualOutputResponse>,
    number: number,
    numberOfElements: number,
    size: number,
    totalElements: number,
    totalPages: number
}

export interface LoadActualOutputAction {
    type: typeof LOAD_ACTUAL_OUTPUT;
}

export interface LoadActualOutputSuccessAction {
    type: typeof LOAD_ACTUAL_OUTPUT_SUCCESS;
    actualOutput: GetActualOutputResponse
}

export interface LoadActualOutputError {
    type: typeof LOAD_ACTUAL_OUTPUT_ERROR;
    error: string;
}

export interface SaveActualOutputAction {
    type: typeof SAVE_ACTUAL_OUTPUT;
}

export interface SaveActualOutputSuccessAction {
    type: typeof SAVE_ACTUAL_OUTPUT_SUCCESS;
    actualOutput: GetActualOutputResponse
}

export interface SaveActualOutputError {
    type: typeof SAVE_ACTUAL_OUTPUT_ERROR;
    error: string;
}


export type ActualOutputAction = 
    LoadActualOutputAction |
    LoadActualOutputSuccessAction |
    LoadActualOutputError | 
    SaveActualOutputAction |
    SaveActualOutputSuccessAction |
    SaveActualOutputError;

export const loadActualOutput = (page: number, pageSize: number) => async (dispatch: any) => {
    dispatch({ type: LOAD_ACTUAL_OUTPUT });

    try {
        const response = 
        await Axios.get<GetActualOutputResponse>(`${AppSettings.ApiBaseUrl}/api/InventoryActualOutput?page=${page}&pageSize=${pageSize}`);
        
        dispatch({
            type: LOAD_ACTUAL_OUTPUT_SUCCESS,
            actualOutput: response.data
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: LOAD_ACTUAL_OUTPUT_ERROR,
                error: errorMessage
            })
        }
    }
}


export const saveActualOutput = (saveRequest: Array<SaveActualsRequest>, currentPage : number, pageSize: number) => async (dispatch: any) => {
    dispatch({ type: SAVE_ACTUAL_OUTPUT });

    try {
        const response = 
        await Axios.put<GetActualOutputResponse>(`${AppSettings.ApiBaseUrl}/api/InventoryActualOutput?page=${currentPage}&pageSize=${pageSize}`, saveRequest);
        
        dispatch({
            type: SAVE_ACTUAL_OUTPUT_SUCCESS,
            actualOutput: response.data
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: SAVE_ACTUAL_OUTPUT_ERROR,
                error: errorMessage
            })
        }
    }
}
