import React, { useEffect, useRef, useState } from 'react';
import { Device } from '@twilio/voice-sdk';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ReactComponent as AddNew } from './Icons/AddNew.svg';
import { ReactComponent as TwoBox } from './Icons/two-box.svg';
import { ReactComponent as ThreeBox } from './Icons/three-box.svg';
import { ReactComponent as FourBox } from './Icons/four-box.svg';
import { ReactComponent as CodeSettings } from './Icons/codeSettings.svg';
import { ReactComponent as ContactsIcon } from './Icons/ContactsIcon.svg';
import { ReactComponent as PhoneCall } from './Icons/phoneCall.svg';
import { ReactComponent as ChatThin } from './Icons/ChatThin.svg';
import { AccountCircle } from '@mui/icons-material';
import clsx from 'clsx';
import PhoneSignIn from './PhoneSignIn';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store/reducers';
import CallStates from './CallStates';
import Axios from 'axios';
import { AppSettings } from '../appSettings';
import { useAuth0 } from '../auth0/react-auth0-wrapper';
import * as signalR from "@microsoft/signalr";
import moment from 'moment';
import { startLoggingState, startPhoneLogging, stopLoggingState, stopPhoneLogging } from '../store/actions/statesLog.action';
import { getPhoneToken } from '../store/actions/phoneToken.action';
import { TaskEntry } from '../StatesLog/StatesLoggingContainer';
import { setCurrentSelectedActivity } from '../store/actions/currentActivity.action';
import { Toast } from 'bryntum-scheduler';
import { loadMyAssignmentData } from '../store/actions/myAssignments.action';
import { getMyRosterCampaigns } from '../store/actions/myRosterCampaigns.action';
import { workerError } from '../store/actions/softPhone/worker.action';
import { fetchReservation, initPhoneWorker } from '../store/actions/softPhone/initPhoneWorker';
import { initPhoneDevice } from '../store/actions/softPhone/initPhoneDevice';
import { RESERVATION_CANCELLED, RESERVATION_CREATED, RESERVATION_WRAPUP, WORKER_ACTIVITY_MISMATCH, WORKER_DISCONNECTED } from '../store/actions/softPhone/actionTypes';
import { initConversationClient } from '../store/actions/softPhone/initConversationClient';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconColor: {
            color: 'white'
        },
        mainRightSide: {
            display: 'flex',
            flexDirection: 'row-reverse',
            position: 'fixed',
            zIndex: 1010
        },
        icons: {
            height: '20px',
            width: '20px',
            backgroundSize: 'contain',
            display: 'block',
        },
        iconOne: {
            background: "url('./Icons/One.svg') no-repeat",
            '&:hover': {
                background: "url('./Icons/One-filled.svg') no-repeat",
            }
        },
        iconOneActive: {
            background: "url('./Icons/One-filled.svg') no-repeat"
        },
        iconTwo: {
            background: "url('./Icons/two-box.svg') no-repeat",
            '&:hover': {
                background: "url('./Icons/TwoHover.svg') no-repeat",
            }
        },
        iconTwoActive: {
            background: "url('./Icons/TwoHover.svg') no-repeat"
        },
        iconThree: {
            background: "url('./Icons/three-box.svg') no-repeat",
            '&:hover': {
                background: "url('./Icons/ThreeHover.svg') no-repeat",
            }
        },
        iconThreeActive: {
            background: "url('./Icons/ThreeHover.svg') no-repeat"
        },
        iconFour: {
            background: "url('./Icons/four-box.svg') no-repeat",
            '&:hover': {
                background: "url('./Icons/FourHover.svg') no-repeat",
            }
        },
        iconFourActive: {
            background: "url('./Icons/FourHover.svg') no-repeat"
        },
        iconChannel: {
            height: '24px',
            width: '24px',
            background: "url('./Icons/channels.svg') no-repeat",
            '&:hover': {
                background: "url('./Icons/Channels-filled.svg') no-repeat",
            }
        },
        iconChannelActive: {
            background: "url('./Icons/Channels-filled.svg') no-repeat"
        },
        rightSidebar: {
            minWidth: 48,
            // minHeight: '95vh',
            minHeight: '90vh',
            background: theme.palette.primary.main,
            textAlign: 'center',
            padding: 5,
            right: 0,
            position: 'fixed',
            zIndex: 1,
            top: '64px'
        },
        top: {
            position: 'absolute',
        },
        bottom: {
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 10
        },
        paddingBtm: {
            paddingBottom: 10,
            cursor: 'pointer'
        },
        iconPointer: {
            cursor: 'pointer'
        },
        iconAdd: {
            '&:hover': {
                fill: "white",
                stroke: 'black'
            }
        }
    })
);

export default function PhoneSidebarContainer() {

    const classes = useStyles();
    const [openContent, setOpenContent] = useState(false);
    const [openOutbound, setOpenOutbound] = useState(false);
    const [callState, setCallState] = useState(CallStates.OFFLINE);
    const [addList, setAddList] = useState([]);
    const [addListTwo, setAddListTwo] = useState([]);
    const [addListThree, setAddListThree] = useState([]);
    const [addListFour, setAddListFour] = useState([]);
    const [view, setView] = useState('one');
    const [phoneType, setPhoneType] = useState('Inbound');
    const [customerHang, setCustomerHang] = useState(false);
    const [callReject, setCallReject] = useState(false);
    const [userReasons, setUserReasons] = useState([]);
    const [myConfig, setMyConfig] = useState(null);
    const [isDisconnected, setIsDisconnected] = useState(false);
    const [isSignOutSuccess, setIsSignOutSuccess] = useState(false);
    const [stateChangeSuccess, setStateChangeSuccess] = useState(false);
    const [count, setCount] = useState(0);
    const [mappings, setMappings] = useState(null);
    const browserTimezone = moment.tz.guess();
    
    //@ts-ignore
    const { userAccessToken } = useAuth0();

    const dispatch = useDispatch();
    const statesLog = useSelector((state: AppState) => state.statesLog);
    const phoneTokenState = useSelector((state: AppState) => state.phoneToken);
    const workspaceSid = phoneTokenState.data && phoneTokenState.data.workspaceSid;
    const taskRouterWorkerState = useSelector((state:AppState) => state.taskRouterWorker);
    const deviceState = useSelector((state: AppState) => state.deviceState);
    
    console.log(taskRouterWorkerState);
    
    const intialState = {
        umatilla: false,
        ashburn: false,
        saoPaulo:false
    }
    const [state, setState] = useState(intialState);

    const handleLocationChange = (event?, isReset?) => {
        if (isReset) {
            setState(intialState);
            return;
        }
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const hanldeOpenContent = (resCreated?:boolean) => {
        if (resCreated) 
            setOpenContent(true)
        else
            setOpenContent(!openContent);
        setPhoneType('Inbound');
        setOpenOutbound(false);
    }

    const handleOutboundContent = (resCreated?:boolean) => {
        console.log(resCreated);
        if (resCreated) 
            setOpenContent(true)
        else
            setOpenOutbound(!openOutbound);                
        setPhoneType('Outbound');
       // setOpenContent(false);
    }

    const handleViewScreen = (viewNumber: string) => setView(viewNumber);

    console.log("why screen not opening", openOutbound);
    console.log("why screen not opening", openContent);

    const getMappings = async () => {
        const response = await Axios.get(`${AppSettings.ApiTwilioUrl}/api/ScheduleTypeMapping`);
        if (response && response.data) {
            setMappings(response.data);
        }
    }

    useEffect(() => {
        if (userAccessToken) {
            getMappings();
            getScheduleTypeForCampaign()
            getMyConfig();
            //Recieve notifications
            const connectionSettings: signalR.IHttpConnectionOptions = {};
            connectionSettings.skipNegotiation = true;
            connectionSettings.transport = signalR.HttpTransportType.WebSockets;
            connectionSettings.accessTokenFactory = () => userAccessToken;

            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${AppSettings.ForecastApiWSBaseUrl}/agentNotifications`,
                    connectionSettings,
                )
                .withAutomaticReconnect()
                .configureLogging(signalR.LogLevel.Information)
                .build();

            connection
                .on("ReceiveSkillMatrixUpdates", (message: string) => {
                    console.info(`Message received ${message}`);
                    dispatch(loadMyAssignmentData());
                    dispatch(getMyRosterCampaigns());
                });

            connection
                .on("ReceiveAssignmentUpdates", (message: string) => {
                    console.info(`Message received ${message}`);
                    dispatch(loadMyAssignmentData());
                    dispatch(getMyRosterCampaigns());
                    // getScheduleTypeForCampaign();
                });

            connection.start().then(() => {
                console.info("Connection established - Agent Notifications");
            });

            const connection_twilio = new signalR.HubConnectionBuilder()
                .withUrl(`${AppSettings.TwilioApiWSBaseUrl}/agentStatesUpdate`,
                    connectionSettings,
                )
                .withAutomaticReconnect()
                .configureLogging(signalR.LogLevel.Information)
                .build();


            connection_twilio.start().then(() => {
                console.info("Connection established from app to twilio api");
            });

            connection_twilio
                .on("Customer_Call_Hangup", (message: string) => {
                    console.log("recieved event here - in customer call hangup")
                    changeCustomerHang();
                });

            return function cleanup() {
                connection_twilio.stop().then(() => {
                    console.info("Connection stopped - twilio");
                })
                connection.stop().then(() => {
                    console.info("Connection stopped - Agent Notifications");
                })
            };
        }

    }, [userAccessToken]);
    
    const getOfflineMapping = () => {
        let activitySid;
        const offlineMapping = mappings && mappings.filter(x => x.scheduleTypeName === "Offline");
        if (offlineMapping) activitySid = offlineMapping[0].twilioActivitySid;
        return activitySid;
    }

    useEffect(()=> {        
        if (taskRouterWorkerState.state === RESERVATION_CREATED) {
            const audio = new Audio("https://sdk.twilio.com/js/client/sounds/releases/1.0.0/incoming.mp3?cache=2.1.1");
            audio.play();
            const currentReservation = taskRouterWorkerState.currentReservation;
            if (currentReservation) {
                if (currentReservation.task.attributes.type !== "text") {
                    setCallState(CallStates.INCOMING);
                    handleStateChange(CallStates.INCOMING);
                }
                if(currentReservation.task.attributes.direction === 'inbound' || currentReservation.task.attributes.direction === "inbound-transfer" || currentReservation.task.attributes.direction === "inbound-conference") {
                    hanldeOpenContent(true);
                } else {
                    console.log("Am I not coming here?");
                    handleOutboundContent(true);
                }                
            }            
        }
        if (taskRouterWorkerState.state === RESERVATION_WRAPUP) {
            deviceState.device && deviceState.device.disconnectAll();
        }
        if (taskRouterWorkerState.state === RESERVATION_CANCELLED) {
            setCallReject(true);
        }               
        if (taskRouterWorkerState.state === WORKER_DISCONNECTED) {
            updatePhoneTwilioActivity(null, "signout taskrouter websocket disconnected", CallStates.OFFLINE);
            Toast.show({
                html: `You have been signed out of the phone because taskrouter websocket disconnected`,
                color: 'b-red',
                style: 'color:white',
                timeout: 30000
            });
            setIsSignOutSuccess(true);
        }
        if (taskRouterWorkerState.state === WORKER_ACTIVITY_MISMATCH) {
            deviceState.device && deviceState.device.disconnectAll();
            CreateAgentPhoneState(CallStates.OFFLINE);
            Toast.show({
                html: `You have been signed out of the phone.`,
                color: 'b-red',
                style: 'color:white',
                timeout: 30000
            });
            setIsSignOutSuccess(true);
        }        
    },[taskRouterWorkerState])

    useEffect(() => {
        try {
            dispatch(initPhoneWorker());
        } catch (e) {
            dispatch(workerError(e))
        }
    },[dispatch])

    useEffect(() => {
        if (phoneTokenState && phoneTokenState.data) {            
            const token = phoneTokenState.data.accessToken;            
            const location = {
                ashburn:state.ashburn,
                umatilla:state.umatilla,
                saoPaulo: state.umatilla
            }
            dispatch(initPhoneDevice(token,location));            
        }
    }, [phoneTokenState]);

    console.log(deviceState);

    const handleAcceptReservation = async () => {
        //TODO - future twilio accept reservation method to be called.
        const currentReservation = taskRouterWorkerState.currentReservation;
        if (currentReservation) {
            const reservationId = currentReservation && currentReservation.sid;
            // var nstRequest = {
            //             reservationSid: currentReservation.sid,
            //             taskSid: currentReservation.task.sid,
            //             fromNumber: currentReservation.task.attributes.from
            //         }
            // const res = await Axios.post("http://localhost:5006/api/TwilioCallActions/ConferenceInst", nstRequest);
            // console.log("Request for conf instruction",nstRequest);
            let request;
            if (currentReservation.task.attributes?.type === "Transfer") {
                request = {
                    "type": "transferaccept",
                    "workspacesid": workspaceSid,
                    "reservation": reservationId
                }
                
            } else if (currentReservation.task.attributes?.type === "Conference") {
                request = {
                    "type": "conferenceaccept",
                    "workspacesid": workspaceSid,
                    "reservation": reservationId
                }
            
            } else {
                request = {
                    "type": "resaccept",
                    "workspacesid": workspaceSid,
                    "reservation": reservationId
                }
                
            }
            const response = await Axios.post(`${AppSettings.ReservationAcceptUrl}/qa/wrapup`, request);
        }
    }

    const rejectPending = (schedule, type,callState) => {
        fetchReservation(taskRouterWorkerState.worker, dispatch);
        const reservations = taskRouterWorkerState.reservations;
        const currentReservation = taskRouterWorkerState.currentReservation;
        // if (reservations) {
        //     const pendingReservations = reservations.filter(x => x.reservationStatus === "pending");
        //     if (pendingReservations && pendingReservations.length > 0) {
        //         pendingReservations.forEach(eachReservation => {
        //             eachReservation.reject();
        //         });
        //     }
        // }
        if (currentReservation !== null) {
            currentReservation.reject();
        }
        updatePhoneTwilioActivity(schedule, type,callState);
    }

    const updatePhoneTwilioActivity = (schedule, type, callState) => {
        let activitySid = ''; let existingMapping = null;
        if (schedule !== null) {
            existingMapping = mappings && mappings.filter(x => x.scheduleTypeId === schedule.id);
            if (existingMapping) activitySid = existingMapping[0].twilioActivitySid;
        }
        if (schedule === null && type === "signout") {
            existingMapping = mappings && mappings.filter(x => x.scheduleTypeName === "Offline");
            activitySid = getOfflineMapping();
        }
        if (existingMapping !== null) dispatch(setCurrentSelectedActivity(existingMapping[0]));

        if (taskRouterWorkerState.worker && activitySid !== "") {
            var workerClient = taskRouterWorkerState.worker;
            //TODO: If it fails to change activity due to pending, try once and then try to login as productve.
            //update the twilio activity
            if (count >= 1) {
                const getProductive = mappings && mappings.filter(x => x.scheduleTypeName === "Productive");
                if (getProductive) {
                    workerClient.update("ActivitySid", getProductive[0].twilioActivitySid, function (error, worker) {
                        if (type === "signIn") {
                            dispatch(startPhoneLogging(browserTimezone, worker.dateStatusChanged.toISOString()));
                            setIsSignOutSuccess(false);
                            dispatch(getPhoneToken());
                            fetchReservation(taskRouterWorkerState.worker, dispatch);                        
                            setCount(0);
                        }
                    });
                }
                
            }
            workerClient.update("ActivitySid", activitySid, function (error, worker) {
                if (error) {
                    //Alert -> activity update failed and then reverting back to prev state. (show state)
                    setStateChangeSuccess(false);
                    rejectPending(schedule, type,callState);
                    setCount(count+1);
                    alert(error.message);                    
                } else {
                    setStateChangeSuccess(true);
                    console.log("after update", worker.dateStatusChanged.toISOString());
                    if (type === "signIn") {
                        dispatch(startPhoneLogging(browserTimezone, worker.dateStatusChanged.toISOString()));
                        setIsSignOutSuccess(false);
                        dispatch(getPhoneToken());
                        fetchReservation(taskRouterWorkerState.worker, dispatch);                        
                    }
                    if (type === "change") {
                        createStatesEntry(schedule, worker.dateStatusChanged.toISOString());
                    }
                    if (type === "signout") {
                        dispatch(stopPhoneLogging(browserTimezone, "User sign out", false));
                        deviceState && deviceState.connection && deviceState.connection.disconnect();
                        setIsSignOutSuccess(true);
                    }                    
                    handleStateChange(callState);
                }
            });
        }
    }

    const createStatesEntry = (scheduleType, startTimeInUtc) => {
        const updatedList = [...statesLog.data];
        if (scheduleType && scheduleType.id) {
            const newStateEntry: TaskEntry = {
                taskEntryGroupId: statesLog.clockInId,
                description: scheduleType.isProductive ? 'Available for Phone' : '',
                startTimeInUtc: startTimeInUtc,
                endTimeInUtc: null,
                scheduleTypeId: scheduleType.id,
            };
            dispatch(startLoggingState(newStateEntry));
        } else {
            //If None is selected close the task
            const indexMatch = updatedList.findIndex(x => x.id === statesLog.clockInId);
            if (indexMatch >= 0) {
                const taskentries = updatedList[indexMatch].taskEntries;
                if (taskentries) {
                    //@ts-ignore
                    const taskIndex = taskentries.findIndex(x => x.id === statesLog.logginInId);
                    if (taskIndex >= 0) {
                        updatedList[indexMatch].taskEntries[taskIndex].endTimeInUtc = moment(Date.now()).utc().format();
                        dispatch(stopLoggingState(updatedList[indexMatch].taskEntries[taskIndex]));
                    }
                }
            }
        }

    }

    const getScheduleTypeForCampaign = async (scheduleTypeId?) => {
        try {
            const response = await Axios.get(`${AppSettings.ApiSchedulerUrl}/api/ScheduleType/ScheduleStates`);
            const schedules = response.data;
            setUserReasons(schedules.scheduleTypes);
        } catch (e) {
            console.log(e);
        }
    }

    const getMyConfig = async () => {
        try {
            const response = await Axios.get(`${AppSettings.ApiTwilioUrl}/AgentConfigure/me`);
            const myConfig = response.data;
            setMyConfig(myConfig);
        } catch (e) {
            console.log(e);
        }
    }

    const handleStateChange = (callState) => {        
        if (callState === CallStates.WRAPUP) {
            //  hangupAgentCall();
            console.log("I come here and do smthg?");
            fetchReservation(taskRouterWorkerState.worker, dispatch);
            deviceState.device && deviceState.device.disconnectAll();
            setCallState(CallStates.WRAPUP);
        }
        if (callState === CallStates.DECLINE) {
            console.log('I am declining call');
            deviceState.connection && deviceState.connection.reject();
            deviceState.device.disconnectAll();
            setCallState(CallStates.DECLINE);
            //When in transfer or conference dont reject.
            const currentTask = taskRouterWorkerState.currentReservation?.task;
            if (currentTask && currentTask.attributes.direction === 'inbound-conference' || currentTask.attributes.direction === 'inbound-transfer' )
                return;

            taskRouterWorkerState.currentReservation.reject(
                function(error, reservation) {
                    console.log("reservation rejected");
                    if(error) {
                        console.log(error.code);
                        console.log(error.message);
                        return;
                    }
                    console.log("reservation rejected");
                    for (const property in reservation) {
                        console.log(property+" : "+reservation[property]);
                    }
                }
            );
        }
        setCallState(callState);        
        CreateAgentPhoneState(callState);
    }

    const changeCustomerHang = () => setCustomerHang(true);
    const hangupFinished = () => setCustomerHang(false);
    const rejectFinished = () => setCallReject(false);

    const CreateAgentPhoneState = async (currentState) => {
        const reservations = taskRouterWorkerState.reservations;
        let currentReservation = taskRouterWorkerState?.reservations?.filter(x => x.sid === taskRouterWorkerState.reservationSid);
        let queueName = ''; let direction = '';
        if (currentReservation[0] && currentReservation[0].task.attributes) {
            const attributes = currentReservation[0].task.attributes;
            direction = attributes.direction;
            if (attributes.direction === "inbound-transfer" || attributes.direction === "inbound-conference" ) {
                queueName = attributes.queue    
            } else {
                queueName = attributes.direction === 'outbound' ? attributes.campaign : attributes.Campaigns;
            }            
        }

        const request =
        {
            newState: currentState,
            campaignName: currentState === CallStates.READY ? '' : queueName,
            direction: currentState === CallStates.READY ? '' : direction
        };
        if (request.newState === CallStates.ON_CALL &&  request.campaignName === "" && request.direction === "") return;
        try {
            await Axios.post(`${AppSettings.ApiTwilioUrl}/api/AgentPhoneStates`, request);
        } catch (e) {
            const errorMsg = e;
            console.log(e);
        }
    }

    const hangupAgentCall = async () => {
        var hangRequest = {
            "deleteParty": "Agent",
            "transfer": false
        }
        try {
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/DeleteConference`, hangRequest);
        } catch (err) {
            console.log(err);
        }
    }

    const addNewListItem = () => {
        let updateList;
        if (view === 'one') updateList = [...addList];
        if (view === 'two') updateList = [...addListTwo];
        if (view === 'three') updateList = [...addListThree];
        if (view === 'four') updateList = [...addListFour];

        const newItem = "item" + updateList.length + 1;
        updateList.push(newItem);

        if (view === 'one') setAddList(updateList);
        if (view === 'two') setAddListTwo(updateList);
        if (view === 'three') setAddListThree(updateList);
        if (view === 'four') setAddListFour(updateList);

    }
    const renderList = (viewList) => {
        return (
            <div>
                {viewList.map((text, index) => (
                    <div className={classes.paddingBtm} key={text}>
                        <AccountCircle className={classes.iconColor} />
                    </div>
                ))}
            </div>
        )
    }

    return (
        <>
            <div className={classes.mainRightSide}>
                <div className={classes.rightSidebar}>
                    <div className={classes.top}>
                        <div style={{ display: 'flex' }}>
                            <span onClick={() => handleViewScreen('one')} className={clsx(classes.icons, classes.iconOne, classes.iconPointer, { [classes.iconOneActive]: view === 'one' })}></span>
                            <span onClick={() => handleViewScreen('two')} className={clsx(classes.icons, classes.iconTwo, classes.iconPointer, { [classes.iconTwoActive]: view === 'two' })}></span>
                        </div>
                        <div style={{ display: 'flex' }} className={classes.paddingBtm}>
                            <span onClick={() => handleViewScreen('three')} className={clsx(classes.icons, classes.iconThree, classes.iconPointer, { [classes.iconThreeActive]: view === 'three' })}></span>
                            <span onClick={() => handleViewScreen('four')} className={clsx(classes.icons, classes.iconFour, classes.iconPointer, { [classes.iconFourActive]: view === 'four' })}></span>
                        </div>
                        <div>
                            {view === 'one' && renderList(addList)}
                            {view === 'two' && <> <TwoBox /> {renderList(addListTwo)}</>}
                            {view === 'three' && <><ThreeBox /> {renderList(addListThree)}</>}
                            {view === 'four' && <><FourBox /> {renderList(addListFour)}</>}
                        </div>
                        <AddNew className={clsx(classes.iconPointer, classes.iconAdd)} onClick={addNewListItem} />
                    </div>
                    <div className={classes.bottom}>
                        <div className={classes.paddingBtm}><CodeSettings className={classes.iconPointer} /></div>
                        <div className={classes.paddingBtm}><ContactsIcon className={classes.iconPointer} /></div>
                        <div className={classes.paddingBtm} onClick={() => hanldeOpenContent()}>
                            <span className={clsx(classes.icons, classes.iconChannel, classes.iconPointer, { [classes.iconChannelActive]: openContent })}></span>
                        </div>
                        <div className={classes.paddingBtm} onClick={() => handleOutboundContent()}>
                            <PhoneCall className={classes.iconPointer} />
                        </div>
                        <div className={classes.paddingBtm}><ChatThin className={classes.iconPointer} /></div>
                    </div>
                </div>                
                {openContent && <PhoneSignIn callState={callState} handleStateChange={handleStateChange} type='Inbound' customerHangup={customerHang} hangupFinished={hangupFinished} callReject={callReject} rejectFinished={rejectFinished} userReasons={userReasons} myConfig={myConfig} isDisconnected={isDisconnected} updateActivity={updatePhoneTwilioActivity} isSignOutSuccess={isSignOutSuccess} stateChangeSuccess={stateChangeSuccess} setStateChangeSuccess={setStateChangeSuccess} signOutSuccess={setIsSignOutSuccess} handleLocationChange={handleLocationChange} handleAcceptReservation={handleAcceptReservation} />}
                {openOutbound && <PhoneSignIn callState={callState} handleStateChange={handleStateChange} type='Outbound' hangupFinished={hangupFinished} customerHangup={customerHang} userReasons={userReasons} myConfig={myConfig} isDisconnected={isDisconnected} updateActivity={updatePhoneTwilioActivity} isSignOutSuccess={isSignOutSuccess} stateChangeSuccess={stateChangeSuccess} setStateChangeSuccess={setStateChangeSuccess} signOutSuccess={setIsSignOutSuccess} handleLocationChange={handleLocationChange} handleAcceptReservation={handleAcceptReservation} />}
            </div>
        </>
    )
}

