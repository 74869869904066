//@ts-nocheck
import createReducer from "./createReducer";
import { 
    LOAD_MY_AVAILABLE_HOURS_ERROR,
    LOAD_MY_AVAILABLE_HOURS_START,
    LOAD_MY_AVAILABLE_HOURS_SUCCESS,
    myAvailableHoursActions,
    LoadMyAvailableHoursStartedAction,
    LoadMyAvailableHoursSuccessAction,
    LoadMyAvailableHoursErrorAction
} from '../actions/myAvailableHours.action'

export interface MyAvailableHoursState {
    state: string;
    error: string;
    data: [];
}

const initialState: MyAvailableHoursState = {
    state: null,
    error: null,
    data: []
}

function loadMyAvailableHrsStart(state:MyAvailableHoursState, action: LoadMyAvailableHoursStartedAction): MyAvailableHoursState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadMyAvailableHrsSuccess(state:MyAvailableHoursState, action: LoadMyAvailableHoursSuccessAction): MyAvailableHoursState {    
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadMyAvailableHrsError(state:MyAvailableHoursState, action: LoadMyAvailableHoursErrorAction): MyAvailableHoursState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const MyAvailableHoursReducer =
    (state: MyAvailableHoursState, action: myAvailableHoursActions): MyAvailableHoursState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_MY_AVAILABLE_HOURS_START]: loadMyAvailableHrsStart,
            [LOAD_MY_AVAILABLE_HOURS_SUCCESS]: loadMyAvailableHrsSuccess,
            [LOAD_MY_AVAILABLE_HOURS_ERROR]: loadMyAvailableHrsError
        });

        return fnUpdateState(state, action);
    }

export default MyAvailableHoursReducer;