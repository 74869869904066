import createReducer from "./createReducer";
import { 
    LOAD_WEEKLY_FORECAST_START, 
    LOAD_WEEKLY_FORECAST_SUCCESS, 
    LOAD_WEEKLY_FORECAST_ERROR 
} from "../actions/forecast.action";

const initialState = {
    state: null,
    error: null,
    isError: false,
    data: []
};

function loadWeeklyForecastSuccess(state, action) {
    return {
        ...state,
        state: action.type,        
        data: action.data
    }
}

function loadWeeklyForecastStarted(state, action) {
    return {
        ...state,        
        state: action.type
    }
}

function loadWeeklyForecastError(state, action) {
    return {
        ...state,        
        error: action.error,
        isError: true,
        state: action.type
    }
}

export default createReducer(initialState, {
    [LOAD_WEEKLY_FORECAST_START]: loadWeeklyForecastStarted,
    [LOAD_WEEKLY_FORECAST_SUCCESS]: loadWeeklyForecastSuccess,
    [LOAD_WEEKLY_FORECAST_ERROR]: loadWeeklyForecastError,
})