const CallStates = {
    CONNECTING: "Connecting",
    READY: "Ready",
    INCOMING: "Incoming",
    DECLINE:"Declined",
    ON_CALL: "OnCall",
    ON_HOLD: "OnHold",
    OFFLINE: "Offline",
    ONLINE: "Online",
    WRAPUP: "WrapUp",
    COMPLETEWRAPUP: "Complete WrapUp",
    ACCEPTED: "Accepted",
    IDLE: "Idle"
  };

  export default CallStates;