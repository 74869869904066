import Axios from "axios";
import { AppSettings } from "../../appSettings";
import { TaskEntry } from "../../StatesLog/StatesLoggingContainer";
import { STATE_START_PHONE_LOG_START, 
        STATE_START_PHONE_LOG_SUCCESS, 
        STATE_START_PHONE_LOG_ERROR,
        STATE_STOP_PHONE_LOG_START,
        STATE_STOP_PHONE_LOG_SUCCESS,
        STATE_STOP_PHONE_LOG_ERROR,
         } from "./statesLog.action";

export const LOAD_PHONE = "LOAD_PHONE";
export const LOAD_PHONE_SUCCESS = "LOAD_PHONE_SUCCESS";
export const LOAD_PHONE_ERROR = "LOAD_PHONE_ERROR";

export interface PhoneTokenResponse {
    agentSecondaryId: string,
    accessToken: string,
    workspaceSid: string,
    agentName: string
}

export interface LoadPhoneAction {
    type: typeof LOAD_PHONE;
}

export interface LoadPhoneSuccessAction {
    type: typeof LOAD_PHONE_SUCCESS;
    data: PhoneTokenResponse
}

export interface LoadPhoneError {
    type: typeof LOAD_PHONE_ERROR;
    error: string;
}

export interface StartPhoneLoggingStartAction {
    type: typeof STATE_START_PHONE_LOG_START;
}

export interface StartPhoneLoggingSuccessAction {
    type: typeof STATE_START_PHONE_LOG_SUCCESS;
    data: any
}

export interface StartPhoneLoggingErrorAction {
    type: typeof STATE_START_PHONE_LOG_ERROR;
    error: string
}

export interface StopPhoneLoggingStartAction {
    type: typeof STATE_STOP_PHONE_LOG_START;
}

export interface StopPhoneLoggingSuccessAction {
    type: typeof STATE_STOP_PHONE_LOG_SUCCESS;
    isForceSignout: boolean
}

export interface StopPhoneLoggingErrorAction {
    type: typeof STATE_STOP_PHONE_LOG_ERROR;
    error: string
}


export type PhoneTokenAction =     LoadPhoneAction | LoadPhoneSuccessAction | LoadPhoneError | 
                                    StartPhoneLoggingStartAction | StartPhoneLoggingSuccessAction |
                                    StartPhoneLoggingErrorAction | StopPhoneLoggingStartAction | StopPhoneLoggingSuccessAction |
                                    StopPhoneLoggingErrorAction;

export const getPhoneToken = () => async (dispatch: any) => {
    dispatch({ type: LOAD_PHONE });
    try {
       
        const response =        
            await Axios.get<PhoneTokenResponse>(`${AppSettings.ApiTwilioUrl}/api/TwilioVoiceToken`);
        dispatch({
            type: LOAD_PHONE_SUCCESS,
            data: response.data
        });
    } catch (err) {
        dispatch({
            type: LOAD_PHONE_ERROR,
            error: err
        })
    }
}
