import createReducer from "./createReducer";
import {
    SAVE_SELECTED_CAMPAIGN,
    SAVE_SELECTED_ENDDATE,
    SAVE_SELECTED_STARTDATE
} from '../actions/filterParametersSelected.action'

const initialState = {
    selectedStartDate: null,
    selectedEndDate: null,
    selectedCampaign: null
};

function setCampaign (state, action) {
    return{
        ...state,
        selectedCampaign: action.campaign
    }
}

function setStartDate (state, action) {
    return{
        ...state,
        selectedStartDate: action.startDate
    }
}
function setEndDate (state, action) {
    return{
        ...state,
        selectedEndDate: action.endDate
    }
}


export default createReducer(initialState, {
    [SAVE_SELECTED_CAMPAIGN]: setCampaign,
    [SAVE_SELECTED_ENDDATE]: setEndDate,
    [SAVE_SELECTED_STARTDATE]: setStartDate
}); 

