//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_MY_APPLIED_HOURS";
export const LOAD_MY_APPLIED_HOURS_START = `${actionNamePrefix}_START`;
export const LOAD_MY_APPLIED_HOURS_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_MY_APPLIED_HOURS_ERROR = `${actionNamePrefix}_ERROR`;

export interface LoadMyAppliedHoursStartedAction {
    type: typeof LOAD_MY_APPLIED_HOURS_START;
}

export interface LoadMyAppliedHoursSuccessAction {
    type: typeof LOAD_MY_APPLIED_HOURS_SUCCESS;
    data: any
}

export interface LoadMyAppliedHoursErrorAction {
    type: typeof LOAD_MY_APPLIED_HOURS_ERROR;
    error: string;
}

export type myAppliedHoursActions = LoadMyAppliedHoursStartedAction |
                                LoadMyAppliedHoursSuccessAction|
                                LoadMyAppliedHoursErrorAction;

export const loadMyAppliedHours = (request) => async (dispatch) => {
    dispatch({type: LOAD_MY_APPLIED_HOURS_START})
    try {
        const response = await Axios.post(`${AppSettings.ApiSchedulerUrl}/api/AppliedHours`, request);
        dispatch({
            type: LOAD_MY_APPLIED_HOURS_SUCCESS,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_MY_APPLIED_HOURS_ERROR,
                error: errorMessage
            })
        }
    }
}

