import createReducer from "./createReducer";
import {LOAD_AGENT_START, LOAD_AGENT_SUCCESS, LOAD_AGENT_ERROR} from '../actions/systemUser.action';


const initialState = {
    data: [],
    state: null,
    error: null
};

function loaded(state, action) {
    return {
        ...state,
        data: action.data,
        error: null,
        state: action.type
    }
}

function loadStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

function loadError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}


export default createReducer(initialState, {
    [LOAD_AGENT_START]: loadStarted,
    [LOAD_AGENT_SUCCESS]: loaded,
    [LOAD_AGENT_ERROR]: loadError    
}); 