import moment from "moment";
import { convertToStartOfDay } from "../../../utils/utilsForDate";

const browserTimezone = moment.tz.guess();

export const getDuration = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const duration = moment.duration(end.diff(start));
    return duration;
}

export default function getFormatedDurationValue(duration) {
    //const durationValue = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
    const durationValue = Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(":mm:ss")
    return durationValue;
}

export const getSelectedResource = (resources, selectedResource) => {
    let selectedResourceName = resources.filter(x => x.id === selectedResource);
    if (selectedResourceName.length > 0) {
        selectedResourceName = selectedResourceName[0].name;
    }
    return selectedResourceName;
}

export const getSelectedScheduleType = (scheduleTypes, selectedSchedule) => {
    let selectedScheduleType = scheduleTypes.filter(x => x.id === selectedSchedule);
    if (selectedScheduleType.length > 0) {
        selectedScheduleType = selectedScheduleType[0].name;
    }
    return selectedScheduleType;
}

export const createStartDateTimeValue = (startDateValue, time) => {
    const timeValue = time.split(':');
    const startDate = convertToStartOfDay(startDateValue, browserTimezone).set({ "hour": timeValue[0], "minute": timeValue[1] }).toDate();
    return startDate;
}

export const createEndDateTimeValue = (startDateValue, time, shiftStartTime) => {
    const timeValue = time.split(':');
    const selectedStartTime = shiftStartTime.replace(":", "");
    const selectedEndTime = time.replace(":", "");
    const difference = selectedEndTime - parseInt(selectedStartTime);

    let endDate = null;

    if (difference > 0) {
        endDate = convertToStartOfDay(startDateValue, browserTimezone).set({ "hour": timeValue[0], "minute": timeValue[1] }).toDate();
    }
    else {
        const nextDay = convertToStartOfDay(startDateValue, browserTimezone).add(1, 'd');
        endDate = nextDay.set({ "hour": timeValue[0], "minute": timeValue[1] }).toDate();
    }
    return endDate;
}

export const getSlotDates = (
    slotTime: string,
    shiftStartDateTime: Date): Date => {

    const numericShiftStartTime: number = (shiftStartDateTime.getHours() * 100) +
        shiftStartDateTime.getMinutes();

    const numericSlotTime: number = parseTimestring(slotTime);

    const slotTimeHours: number = Math.floor(numericSlotTime / 100);
    const slotTimeMins: number = numericSlotTime % 100;

    // 3 Cases
    // Entire slot start next day than shift.
    let slotDateTime: Date = null;

    const da = moment.tz(shiftStartDateTime, browserTimezone).local().startOf("day");

    if (numericSlotTime < numericShiftStartTime) {
        const nextDayToShiftStartDate = da
            .add(1, "day");

        slotDateTime = nextDayToShiftStartDate
            .add(slotTimeHours, "hours")
            .add(slotTimeMins, "minutes")
            .toDate();
    }
    else {
        slotDateTime = da
            .add(slotTimeHours, "hours")
            .add(slotTimeMins, "minutes")
            .toDate();
    }

    return slotDateTime;
}


const parseTimestring = (timestring: String): number => {
    const formattedTimestring = timestring.toUpperCase();
    var numericTime = parseInt(timestring.replace(/[^0-9.]/g, ""));

    // FOR PM Times just add 1200.. so 1:00 PM becomes 100 and then 1200 + 100 becomes 1300
    if (formattedTimestring.indexOf("PM") !== -1)
        numericTime = numericTime >= 1200 && numericTime <= 1259 ?
            numericTime :
            numericTime + 1200;

    return numericTime;
}

export const getDifferenceInMinutes = (dt2, dt1) => {
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));//TODO remove
}

export const isSlotsOverlap = (slots) => {
    let overlapping = {}; 
    for(var eachSlot of slots) {
        overlapping = slots.find(s =>   eachSlot.endDate > s.startDate &&
            eachSlot.startDate < s.endDate &&
            s != eachSlot);
        if(overlapping !== undefined || overlapping !== null) 
        {             
            break; 
        } 
        else {          
            continue;
        } 
    }
    return overlapping;        
}

export const removeEmptySlotRow = (slotsData) => {
    let slots = [...slotsData];
    slots = slots.filter(value => Object.keys(value).length !== 0);
    return slots;
}

export const getDatesOfRange = (dateRange) => {
    if (dateRange.to != null) {
        for (var arr = [], dt = new Date(dateRange.from); dt <= dateRange.to; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    }
}