import {
    LoadRoleAction,
    LoadRolesError,
    LoadRoleSuccess,
    LOAD_ROLES,
    LOAD_ROLES_SUCCESS,
    LOAD_ROLES_ERROR,
    RoleActions,
    RolesResponse
} from "../actions/roles.action";
import createReducer from "./createReducer";

export interface RolesState {
    state: string;
    error: string;
    roles: Array<RolesResponse>;
}

const initialState: RolesState = {
    state: null,
    error: null,
    roles: []
}

function loadRoles(state: RolesState, action: LoadRoleAction): RolesState {
    return {
        ...state,
        state: action.type,
        error: null,
        roles: []
    }
}

function loadRolesSuccess(state: RolesState, action: LoadRoleSuccess): RolesState {
    return {
        ...state,
        state: action.type,
        error: null,
        roles: action.roles
    }
}

function loadRolesError(state: RolesState, action: LoadRolesError): RolesState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        roles: []
    }
}

const RolesReducer =
    (state: RolesState, action: RoleActions): RolesState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_ROLES]: loadRoles,
            [LOAD_ROLES_SUCCESS]: loadRolesSuccess,
            [LOAD_ROLES_ERROR]: loadRolesError
        });

        return fnUpdateState(state, action);
    }

export default RolesReducer;
