import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Breadcrumb } from "./Breadcrumb";
import { getRoutes } from './util/getRoutes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IconButton, Grid } from "@mui/material";

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    menuBar: {
        backgroundColor: '#ffffff',
        color: '#686868',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        height: 'auto',
        marginTop: '65px',
    },
    drawserStyle: {
        justifyContent:'flex-end', 
        display: 'flex',
        height:'10px'
    },
}));

export default function InnerHeader(props) {

    const classes = useStyles({});
    const [open, setOpen] = useState(true);
    const pathname = window.location.pathname;
    const routesList = getRoutes();

    useEffect(() => {
        console.log(pathname);
        if(pathname === "/my-work") setOpen(false);
    },[pathname])   

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return <>
        <div className={clsx(classes.menuBar)}>
            {
                open && <Breadcrumb routesList={routesList} currentPath={pathname} />
            }
        </div>
        { !open && <Grid className={classes.drawserStyle}><IconButton onClick={handleDrawerOpen} size="large"><ExpandMoreIcon /></IconButton></Grid> }
        { open && <Grid className={classes.drawserStyle}><IconButton onClick={handleDrawerClose} size="large"><ExpandLessIcon /></IconButton></Grid> }
    </>;
}