import {
    ActualOutputAction,
    LoadActualOutputAction,
    LoadActualOutputError,
    GetActualOutputResponse,
    LoadActualOutputSuccessAction,
    SaveActualOutputAction,
    SaveActualOutputSuccessAction,
    SaveActualOutputError,
    LOAD_ACTUAL_OUTPUT,
    LOAD_ACTUAL_OUTPUT_ERROR,
    LOAD_ACTUAL_OUTPUT_SUCCESS, 
    SAVE_ACTUAL_OUTPUT,
    SAVE_ACTUAL_OUTPUT_ERROR,
    SAVE_ACTUAL_OUTPUT_SUCCESS
} from "../actions/actualOutput.action";
import createReducer from "./createReducer";

export interface ActualOutputState {
    state: string;
    error: string;
    actualOutput: GetActualOutputResponse;
}

const initialState: ActualOutputState = {
    state: null,
    error: null,
    actualOutput: null
}

function loadActualOutput(state:ActualOutputState, action: LoadActualOutputAction): ActualOutputState {
    return {
        ...state,
        state: action.type,
        error: null,
        actualOutput: null
    }
}

function loadActualOutputSuccess(state:ActualOutputState, action: LoadActualOutputSuccessAction): ActualOutputState {
    return {
        ...state,
        state: action.type,
        error: null,
        actualOutput: action.actualOutput
    }
}

function loadActualOutputError(state:ActualOutputState, action: LoadActualOutputError): ActualOutputState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function saveActualOutput(state:ActualOutputState, action: SaveActualOutputAction): ActualOutputState {
    return {
        ...state,
        state: action.type
    }
}

function saveActualOutputSuccess(state:ActualOutputState, action: SaveActualOutputSuccessAction): ActualOutputState {
    return {
        ...state,
        state: action.type,
        error: null,
        actualOutput: action.actualOutput
    }
}

function saveActualOutputError(state:ActualOutputState, action: SaveActualOutputError): ActualOutputState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const ActualOutputReducer =
    (state: ActualOutputState, action: ActualOutputAction): ActualOutputState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_ACTUAL_OUTPUT]: loadActualOutput,
            [LOAD_ACTUAL_OUTPUT_SUCCESS]: loadActualOutputSuccess,
            [LOAD_ACTUAL_OUTPUT_ERROR]: loadActualOutputError,
            [SAVE_ACTUAL_OUTPUT]: saveActualOutput,
            [SAVE_ACTUAL_OUTPUT_SUCCESS]: saveActualOutputSuccess,
            [SAVE_ACTUAL_OUTPUT_ERROR]: saveActualOutputError,
        });

        return fnUpdateState(state, action);
    }

export default ActualOutputReducer;
