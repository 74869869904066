//@ts-nocheck
import createReducer from "./createReducer";
import { 
    LOAD_SHIFT_ERROR,
    LOAD_SHIFT_START,
    LOAD_SHIFT_SUCCESS,
    shiftActions,
    LoadShiftStartedAction,
    LoadShiftSuccessAction,
    LoadShiftErrorAction
} from '../actions/shift.action'

export interface ShiftState {
    state: string;
    error: string;
    data: [];
}

const initialState: ShiftState = {
    state: null,
    error: null,
    data: []
}

function loadShiftStart(state:ShiftState, action: LoadShiftStartedAction): ShiftState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadShiftSuccess(state:ShiftState, action: LoadShiftSuccessAction): ShiftState {    
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadShiftError(state:ShiftState, action: LoadShiftErrorAction): ShiftState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const ShiftReducer =
    (state: ShiftState, action: shiftActions): ShiftState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_SHIFT_START]: loadShiftStart,
            [LOAD_SHIFT_SUCCESS]: loadShiftSuccess,
            [LOAD_SHIFT_ERROR]: loadShiftError
        });

        return fnUpdateState(state, action);
    }

export default ShiftReducer;