import {
    CustomAttributesValueAction,
    LoadCustomAttributesValueAction,
    LoadCustomAttributesValueError,
    LoadCustomAttributesValueSuccessAction,
    LOAD_CUSTOM_ATTRIBUTES_VALUE,
    LOAD_CUSTOM_ATTRIBUTES_VALUE_ERROR,
    LOAD_CUSTOM_ATTRIBUTES_VALUE_SUCCESS, 
    SAVE_CUSTOM_ATTRIBUTES_VALUE,
    SAVE_CUSTOM_ATTRIBUTES_VALUE_ERROR,
    SAVE_CUSTOM_ATTRIBUTES_VALUE_SUCCESS,
    SaveCustomAttributesValueAction,
    SaveCustomAttributesValueError,
    SaveCustomAttributesValueSuccessAction
    
} from "../actions/customAttributesValue.action";
import createReducer from "./createReducer";

export interface CustomAttributesValueState {
    state: string;
    error: string;
    customAttributesValue: Array<any>;
}

const initialState: CustomAttributesValueState = {
    state: null,
    error: null,
    customAttributesValue: []
}

function loadCustomAttributesValue(state:CustomAttributesValueState, action: LoadCustomAttributesValueAction): CustomAttributesValueState {
    return {
        ...state,
        state: action.type,
        error: null,
        customAttributesValue: []
    }
}

function loadCustomAttributesValueSuccess(state:CustomAttributesValueState, action: LoadCustomAttributesValueSuccessAction): CustomAttributesValueState {
    console.log(action);
    return {
        ...state,
        state: action.type,
        error: null,
        customAttributesValue: action.customAttributesValue
    }
}

function loadCustomAttributesValueError(state:CustomAttributesValueState, action: LoadCustomAttributesValueError): CustomAttributesValueState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        customAttributesValue: []
    }
}

function saveCustomAttributesValue(state:CustomAttributesValueState, action: SaveCustomAttributesValueAction): CustomAttributesValueState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function saveCustomAttributesValueSuccess(state:CustomAttributesValueState, action: SaveCustomAttributesValueSuccessAction): CustomAttributesValueState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function saveCustomAttributesValueError(state:CustomAttributesValueState, action: SaveCustomAttributesValueError): CustomAttributesValueState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const CustomAttributesValueReducer =
    (state: CustomAttributesValueState, action: CustomAttributesValueAction): CustomAttributesValueState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_CUSTOM_ATTRIBUTES_VALUE]: loadCustomAttributesValue,
            [LOAD_CUSTOM_ATTRIBUTES_VALUE_SUCCESS]: loadCustomAttributesValueSuccess,
            [LOAD_CUSTOM_ATTRIBUTES_VALUE_ERROR]: loadCustomAttributesValueError,
            [SAVE_CUSTOM_ATTRIBUTES_VALUE]: saveCustomAttributesValue,
            [SAVE_CUSTOM_ATTRIBUTES_VALUE_SUCCESS]: saveCustomAttributesValueSuccess,
            [SAVE_CUSTOM_ATTRIBUTES_VALUE_ERROR]: saveCustomAttributesValueError
        });

        return fnUpdateState(state, action);
    }

export default CustomAttributesValueReducer;
