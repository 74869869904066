//@ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppSettings } from "./appSettings";
import store from "./store/store";
import { Auth0Provider } from './auth0/react-auth0-wrapper';
import setupInterceptor from './auth0/HttpRequestInterceptor';
import { createBrowserHistory } from "history";

//const store = configureStore();
setupInterceptor();
const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  history.replace(path);
}

const a:Number = 6;

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
    <Auth0Provider
        domain={AppSettings.Auth0Domain}
        client_id={AppSettings.Auth0ClientId}
        redirect_uri={AppSettings.Auth0Redirect}
        audience={AppSettings.Auth0Audience}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens={true}
        scope="groups permissions roles"
        prompt="login">
        <Provider store={store}>
            <App />
        </Provider>
    </Auth0Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
