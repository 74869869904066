//@ts-nocheck
import createReducer from "./createReducer";
import { 
    LOAD_MY_BASE_SCHEDULE_START,
    LOAD_MY_BASE_SCHEDULE_SUCCESS,
    LOAD_MY_BASE_SCHEDULE_ERROR,
    myDefaultCampaignActions,
    LoadDefaultCampaignStartedAction,
    LoadDefaultCampaignSuccessAction,
    LoadDefaultCampaignErrorAction
} from '../actions/myBaseSchedule.action'

export interface MyBaseScheduleState {
    state: string;
    error: string;
    data: [];
}

const initialState: MyBaseScheduleState = {
    state: null,
    error: null,
    data: []
}

function loadDefaultCampaign(state:MyBaseScheduleState, action: LoadDefaultCampaignStartedAction): MyBaseScheduleState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadDefaultCampaignSuccess(state:MyBaseScheduleState, action: LoadDefaultCampaignSuccessAction): MyBaseScheduleState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadDefaultCampaignError(state:MyBaseScheduleState, action: LoadDefaultCampaignErrorAction): MyBaseScheduleState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const MyBaseScheduleReducer =
    (state: MyBaseScheduleState, action: myDefaultCampaignActions): MyBaseScheduleState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_MY_BASE_SCHEDULE_START]: loadDefaultCampaign,
            [LOAD_MY_BASE_SCHEDULE_SUCCESS]: loadDefaultCampaignSuccess,
            [LOAD_MY_BASE_SCHEDULE_ERROR]: loadDefaultCampaignError
        });

        return fnUpdateState(state, action);
    }

export default MyBaseScheduleReducer;