//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";
import InventoryAdjustedForecast from "../../vendor/forecast/models/InventoryAdjustedForecast";
import UpdateInventoryAdjustedForecastRequest from "../../vendor/forecast/adjustForecast/UpdateInventoryAdjustedForecastRequest";

const actionNamePrefixLoad = "LOAD_INVENTORY_ADJUSTED_FORECAST";
export const LOAD_INVENTORY_ADJUSTED_FORECAST_START = `${actionNamePrefixLoad}_START`;
export const LOAD_INVENTORY_ADJUSTED_FORECAST_SUCCESS = `${actionNamePrefixLoad}_SUCCESS`;
export const LOAD_INVENTORY_ADJUSTED_FORECAST_ERROR = `${actionNamePrefixLoad}_ERROR`;

const actionNamePrefixUpdate = "UPDATE_INVENTORY_ADJUSTED_FORECAST";
export const UPDATE_ADJUSTED_INVENTORY_FORECAST_START = `${actionNamePrefixUpdate}_START`;
export const UPDATE_ADJUSTED_INVENTORY_FORECAST_SUCCESS = `${actionNamePrefixUpdate}_SUCCESS`;
export const UPDATE_ADJUSTED_INVENTORY_FORECAST_ERROR = `${actionNamePrefixUpdate}_ERROR`;

export const CLEAN_UP_ADJUSTED_INVENTORY_FORECAST = `{actionNamePrefixUpdate}_CLEANUP`;

function loadStart() {
    return {
        type: LOAD_INVENTORY_ADJUSTED_FORECAST_START
    };
}

function loadSuccess(inventoryAdjustedForecast: Array<InventoryAdjustedForecast>) {
    return {
        type: LOAD_INVENTORY_ADJUSTED_FORECAST_SUCCESS,
        data: inventoryAdjustedForecast
    };
}

function loadError(error) {
    return {
        type: LOAD_INVENTORY_ADJUSTED_FORECAST_ERROR,
        error
    }
}

export interface LoadInventoryAdjustedForecastRequest {
    campaignId: number;
    startDate: string;
    endDate: string;
}

export const loadInventoryAdjustedForecast = (request: LoadInventoryAdjustedForecastRequest) => async (dispatch) => {
    dispatch(loadStart());
    try {
        const response = 
        await Axios.get(`${AppSettings.ApiBaseUrl}/api/campaign/${request.campaignId}/fromDateTime/${request.startDate}/toDateTime/${request.endDate}/InventoryAdjustedForecast`);
        dispatch(loadSuccess(response.data));
    } catch (e) {
        dispatch(loadError(e.response.data.Message || e.message));
    }
}

function updateStart() {
    return {
        type: UPDATE_ADJUSTED_INVENTORY_FORECAST_START
    };
}

function updateSuccess() {
    return {
        type: UPDATE_ADJUSTED_INVENTORY_FORECAST_SUCCESS,
    };
}

function updateError(error) {
    return {
        type: UPDATE_ADJUSTED_INVENTORY_FORECAST_ERROR,
        error
    }
}

export const updateInventoryAdjustedForecast = (request: Array<UpdateInventoryAdjustedForecastRequest>) => async (dispatch) => {
    dispatch(updateStart());
    try {
        const response = 
        await Axios.post(`${AppSettings.ApiBaseUrl}/api/InventoryAdjustedForecast`, request);
        dispatch(updateSuccess());
    } catch (e) {
        dispatch(updateError(e.response.data.Message || e.message));
    }
}

function cleanUp() {
    return {
        type: CLEAN_UP_ADJUSTED_INVENTORY_FORECAST
    };
}

export const cleanUpInventoryAdjustedForecast = () => (dispatch) => {
    dispatch(cleanUp())
}