import React, { useEffect, useState } from 'react';
import { useAuth0 } from '../auth0/react-auth0-wrapper';
import {Button,Theme,CircularProgress,Checkbox,FormControl,FormLabel,FormGroup,FormControlLabel} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/reducers';
import PhoneListContainer from './PhoneListContainer';
import { loadStatesLog, STATE_START_PHONE_LOG_START, STATE_START_PHONE_LOG_SUCCESS, STATE_START_PHONE_LOG_ERROR, STATE_STOP_PHONE_LOG_SUCCESS } from '../store/actions/statesLog.action';
import { loadMyAssignmentData, LOAD_ASSIGNMENT_DATA_SUCCESS } from '../store/actions/myAssignments.action';
import { getMyRosterCampaigns, LOAD_MY_ROSTER_CAMPAIGNS_SUCCESS } from '../store/actions/myRosterCampaigns.action';
import CallStates from './CallStates';
import OperationStatusIndicator from '../OperationStatusIndicator';
import { WORKER_INIT_ERROR } from '../store/actions/softPhone/worker.action';
import AlertItems from './AlertItems';
import { WORKER_ERROR } from '../store/actions/softPhone/actionTypes';

interface SignInProps {
    callState: string,
    handleStateChange: (state) => void;
    type: string;
    customerHangup?: boolean
    hangupFinished: () => void;
    callReject?: boolean,
    rejectFinished?: () => void;
    userReasons: any,
    myConfig: any,
    isDisconnected: boolean,
    updateActivity: (id, type, callState) => void;
    isSignOutSuccess: boolean;
    stateChangeSuccess: boolean;
    setStateChangeSuccess: (val) => void;
    signOutSuccess: (val) => void;
    handleLocationChange: (e?, isReset?) => void;
    handleAcceptReservation?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        phoneContainer: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 64,
            height: '90vh',
            width: '450px',
            background: '#1D1E21',
            right: '47px'
        },
        btnContainer: {
            textAlign: 'center',
            margin: 'auto'
        },
        signOutContainer: {
            height: '45px',
            bottom: 0,
            // position: 'absolute',
            width: '100%'
        }
    })
);


export default function PhoneSignIn(props: SignInProps) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [assignments, setAssigments] = useState([]);
    const [rosterCam, setRosterCam] = useState([]);
    //@ts-ignore
    const { userAccessToken } = useAuth0();
    const dispatch = useDispatch();
    const statesLog = useSelector((state: AppState) => state.statesLog);
    const myAssignments = useSelector((state: AppState) => state.myAssignments);
    const rosterCampaigns = useSelector((state: AppState) => state.myRosterCampaign);
    const phoneTokenState = useSelector((state: AppState) => state.phoneToken);
    const worker = useSelector((state: AppState) => state.taskRouterWorker);
    const deviceState = useSelector((state: AppState) => state.deviceState);
    const intialState = {
        umatilla: false,
        ashburn: true,
        saoPaulo:false
    };
    const [state, setState] = useState(intialState);

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        props.handleLocationChange(event, false);
    };

    useEffect(() => {
        if (statesLog.type === null) {
            dispatch(loadStatesLog());
        }
    }, [])

    useEffect(() => {
        if (props.isDisconnected === true) {
            setIsSignedIn(false);
        }
    }, [props.isDisconnected])

    useEffect(() => {
        if (props.isSignOutSuccess === true) {
            setIsSignedIn(false);
            setTimeout(() => {
                props.signOutSuccess(false);
            }, 5000);
        }
    }, [props.isSignOutSuccess])

    useEffect(() => {
        if (myAssignments.data) {
            const { assignments } = myAssignments.data;
            setAssigments(assignments);
        }

    }, [myAssignments.data])

    useEffect(() => {
        if (rosterCampaigns.data) {
            setRosterCam(rosterCampaigns.data);
        }

    }, [rosterCampaigns.data]);

    useEffect(() => {
        if (statesLog.data && statesLog.clockInId > 0) {
            if (myAssignments.state !== LOAD_ASSIGNMENT_DATA_SUCCESS) dispatch(loadMyAssignmentData());
            if (rosterCampaigns.state !== LOAD_MY_ROSTER_CAMPAIGNS_SUCCESS) dispatch(getMyRosterCampaigns());
        }
    }, [statesLog])

    useEffect(() => {
        if (phoneTokenState.state === STATE_START_PHONE_LOG_START) {
            setLoading(true);
        }
        if (phoneTokenState.state === STATE_START_PHONE_LOG_SUCCESS || phoneTokenState.state === STATE_STOP_PHONE_LOG_SUCCESS) {
            setLoading(false);
            dispatch(loadStatesLog());
        }
        if (phoneTokenState.state === STATE_START_PHONE_LOG_ERROR) {
            setLoading(false);
        }
        setIsSignedIn(phoneTokenState.isSignedIn);
    }, [phoneTokenState])

    const handleSignIn = () => {
        if (userAccessToken) {
            //Changes come here.  
            const defaultPhAux = props.userReasons && props.userReasons.filter(x => x.name === "Default Phone Aux");
            if (defaultPhAux) {
                props.updateActivity(defaultPhAux[0], "signIn", CallStates.IDLE);
            }
        }
    }

    const handleSignOut = async () => {
        if (isSignedIn) {
            console.log(deviceState);
            props.updateActivity(null, "signout", CallStates.OFFLINE);
            setState(intialState);
            props.handleLocationChange(null, true)
            deviceState && deviceState.connection && deviceState.connection.disconnect();
        }
    }

    const renderSignInError = () => {
        let error = "";
        if (worker.state === WORKER_ERROR || worker.state === WORKER_INIT_ERROR) {
            error = worker.error;
        }
        if (phoneTokenState.state === STATE_START_PHONE_LOG_ERROR) {
            error = JSON.stringify(phoneTokenState.error);
        }        
        if (error !== '') {
            return ( 
                <AlertItems msg={error} severity="error" />  
            )
        }
    }

    const renderSignIn = () => {
        return (
            <div className={classes.btnContainer}>
                {
                    props.userReasons.length === 0
                        ? <p>Please check if assignments are present</p>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormControl component="fieldset" style={{ color: '#fff' }}>
                                <FormLabel component="legend" style={{ color: '#fff' }}>Select Location</FormLabel>
                                <FormGroup style={{ color: '#fff' }}>
                                    <FormControlLabel
                                        style={{ color: '#fff' }}
                                        control={<Checkbox checked={state.saoPaulo} value="saoPaulo" onChange={handleChange} name="saoPaulo" />}
                                        label="Brazil"
                                    />
                                    <FormControlLabel
                                        style={{ color: '#fff' }}
                                        control={<Checkbox checked={state.umatilla} value="umatilla" onChange={handleChange} name="umatilla" />}
                                        label="US West Coast (Oregon)"
                                    />
                                    <FormControlLabel
                                        style={{ color: '#fff' }}
                                        control={<Checkbox checked={state.ashburn} value="ashburn " onChange={handleChange} name="ashburn" />}
                                        label="US East Coast (Virginia)"
                                    />
                                </FormGroup>
                            </FormControl>

                            <Button variant="contained" color="primary" onClick={handleSignIn}>
                                Sign In
                            </Button>
                            {loading ? <CircularProgress style={{ color: '#FFFFFF' }} /> : null}
                            {phoneTokenState && phoneTokenState.state === STATE_START_PHONE_LOG_ERROR && <p style={{ color: '#ffffff' }}>{phoneTokenState.error}</p>}
                        </div>
                }

            </div>
        )
    }

    const renderPhoneTaskList = () => {
        
        return (
            <PhoneListContainer
                userReasons={props.userReasons} 
                assignments={assignments}
                rosterCampaigns={rosterCam}
                callState={props.callState}
                handleStateChange={props.handleStateChange}
                phoneType={props.type}
                customerHang={props.customerHangup}
                hangupFinished={props.hangupFinished}
                callReject={props.callReject}
                rejectFinished={props.rejectFinished}
                myConfig={props.myConfig}
                handleSignout={handleSignOut}
                updateActivity={props.updateActivity}
                stateChangeSuccess={props.stateChangeSuccess}
                setStateChangeSuccess={props.setStateChangeSuccess}
                handleAcceptReservation={props.handleAcceptReservation}
            />
        )
    }

    return (
        <div className={classes.phoneContainer}>
            {!isSignedIn && renderSignIn()}
            {!isSignedIn && renderSignInError()}
            {isSignedIn && renderPhoneTaskList()}
            {props.isSignOutSuccess &&
                <OperationStatusIndicator
                    isError={true}
                    inProgress={false}
                    isSuccess={false}
                    successMessage=""
                    errorMessage="You have signed out of phone!!!"
                />}

        </div>
    );
}

