import moment from "moment";
import { Dispatch } from "redux";
import store from "../../../store";
import { stopPhoneLogging } from "../../statesLog.action";
import { WORKER_READY, WORKER_ACTIVITY_UPDATED, WORKER_TOKEN_UPDATE,WORKER_ERROR,WORKER_DISCONNECTED, WORKER_ACTIVITY_MISMATCH } from "../actionTypes";
import { getWorkerToken } from "../initPhoneWorker";

export const initWorkerListener = (worker: any, dispatch: any) => {    
    var browserTimezone = moment.tz.guess();

    worker.addListener("ready", (worker: any) => {
        dispatch({
            type: WORKER_READY,
            data: { worker }
        });
    });

    worker.addListener("token.expired", () => {
        const token = getWorkerToken();
        worker.updateToken(token);
        dispatch({
            type: WORKER_TOKEN_UPDATE,
            data: worker   
        });
    });

    worker.addListener("activity.update", (worker) => {
        console.log("activity.update", worker.sid)             // 'WKxxx'
        console.log("activity.update", worker.friendlyName)   // 'Worker 1'
        console.log("activity.update", worker.activityName)   // 'Reserved'
        console.log("activity.update", worker.available);       // false   
        const selectedCurrentActivity = store.getState().currentActivity.selectedCurrentActivity;
        dispatch({
            type: WORKER_ACTIVITY_UPDATED,
            data: { worker }
        });
        //if i am logged in to ph and my worker state is changed
        //update if twilio updating...
        //TODO In future everythg comes from twilio
        //send scott the api to update taskentry in phone
         
        if (selectedCurrentActivity?.twilioActivityName !== worker.activityName) {
            //dispatch logout
            var syncMessage = "Activites sync failed. Current Activity -"+ selectedCurrentActivity.twilioActivityName + " Worker updated activity - "+ worker.activityName;
            dispatch(stopPhoneLogging(browserTimezone,syncMessage, true));
            dispatch({
                type: WORKER_ACTIVITY_MISMATCH
            })
        }

    });

    worker.addListener("disconnected", () => {
        dispatch({
            type: WORKER_DISCONNECTED  
        });
        // console.log("Websocket has disconnected - worker");
        //         updatePhoneTwilioActivity(null, "signout taskrouter websocket disconnected", CallStates.OFFLINE);
        //       //  setCallState(CallStates.OFFLINE);
        //         Toast.show({
        //             html: `You have been signed out of the phone because taskrouter websocket disconnected`,
        //             color: 'b-red',
        //             style: 'color:white',
        //             timeout: 30000
        //         });
        //         //TODO - Phone signout the user
        //         setOnline(false);
        //         setIsSignOutSuccess(true);
    });

    worker.addListener("error", (error) => {        
        dispatch({
            type: WORKER_ERROR,
            data: JSON.stringify(error)   
        });
    });
};
