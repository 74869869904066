//@ts-nocheck
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { List, Divider } from "@mui/material";
import ListItemLink from "./ListItemLink";
import { drawerWidth } from "./Dimensions";
import { Auth0Consumer, useAuth0 } from "../auth0/react-auth0-wrapper";
import Can from "../auth0/Can";
import { getRoutes } from './util/getRoutes';
import { Assessment } from "@mui/icons-material";
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawerPaper: {
        width: drawerWidth,
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    toolbar: theme.mixins.toolbar,

    list: {
        paddingTop: 0
    },
    disabledMenu: {
        pointerEvents: 'none',
        opacity: 0.7
    }
}));

export default function LeftNav() {
    const classes = useStyles();
    const routesList = getRoutes();
    const { user } = useAuth0();

    const renderListItemLink = (listItem, nested) => {
        return (
            <Auth0Consumer key={listItem.text}>
                { ({ user }) => (
                    <Can
                        permissions={user.permissions}
                        perform={listItem.permission}
                        yes={() => (<><ListItemLink key={listItem.text} to={listItem.path} primary={listItem.text} icon={listItem.icon} className={!listItem.active ? classes.disabledMenu : ''} />
                            <Divider /></>)}
                        no={() => (<div></div>)}
                    />
                )}
            </Auth0Consumer>
        )
    }

    return (
        <List className={classes.list}>
            {
                user && routesList.map((eachItem, index) => {
                    return (
                        <React.Fragment key={index}>
                            {renderListItemLink(eachItem, '', index)}
                        </React.Fragment>
                    )
                })
            }


            {/* <Auth0Consumer>
            {({ user }) => (
                <Can
                    permissions={user.permissions}
                    perform="apply:forecast"
                    yes={() => (<List component="div" disablePadding>
                        <ListItemLink to="/vendor-forecast" primary="Forecast" icon={<NatureIcon />} className={classes.nested} />
                        <ListItemLink to="/vendor-adjust-forecast" primary="Adjust Forecast" icon={<EqualizerSharpIcon />} className={classes.nested} />
                        <ListItemLink to="/vendor-weekly-forecast" primary="Weekly Forecast" icon={<DateRangeIcon />} className={classes.nested} />
                    </List>)}
                    no={() => ("")}
                />
            )}
        </Auth0Consumer> */}

        </List>);
}