//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_GRID_PARAMS";
export const LOAD_GRID_PARAMS_START = `${actionNamePrefix}_START`;
export const LOAD_GRID_PARAMS_ERROR = `${actionNamePrefix}_ERROR`;
export const LOAD_GRID_PARAMS_SUCCESS = `${actionNamePrefix}_SUCCESS`;


const actionNamePrefixSave = "SAVE_SELECTED";
export const SAVE_SELECTED_START = `${actionNamePrefixSave}_START`;
export const SAVE_SELECTED_ERROR = `${actionNamePrefixSave}_ERROR`;
export const SAVE_SELECTED_SUCCESS = `${actionNamePrefixSave}_SUCCESS`;

export interface LoadGridParamsStartAction {
    type: typeof LOAD_GRID_PARAMS_START;
}

export interface LoadGridParamsSuccessAction {
    type: typeof LOAD_GRID_PARAMS_SUCCESS;
    data: any
}

export interface LoadGridParamsErrorAction {
    type: typeof LOAD_GRID_PARAMS_ERROR;
    error: string;
}

export interface SaveGridParamsStart {
    type: typeof SAVE_SELECTED_START,
}

export interface SaveGridParamsSuccess {
    type: typeof SAVE_SELECTED_SUCCESS,
    selectedParams: data
}

export interface SaveGridParamsError {
    type: typeof SAVE_SELECTED_ERROR,
    error: string
}

export type gridParamsActions = LoadGridParamsStartAction |
                                LoadGridParamsSuccessAction|
                                LoadGridParamsErrorAction | SaveGridParamsStart | SaveGridParamsSuccess | SaveGridParamsError;

export const getSelectedGridParams = (request) => async (dispatch) => {
    dispatch({type: LOAD_GRID_PARAMS_START})
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/PersistGridState?pagePath=${request}`);
        dispatch({
            type: LOAD_GRID_PARAMS_SUCCESS,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_GRID_PARAMS_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveSelectedSGridParams = (selectedData) => async (dispatch) => {
    dispatch({type: SAVE_SELECTED_START})
    try {
       const response = await Axios.post(`${AppSettings.ApiBaseUrl}/PersistGridState`, selectedData);
        dispatch({
            type: SAVE_SELECTED_SUCCESS,
            selectedParams: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: SAVE_SELECTED_ERROR,
                error: errorMessage
            })
        }
    }
}

