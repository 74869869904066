//@ts-nocheck
import Axios, { AxiosError } from "axios";
import { AppSettings } from "../../appSettings";

export const LOAD_ROLES = "LOAD_ROLES";
export const LOAD_ROLES_SUCCESS = "LOAD_ROLES_SUCCESS";
export const LOAD_ROLES_ERROR = "LOAD_ROLES_ERROR";

export interface RolesResponse {
    id: number;
    name: string;
}

export interface LoadRolesError {
    id: string;
    error: string;
}

export interface LoadRoleAction {
    type: typeof LOAD_ROLES
}

export interface LoadRoleSuccess {
    type: typeof LOAD_ROLES_SUCCESS;
    roles: Array<RolesResponse>;
}

export interface LoadRolesError {
    type: typeof LOAD_ROLES_ERROR;
    message: string;
}

export type RoleActions =
    LoadRoleAction |
    LoadRoleSuccess |
    LoadRolesError;

export const loadRoles = () => async (dispatch: any) => {
    dispatch({ type: LOAD_ROLES });

    try {
        const response = await 
        Axios.get<Array<RolesResponse>>(`${AppSettings.ApiRoleManagerUrl}/api/roles`);
        
        dispatch({
            type: LOAD_ROLES_SUCCESS,
            roles: response.data
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: LOAD_ROLES_ERROR,
                error: errorMessage
            })
        }
    }
}