import createReducer from "./createReducer";
import {
    STATES_LOAD_START,
    STATES_LOAD_SUCCESS,
    STATES_LOAD_ERROR,
    STATE_CLOCKIN_START,
    STATE_CLOCKIN_SUCCESS,
    STATE_CLOCKIN_ERROR,
    STATE_CLOCKOUT_START,
    STATE_CLOCKOUT_SUCCESS,
    STATE_CLOCKOUT_ERROR,
    STATE_START_LOG_START,
    STATE_START_LOG_SUCCESS,
    STATE_START_LOG_ERROR,
    STATE_UPDATE_LOG_START,
    STATE_UPDATE_LOG_SUCCESS,
    STATE_UPDATE_LOG_ERROR,
    STATE_STOP_LOG_START,
    STATE_STOP_LOG_SUCCESS,
    STATE_STOP_LOG_ERROR,
    StateLogActions,
    StatesLoadStartAction,
    StatesLoadSuccessAction,
    StatesLoadErrorAction,
    StateClockInStartAction,
    StateClockInSuccessAction,
    StateClockInErrorAction,
    StateClockOutStartAction,
    StateClockOutSuccessAction,
    StateClockOutErrorAction,
    StartLoggingStartAction,
    StartLoggingSuccessAction,
    StartLoggingErrorAction,
    UpdateLoggingStartAction,
    UpdateLoggingSuccessAction,
    UpdateLoggingErrorAction,
    StopLoggingStartAction,
    StopLoggingSuccessAction,
    StopLoggingErrorAction
    
} from "../actions/statesLog.action";
import { TaskGroup } from "../../StatesLog/StatesLoggingContainer";

export interface StateLogState {
    type: string;
    error: string;
    data: Array<TaskGroup>;
    clockInId: number;
    logginInId: number;
}

const initialState: StateLogState = {
    type: null,
    error: null,
    data: [],
    clockInId: 0,
    logginInId: 0
}

function statesLoadStart(state: StateLogState, action: StatesLoadStartAction): StateLogState {
    return {
        type: action.type,
        error: null,
        data: [],
        clockInId: 0,
        logginInId: 0
    }
}

function statesLoadSuccess(state: StateLogState, action: StatesLoadSuccessAction): StateLogState {  
    const emptyClockOut = action.data.findIndex(x => x.clockOutTimeUtc === '' || x.clockOutTimeUtc === null);
    var clockInId = 0;var logginInId = 0;
    if (emptyClockOut > -1) {
        clockInId = action.data[emptyClockOut].id;
        const runningTaskEntry = action.data[emptyClockOut].taskEntries.findIndex(x => x.endTimeInUtc === null );
        if (runningTaskEntry > -1) logginInId = action.data[emptyClockOut].taskEntries[runningTaskEntry].id;
    }
    return {
        ...state,
        data: action.data,
        type: action.type,
        error: null,
        clockInId: clockInId,
        logginInId: logginInId
    }
}

function statesLoadError(state: StateLogState, action: StatesLoadErrorAction): StateLogState {
    return {
        ...state,
        type: action.type,
        error: action.error,
    }
}


function stateClockInStart(state: StateLogState, action: StateClockInStartAction): StateLogState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function stateClockInSuccess(state: StateLogState, action: StateClockInSuccessAction): StateLogState {  
    let updatedList = [...state.data];
    updatedList = [action.data, ...updatedList];
    return {
        ...state,
        data: updatedList,
        type: action.type,
        error: null,
        clockInId: action.data.id
    }
}

function stateClockInError(state: StateLogState, action: StateClockInErrorAction): StateLogState {
    return {
        ...state,
        type: action.type,
        error: action.error,
    }
}

function stateClockOutStart(state: StateLogState, action: StateClockOutStartAction): StateLogState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function stateClockOutSuccess(state: StateLogState, action: StateClockOutSuccessAction): StateLogState {  
    const updatedList = [...state.data];
    const matchingIndex = updatedList.findIndex(x => x.id === action.data.id);
    updatedList[matchingIndex] = action.data;
    return {
        ...state,
        data: updatedList,
        type: action.type,
        error: null,
        clockInId: 0,
        logginInId: 0
    }
}

function stateClockOutError(state: StateLogState, action: StateClockOutErrorAction): StateLogState {
    return {
        ...state,
        type: action.type,
        error: action.error,
    }
}

function startLoggingStart(state: StateLogState, action: StartLoggingStartAction): StateLogState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function startLoggingSuccess(state: StateLogState, action: StartLoggingSuccessAction): StateLogState {  
    const updatedList = [...state.data];
    const matchingIndex = updatedList.findIndex(x => x.id === action.data.taskEntryGroupId);
    if (matchingIndex > -1) {
        updatedList[matchingIndex].taskEntries.push(action.data);
    }
    return {
        ...state,
        data: updatedList,
        type: action.type,
        error: null,
        clockInId: action.data.taskEntryGroupId,
        logginInId: action.data.id
    }
}

function startLoggingError(state: StateLogState, action: StartLoggingErrorAction): StateLogState {
    return {
        ...state,
        type: action.type,
        error: action.error,
    }
}

function updateLoggingStart(state: StateLogState, action: UpdateLoggingStartAction): StateLogState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function updateLoggingSuccess(state: StateLogState, action: UpdateLoggingSuccessAction): StateLogState {  
    const updatedList = [...state.data];
    const index = updatedList.findIndex(x => x.id === action.data.taskEntryGroupId);
    const matchingIndex = updatedList[index].taskEntries.findIndex(x => x.id === action.data.id);
    updatedList[index].taskEntries[matchingIndex] = action.data;
    return { 
        ...state,
        data: updatedList,
        type: action.type,
        error: null,
        clockInId: action.data.taskEntryGroupId,
        logginInId: action.data.id
    }
}

function updateLoggingError(state: StateLogState, action: UpdateLoggingErrorAction): StateLogState {
    return {
        ...state,
        type: action.type,
        error: action.error,
    }
}

function stopLoggingStart(state: StateLogState, action: StopLoggingStartAction): StateLogState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function stopLoggingSuccess(state: StateLogState, action: StopLoggingSuccessAction): StateLogState {  
    const updatedList = [...state.data];
    const index = updatedList.findIndex(x => x.id === action.data.taskEntryGroupId);
    const matchingIndex = updatedList[index].taskEntries.findIndex(x => x.id === action.data.id);
    updatedList[index].taskEntries[matchingIndex] = action.data;
    return { 
        ...state,
        data: updatedList,
        type: action.type,
        error: null,
        clockInId: action.data.taskEntryGroupId,
        logginInId: 0
    }
}

function stopLoggingError(state: StateLogState, action: StopLoggingErrorAction): StateLogState {
    return {
        ...state,
        type: action.type,
        error: action.error,
    }
}

const StateLogReducer = (state: StateLogState, action: StateLogActions): StateLogState => {
    const fnUpdateState = createReducer(initialState, {
        [STATES_LOAD_START]: statesLoadStart,
        [STATES_LOAD_SUCCESS]: statesLoadSuccess,
        [STATES_LOAD_ERROR]: statesLoadError,
        [STATE_CLOCKIN_START]: stateClockInStart,
        [STATE_CLOCKIN_SUCCESS]: stateClockInSuccess,
        [STATE_CLOCKIN_ERROR]: stateClockInError,
        [STATE_CLOCKOUT_START]: stateClockOutStart,
        [STATE_CLOCKOUT_SUCCESS]: stateClockOutSuccess,
        [STATE_CLOCKOUT_ERROR]: stateClockOutError,
        [STATE_START_LOG_START]: startLoggingStart,
        [STATE_START_LOG_SUCCESS]: startLoggingSuccess,
        [STATE_START_LOG_ERROR]: startLoggingError,
        [STATE_UPDATE_LOG_START]: updateLoggingStart,
        [STATE_UPDATE_LOG_SUCCESS]: updateLoggingSuccess,
        [STATE_UPDATE_LOG_ERROR]: updateLoggingError,
        [STATE_STOP_LOG_START]: stopLoggingStart,
        [STATE_STOP_LOG_SUCCESS]: stopLoggingSuccess,
        [STATE_STOP_LOG_ERROR]: stopLoggingError
    });

    return fnUpdateState(state, action);
}

export default StateLogReducer;