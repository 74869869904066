//@ts-nocheck
import createReducer from "./createReducer";
import { LOAD_MY_ROSTER_CAMPAIGNS_ERROR, LOAD_MY_ROSTER_CAMPAIGNS_SUCCESS, LOAD_MY_ROSTER_CAMPAIGNS_START } from "../actions/myRosterCampaigns.action";

const initialState = {
    data: [],
    state: null,
    error: null   
};

function loaded(state, action) {
    return {
        ...state,
        data: action.data,
        error: null,
        state: action.type        
    }
}

function loadStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

function loadError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}

export default createReducer(initialState, {
    [LOAD_MY_ROSTER_CAMPAIGNS_START]: loadStarted,
    [LOAD_MY_ROSTER_CAMPAIGNS_SUCCESS]: loaded,
    [LOAD_MY_ROSTER_CAMPAIGNS_ERROR]: loadError   
}); 