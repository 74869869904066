//@ts-nocheck
import React from "react"; //, {useState}
import { useHistory } from 'react-router-dom';
import { pathTo, getTheCurrentParent } from "./util/routerUtil"; //, getTheCurrentParent
import Paper from '@mui/material/Paper';
import "./nestedMenu.css";
import { Auth0Consumer, useAuth0 } from "../auth0/react-auth0-wrapper";
import Can from "../auth0/Can";

const Menu = ({ routes, level }) => {
    const pathname = window.location.pathname;
    const history = useHistory();
    const handleClick = (newValue: string) => {
        history.push(newValue);
    };
    let parentPath = getTheCurrentParent(pathname);
    parentPath = parentPath.path;
    
    const className = "menuStyle-level-" + level;
    return (
        <Paper square className={className}>
            {routes && routes.map((route, index) => {
                const isActiveTab = (pathname === route.path);
                const isParentActive = (parentPath === route.path);
                const menuClasses = ["menuStyle"];
                if (isActiveTab) menuClasses.push("activeMenu");
                if (isParentActive) menuClasses.push("activeMenu");
                if (!route.active) menuClasses.push("disabledMenu");
                return (
                    <Auth0Consumer key={index}>                        
                        { ({ user }) => (
                            <Can
                                permissions={user.permissions}
                                perform={route.permission}
                                yes={() => (<div className={menuClasses.join(' ')} key={index} onClick={() => handleClick(route.path)}>
                                    <div className="icon"> {route.icon} </div>
                                    <div className="menuLink" key={index} onClick={() => handleClick(route.path)}> {route.text} </div>
                                </div>)}
                                no={() => (<div></div>)}
                            />
                        )}
                    </Auth0Consumer>
                )
            }
            )}
        </Paper>
    )
}


const NestedMenu = ({ route }) => {
    //Show two levels on menu    
    const getTwoLevels = route && pathTo(route).filter(r => r.routes).slice(-2);
    //@ts-ignore
    const { user } = useAuth0();
    return (
        <>
            {user && getTwoLevels && getTwoLevels
                .map((r, index) => (
                    <Menu key={index} routes={r.routes} level={index} />
                ))}
        </>
    )
};

export default NestedMenu;
