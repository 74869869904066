//@ts-nocheck
import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import { setAccessToken } from "./HttpRequestInterceptor";
import Axios from "axios";
import { AppSettings } from "../appSettings";


const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const Auth0Consumer = Auth0Context.Consumer;
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [userAccessToken, setUserAccessToken] = useState();

  useEffect(() => {    
    const initAuth0 = async () => {
     
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);
     
      if (window.location.search.includes("code=")) {        
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }
      
      if(window.location.search.includes("error=")) {                
        var urlParams = new URLSearchParams(window.location.search);
        console.log(urlParams.get('error_description'));
        
        window.location = "/login-error?errormessage=unauthorized&errordescription="+urlParams.get('error_description');
        return;
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      if (isAuthenticated) {
        const token = await auth0FromHook.getTokenSilently();
      //  console.log(token)
        setAccessToken(token);
        setUserAccessToken(token);
        const user = await auth0FromHook.getUser();
        user.permissions = await loadPermissions();

        setUser(user);
      }

      setIsAuthenticated(isAuthenticated);
      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const loadPermissions = async() => {
    const request = await  Axios.get(`${AppSettings.ApiBaseUrl}/UserPermission`);
    return request.data;
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        userAccessToken,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        getTokenSilentlyWithScope: (...p) =>
        auth0Client.getTokenSilentlyWithScope(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
