//@ts-nocheck
import createReducer from "./createReducer";
import {
    STOPWATCH,
    STOPWATCH_GET,
    STOPWATCH_START,
    STOPWATCH_STOP,
    STOPWATCH_ERROR,
    StopwatchActions,
    StopwatchStartAction,
    StopwatchStopAction,
    StopwatchErrorAction
} from '../actions/stopwatch.action'

export interface StopwatchState {
    state: string;
    error: string;
    data: any;
}

const initialState: StopwatchState = {
    state: null,
    error: null,
    data: null
}

function stopwatch(state:StopwatchState, action: StopwatchAction): StopwatchState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function stopwatchGet(state:StopwatchState, action: StopwatchGetAction): StopwatchState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function stopwatchStart(state:StopwatchState, action: StopwatchStartAction): StopwatchState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function stopwatchStop(state:StopwatchState, action: StopwatchStopAction): StopwatchState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function stopwatchError(state:StopwatchState, action: StopwatchErrorAction): StopwatchState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        data: action.data
    }
}

const StopwatchReducer =
    (state: StopwatchState, action: StopwatchActions): StopwatchState => {

        const fnUpdateState = createReducer(initialState, {
            [STOPWATCH]: stopwatch,
            [STOPWATCH_GET]: stopwatchGet,
            [STOPWATCH_START]: stopwatchStart,
            [STOPWATCH_STOP]: stopwatchStop,
            [STOPWATCH_ERROR]: stopwatchError
        });

        return fnUpdateState(state, action);
    }

export default StopwatchReducer;