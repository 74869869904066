import { NotificationImportant } from '@mui/icons-material';
import { Badge, ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import * as signalR from "@microsoft/signalr";
import React, { useEffect, useState } from 'react';
import Popper from '@mui/material/Popper';
import { AppSettings } from '../../appSettings';
import { useAuth0 } from '../../auth0/react-auth0-wrapper';
import { Toast } from 'bryntum-scheduler';
import { UserNotification } from '../models/UserNotification';


function Notifications(props) {
    const [notifications, setNotifications] = useState(Array<UserNotification>(
                                                        {message: "Something updated for user", new: true},
                                                        {message: "There is new work assigned", new: false},
                                                        {message: "You need to check new schedule for next week", new: false},
                                                        {message: "Some other notification...", new: true}
                                                        ));
    const [showMenu, setShowMenu] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    //@ts-ignore
    const { userAccessToken } = useAuth0();

    useEffect(() => {
        const connectionSettings: signalR.IHttpConnectionOptions = {};
        connectionSettings.skipNegotiation = true;
        connectionSettings.transport = signalR.HttpTransportType.WebSockets;
        connectionSettings.accessTokenFactory = () => userAccessToken;

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${AppSettings.ForecastApiWSBaseUrl}/userNotifications`,
                        connectionSettings,
                        )
            .configureLogging(signalR.LogLevel.Information)
            .build();
    
        connection
            .on("Notify", (notification: UserNotification) => {
                console.info(`Notification received ${notification.message}`);
                
                setNotifications([...notifications, notification]);

                Toast.show({
                    html: `Norification received ${notification.message}`,
                    color: 'b-red',
                    style: 'color:white',
                    timeout: 10000
                });
            });        

        connection.start().then(() => {
            console.info("Connection established");
        });

        return function cleanup() {
            connection.stop().then(() => {
                console.info("Connection stopped");
            })
        };
    }, [])

    const getNumberOfNew = () => {
        return notifications.filter(x => x.new).length;
    }

    const showNotifications = () => {
        // TODO navigate to notifications
    }

    const dismissAll = () => {
        const newArr = [...notifications].map(x => { return {...x, new: false}} );
        setNotifications(newArr);

        // TODO update on server?
    }
    const handleClickaway = () =>{
        if (showMenu){
            setShowMenu(false)
            setAnchorEl(null)
        }
    }
    
    const getMenu = () => {
        return (
            <Popper open={showMenu} anchorEl={anchorEl} transition
                disablePortal={true}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: false
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundariesElement: 'scrollParent'
                        }
                        
                    }

                ]}
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClickaway}>
                                <MenuList>
                                    {notifications.sort((a,b) => a.new ? -1 : 1).map(x => {
                                        return <MenuItem>{x.message}</MenuItem>
                                    })}
                                    {<Divider />}
                                    {<MenuItem onClick={showNotifications}>Show all notifications</MenuItem>}
                                    {<Divider />}
                                    {notifications?.length > 0 && <MenuItem onClick={dismissAll}>Dismiss all</MenuItem>}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        )
    }
    
    // const getMenu = () =>{
    //     console.log('menu')
    //     const open = Boolean(anchorEl);
    //     console.log('open',open)
    //     const id = open ? 'simple-popper' : undefined;
    //     return(
    //         <Popper id={id} open={showMenu} anchorEl={anchorEl} transition>
    //              <ClickAwayListener onClickAway={handleClickaway}>
    //                 <div style = {{height : '200px', border : 'solid 2px'}}>The content of the Popper.</div>
    //             </ClickAwayListener>
    //         </Popper>
    //     )
    // }

    const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {        
        setAnchorEl(anchorEl ? null : event.currentTarget);
        console.log('show menu',showMenu,anchorEl,)
        setShowMenu(!showMenu);
    }

    return <>
            <IconButton
                onClick={toggleMenu}
                aria-label={"show " + getNumberOfNew() + " new notifications"}
                color="inherit"
                size="large">
                <Badge badgeContent={getNumberOfNew()} color="secondary">
                    <NotificationImportant />
                </Badge>
            </IconButton>
            {showMenu && getMenu()}
        </>;
    
}

export default Notifications;
