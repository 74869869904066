//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";
import AdjustForecastRequest from "../../vendor/forecast/adjustForecast/AdjustForecastRequest";

const actionNamePrefixLoad = "LOAD_PHONE_INTERVAL_STAFFING";
export const LOAD_PHONE_INTERVAL_STAFFING_START = `${actionNamePrefixLoad}_START`;
export const LOAD_PHONE_INTERVAL_STAFFING_SUCCESS = `${actionNamePrefixLoad}_SUCCESS`;
export const LOAD_PHONE_INTERVAL_STAFFING_ERROR = `${actionNamePrefixLoad}_ERROR`;

function loadStart() {
    return {
        type: LOAD_PHONE_INTERVAL_STAFFING_START
    };
}

function loadSuccess(phoneIntervals) {
    return {
        type: LOAD_PHONE_INTERVAL_STAFFING_SUCCESS,
        data: phoneIntervals
    };
}

function loadError(error) {
    return {
        type: LOAD_PHONE_INTERVAL_STAFFING_ERROR,
        error
    }
}

export interface LoadPhoneIntervalStaffingRequest {
    campaign: any;
    startDate: string;
    endDate: string;
}

export const loadPhoneIntervalStaffing = (request: LoadPhoneIntervalStaffingRequest) => async (dispatch) => {
    dispatch(loadStart());
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/PhoneForecast/campaignId/${request.campaign.id}/startInUtc/${request.startDate}/endInUtc/${request.endDate}`);
        dispatch(loadSuccess(response.data));
    } catch (e) {
        dispatch(loadError(e.response.data.Message || e.message));
    }
}

const actionPrefixNameSave = "SAVE_PHONE_INTERVAL_STAFFING";
export const SAVE_PHONE_INTERVAL_STAFFING_START = `${actionPrefixNameSave}_START`;
export const SAVE_PHONE_INTERVAL_STAFFING_SUCCESS = `${actionPrefixNameSave}_SUCCESS`;
export const SAVE_PHONE_INTERVAL_STAFFING_ERROR = `${actionPrefixNameSave}_ERROR`;

function saveStart() {
    return {
        type: SAVE_PHONE_INTERVAL_STAFFING_START
    };
}

function saveSuccess() {
    return {
        type: SAVE_PHONE_INTERVAL_STAFFING_SUCCESS
    };
}

function saveError(error) {
    return {
        type: SAVE_PHONE_INTERVAL_STAFFING_ERROR,
        error
    }
}

export const savePhoneIntervalStaffing = (phoneIntervals: Array<any>) => async (dispatch) => {
    dispatch(saveStart());
    try {
        await Axios.put(`${AppSettings.ApiBaseUrl}/api/PhoneForecast/bulk`, phoneIntervals);
        dispatch(saveSuccess());
    } catch (e) {
        dispatch(saveError(e.response.data.Message || e.message));
    }
}

const actionNamePrefixCreate = "CREATE_PHONE_INTERVAL_STAFFING";
export const CREATE_PHONE_INTERVAL_STAFFING_START = `${actionNamePrefixCreate}_START`;
export const CREATE_PHONE_INTERVAL_STAFFING_SUCCESS = `${actionNamePrefixCreate}_SUCCESS`;
export const CREATE_PHONE_INTERVAL_STAFFING_ERROR = `${actionNamePrefixCreate}_ERROR`;

function createStart() {
    return {
        type: CREATE_PHONE_INTERVAL_STAFFING_START
    };
}

function createSuccess(response) {
    return {
        type: CREATE_PHONE_INTERVAL_STAFFING_SUCCESS,
    };
}

function createError(error) {
    return {
        type: CREATE_PHONE_INTERVAL_STAFFING_ERROR,
        error
    }
}

export const createAdjustForecast = (campaignId: number, fromDateString: string, toDateString: string) => async (dispatch) => {
    dispatch(createStart());
    try {
        const response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/campaign/${campaignId}/fromDateTime/${fromDateString}/toDateTime/${toDateString}/PhoneIntervalStaffings`);
        dispatch(createSuccess(response.data));
    }
    catch (e) {
        dispatch(createError(e.response.data.Message || e.message));
    }
}
