import createReducer from "./createReducer";

import {
    LOAD_INVENTORY_AGENT_SCHEDULES_START,
    LOAD_INVENTORY_AGENT_SCHEDULES_ERROR,
    LOAD_INVENTORY_AGENT_SCHEDULES_SUCCESS,
    UPDATE_INVENTORY_AGENT_SCHEDULES_ERROR,
    UPDATE_INVENTORY_AGENT_SCHEDULES_START,
    UPDATE_INVENTORY_AGENT_SCHEDULES_SUCCESS,
    CREATE_INVENTORY_AGENT_SCHEDULES_ERROR,
    CREATE_INVENTORY_AGENT_SCHEDULES_START,
    CREATE_INVENTORY_AGENT_SCHEDULES_SUCCESS,
    DELETE_INVENTORY_AGENT_SCHEDULE_START,
    DELETE_INVENTORY_AGENT_SCHEDULE_SUCCESS,
    DELETE_INVENTORY_AGENT_SCHEDULE_ERROR
}
from '../actions/inventoryAgentSchedule.action';

const initialState = {
    data: [],
    state: null,
    error: null,
    updateState: null,
    updateError: null,
    createState: null,
    createError: null,
    clientIdOfUnsavedObject: null,
    createdObject: null,
    deleteState: null,
    deleteteError: null,
};

function loadStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

function loadError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error,
        state: action.type
    }
}

function loadSuccess(state, action) {
    return {
        ...state,
        data: action.data,
        error: null,
        state: action.type
    }
}

function updateStarted(state, action) {
    return {
        ...state,
        updateState: action.type,
        updateError: null
    }
}

function updateSuccess(state, action) {
    return {
        ...state,
        updateState: action.type,
        updateError: null
    }
}

function updateError(state, action) {
    return {
        ...state,
        updateState: action.type,
        updateError: action.error
    }
}

function createStarted(state, action) {
    return {
        ...state,
        createState: action.type,
        createError: null,
        clientIdOfUnsavedObject: action.clientId,
        createdObject: null,
    }
}

function createSuccess(state, action) {
    return {
        ...state,
        createState: action.type,
        createError: null,
        clientIdOfUnsavedObject: action.clientId,
        createdObject: action.data,
    }
}

function createError(state, action) {
    return {
        ...state,
        createState: action.type,
        createError: action.error,
        clientIdOfUnsavedObject: action.clientId,
        createdObject: null,
    }
}

function deleteStarted(state, action) {
    return {
        ...state,
        deleteState: action.type,
        deleteError: null
    }
}

function deleteSuccess(state, action) {
    return {
        ...state,
        deleteState: action.type,
        deleteError: null
    }
}

function deleteError(state, action) {
    return {
        ...state,
        deleteState: action.type,
        deleteError: action.error
    }
}

export default createReducer(initialState, {
    [LOAD_INVENTORY_AGENT_SCHEDULES_START]: loadStarted,
    [LOAD_INVENTORY_AGENT_SCHEDULES_SUCCESS]: loadSuccess,
    [LOAD_INVENTORY_AGENT_SCHEDULES_ERROR]: loadError,

    [UPDATE_INVENTORY_AGENT_SCHEDULES_START]: updateStarted,
    [UPDATE_INVENTORY_AGENT_SCHEDULES_SUCCESS]: updateSuccess,
    [UPDATE_INVENTORY_AGENT_SCHEDULES_ERROR]: updateError,

    [CREATE_INVENTORY_AGENT_SCHEDULES_START]: createStarted,
    [CREATE_INVENTORY_AGENT_SCHEDULES_SUCCESS] : createSuccess,
    [CREATE_INVENTORY_AGENT_SCHEDULES_ERROR]: createError,

    [DELETE_INVENTORY_AGENT_SCHEDULE_START]: deleteStarted,
    [DELETE_INVENTORY_AGENT_SCHEDULE_SUCCESS]: deleteSuccess,
    [DELETE_INVENTORY_AGENT_SCHEDULE_ERROR]: deleteError,
});