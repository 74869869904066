import React, { useState } from "react";
import { Box, Button, TextField, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import KeypadButton from "./KeypadButton";
import PhoneInput from "react-phone-input-2";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    keypad: {
      display: "grid",
      listStyle: "none",
      padding: 0,
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "0px",
    },
    dailerContainer: {
      background: "#152238",
      padding: "0px 20px",
      color: "#fff",
    },
    multiColor: {
      backgroundColor: "#152238",
      border: "none",
      color: "#fff",
},
    listStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    keypadBtn: {
      color: "#fff",
      backgroundColor: "#1D1F28",
      borderRadius: "50px",
      marginBottom: "4px",
      "&:hover": {
        opacity: "0.5",
      },
    },
  })
);

const lists = [
  { id: "1", title: "" },
  { id: "2", title: "ABC" },
  { id: "3", title: "DEF" },
  { id: "4", title: "GHI" },
  { id: "5", title: "JKL" },
  { id: "6", title: "MNO" },
  { id: "7", title: "PQRS" },
  { id: "8", title: "TUV" },
  { id: "9", title: "WXYZ" },
  { id: "0", title: "" },
  { id: "*", title: "" },
  { id: "#", title: "" },
];

interface KeyPadProps {
  handlePress: (val) => void;
  // handleBackSpace:() => void;
  number: any;
}

export default function KeyPad(props: KeyPadProps) {
  const classes = useStyles();
  const [btnColor, setBtnColor] = useState("");
  const [style, setStyle] = useState(true);
  console.log(props.number);
  const handleNumberPressed = (newNumber) => {
    return () => {
      setBtnColor(newNumber);
      props.handlePress(`${newNumber}`); //${props.number}
      setTimeout(changeBtnColor, 300);
    };
  };

  const changeBtnColor = () => {
    setBtnColor("");
    setStyle(true);
  };

  const handleKeyPress = (e) => {
    const { key, keyCode } = e;
    //${props.number}
    props.handlePress(`${key}`);
  };

  const handleKeyDown = (e) => {
    const { key, keyCode } = e;
    if (keyCode === 8 || keyCode === 46) {
      handleBackSpace();
    }
  };

  const handleBackSpace = () => {
    setStyle(false);
    const number = props.number;
    props.handlePress(number.substring(0, number.length - 1));
    setTimeout(changeBtnColor, 300);
  };

  return (
    <div className={classes.dailerContainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "2px solid grey",
        }}
      >
        <TextField
          value={props.number}
          onKeyPress={(e) => handleKeyPress(e)}
          onKeyDown={(e) => handleKeyDown(e)}
          className="multiColor"
          inputProps={{
            className: classes.multiColor
          }}
        />
      </div>
      <ol className={classes.keypad}>
        {lists.map((x) => (
          <li className={classes.listStyle} key={x.id}>
            <Button
              className={classes.keypadBtn}
              style={{
                backgroundColor: x.id === btnColor ? "#cecece" : "#1D1F28",
                color: x.id === btnColor ? "#000" : "#fff",
              }}
              onClick={handleNumberPressed(x.id)}
            >
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Box>{x.id}</Box>
                <Box style={{ fontSize: "7px", color: "grey" }}>{x.title}</Box>
              </Box>
            </Button>
          </li>
        ))}
        {props.number && props.number.length > 0 && (
          <li className={classes.listStyle}>
            <Button
              className={classes.keypadBtn}
              style={{ backgroundColor: style ? "#1D1F28" : "#cecece" }}
              onClick={handleBackSpace}
            >
              &lt;&lt;
            </Button>
          </li>
        )}
      </ol>
    </div>
  );
}