import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { AppSettings } from '../appSettings';
import { IConferenceCall } from './CallDetailContainer';
import { ReactComponent as Answer } from './Icons/Answer.svg';
import { ReactComponent as HangUp } from './Icons/HangUp.svg';
import { ReactComponent as Hold } from './Icons/HoldNoActive.svg';
import { ReactComponent as Mike } from './Icons/Mike.svg';
import { ReactComponent as HoldActive } from './Icons/HoldActive.svg';
import { ReactComponent as MikeMute } from './Icons/MuteActive.svg';
import { ReactComponent as PhoneEnd } from './Icons/PhoneEnd.svg';
import DailerControl from './DailerControl';
import { MenuItem, Select } from '@mui/material';
import useInterval from '../hooks/useInterval';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/reducers';
import { updateConfCallNumbers } from '../store/actions/activeCalls.action';
import { isEmptyArray } from 'formik';

interface ConferenceProps {
    onConference: Array<IConferenceCall>,
    //handleAddParty: (party) => void;
    handleAgentHold:(val) => void;
    loggedInAgentName: string;
    workspaceSid:string;
    agentCallState:string
}

export default function ConferenceCallContainer(props: ConferenceProps) {
    
    const [confNumbers, setConfNumbers] = useState([]);    
    const [value, setValue] = useState<string>();
    const [name, setName] = useState<string>();
    const [ansVal, setAnsVal] = useState('answer')
    const [clicked, setClicked] = useState(false);
    const [clickedTransfer, setClickedTransfer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const activeCallsState = useSelector((state: AppState) => state.activeCalls);
    let apiTimeoutCall; let pollTimeout;

    useEffect(() => {
        if (!isEmptyArray(activeCallsState.conferenceNumbers) || activeCallsState.conferenceNumbers !== null ) {
            setConfNumbers([...activeCallsState.conferenceNumbers]);
        }        
    }, [activeCallsState.conferenceNumbers]);

    useInterval(async () => {
        const response = await pollApi();
        const confNumbers = activeCallsState.conferenceNumbers;
        if (response) {
            let matching = null;
            const thirdPartyIndex = confNumbers.findIndex(x => x.type === "ThirdParty");
            const thirdParty = confNumbers.find(x => x.type === "ThirdParty");  
            if (thirdParty) {
                var value = thirdParty.phoneNumber;
                if (undefined !== value) {                
                    if (value.includes('client:')) {   
                        var checkVal = value.split(':')[1];
                        matching = checkVal && response.find(x => x.label && x.label.includes(checkVal));
                    } else {
                        matching = response.find(x => x.label && x.label.includes(value));
                    }
                }
                if (matching) {
                    let matchingIndex = -1;
                    matchingIndex = confNumbers.findIndex(x => x.phoneNumber === value);
                    if (matchingIndex > -1) {
                        confNumbers[matchingIndex].status = 'Connected';
                    } 
                    dispatch(updateConfCallNumbers(confNumbers));                
                } else {
                    if (thirdParty.status === 'Connected') {
                        const updatedConf = [...confNumbers];
                        updatedConf.splice(thirdPartyIndex, 1);                 
                        dispatch(updateConfCallNumbers(updatedConf));                
                    }
                }
            }
        } 
    }, !isLoading ? 3000 : null);

    const pollApi = async () => {
        if (isLoading === false) {
            setIsLoading(true);
            const response = await conferenceCallerStatus();            
            return response;
        }
    }

    
    const handleMute = async (isMute: string,phoneNum: string, muteParty: string) => {
        const updatedConf = [...confNumbers];
        const matchingIndex = updatedConf.findIndex(x => x.phoneNumber === phoneNum);
        var muteRequest = {
            isMute: isMute === 'mute' ? "true" : "false",
            muteParty: muteParty
        }
        try {
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/Mute`, muteRequest);
            if (response && response.data) {
                const entries = response.data.entries;
                if (entries[0].eventId !== null) {
                    updatedConf[matchingIndex].mute = isMute === 'mute' ? true : false;
                } else {
                    updatedConf[matchingIndex].mute = false;
                }
            }
        } catch (err) {
            console.log(err);
            updatedConf[matchingIndex].mute = false;
        }
        //setConfNumbers(updatedConf);
        dispatch(updateConfCallNumbers(updatedConf));
    }

    const handleHold = async (isHold: string, phoneNum: string, holdparty: string) => {
        const updatedConf = [...confNumbers];
        const matchingIndex = updatedConf.findIndex(x => x.phoneNumber === phoneNum);
        var holdRequest = {
            isHold: isHold === 'hold' ? "true" : "false",
            holdparty: holdparty === 'Agent' ? "Customer" : holdparty
        }
        try {
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/Hold`, holdRequest);
            if (response && response.data) {
                const entries = response.data.entries;
                if (entries[0].eventId !== null) {
                     updatedConf[matchingIndex].hold = isHold === 'hold' ? true : false;
                } else {
                    updatedConf[matchingIndex].hold = false;
                }
                if(holdparty === 'Agent') {
                    props.handleAgentHold(isHold);
                }
            }
        } catch (err) {
            console.log(err);
            updatedConf[matchingIndex].hold = false;
        }
        dispatch(updateConfCallNumbers(updatedConf));
    }

    const handleHangUpApi = () => {
        var request = {
            "workspacesid": props.workspaceSid,
            "agentname": props.loggedInAgentName
        }
        try {
            const res = Axios.post(`https://uy5154u0f0.execute-api.us-east-1.amazonaws.com/qa/set`, request);
        } catch (e) {
            console.log(e)
        }
    }

    const handleHangUp = async (phoneNum: string, hangParty: string) => {
        const updatedConf = [...activeCallsState.conferenceNumbers];
        var hangRequest = {
            "deleteParty": hangParty,
            "transfer": false
        }        
        try {
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/DeleteConference`, hangRequest);
            if (response && response.data) {
                const entries = response.data.entries;
                if (entries[0].eventId !== null) {
                    const confList = updatedConf.filter(x => x.type != hangParty);                    
                    dispatch(updateConfCallNumbers(confList));
                    clearTimeout(apiTimeoutCall);
                    clearTimeout(pollTimeout);
                } 
            }
            if (hangParty === 'Agent') {
                handleHangUpApi();
            }
        } catch (err) {
            console.log(err);
        }
    }


    const handleAddParty = async () => {        
        setClicked(true);
        //Call api to handle party        
        const addPartyNumber = [...confNumbers];
        let nameVal = value.includes("client:") ? name : value;
        const newEntry: IConferenceCall = {
            phoneNumber: value,
            status: 'Dialing',
            type: "ThirdParty",
            hold: false,
            mute: false,
            name: nameVal
        }
        addPartyNumber.push(newEntry);
        try {
            const confRequest = {
                "addParty": value,
                "transfer": false
            }
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/AddConference`, confRequest);
            console.log("adding third number",addPartyNumber);
            if (response && response.data) {
                const entries = response.data.entries;
                if (entries[0].eventId != null) {
                    console.log(entries[0].eventId);
                    console.log("adding third number success",addPartyNumber);
                    dispatch(updateConfCallNumbers(addPartyNumber));
                } 
            }
        } catch (err) {
            console.log(err);
        }
        setClicked(false);
    }

    const conferenceCallerStatus = async () => {         //confList?, type?:string        
        const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/Status`);
        if (response && response.data) {
            setIsLoading(false);
            return response.data;
        } else {
            return null;
        }    
        
    }

    const handleMuteHoldBeforeTransfer = async () => {
        var conf = [...confNumbers];
        var isCustomer = conf && conf.filter(x => x.type === "Customer");
        if (isCustomer) {
            var holdRequest = {
                isHold: "false",
                holdparty: "Customer"
            }
            var muteRequest =  {
                isMute: "false",
                muteParty: "Customer"
            }
            holdMuteRequest(holdRequest, muteRequest);
        }
        var isThirdParty = conf && conf.filter(x => x.type === "ThirdParty");
        if (isThirdParty) {
            var holdRequest = {
                isHold: "false",
                holdparty: "ThirdParty"
            }
            var muteRequest =  {
                isMute: "false",
                muteParty: "ThirdParty"
            }
            holdMuteRequest(holdRequest, muteRequest)
        }
    }

    const holdMuteRequest =  async (holdRequest, muteRequest) => {
        await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/Hold`, holdRequest);
        await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/Mute`, muteRequest);
    }

    const handleColdTransfer = async () => {
        setClickedTransfer(true);
        try {
            handleMuteHoldBeforeTransfer();
            const confRequest = {
                "addParty": value,
                "transfer": true
            }
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/AddConference`, confRequest);
            if (response && response.data) {
                const entries = response.data.entries;
                if (entries[0].eventId != null) {
                    console.log(entries[0].eventId);
                } 
            }
        } catch (err) {
            console.log(err);
        }   
        setClickedTransfer(false);     
    }

    const handleAnswer = (e) => {
        setAnsVal(e.target.value);
        //if (e.target.value === 'answer') handleAddParty();
        //if (e.target.value === 'transfer') handleColdTransfer();
    }

    const renderAnswerList = () => {
        const numbers = [...confNumbers];   
        let isDisabled = false;
        const dialNumber = numbers && numbers.filter(x => x.status === "Dialing");
        if (dialNumber.length > 0) isDisabled = true;
        return (
            <Select
                style={{ color: '#fff' }}
                onChange={handleAnswer}
                autoWidth={true}
                name='answer'
                value={ansVal}
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
            >
                <MenuItem style={{ color: '#000000' }} value='answer' disabled={clicked ||( numbers && numbers.length === 3)}> <span onClick={handleAddParty}>Conference</span></MenuItem>
                <MenuItem style={{ color: '#000000' }} value='transfer' disabled={clickedTransfer || isDisabled || (numbers && numbers.length > 2)}> <span  onClick={handleColdTransfer}>Transfer</span></MenuItem>                
            </Select>
        )
    }
    
    return (
        <div>
            <div style={{ display: 'flex', alignItems:'center' }}>
                <DailerControl type='conference' number={value} setNumber={setValue} handleCall={handleAddParty} setAgent={setValue} setAgentName={setName} />
                { value ? renderAnswerList() : null}
                {/* addNum && */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }} >
                {
                    activeCallsState.conferenceNumbers?.map((eachNum, index) => {
                        return (
                            <div style={{ display: 'block' }} key={eachNum.phoneNumber}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{width: '130px'}}>[{eachNum.name !== "" ? eachNum.name : eachNum.phoneNumber }] &nbsp;</div>
                                    <div style={{ transform: 'rotate(270deg)' , width:'20px'}}><PhoneEnd height='12' /></div>
                                    <div style={{width: '130px'}}>{eachNum.status}</div>
                                    { eachNum.type !== 'Agent' ? 
                                    <div style={{ justifyContent: 'space-evenly', width: '200px' }} >                                        
                                        {!eachNum.hold ? <Hold height={20} onClick={() => handleHold('hold',eachNum.phoneNumber, eachNum.type)} /> : <HoldActive height={20} onClick={() => handleHold('noHold',eachNum.phoneNumber,eachNum.type)} />}
                                        {!eachNum.mute ? <Mike height={20} onClick={() => handleMute('mute',eachNum.phoneNumber, eachNum.type)} /> : <MikeMute height={20} onClick={() => handleMute('noMute',eachNum.phoneNumber, eachNum.type)} />}                                                                                
                                        { eachNum.type !== 'Customer' && <HangUp height={20} onClick={() => handleHangUp(eachNum.phoneNumber,eachNum.type)} /> }
                                    </div>
                                    : <div style={{ justifyContent: 'space-evenly', width: '200px' }} ></div>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>

    )
}