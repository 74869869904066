//@ts-nocheck
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "./react-auth0-wrapper";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  
  //@ts-ignore
  const { isAuthenticated, loginWithRedirect, loading, user } = useAuth0();

  useEffect(() => {
    const fn = async () => {
      if (!loading && !isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: path }
        });
      } else {
        console.log(user);
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect, path]);

  const render = props =>
    isAuthenticated === true ? <Component {...props} /> : null;

  return <Route path={path} render={render} {...rest} />;
  
};

export default PrivateRoute;
