//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_SCHEDULE_TEMPLATES";
export const LOAD_SCHEDULE_TEMPLATES_START = `${actionNamePrefix}_START`;
export const LOAD_SCHEDULE_TEMPLATES_ERROR = `${actionNamePrefix}_ERROR`;
export const LOAD_SCHEDULE_TEMPLATES_SUCCESS = `${actionNamePrefix}_SUCCESS`;

const actionNamePrefixSave = "SAVE_SCHEDULE_TEMPLATES";
export const SAVE_SCHEDULE_TEMPLATES_START = `${actionNamePrefixSave}_START`;
export const SAVE_SCHEDULE_TEMPLATES_ERROR = `${actionNamePrefixSave}_ERROR`;
export const SAVE_SCHEDULE_TEMPLATES_SUCCESS = `${actionNamePrefixSave}_SUCCESS`;

interface IRequestData {
    name: string;
    id : number,
    description: string;
    templateType: string;
}

export interface LoadScheduleTemplatesStartAction {
    type: typeof LOAD_SCHEDULE_TEMPLATES_START;
}

export interface LoadScheduleTemplatesSuccessAction {
    type: typeof LOAD_SCHEDULE_TEMPLATES_SUCCESS;
    data: any
}

export interface LoadScheduleTemplatesErrorAction {
    type: typeof LOAD_SCHEDULE_TEMPLATES_ERROR;
    error: string;
}

export interface SaveScheduleTemplateStart {
    type: typeof SAVE_SCHEDULE_TEMPLATES_START,
}

export interface SaveScheduleTemplateSuccess {
    type: typeof SAVE_SCHEDULE_TEMPLATES_SUCCESS,
    template: data
}

export interface SaveScheduleTemplateError {
    type: typeof SAVE_SCHEDULE_TEMPLATES_ERROR,
    error: string
}

export type scheudleTemplateActions = LoadScheduleTemplatesStartAction |
                                LoadScheduleTemplatesSuccessAction|
                                LoadScheduleTemplatesErrorAction | SaveScheduleTemplateStart | SaveScheduleTemplateSuccess | SaveScheduleTemplateError;

export const getScheduleTemplates = () => async (dispatch) => {
    dispatch({type: LOAD_SCHEDULE_TEMPLATES_START})
    try {
        const response = await Axios.get(`${AppSettings.ApiSchedulerUrl}/api/ScheduleTemplate`);
        dispatch({
            type: LOAD_SCHEDULE_TEMPLATES_SUCCESS,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_SCHEDULE_TEMPLATES_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveScheudleTemplates = (request: IRequestData) => async (dispatch) => {
    dispatch({type: SAVE_SCHEDULE_TEMPLATES_START})
    try {
        let response:any;
        if (request.templateType === "shift") {
            const data = {
                shiftId: request.id,
                name: request.name,
                description: request.description
            }
            response = await Axios.post(`${AppSettings.ApiSchedulerUrl}/api/ScheduleTemplate/createShiftScheduleTemplate`, data);
        } else {
            const data = {
                baseScheduleId: request.id,
                name: request.name,
                description: request.description
            }
            response = await Axios.post(`${AppSettings.ApiSchedulerUrl}/api/ScheduleTemplate/createBaseScheduleTemplate`, data);
        }
        dispatch({
            type: SAVE_SCHEDULE_TEMPLATES_SUCCESS,
            template: response.data
            // response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: SAVE_SCHEDULE_TEMPLATES_ERROR,
                error: errorMessage
            })
        }
    }
}

export const cleanUpTemplates = () => async (dispatch) =>{
    dispatch({type: LOAD_SCHEDULE_TEMPLATES_START})   
}


export const loadTemplateForDate = async (data) => {
    try {       
        const response = await Axios.get(`${AppSettings.ApiSchedulerUrl}/api/ScheduleTemplates/${data.templateId}?date-in-btz=${data.date}`);        
        return response.data;   
    } catch (error) {
        const errorMsg = {
          "errorMsg": error.response.data.message
        };
        
        return errorMsg;
    }
}