import { Device } from "@twilio/voice-sdk";
import { DEVICE_CALL_CONNECTED, DEVICE_CALL_DISCONNECTED, DEVICE_CANCEL, DEVICE_CONNECT, DEVICE_DISCONNECT, DEVICE_ERROR, DEVICE_HANGUP, DEVICE_INCOMING, DEVICE_OFFLINE, DEVICE_REGISTERD, DEVICE_REJECT } from "../actionTypes";

export const initDeviceListener = (device: Device, dispatch: any) => {
    device.addListener("registered", (device: any) => {
        dispatch({
            type: DEVICE_REGISTERD,
            data: { device }
        });
    });

    device.addListener("offline", (device) => {

        dispatch({
            type: DEVICE_OFFLINE,
            data: device
        });
    });

    device.addListener("connect", (connection) => {
        console.log("I am in connected device event");
        dispatch({
            type: DEVICE_CONNECT,
            data: connection
        });
    });

    device.addListener("disconnect", () => {
        console.log("I am in disconnect device event");
        dispatch({
            type: DEVICE_DISCONNECT
        });
    });

    device.addListener("hangup", () => {
        console.log("I am in hangup device event");
        dispatch({
            type: DEVICE_HANGUP
        });
    });

    device.addListener("incoming", (connection) => {
        console.log("Connenction", connection);
        connection.accept();
        console.log(connection.direction);
        console.log("connection params", connection.parameters);
        console.log('Incoming connection from ' + connection.parameters.From);
        var archEnemyPhoneNumber = '+12093373517';
        if (connection.parameters.From === archEnemyPhoneNumber) {
            connection.reject();
            console.log("It's your nemesis. Rejected call.");
        }
        connection.on("accept", () => {
            console.log("I accepted the call");
            dispatch({
                type: DEVICE_CALL_CONNECTED,
                data: connection
            });
        });
        connection.on("disconnect", (connection) => {
            console.log("I am in connection disconnect", connection);
            dispatch({
                type: DEVICE_CALL_DISCONNECTED,
                data: connection
            });
        });
        connection.on("reject", () => {
            console.log("It's your nemesis. anyone call.");
        });
        connection.on("cancel", () => {
            console.log("The incoming call stopped");
        });
        dispatch({
            type: DEVICE_INCOMING,
            data: connection
        });
    });

    device.addListener("cancel", () => {
        dispatch({
            type: DEVICE_CANCEL
        });
    });

    device.addListener("reject", () => {
        //call state to be ready
        dispatch({
            type: DEVICE_REJECT
        });
    });

    device.addListener("error", (error) => {
        console.log("voice sdk device error", JSON.stringify(error));
        let errorMsg;
        if (error) {
            var deviceError = error.originalError;
            errorMsg = deviceError.message;
        }
        dispatch({
            type: DEVICE_ERROR,
            data: errorMsg
        });
    });

};

