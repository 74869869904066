const actionNamePrefix = "SAVE_SELECTED";
export const SAVE_SELECTED_ACTIVITY = `${actionNamePrefix}_ACTIVITY`;

function saveCurrentActivity(activity) {
    return {
        type: SAVE_SELECTED_ACTIVITY,
        selectedActivity: activity
    };
}


export const setCurrentSelectedActivity = (activity) => (dispatch) => {    
    dispatch(saveCurrentActivity(activity));
}

