//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "LOAD_SCHEDULE_TYPES";
export const LOAD_SCHEDULE_TYPES_START = `${actionNamePrefix}_START`;
export const LOAD_SCHEDULE_TYPES_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_SCHEDULE_TYPES_ERROR = `${actionNamePrefix}_ERROR`;

const actionNamePrefixSave = "SAVE_SCHEDULE_TYPES";
export const SAVE_SCHEDULE_TYPES_START = `${actionNamePrefixSave}_START`;
export const SAVE_SCHEDULE_TYPES_SUCCESS = `${actionNamePrefixSave}_SUCCESS`;
export const SAVE_SCHEDULE_TYPES_ERROR = `${actionNamePrefixSave}_ERROR`;

const actionNamePrefixUpdate = "UPDATE_SCHEDULE_TYPES";
export const UPDATE_SCHEDULE_TYPES_START = `${actionNamePrefixUpdate}_START`;
export const UPDATE_SCHEDULE_TYPES_SUCCESS = `${actionNamePrefixUpdate}_SUCCESS`;
export const UPDATE_SCHEDULE_TYPES_ERROR = `${actionNamePrefixUpdate}_ERROR`;

export interface IScheduleType {
    id: number,
	name: string,
	colorCode: string,
	isProductive: boolean,
    exclude: boolean,
    inOffice: boolean,
    paid: boolean,
	campaignType: string,
	planned: string,
	abbrevation: string,
    campaignIds: Array<any>,
    allowUser: boolean
}

export interface LoadScheduleTypesStart {
    type: typeof LOAD_SCHEDULE_TYPES_START
}

export interface LoadScheduleTypesSuccess {
    type: typeof LOAD_SCHEDULE_TYPES_SUCCESS;
    scheduleTypes: Array<IScheduleType>
}

export interface LoadScheduleTypesError {
    type: typeof LOAD_SCHEDULE_TYPES_ERROR;
    message: string;
}

export interface SaveScheduleTypesStart {
    type: typeof SAVE_SCHEDULE_TYPES_START
}

export interface SaveScheduleTypesSuccess {
    type: typeof SAVE_SCHEDULE_TYPES_SUCCESS;
    scheduleType: IScheduleType
}

export interface SaveScheduleTypesError {
    type: typeof SAVE_SCHEDULE_TYPES_ERROR;
    message: string;
}

export interface UpdateScheduleTypesStart {
    type: typeof UPDATE_SCHEDULE_TYPES_START
}

export interface UpdateScheduleTypesSuccess {
    type: typeof UPDATE_SCHEDULE_TYPES_SUCCESS;
    scheduleType: IScheduleType
}

export interface UpdateScheduleTypesError {
    type: typeof UPDATE_SCHEDULE_TYPES_ERROR;
    message: string;
}

export type ScheduleTypeActions =
    LoadScheduleTypesStart |
    LoadScheduleTypesSuccess |
    LoadScheduleTypesError |
    SaveScheduleTypesStart |
    SaveScheduleTypesSuccess |
    SaveScheduleTypesError |
    UpdateScheduleTypesStart |
    UpdateScheduleTypesSuccess |
    UpdateScheduleTypesError;

export const getScheduleTypes = () => async (dispatch) => {
    dispatch({ type: LOAD_SCHEDULE_TYPES_START });

    try {
        const response = await
            Axios.get<IScheduleType>(`${AppSettings.ApiSchedulerUrl}/api/ScheduleType`);
        dispatch({
            type: LOAD_SCHEDULE_TYPES_SUCCESS,
            scheduleTypes: response.data
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_SCHEDULE_TYPES_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveScheduleTypes = (saveScheduleType : IScheduleType) => async (dispatch) => {
    dispatch({ type: SAVE_SCHEDULE_TYPES_START });

    try {
        const response = await
            Axios.post(`${AppSettings.ApiSchedulerUrl}/api/ScheduleType`, saveScheduleType);
        dispatch({
            type: SAVE_SCHEDULE_TYPES_SUCCESS,
            scheduleType: response.data
            //scheduleType: saveScheduleType
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: SAVE_SCHEDULE_TYPES_ERROR,
                error: errorMessage
            })
        }
    }
}

export const updateScheduleTypes = (updatedScheduleType : IScheduleType) => async (dispatch) => {
    dispatch({ type: UPDATE_SCHEDULE_TYPES_START });

    try {
        const response = await
            Axios.post(`${AppSettings.ApiSchedulerUrl}/api/ScheduleType`, updatedScheduleType);
        dispatch({
            type: UPDATE_SCHEDULE_TYPES_SUCCESS,
            scheduleType: updatedScheduleType
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: UPDATE_SCHEDULE_TYPES_ERROR,
                error: errorMessage
            })
        }
    }
}

