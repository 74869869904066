import createReducer from "./createReducer";
import {
    SAVE_TIMER_OPTION
} from '../actions/selectedTimer.action'

const initialState = {
    selectedOption: null
};


function setSaveTimer (state, action) {
    return{
        ...state,
        selectedOption: action.option
    }
}

export default createReducer(initialState, {   
    [SAVE_TIMER_OPTION]: setSaveTimer
}); 

