//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";
import { CAMPAIGN_TYPE_INVENTORY } from "../../vendor/forecast/models/CampaignTypes";

const actionNamePrefix = "DELETE_AGENT_SCHEDULE";
export const DELETE_AGENT_SCHEDULE_START = `${actionNamePrefix}_START`;
export const DELETE_AGENT_SCHEDULE_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const DELETE_AGENT_SCHEDULE_ERROR = `${actionNamePrefix}_ERROR`;

const actionNamePrefix1 = "GET_SCHEDULED_AGENT_COUNT";
export const GET_SCHEDULED_AGENT_COUNT_START = `${actionNamePrefix1}_START`;
export const GET_SCHEDULED_AGENT_COUNT_SUCCESS = `${actionNamePrefix1}_SUCCESS`;
export const GET_SCHEDULED_AGENT_COUNT_ERROR = `${actionNamePrefix1}_ERROR`;

function deleteStart() {
    return {
        type: DELETE_AGENT_SCHEDULE_START
    };
}

function deleteSuccess(result) {
    return {
        type: DELETE_AGENT_SCHEDULE_SUCCESS
    };
}

function deleteError(error) {
    return {
        type: DELETE_AGENT_SCHEDULE_ERROR,
        error
    }
}

export const deleteAgentSchedule = (campaign, agentUserId) => async (dispatch) => {
    dispatch(deleteStart());
    try {
        let response = null;
        if (campaign.type.toUpperCase() === CAMPAIGN_TYPE_INVENTORY)
            response = await Axios.delete(`${AppSettings.ApiBaseUrl}/inventoryagentschedule/campaign/${campaign.id}/Agent/${agentUserId}/`);
        else
            response = await Axios.delete(`${AppSettings.ApiBaseUrl}/agentschedule/campaign/${campaign.id}/Agent/${agentUserId}/`);

        dispatch(deleteSuccess(response.data));
    }
    catch (e) {
        dispatch(deleteError(e.response.data.Message || e.message));
    }
}


function loadScheduledAgentCountStart() {
    return {
        type: GET_SCHEDULED_AGENT_COUNT_START
    };
}

function loadScheduledAgentCountSuccess(data) {
    return {
        type: GET_SCHEDULED_AGENT_COUNT_SUCCESS,
        response: data
    };
}

function loadScheduledAgentCountError(error) {
    return {
        type: GET_SCHEDULED_AGENT_COUNT_ERROR,
        error
    }
}

export const loadScheduledAgentCount = (fromInUtc, toInUtc) => async (dispatch) => {
    dispatch(loadScheduledAgentCountStart());

    try {        
        let response = await Axios.get(`${AppSettings.ApiSchedulerUrl}/api/ScheduledAgentCountReport/onDateInUtc/${fromInUtc}`);
        dispatch(loadScheduledAgentCountSuccess(response.data));
    }
    catch (e) {
        dispatch(loadScheduledAgentCountError(e.response.data.Message || e.message));
    }
}

