import React, { useEffect, useState, useRef } from 'react';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    Table,
    IconButton,
    Typography,
    TableRow,
    TableCell,
    TableContainer,
    Button,
    TableBody,
    CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ReactComponent as Incoming } from './Icons/Incoming.svg';
import { ReactComponent as Sms } from './Icons/SMS.svg';
import { ReactComponent as Chat } from './Icons/Chat.svg';
import { ReactComponent as Answer } from './Icons/Answer.svg';
import { ReactComponent as Decline } from './Icons/Decline.svg';
import { ReactComponent as Facebook } from './Icons/Facebook.svg';
import { ReactComponent as OnGoing } from './Icons/OnGoing.svg';
import { ReactComponent as Hold } from './Icons/CallHold.svg';
import { ReactComponent as HoldActive } from './Icons/HoldActive.svg';
import { ReactComponent as PhoneEnd } from './Icons/PhoneEnd.svg';
import { ReactComponent as PhoneEndNoFill } from './Icons/PhoneEndNoFill.svg';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CallStates from './CallStates';
import CallTimer from './CallTimer';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/reducers';
import moment from 'moment';
import { getDuration } from '../vendor/staff/manageAgentSchedule/util';
import Axios from 'axios';
import { AppSettings } from '../appSettings';
import { endCall } from '../store/actions/activeCalls.action';
import { handleCallTimers, handleHangUpApi, handleReject } from './utils';

interface PhoneTaskListProps {
    callState: string,
    handleHold: (val) => void;
    handleHangUp: () => void;
    handleDecline: () => void;
    handleIncoming: () => void;
    handleWrapUp: () => void;
    callerInfo: any,
    attributes: any
    task: any,
    phoneType: string,
    myConfig: any,
    showMenu: boolean,
    stateTimer: number,
    handleSelectedTask: (taskId) => void;
    selectedTask: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableRoot: {
            padding: 0,
            border: '1px solid #373F51'
        },
        iconColor: {
            color: 'white'
        },
        iconGreen: {
            color: '#0A7D41'
        },
        iconRed: {
            color: ' #CC292D'
        },
        taskHeader: {
            textTransform: 'uppercase',
            padding: 5,
            backgroundColor: '#252935',
            border: '1px solid #373F51',
            color: '#FFFFFF',
            height: '30px',
            fontWeight: 'bold'
        },
        taskDivider: {
            backgroundColor: '#252935',
            border: '1px solid #373F51',
            color: '#FFFFFF',
            height: '30px',
            textAlign: 'center'
        },
        phoneListContainer: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 64,
            height: '90vh'
        },
        containerDiv: {
            background: '#1D1E21',
            height: '250px',
            color: 'white',
            width: '360px',
            paddingTop: 0,
            zIndex: 100,
            top: 64,
            overflowY: 'auto'
        },
        tableRow: {
            border: '1px solid #373F51'
        },
        tableIcon: {
            height: 30,
            background: '#949CB2',
            textAlign: 'center',
            width: 30,
            border: 'none',
            padding: 0
        },
        tableContentCol: {
            display: 'flex !important',
            fontSize: 14,
            border: 'none',
            padding: 0,
            "&:hover": {
                backgroundColor: "#373F51",
            },
        },
        spanCol: {
            color: '#A6A6A6',
            display: 'flex',
            alignItems: 'baseline',
            fontSize: '10px'
        },
        callIcons: {
            marginLeft: 10,
            cursor: 'pointer',
            padding: '13px'
        }

    })
);

export default function PhoneTaskList(props: PhoneTaskListProps) {
    const classes = useStyles();
    const [isHold, setIsHold] = useState(false);
    const [isHangClicked, setIsHangClicked] = useState(false);
    const [timer, setTimer] = useState(0);
    const [isRecord, setIsRecord] = useState(false);
    const dispatch = useDispatch();
    const taskRouterWorker = useSelector((state: AppState) => state.taskRouterWorker);
    const activeCallsState = useSelector((state: AppState) => state.activeCalls);
    const phoneTokenState = useSelector((state: AppState) => state.phoneToken);
    const loggedInAgentName = phoneTokenState.data && phoneTokenState.data.agentName;
    const workspaceSid = phoneTokenState.data && phoneTokenState.data.workspaceSid;

    useEffect(() => {
        const timer =  handleCallTimers(activeCallsState);
        setTimer(timer);
    }, [])

    const handleClick = () => {
        if (props.task) {
            console.log("Selected task on click", props.task)
            props.handleSelectedTask(props.task.sid);
        }
    }

    const handleHold = async (isHold: string) => {
        var holdRequest = {
            isHold: isHold === 'hold' ? "true" : "false",
            holdparty: "Customer"
        }
        try {
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/Hold`, holdRequest);
            if (response && response.data) {
                const entries = response.data.entries;
                if (entries[0].eventId != null) {
                    setIsHold(isHold === 'hold' ? true : false);
                    props.handleHold(isHold === 'hold' ? true : false);
                } else {
                    setIsHold(false);
                    props.handleHold(false);
                }
            }
        } catch (err) {
            console.log(err);
            setIsHold(false);
            props.handleHold(false);
        }

    }  

    const handleDecline = async () => {
        props.handleDecline();
        const currentReservation = taskRouterWorker.currentReservation;
        const reservationId = currentReservation.sid;
        await handleReject(workspaceSid,reservationId, props.selectedTask);
    }
    const handleHangUp = async () => {  
        setIsHangClicked(true);
        await handleHangUpApi(workspaceSid, loggedInAgentName, taskRouterWorker.worker, props.selectedTask);
        props.handleHangUp();        
        dispatch(endCall());
        setIsHangClicked(false);
    }
    const handleWrapUp = () => props.handleWrapUp();
    const handleIncoming = () => props.handleIncoming();

    const handleRecord = async () => {
        var request = {
            pause: !isRecord
        }
        try {
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/CallRecording`, request);
            if (response && response.data) {
                const entries = response.data.entries;
                if (entries[0].eventId != null) {
                    setIsRecord(!isRecord)
                } else {
                    setIsRecord(false)
                }
            }
        } catch (err) {
            console.log("Error in record", err);
            setIsRecord(false)
        }
    }

    useEffect(() => {
       const timer =  handleCallTimers(activeCallsState);
       setTimer(timer);
    }, [activeCallsState.state])

    const truncate = (str, n) => {
        return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
    };

    // const handleChatIncoming = async () => {
    //     try {
    //         const conSid = props.attributes && props.attributes.ConversationSid;
    //         const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/ChatParticipant?conversationSid=${conSid}`)
    //         if (response && response.data) {
    //             console.log(response.data);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    const renderTableRow = () => {
        let to = ''; let from = ''; let direction = ''; let queueName = ''; let directionText = ''; let agentName = '';
        if (props.attributes) {
            agentName = props.attributes.Agent;
            to = props.attributes.direction === 'outbound' ? props.attributes.outbound_to : props.attributes.to
            if (to && to.length >= 12) {
                to = truncate(to, 12);
            }
            from = props.attributes.direction === 'outbound' ? props.attributes.CustomerPhone : props.attributes.from

            direction = props.attributes.direction;
            queueName = props.attributes.direction === 'outbound' ? props.attributes.campaign : props.attributes.Campaigns;
            directionText = props.attributes.direction === 'outbound' ? "Oubound call from" : "Incoming call to";
        }

        return (
            <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableIcon}>
                    {props.callState === CallStates.INCOMING && <Incoming />}
                    {(props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD) && <OnGoing />}
                    {props.callState === CallStates.WRAPUP && <PhoneEnd />}
                </TableCell>
                <TableCell className={classes.tableContentCol} {...(props.selectedTask && props.selectedTask.sid === props.task.sid && { style: { backgroundColor: "#373F51" } })}>
                    <>
                        <div style={{ width: '65%' }} onClick={handleClick}>
                            {agentName !== '' && <span> {agentName} <br /></span>}
                            <span className={classes.spanCol} style={{ fontSize: '12px' }}>
                                {from} <br />{direction}
                            </span>
                            {props.callState === CallStates.INCOMING && <span className={classes.spanCol}>{directionText} {queueName} </span>}
                            {(props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD) && (
                                <>
                                    <span className={classes.spanCol}> {directionText} {to} </span>
                                    <span className={classes.spanCol} key={timer === 0 ? 11111 : timer}>Live | Total Time &nbsp; &nbsp;{<CallTimer timer={timer} />}</span>
                                    {/* <span className={classes.spanCol}  key={props.stateTimer}>{props.callState} |{ <CurrentCallTimer timer={props.stateTimer} /> }  </span> */}
                                </>
                            )}
                            {props.callState === CallStates.WRAPUP && <span className={classes.spanCol}><span onClick={handleWrapUp}>WrapUp</span></span>}
                        </div>
                        <div style={{ alignSelf: 'center', marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                            <div className={classes.callIcons}>
                                {props.phoneType.toLowerCase() === 'inbound' && props.myConfig && props.myConfig.isInboundRecord && ((props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD) && <RadioButtonCheckedIcon {...(props.myConfig && props.myConfig.canPauseRecording === true) && { onClick: () => handleRecord() }} style={isRecord === false ? { color: 'red' } : { color: 'gray' }} />)}
                                {props.phoneType === "Outbound" && props.myConfig && props.myConfig.isOutboundRecord && ((props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD) && <RadioButtonCheckedIcon {...(props.myConfig && props.myConfig.canPauseRecording === true) && { onClick: () => handleRecord() }} style={isRecord === false ? { color: 'red' } : { color: 'gray' }} />)}

                            </div>
                            <div className={classes.callIcons}>
                                {/* {props.isChat && !props.hasJoined && <Answer onClick={handleChatIncoming} />} */}
                                {props.callState === CallStates.INCOMING && <Answer onClick={handleIncoming} />}
                                {(props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD) && (props.callState !== CallStates.ON_HOLD ? <Hold onClick={() => handleHold('hold')} /> : <HoldActive onClick={() => handleHold('noHold')} />)}
                            </div>
                            <div className={classes.callIcons}>
                                {props.callState === CallStates.INCOMING && <Decline onClick={handleDecline} />}
                                {(props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD) && ( isHangClicked ? <CircularProgress style={{ color: '#ffffff' }} /> : <PhoneEndNoFill onClick={handleHangUp} /> ) }
                            </div>

                        </div>
                    </>
                </TableCell>
            </TableRow>
        )
    }

    return (
        props.task !== null && renderTableRow()
    );
}