//@ts-nocheck
const check = (permissions, action, data) => {
  return permissions.indexOf(action) !== -1;
};

const Can = props =>
  check(props.permissions.permissions, props.perform, props.data)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export default Can;
