import createReducer from "./createReducer";
import {
    LOAD_SCHEDULE_TYPES_START,
    LOAD_SCHEDULE_TYPES_SUCCESS,
    LOAD_SCHEDULE_TYPES_ERROR,
    LoadScheduleTypesStart,
    LoadScheduleTypesSuccess,
    LoadScheduleTypesError,
    SAVE_SCHEDULE_TYPES_START,
    SAVE_SCHEDULE_TYPES_SUCCESS,
    SAVE_SCHEDULE_TYPES_ERROR,
    SaveScheduleTypesStart,
    SaveScheduleTypesSuccess,
    SaveScheduleTypesError,
    UPDATE_SCHEDULE_TYPES_START,
    UPDATE_SCHEDULE_TYPES_SUCCESS,
    UPDATE_SCHEDULE_TYPES_ERROR,
    UpdateScheduleTypesStart,
    UpdateScheduleTypesSuccess,
    UpdateScheduleTypesError,
    ScheduleTypeActions,
    IScheduleType
} from "../actions/scheduleSlotTypes.action";

export interface ScheduleTypesState {
    type: string;
    error: string;
    scheduleTypes: Array<IScheduleType>;
}

const initialState: ScheduleTypesState = {
    type: null,
    error: null,
    scheduleTypes: []
}

function loadScheduleTypesStart(state: ScheduleTypesState, action: LoadScheduleTypesStart): ScheduleTypesState {
    return {
        ...state,
        type: action.type,
        error: null,
        scheduleTypes: []
    }
}

function loadScheduleTypesSuccess(state: ScheduleTypesState, action: LoadScheduleTypesSuccess): ScheduleTypesState {
    return {
        ...state,
        type: action.type,
        error: null,
        scheduleTypes: action.scheduleTypes
    }
}

function loadScheduleTypesError(state: ScheduleTypesState, action: LoadScheduleTypesError): ScheduleTypesState {
    return {
        ...state,
        type: action.type,
        error: action.message
    }
}

function saveScheduleStart(state: ScheduleTypesState, action: SaveScheduleTypesStart): ScheduleTypesState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function saveScheduleSuccess(state: ScheduleTypesState, action: SaveScheduleTypesSuccess): ScheduleTypesState {    
    return {
        ...state,
        scheduleTypes:  [
            ...state.scheduleTypes,
            {...action.scheduleType}
        ],
        type: action.type,
        error: null
    }
}

function saveScheduleError(state: ScheduleTypesState, action: SaveScheduleTypesError): ScheduleTypesState {
    return {
        ...state,
        type: action.type,
        error: action.message,
    }
}

function updateScheduleStart(state: ScheduleTypesState, action: UpdateScheduleTypesStart): ScheduleTypesState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function updateScheduleSuccess(state: ScheduleTypesState, action: UpdateScheduleTypesSuccess): ScheduleTypesState {    
    const elementsIndex = state.scheduleTypes.findIndex(scheduleType => scheduleType.id == action.scheduleType.id);
    const scheduleTypesArray = [...state.scheduleTypes];
    scheduleTypesArray[elementsIndex] = {        
        ...action.scheduleType
    }
    return {
        ...state,
        scheduleTypes:  [
            ...scheduleTypesArray
        ],
        type: action.type,
        error: null
    }
}

function updateScheduleError(state: ScheduleTypesState, action: UpdateScheduleTypesError): ScheduleTypesState {
    return {
        ...state,
        type: action.type,
        error: action.message,
    }
}


const ScheculeTypeReducer = (state: ScheduleTypesState, action: ScheduleTypeActions): ScheduleTypesState => {
    const fnUpdateState = createReducer(initialState, {
        [LOAD_SCHEDULE_TYPES_START]:loadScheduleTypesStart,
        [LOAD_SCHEDULE_TYPES_SUCCESS]: loadScheduleTypesSuccess,
        [LOAD_SCHEDULE_TYPES_ERROR]: loadScheduleTypesError,
        [SAVE_SCHEDULE_TYPES_START]: saveScheduleStart,
        [SAVE_SCHEDULE_TYPES_SUCCESS]: saveScheduleSuccess,
        [SAVE_SCHEDULE_TYPES_ERROR]: saveScheduleError,
        [UPDATE_SCHEDULE_TYPES_START]: updateScheduleStart,
        [UPDATE_SCHEDULE_TYPES_SUCCESS]: updateScheduleSuccess,
        [UPDATE_SCHEDULE_TYPES_ERROR]: updateScheduleError
    });

    return fnUpdateState(state, action);
}

export default ScheculeTypeReducer;