//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "UPDATE_MY_PROFILE";
export const UPDATE_MY_PROFILE_START = `${actionNamePrefix}_START`;
export const UPDATE_MY_PROFILE_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const UPDATE_MY_PROFILE_ERROR = `${actionNamePrefix}_ERROR`;

export interface IUserUpdate {
        userId: string,
		name: string,
		nickname: string,
		picture: string
}

export interface UpdateProfileAction {
    type: typeof UPDATE_MY_PROFILE_START
}

export interface UpdateProfileSuccessAction {
    type: typeof UPDATE_MY_PROFILE_SUCCESS;
    profile: IUserUpdate,
    message: string
}

export interface UpdateProfileErrorAction {
    type: typeof UPDATE_MY_PROFILE_ERROR;
    error: string;
}

export type MyProfileActions =
    UpdateProfileAction|
    UpdateProfileSuccessAction |
    UpdateProfileErrorAction;

export const updateProfile = (updatedProfile: IUserUpdate, userId: string) => async (dispatch: any) => {
    dispatch({ type: UPDATE_MY_PROFILE_START });

    try {
        await Axios.post(`${AppSettings.ApiBaseUrl}/api/UserProfile`, updatedProfile);
        dispatch({
            type: UPDATE_MY_PROFILE_SUCCESS,
            profile: updatedProfile
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.data.Message}`;
            dispatch({
                type: UPDATE_MY_PROFILE_ERROR,
                error: errorMessage
            })
        }
    }
}