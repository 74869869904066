//@ts-nocheck
import Axios, { AxiosError } from "axios";
import { AppSettings } from "../../appSettings";
import { SaveAttributeRequest } from "../../vendor/staff/attributes/mappers/convertToSaveAttributes";

export const LOAD_CUSTOM_ATTRIBUTES = "LOAD_CUSTOM_ATTRIBUTES";
export const LOAD_CUSTOM_ATTRIBUTES_SUCCESS = "LOAD_CUSTOM_ATTRIBUTES_SUCCESS";
export const LOAD_CUSTOM_ATTRIBUTES_ERROR = "LOAD_CUSTOM_ATTRIBUTES_ERROR";

export const SAVE_CUSTOM_ATTRIBUTES = "SAVE_CUSTOM_ATTRIBUTES";
export const SAVE_CUSTOM_ATTRIBUTES_SUCCESS = "SAVE_CUSTOM_ATTRIBUTES_SUCCESS";
export const SAVE_CUSTOM_ATTRIBUTES_ERROR = "SAVE_CUSTOM_ATTRIBUTES_ERROR";

export const EDIT_CUSTOM_ATTRIBUTES = "EDIT_CUSTOM_ATTRIBUTES";
export const EDIT_CUSTOM_ATTRIBUTES_SUCCESS = "EDIT_CUSTOM_ATTRIBUTES_SUCCESS";
export const EDIT_CUSTOM_ATTRIBUTES_ERROR = "EDIT_CUSTOM_ATTRIBUTES_ERROR";

export const LOAD_CUSTOM_ATTRIBUTES_AFTERSAVE = "LOAD_CUSTOM_ATTRIBUTES_AFTERSAVE";

export const DELETE_CUSTOM_ATTRIBUTES = "DELETE_CUSTOM_ATTRIBUTES";
export const DELETE_CUSTOM_ATTRIBUTES_SUCCESS = "DELETE_CUSTOM_ATTRIBUTES_SUCCESS";
export const DELETE_CUSTOM_ATTRIBUTES_ERROR = "DELETE_CUSTOM_ATTRIBUTES_ERROR";

export interface LoadCustomAttributesError {
    id: string;
    message: string;
}

export interface SaveCustomAttributesError {
    id: string;
    message: string;
}

export interface LoadCustomAttributesAction {
    type: typeof LOAD_CUSTOM_ATTRIBUTES;
}

export interface LoadCustomAttributesSuccessAction {
    type: typeof LOAD_CUSTOM_ATTRIBUTES_SUCCESS;
    customAttributes: Array <any>
}

export interface LoadCustomAttributesError {
    type: typeof LOAD_CUSTOM_ATTRIBUTES_ERROR;
    error: string;
}

export interface SaveCustomAttributesAction {
    type: typeof SAVE_CUSTOM_ATTRIBUTES
}

export interface SaveCustomAttributesSuccessAction {
    type: typeof SAVE_CUSTOM_ATTRIBUTES_SUCCESS;
    customAttribute: Array<any>;
    message: string
}

export interface SaveCustomAttributesError {
    type: typeof SAVE_CUSTOM_ATTRIBUTES_ERROR;
    error: string;
}

export interface EditCustomAttributesAction {
    type: typeof EDIT_CUSTOM_ATTRIBUTES
}

export interface EditCustomAttributesSuccessAction {
    type: typeof EDIT_CUSTOM_ATTRIBUTES_SUCCESS;
    customAttribute: Array<any>;
    message: string
}

export interface EditCustomAttributesError {
    type: typeof EDIT_CUSTOM_ATTRIBUTES_ERROR;
    error: string;
}

export interface DeleteCustomAttributesAction {
    type: typeof DELETE_CUSTOM_ATTRIBUTES
}

export interface DeleteCustomAttributesSuccessAction {
    type: typeof DELETE_CUSTOM_ATTRIBUTES_SUCCESS;
    customAttributeId: number
}

export interface DeleteCustomAttributesError {
    type: typeof DELETE_CUSTOM_ATTRIBUTES_ERROR;
    error: string;
}

export interface LoadCustomAttributesAfterSave {
    type: typeof LOAD_CUSTOM_ATTRIBUTES_AFTERSAVE;
}


export type CustomAttributesAction = 
    LoadCustomAttributesAction |
    LoadCustomAttributesSuccessAction |
    LoadCustomAttributesError | 
    SaveCustomAttributesAction |
    SaveCustomAttributesSuccessAction |
    SaveCustomAttributesError |
    LoadCustomAttributesAfterSave |
    EditCustomAttributesAction |
    EditCustomAttributesSuccessAction |
    EditCustomAttributesError |
    DeleteCustomAttributesAction |
    DeleteCustomAttributesSuccessAction |
    DeleteCustomAttributesError;

export const loadCustomAttributes = () => async (dispatch: any) => {
    dispatch({ type: LOAD_CUSTOM_ATTRIBUTES });

    try {
        const response = 
        await Axios.get<Array<any>>(`${AppSettings.ApiRoleManagerUrl}/api/CustomAttributes`);
        
        dispatch({
            type: LOAD_CUSTOM_ATTRIBUTES_SUCCESS,
            customAttributes: response.data
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: LOAD_CUSTOM_ATTRIBUTES_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveCustomAttributes = (saveAttribute) => async (dispatch: any) => {
    dispatch({ type: SAVE_CUSTOM_ATTRIBUTES });

    try {
        const response = await Axios.post(`${AppSettings.ApiRoleManagerUrl}/api/customAttributes`, saveAttribute);
        dispatch({
            type: SAVE_CUSTOM_ATTRIBUTES_SUCCESS,
            customAttribute: response.data
        });
    } catch (err) {
        if (err && err.response) {
            console.log(err.response.data);
            let errors = "";
            if (err.response.data.errors && err.response.data.errors !== null) {
                errors = JSON.stringify(err.response.data.errors);
            } else {
                errors = err.response.data.Message;
                //errors = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            }
            // if (err.response.data && err.response.data.Message !== null) {
            //     errors = err.response.data.Message;
            // }
            const errorMessage = `Error: ${errors}`;
            dispatch({
                type: SAVE_CUSTOM_ATTRIBUTES_ERROR,
                error: errorMessage
            })
        }
    }
}

export const editCustomAttributes = (saveAttribute) => async (dispatch: any) => {
    dispatch({ type: EDIT_CUSTOM_ATTRIBUTES });

    try {
        const response = await Axios.patch(`${AppSettings.ApiRoleManagerUrl}/api/customAttributes`, saveAttribute);
        dispatch({
            type: EDIT_CUSTOM_ATTRIBUTES_SUCCESS,
            customAttribute: response.data
        });
    } catch (err) {
        if (err && err.response) {
            console.log(err.response.data);
            let errors = "";
            if (err.response.data.errors && err.response.data.errors !== null) {
                errors = JSON.stringify(err.response.data.errors);
            } else {
                errors = err.response.data.Message;
                //errors = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            }
            // if (err.response.data && err.response.data.Message !== null) {
            //     errors = err.response.data.Message;
            // }
            const errorMessage = `Error: ${errors}`;
            dispatch({
                type: EDIT_CUSTOM_ATTRIBUTES_ERROR,
                error: errorMessage
            })
        }
    }
}


export const deleteCustomAttributes = (deleteCustomAttribute) => async (dispatch: any) => {
    dispatch({ type: DELETE_CUSTOM_ATTRIBUTES });

    try {
        const response = await Axios.delete(`${AppSettings.ApiRoleManagerUrl}/api/customAttributes`, {data: deleteCustomAttribute } );
        dispatch({
            type: DELETE_CUSTOM_ATTRIBUTES_SUCCESS,
            customAttributeId: deleteCustomAttribute.id
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: DELETE_CUSTOM_ATTRIBUTES_ERROR,
                error: errorMessage
            })
        }
    }
}

export const loadAfterSave = () => (dispatch: any) => {
    dispatch({ type: LOAD_CUSTOM_ATTRIBUTES_AFTERSAVE}); 
}