import React, { useState } from 'react';
import ImageUpload from '../../../MyProfile/ImageUpload';
import EditorDialogContainer from '../manageAgentSchedule/EditorDialogContainer';
import FileUpload from './FileUpload';

export default function UploadDialogComponent(props) {
    const [profileImg, setProfileImg] = useState(null);

    const handleUploadClick = (imageUrl) => {
        if (imageUrl != null) {
            setProfileImg(imageUrl);
        }
    }

    const renderImageContent = () => {
        return (
            <ImageUpload
                imgServer={profileImg}
                onSaveImage={handleUploadClick}
            />
        )
    }

    const renderFileContent = () => {
        return (
            <FileUpload/>
        )
    }

    return (
        <EditorDialogContainer
            isCustomTitle={true}
            title={"Upload Image"}
            content={props.type === 'image' ? renderImageContent() : renderFileContent()}
            onClose={props.cancelEditor}
            id="flow"
        />
    )
}