//@ts-nocheck
import Axios, { AxiosError } from "axios";
import { SaveOOTRequest } from "../../Administration/leaveManagement/outOfOfficeTreshold/mapper/convertToSaveOOT";
import { AppSettings } from "../../appSettings";

export const LOAD_OOT = "LOAD_OOT";
export const LOAD_OOT_SUCCESS = "LOAD_OOT_SUCCESS";
export const LOAD_OOT_ERROR = "LOAD_OOT_ERROR";

export const SAVE_OOT = "SAVE_OOT";
export const SAVE_OOT_SUCCESS = "SAVE_OOT_SUCCESS";
export const SAVE_OOT_ERROR = "SAVE_OOT_ERROR";

export interface LoadOOTError {
    id: string;
    message: string;
}

export interface OOTResponse {
    id: number,
    businessId: number,
    projectId: number,
    campaignId: number,
    businessName: string,
    projectName: string,
    campaignName: string,
    minimumDailyFTE: number,
    minimumApprovalHrs: number,
    rule: string,
    editable: boolean
}

export interface LoadOOTAction {
    type: typeof LOAD_OOT;
}

export interface LoadOOTSuccessAction {
    type: typeof LOAD_OOT_SUCCESS;
    data: Array<OOTResponse>
}

export interface LoadOOTError {
    type: typeof LOAD_OOT_ERROR;
    error: string;
}

export interface SaveOOTAction {
    type: typeof SAVE_OOT;
}

export interface SaveOOTSuccessAction {
    type: typeof SAVE_OOT_SUCCESS;
}

export interface SaveOOTError {
    type: typeof SAVE_OOT_ERROR;
    error: string;
}


export type OOTAction = 
    LoadOOTAction |
    LoadOOTSuccessAction |
    LoadOOTError | 
    SaveOOTAction |
    SaveOOTSuccessAction |
    SaveOOTError;

export const loadOOT = () => async (dispatch: any) => {
    dispatch({ type: LOAD_OOT });

    try {
        const response = 
        await Axios.get<Array<OOTResponse>>(`${AppSettings.ApiBaseUrl}/api/OutOfOffice`);
        
        dispatch({
            type: LOAD_OOT_SUCCESS,
            data: response.data
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: LOAD_OOT_ERROR,
                error: errorMessage
            })
        }
    }
}


export const saveOOT = (saveRequest: Array<SaveOOTRequest>) => async (dispatch: any) => {
    dispatch({ type: SAVE_OOT });

    try {
        //const response = 
        await Axios.put(`${AppSettings.ApiBaseUrl}/api/OutOfOffice`, saveRequest);
        
        dispatch({
            type: SAVE_OOT_SUCCESS
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: SAVE_OOT_ERROR,
                error: errorMessage
            })
        }
    }
}
