import React, {useEffect} from 'react';
import { Theme, Table, TableRow, TableCell, TableContainer, Button, TableBody } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Axios from 'axios';
import { AppSettings } from '../appSettings';
import { ReactComponent as Chat } from './Icons/Chat.svg';
import { ReactComponent as Answer } from './Icons/Answer.svg';
import { ReactComponent as Decline } from './Icons/Decline.svg';
import { ReactComponent as PhoneEnd } from './Icons/PhoneEnd.svg';
import moment from 'moment';
import { getDuration } from '../vendor/staff/manageAgentSchedule/util';
import CallTimer from './CallTimer';
import { Conversation } from '@twilio/conversations';
import { useSelector } from 'react-redux';
import { AppState } from '../store/reducers';
import { isEmptyArray } from 'formik';

interface ChatTaskProps {
    callerInfo: any,
    attributes: any
    taskDetail?: any,
    handleSelectedTask: (taskId) => void;
    selectedTask?: any
    selectedReservation?: any
    worker: any,
    conversation: Conversation
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableRoot: {
            padding: 0,
            border: '1px solid #373F51'
        },
        tableRow: {
            border: '1px solid #373F51'
        },
        tableIcon: {
            height: 30,
            background: '#949CB2',
            textAlign: 'center',
            width: 30,
            border: 'none',
            padding: 0
        },
        tableContentCol: {
            display: 'flex !important',
            fontSize: 14,
            border: 'none',
            padding: 0,
            "&:hover": {
                backgroundColor: "#373F51",
            },
        },
        spanCol: {
            color: '#A6A6A6',
            display: 'flex',
            alignItems: 'baseline',
            fontSize: '10px'
        },
        callIcons: {
            marginLeft: 10,
            cursor: 'pointer',
            padding: '13px'
        }

    })
);

export default function ChatTasksList(props: ChatTaskProps) {
    const classes = useStyles();
    const taskRouterWorker = useSelector((state: AppState) => state.taskRouterWorker);

    const handleClick = () => {
        if (props.taskDetail) {
            props.handleSelectedTask(props.taskDetail.sid);
        }
    }

    useEffect(() => {
        const matchingReservation = getMatchingReservation();
        if (matchingReservation && !isEmptyArray(matchingReservation) && matchingReservation[0].task.attributes.type === "text" && matchingReservation[0].reservationStatus === "accepted") {
            if (props.conversation?.status !== "joined") joinChat();
        }
    },[props.selectedTask])

    const handleTaskWrapup = async () => {
        const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/WrapUp`);
        if (response && response.data) {
            const entries = response.data.entries;
            if (entries[0].eventId != null)
                console.log('Success')
        }
    }

    const getMatchingReservation = () => {
        if (props.taskDetail) {
            const reservations = [...taskRouterWorker.reservations];
            if (taskRouterWorker.currentReservation) reservations.push(taskRouterWorker.currentReservation);
            const matchReservation = reservations && reservations.filter(x => x.task.sid === props.taskDetail.sid);
            if (matchReservation && !isEmptyArray(matchReservation)) {
                return matchReservation;
            }
        }
    }

    const getMatchingReservationStatus = () => {
        const matchingReservation = getMatchingReservation();
        if (matchingReservation && !isEmptyArray(matchingReservation)) {
            return matchingReservation[0].reservationStatus ;
        }
    }

    const joinChat = async () => {
        try {
            const conSid = props.attributes && props.attributes.ConversationSid;
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/ChatParticipant?conversationSid=${conSid}`)
            if (response && response.data) {
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleChatIncoming = async () => {
        //props.conversation.join();
        //if it is conversation, then use accept reservation here.
        const matchingReservation = getMatchingReservation();
        if (matchingReservation && !isEmptyArray(matchingReservation) && matchingReservation[0].task.attributes.type === "text" && matchingReservation[0].reservationStatus === "pending") {
            matchingReservation[0].accept(
                function (error, reservation) {
                    if (error) {
                        console.log(error.code);
                        console.log(error.message);
                        return;
                    }
                    console.log("reservation accepted");
                    for (const property in reservation) {
                        console.log(property + " : " + reservation[property]);
                    }
                }
            );
        } 
        await joinChat();
    }

    const handleDecline = () => {
        console.log("handle decline comes here")
        //if it is conversation, then use accept reservation here.        
        const matchingReservation = getMatchingReservation();
        if (matchingReservation && !isEmptyArray(matchingReservation)) {
            matchingReservation[0].reject(
                function (error, reservation) {
                    console.log("reservation rejected");
                    if (error) {
                        console.log(error.code);
                        console.log(error.message);
                        return; 
                    }
                    console.log("reservation rejected");
                    for (const property in reservation) {
                        console.log(property + " : " + reservation[property]);
                    }
                }
            );
        }
    }

    const renderTableRow = () => {
        let direction = ''; let queueName = ''; let directionText = ''; let agentName = '';
        let assignmentStatus = "";
        if (props.taskDetail) {
            assignmentStatus = props.taskDetail.assignmentStatus;
        }
        if (props.attributes) {
            agentName = props.attributes.Author;
            direction = props.attributes.direction;
            queueName = props.attributes.Campaigns;
            directionText = props.attributes.Body;
        }

        const currentTime = moment(Date.now()).utc().format();
        const updatedTime = moment(props.taskDetail.dateUpdated).utc().format();
        const duration = getDuration(updatedTime, currentTime);
        let durationMS = duration.asMilliseconds();
        durationMS = durationMS < 0 ? 0 : durationMS;

        return (
            <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableIcon}>
                    <Chat />
                </TableCell>
                <TableCell className={classes.tableContentCol} {...(props.selectedTask && props.selectedTask.sid === props.taskDetail.sid && { style: { backgroundColor: "#373F51" } })}>
                    <>
                        <div style={{ width: '65%', paddingLeft: '5px' }} onClick={handleClick}>
                            {agentName !== ' ' && <span className={classes.spanCol} style={{ fontSize: '12px' }}> {agentName} <br /></span>}
                            <span className={classes.spanCol} style={{ fontSize: '12px' }}>
                                {direction} | {queueName}
                            </span>
                            <span className={classes.spanCol} style={{ fontSize: '12px' }}>
                                {directionText}
                            </span>
                            <span className={classes.spanCol}> <CallTimer timer={durationMS} />  </span>
                        </div>
                        <div style={{ alignSelf: 'center', marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                        {/* && (!props.conversation || (props.conversation && props.conversation.status !== "joined")) && */}
                        {/* (!props.conversation || (props.conversation && props.conversation.status !== "joined")) && */}
                            <div className={classes.callIcons}>
                                { getMatchingReservationStatus() !== "accepted" && <Answer onClick={() => handleChatIncoming()} />}
                            </div>
                            <div className={classes.callIcons}>
                                {getMatchingReservationStatus() !== "accepted" &&  <Decline onClick={() => handleDecline()} />}
                            </div>
                        </div>
                    </>
                </TableCell>
            </TableRow>
        )
    }

    return (
        props.taskDetail !== null && renderTableRow()
    );
}