import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "LOAD_SCHEDULE";
export const LOAD_SCHEDULE_START = `${actionNamePrefix}_START`;
export const LOAD_SCHEDULE_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_SCHEDULE_ERROR = `${actionNamePrefix}_ERROR`;

export interface ILoadProjectAgentScheduleRequest {
    fromInUtc: string,
    toInUtc: string,
}

function loadStart() {
    return {
        type: LOAD_SCHEDULE_START
    };
}

function loadSuccess(schedule) {
    return {
        type: LOAD_SCHEDULE_SUCCESS,
        data: schedule
    };
}

function loadError(error) {
    return {
        type: LOAD_SCHEDULE_ERROR,
        error
    }
}

export const getSchedule = (request: ILoadProjectAgentScheduleRequest) => (dispatch) => {
    dispatch(loadStart);
    Axios.get(`${AppSettings.ApiSchedulerUrl}/schedulercrud/fromDateTime/${request.fromInUtc}/toDateTime/${request.toInUtc}/ProjectSchedule`)
        .then(response => {
            dispatch(loadSuccess(response.data));
        })
        .catch(error => {
            dispatch(loadError(error));
        });
}