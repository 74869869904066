//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_ASSIGNMENT_DATA";
export const LOAD_ASSIGNMENT_DATA_START = `${actionNamePrefix}_START`;
export const LOAD_ASSIGNMENT_DATA_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_ASSIGNMENT_DATA_ERROR = `${actionNamePrefix}_ERROR`;

export interface LoadAssignmentResponse {
    agents: Array<any>,
    projects: Array<any>,
    assignments: Array<any>
}

export interface LoadAssignmentStartedAction {
    type: typeof LOAD_ASSIGNMENT_DATA_START;
}

export interface LoadAssignmentSuccessAction {
    type: typeof LOAD_ASSIGNMENT_DATA_SUCCESS;
    data: LoadAssignmentResponse
}

export interface LoadAssignmentErrorAction {
    type: typeof LOAD_ASSIGNMENT_DATA_ERROR;
    error: string;
}

export type myAssignmentActions = LoadAssignmentStartedAction |
                                LoadAssignmentSuccessAction|
                                LoadAssignmentErrorAction;

export const loadMyAssignmentData = () => async (dispatch) => {
    dispatch({type: LOAD_ASSIGNMENT_DATA_START})
    try {
        const response = await Axios.get<LoadAssignmentResponse>(`${AppSettings.ApiBaseUrl}/api/MyAssignment`);        
        dispatch({
            type: LOAD_ASSIGNMENT_DATA_SUCCESS,
            data: response.data
         })
    }
    catch (e) {
        if (e && e.response) {
            const errorMessage = `Error: ${e.response.Message}`;
            dispatch({
                type: LOAD_ASSIGNMENT_DATA_ERROR,
                error: errorMessage
            })
        }
    }
}

