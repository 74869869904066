const actionNameLoadPrefix = "WORKER_INIT";
export const WORKER_INIT_START = `${actionNameLoadPrefix}_START`;
export const WORKER_INIT_SUCCESS = `${actionNameLoadPrefix}_SUCCESS`;
export const WORKER_INIT_ERROR = `${actionNameLoadPrefix}_ERROR`;

export interface WorkerInitStartedAction {
    type: typeof WORKER_INIT_START,
    data: any
}

export interface WorkerInitEndAction {
    type: typeof WORKER_INIT_SUCCESS,
    data: any
}

export interface WorkerInitErrorAction {
    type: typeof WORKER_INIT_ERROR,
    data: string
}

export type WorkerActions = WorkerInitStartedAction | WorkerInitEndAction | WorkerInitErrorAction;

export const initalizeWorker = (worker) => async (dispatch) =>  {
    dispatch({ type: WORKER_INIT_START });
    try  {        
        dispatch({
            type: WORKER_INIT_SUCCESS,
            data: worker
        });
    } catch (e) {
        dispatch({
            type: WORKER_INIT_ERROR,
            data: JSON.stringify(e)
        })
    }    
}

export const workerError = (e) => async (dispatch) =>  {
    dispatch({
        type: WORKER_INIT_ERROR,
        data: JSON.stringify(e)
    }) 
}
