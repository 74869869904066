import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { loadAttributes, saveAttributes } from "../../../store/actions/attributes.action";
import { loadCustomAttributes } from "../../../store/actions/customAttributes.action";
import { loadCustomAttributesValue, saveCustomAttributesValue } from "../../../store/actions/customAttributesValue.action";
import { loadRoles } from "../../../store/actions/roles.action";
import { RootState } from "../../../store/reducers";
import { AttributesState } from "../../../store/reducers/attributes.reducer";
import { CustomAttributesState } from "../../../store/reducers/customAttributes.reducer";
import { CustomAttributesValueState } from "../../../store/reducers/customAttributesValue.reducer";
import { RolesState } from "../../../store/reducers/roles.reducer";
import AttributesGrid from "./attributesGrid";
import { SaveAttributeRequest } from "./mappers/convertToSaveAttributes";

interface AttributesContainerStateProps {
    attributesState: AttributesState;
    customAttributesState: CustomAttributesState;
    customAttributesValueState: CustomAttributesValueState;
    rolesState: RolesState;
}

interface AttributesContainerDispatchProps {
    loadAttributes: () => void;
    loadCustomAttributes: () => void;
    loadCustomAttributesValue: () => void;
    loadRoles: () => void;
    saveAttributes: (saveAttributesData: Array<SaveAttributeRequest>) => void;
    saveCustomAttributesValue: (saveValues) => void;
    saveState: string,
    errorState: string
}

type AttributesContainerProps =
    AttributesContainerStateProps &
    AttributesContainerDispatchProps;

function AttributesContainer(props: AttributesContainerProps) {

    useEffect(() => {
        props.loadCustomAttributes();
        props.loadRoles();
        props.loadAttributes();        
        props.loadCustomAttributesValue();
    }, []);

    const saveAttributes = (rowsData: Array<SaveAttributeRequest>, customRowsData) => {
        props.saveAttributes(rowsData);
        props.saveCustomAttributesValue(customRowsData);
    }

    const renderLoading = () => {
        return (<div>Loading data...!</div>)
    }

    const renderError = () => {
        return (<ul>
            {props.rolesState.error ? (<li> Error loading roles - {props.rolesState.error}</li>) : (<React.Fragment />)}
            {props.attributesState.error ? (<li>Error loading attributes -  {props.attributesState.error}</li>) : (<React.Fragment />)}
        </ul>)
    }
    
    const renderGrid = () => {
        return (<AttributesGrid attributes={props.attributesState.attributes}
            customAttributes={props.customAttributesState && props.customAttributesState.customAttributes}
            customAttributesValues={props.customAttributesValueState && props.customAttributesValueState.customAttributesValue}
            roles={props.rolesState.roles} saveAttributes={saveAttributes} 
            saveState={props.attributesState.state} errorState={props.attributesState.error} />)
    }

    const render = () => {
        if (props.rolesState.roles &&
            props.rolesState.roles.length > 0 && 
            props.attributesState.attributes &&
            props.attributesState.attributes.length > 0)
            return renderGrid();

        if (props.rolesState.error || props.attributesState.error)
            return renderError();

        return renderLoading();
    }

    return (<div>        
        <Typography variant="h5" gutterBottom>
            Attributes
        </Typography>
        {render()}
    </div>)
}


const mapStateToProps = (state: RootState) => {
    return {
        attributesState: state.attributes,
        customAttributesState: state.customAttributes,
        customAttributesValueState: state.customAttributesValue,
        rolesState: state.roles
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadAttributes: () => dispatch(loadAttributes()),
        loadCustomAttributes:() => dispatch(loadCustomAttributes()),
        loadCustomAttributesValue: () => dispatch(loadCustomAttributesValue()),
        loadRoles: () => dispatch(loadRoles()),
        saveAttributes: (saveAttributesData: Array<SaveAttributeRequest>) => dispatch(saveAttributes(saveAttributesData)),
        saveCustomAttributesValue: (saveCustomValues) => dispatch(saveCustomAttributesValue(saveCustomValues))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesContainer);