//@ts-nocheck
import createReducer from "./createReducer";
import {
    LOAD_PHONE_INTERVAL_STAFFING_START,
    LOAD_PHONE_INTERVAL_STAFFING_SUCCESS,
    LOAD_PHONE_INTERVAL_STAFFING_ERROR,
    SAVE_PHONE_INTERVAL_STAFFING_START,
    SAVE_PHONE_INTERVAL_STAFFING_SUCCESS,
    SAVE_PHONE_INTERVAL_STAFFING_ERROR
}
    from "../actions/phoneIntervalStaffing.action";

const initialState = {
    data: [],
    state: null,
    error: null,
    saveState: null,
    saveError: null
};

function loadStarted(state, action) {;
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

function loaded(state, action) {
    return {
        ...state,
        data: action.data,
        error: null,
        state: action.type
    }
}

function loadError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}

function saveStart(state, action) {
    return {
        ...state,
        saveState: action.type,
        saveError: null
    }
}

function saveSuccess(state, action) {    
    return {
        ...state,
        saveState: action.type,
        saveError: null,
    }
}

function saveError(state, action) {
    return {
        ...state,
        saveState: action.type,
        saveError: action.error
    }
}

export default createReducer(initialState, {
    [LOAD_PHONE_INTERVAL_STAFFING_START]: loadStarted,
    [LOAD_PHONE_INTERVAL_STAFFING_SUCCESS]: loaded,
    [LOAD_PHONE_INTERVAL_STAFFING_ERROR]: loadError,

    [SAVE_PHONE_INTERVAL_STAFFING_START]: saveStart,
    [SAVE_PHONE_INTERVAL_STAFFING_SUCCESS]: saveSuccess,
    [SAVE_PHONE_INTERVAL_STAFFING_ERROR]: saveError,
}); 