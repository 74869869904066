import React, { useCallback, useEffect, useState } from 'react';
import { Conversation, Client } from '@twilio/conversations';
import {
    FormControl,
    NativeSelect,
    Theme,
    CircularProgress,
    TableContainer,
    Table,
    TableBody,
    Typography,
    Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CallDetailContainer from './CallDetailContainer';
import { ReactComponent as TaskDivider } from './Icons/TaskDividerIcon.svg';
import CallStates from './CallStates';
import PhoneTaskList from './PhoneTasksList';
import HeaderSummary from './HeaderSummary';
import { ICampaign } from '../store/actions/administration.action';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/reducers';
import { getPhoneToken } from '../store/actions/phoneToken.action';
import { loadStatesLog, startLoggingState, STATE_START_LOG_SUCCESS, STATES_LOAD_SUCCESS, stopLoggingState } from '../store/actions/statesLog.action';
import Axios from 'axios';
import { AppSettings } from '../appSettings';
import { useAuth0 } from '../auth0/react-auth0-wrapper';
import { loadMyDefaultCampaign } from '../store/actions/myDefaultCampaign.action';
import MemoSettingsMenu from './SettingsMenu';
import DailerControl from './DailerControl';
import { acceptCall, CALL_ONCALL, outboundCallHandler } from '../store/actions/activeCalls.action';
import moment from 'moment';
import { DateHelper } from 'bryntum-scheduler';
import { getDuration } from '../vendor/staff/manageAgentSchedule/util';
import PhoneWrapupTasks from './PhoneWrapupTasks';
import { isEmptyArray } from 'formik';
import WebChatContainer from './ChatComponent/WebChatContainer';
import ChatTasksList from './ChatTasksList';
import AlertItems from './AlertItems';
import { DEVICE_ERROR, DEVICE_INIT_ERROR, RESERVATION_ACCEPTED, RESERVATION_CANCELLED, RESERVATION_COMPLETED, RESERVATION_CREATED, RESERVATION_REJECTED, RESERVATION_WRAPUP, WORKER_ERROR } from '../store/actions/softPhone/actionTypes';
import { WORKER_INIT_ERROR } from '../store/actions/softPhone/worker.action';
import { getForms } from '../store/actions/forms.action';
import { checkIfLiquidString, findAndReplaceRuleString, getLiquidString, getValues } from './ChatComponent/utils/liquidString';
import { useHistory } from 'react-router-dom';

interface PhoneListProps {
    userReasons: Array<any>,
    assignments: Array<any>,
    rosterCampaigns: Array<ICampaign>,
    callState: string,
    handleStateChange: (value) => void;
    phoneType: string,
    hangupFinished: () => void;
    customerHang?: boolean
    callReject?: boolean
    rejectFinished?: () => void;
    myConfig: any,
    handleSignout: () => void;
    updateActivity: (val, type, state) => void
    stateChangeSuccess: boolean,
    setStateChangeSuccess: (val) => void;
    handleAcceptReservation: () => void;
}

export interface callerDetails {
    Color: string,
    LicensePlate: string,
    MemberAge: string,
    MemberNamePreference: string,
    MemberPlan: string,
    PhoneNumber: string,
    Status: string,
    Vehicle1: string,
}

interface RuleInfoLookUp {
    RuleInfo: string
}

interface callerDefinitionItem {
    caller: string,
    direction: string
}

interface callerDirection {
    Items: Array<any>
}
interface campaignInTaskRule {
    campaignId: number
    campaignName: string,

}
interface TaskRules {
    id: number,
    ruleName: string,
    ruleString: string,
    ruleAction: string,
    ruleType: string,
    campaigns: Array<campaignInTaskRule>
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableRoot: {
            padding: 0,
            border: '1px solid #373F51'
        },
        iconColor: {
            color: 'white'
        },
        iconGreen: {
            color: '#0A7D41'
        },
        iconRed: {
            color: ' #CC292D'
        },
        taskHeader: {
            textTransform: 'uppercase',
            padding: 5,
            backgroundColor: '#252935',
            border: '1px solid #373F51',
            color: '#FFFFFF',
            height: '30px',
            fontWeight: 'bold'
        },
        taskDivider: {
            backgroundColor: '#252935',
            border: '1px solid #373F51',
            color: '#FFFFFF',
            height: '30px',
            textAlign: 'center',
            letterSpacing: '10px',
            cursor: 'row-resize',
            userSelect: 'none' /* disable selection */
        },
        phoneListContainer: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 64,
            height: '90vh',
            zIndex: 1000
        },
        containerDiv: {
            background: '#1D1E21',
            //height: '30%',
            color: 'white',
            // width: '320px',
            paddingTop: 0,
            zIndex: 100,
            top: 64,
            overflowY: 'auto'
        },
        containerCallDiv: {
            background: '#1D1E21',
            color: 'white',
            // width: '335px',
            height: '65%',
            paddingTop: 0,
            zIndex: 100,
            top: 64
        },
        tableRow: {
            border: '1px solid #373F51'
        },
        tableIcon: {
            height: 30,
            background: '#949CB2',
            textAlign: 'center',
            width: 30,
            padding: 15,
            border: 'none'
        },
        spanCol: {
            color: '#A6A6A6'
        },
        callIcons: {
            marginLeft: 10,
            cursor: 'pointer'
        },
        iconBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '50%',
            padding: '0 10px 0 10px'
        },
        textStyle: {
            color: '#ffffff',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            alignItems: 'center'
        },
        icon: {
            fill: '#ffffff',
        },
        slider: {
            textAlign: 'center',
            letterSpacing: '10px',
            cursor: 'row-resize',
            userSelect: 'none' /* disable selection */
        },
        navContainer: {
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '100%',
            height: '50px',
            backgroundColor: '#373F51',
        },
        navButton: {
            // backgroundColor:'red', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20%',
            height: '100%',
            lineHeight: '20px',
            color: '#fff'
        }

    })
);

export default function PhoneListContainer(props: PhoneListProps) {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [callState, setCallState] = useState(props.callState);
    const [callerInfo, setCallerInfo] = useState<Partial<callerDetails>>({});
    const [scheduleId, setScheduleId] = useState(23);
    const [number, setNumber] = useState("");
    const [outboundCamp, setOutboundCamp] = useState('None');
    const [skills, setSkills] = useState([]);
    const [skillType, setSkillType] = useState([]);
    const [isWrap, setIsWrap] = useState(false);
    const [currentTask, setCurrentTask] = useState(null);
    const [wrapupTasks, setWrapupTasks] = useState([]);
    const [chatTasks, setChatTasks] = useState([]);
    const [onGoingCallTask, setOngoingCallTask] = useState(null);
    const [task, setTask] = useState(null);
    const [queue, setQueue] = useState('');
    const [attributes, setAttributes] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedReservation, setSelectedReservation] = useState(null);
    const [timer, setTimer] = useState(0);
    const [isChat, setIsChat] = useState(false);
    const [hasJoined, setHasJoined] = useState(false);
    const [conversation, setConversation] = useState(null);
    const [conversationClient, setConversationClient] = useState<Client>(null);
    const [taskRules, setTaskRules] = useState<Array<TaskRules>>(null);
    const history = useHistory();
    //@ts-ignore
    const { userAccessToken } = useAuth0();

    const dispatch = useDispatch();
    const phoneTokenState = useSelector((state: AppState) => state.phoneToken);
    const myDefaultCampaign = useSelector((state: AppState) => state.myDefaultCampaign);
    const statesLog = useSelector((state: AppState) => state.statesLog);
    const taskRouterWorker = useSelector((state: AppState) => state.taskRouterWorker);
    const agentSecondaryId = phoneTokenState.data && phoneTokenState.data.agentSecondaryId;
    const loggedInAgentName = phoneTokenState.data && phoneTokenState.data.agentName;
    const workspaceSid = phoneTokenState.data && phoneTokenState.data.workspaceSid;
    const deviceState = useSelector((state: AppState) => state.deviceState);
    const activeCallsState = useSelector((state: AppState) => state.activeCalls);
    const loggedInBusiness = useSelector((state:AppState) => state.loggedInBusiness);
    useEffect(() => {
        dispatch(getForms('phone-forms', loggedInBusiness.data.id));
    }, [loggedInBusiness.data]);

    useEffect(() => {
        (async function () {
            if (taskRouterWorker.state === RESERVATION_CREATED) {
                const currentReservation = taskRouterWorker.currentReservation;
                const task = currentReservation.task;
                if (task.attributes.type === "text") {
                    console.log(task);
                    setIsChat(true);
                }
                setCurrentTask(task);
                setAttributes(task.attributes);
                setSelectedReservation(currentReservation);
                setSelectedTask(task);
                setShow(true);
                const queueName = task.attributes.direction === 'outbound' ? task.attributes.campaign : task.attributes.Campaigns;
                setQueue(queueName);
            }
            if (taskRouterWorker.state === RESERVATION_CANCELLED || taskRouterWorker.state === RESERVATION_REJECTED) {
                const reservations = taskRouterWorker.reservations;
                const checkIfCancelled = reservations && reservations.filter(x => x.sid === taskRouterWorker.reservationSid);
                if (onGoingCallTask !== null && (isEmptyArray(checkIfCancelled) || checkIfCancelled[0].task.sid === onGoingCallTask.sid)) {
                    setOngoingCallTask(null);
                }
                props.handleStateChange(CallStates.READY);
                setShow(false);
                setSelectedTask(null);
            }
            if (taskRouterWorker.state === RESERVATION_WRAPUP) {
                const currentReservation = taskRouterWorker.reservations && taskRouterWorker.reservations.filter(x => x.sid === taskRouterWorker.reservationSid);
                const task = currentReservation[0].task;
                if (task) {
                    setCurrentTask(task);
                    setAttributes(task.attributes);
                    setSelectedTask(task);
                    setSelectedReservation(currentReservation);
                }
                props.handleStateChange(CallStates.WRAPUP);
            }
            if (taskRouterWorker.state === RESERVATION_ACCEPTED) {
                const currentReservation = taskRouterWorker.reservations && taskRouterWorker.reservations.filter(x => x.sid === taskRouterWorker.reservationSid);
                const task = currentReservation[0].task;
                console.log("Current Reservation", currentReservation)
                console.log(task.attributes.type)
                if (task.attributes.type !== "text") {
                    if (activeCallsState.currentCallState !== CallStates.ON_CALL) {
                        deviceState && deviceState.connection && deviceState.connection.accept();
                        props.handleStateChange(CallStates.ON_CALL);
                        if (task.attributes.SalesforceURL) {
                            const url = task.attributes.SalesforceURL;
                            window.open(url, '_blank', 'noopener,noreferrer');
                        }
                        console.log("Current Reservation attr", task.attributes);
                        if (task.attributes.ScreenPop) {                            
                            let url = task.attributes.ScreenPop;
                            url = url.replace(/\\\//g, "/");
                            window.open(url, '_blank', 'noopener,noreferrer');
                        }
                        
                        if (task.attributes.iframeURL) {
                            let url = task.attributes.iframeURL
                            url = url.replace(/\\\//g, "/");
                            history.push({
                                pathname:`/tasks`,
                                state: url
                            })
                        }

                    }
                }
                setCurrentTask(task);
                setAttributes(task.attributes);
                setSelectedTask(task);
                setSelectedReservation(currentReservation);
            }
            if (taskRouterWorker.state === RESERVATION_COMPLETED) {
                const reservations = taskRouterWorker.reservations;
                const currentReservation = reservations && reservations.filter(x => (x.task.assignmentStatus !== "completed" && x.task.assignmentStatus !== "canceled"));
                console.log("in completed", currentReservation);
                if (!isEmptyArray(currentReservation)) {
                    setCurrentTask(currentReservation[0].task);
                    setAttributes(currentReservation[0].task.attributes);
                    setSelectedTask(currentReservation[0].task);
                    setSelectedReservation(currentReservation[0]);
                } else {
                    setCurrentTask(null);
                    setAttributes(null);
                    setSelectedTask(null);
                    setSelectedReservation(null);
                }
                const checkIfOngoingIsCompleted = reservations && reservations.filter(x => x.sid === taskRouterWorker.reservationSid);
                console.log("in completed - if there is the task", checkIfOngoingIsCompleted);
                if (onGoingCallTask !== null && (isEmptyArray(checkIfOngoingIsCompleted) || checkIfOngoingIsCompleted[0].task.sid === onGoingCallTask.sid)) {
                    setOngoingCallTask(null);
                }
                setShow(false);
                if (props.callState === CallStates.WRAPUP) props.handleStateChange(CallStates.READY);
            }
            await arrangeTasks();
        })();

    }, [taskRouterWorker.state])

    useEffect(() => {
        (async function () {
            if (taskRouterWorker.state === RESERVATION_ACCEPTED) {
                const currentReservation = taskRouterWorker.reservations && taskRouterWorker.reservations.filter(x => x.sid === taskRouterWorker.reservationSid);
                const task = currentReservation[0].task;
                if (task.attributes.type === "text" && conversationClient !== null) {
                    const conversationSid = task.attributes.ConversationSid;
                    const conversation = await conversationClient.getConversationBySid(conversationSid);
                    console.log(conversation);
                    setConversation(conversation);
                }
            }
        })();
    }, [taskRouterWorker.state, conversationClient])

    useEffect(() => {
        if (currentTask) {
            getLookupData(currentTask.attributes);
            if (currentTask.attributes.type === "text") {
                setIsChat(true);
                const checkIfPresent = !isEmptyArray(chatTasks) && chatTasks.filter(x => x.sid === currentTask.sid);
                if (!checkIfPresent) {
                    setChatTasks([currentTask, ...chatTasks]);
                }
            } else {
                setIsChat(false);
                setOngoingCallTask(currentTask);
            }
            setSelectedTask(currentTask);
        }
        if (currentTask === null) {
            setOngoingCallTask(null);
        }
    }, [currentTask])

    useEffect(() => {
        //@ts-ignore
        if (myDefaultCampaign && Object.keys(myDefaultCampaign.data).length > 0) {
            //@ts-ignore
            const campaign = myDefaultCampaign.data.campaign;
            setOutboundCamp(campaign.name);
        } else {
            dispatch(loadMyDefaultCampaign())
        }
        (async function () {
            const taskRulesResponse = await Axios.get(`${AppSettings.ApiBaseUrl}/api/TaskRule`);
            if (taskRulesResponse && taskRulesResponse.data) {
                setTaskRules(taskRulesResponse.data);
            }
        })();
    }, []);

    useEffect(() => {
        if (taskRules && selectedTask !== null && Object.keys(selectedTask).length > 0)
            getLookupData(selectedTask.attributes);
    },[taskRules, selectedTask])

    useEffect(() => {
        if (myDefaultCampaign && myDefaultCampaign.data && Object.keys(myDefaultCampaign.data).length > 0) {
            //@ts-ignore
            const campaign = myDefaultCampaign.data.campaign;
            setOutboundCamp(campaign.name);
        }
    }, [myDefaultCampaign])

    useEffect(() => {
        if (props.callState === CallStates.ON_CALL) {
            setShow(true);
        }
    }, [props.callState])

    useEffect(() => {
        if (props.customerHang === true) {
            deviceState && deviceState.device.disconnectAll();
            props.handleStateChange(CallStates.WRAPUP);
            props.hangupFinished();
            if (props.phoneType === 'Outbound') {
                (async function () {
                    const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/CompleteOutbound`);
                    if (response && response.data) {
                        const entries = response.data.entries;
                        if (entries[0].eventId != null)
                            console.log('Success')

                    }
                })();
            }
        }
    }, [props.customerHang])

    useEffect(() => {
        if (props.callReject === true && deviceState !== null) {
            deviceState && deviceState.connection && deviceState.connection.reject()
            deviceState && deviceState.device && deviceState.device.disconnectAll();
            props.handleStateChange(CallStates.READY);
            props.rejectFinished();
        }
    }, [props.callReject])

    useEffect(() => {
        if (props.callState === CallStates.DECLINE) {
            props.handleStateChange(CallStates.READY);
        }
        getCurrentStateTimer('from call state change');
    }, [props.callState])

    useEffect(() => {
        const skills = getMySkills(props.assignments, props.rosterCampaigns);
        if (myDefaultCampaign && myDefaultCampaign.data !== null && Object.keys(myDefaultCampaign.data).length === 0) {
            setOutboundCamp(skills[0]);
        }
    }, [props.assignments, props.rosterCampaigns])

    const arrangeTasks = () => {
        const data = taskRouterWorker.reservations;
        let wrapTasks = []; let chatTasks = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].task.assignmentStatus === "wrapping" && data[i].reservationStatus !== "rejected") {
                    const checkIfPresent = !isEmptyArray(wrapTasks) && wrapTasks.filter(x => x.sid === data[i].task.sid && x.assignmentStatus === "wrapping");
                    if (!checkIfPresent) {
                        wrapTasks.push(data[i].task);
                    }
                    if (selectedTask && selectedTask.sid === data[i].task.sid) {
                        setSelectedTask(data[i].task);
                    }
                }

                if (data[i].task.attributes.type === "text" && (data[i].reservationStatus !== "rejected" && data[i].reservationStatus !== "timeout" && data[i].reservationStatus !== "canceled" && data[i].task.assignmentStatus !== "completed" && data[i].task.assignmentStatus !== "canceled")) {
                    const checkIfPresent = !isEmptyArray(chatTasks) && chatTasks.filter(x => x.sid === data[i].task.sid);
                    //|| data[i].reservationStatus !== "timeout" || data[i].reservationStatus !== "rejected"
                    if (!checkIfPresent) {
                        console.log("how did I get in", data[i].reservationStatus)
                        chatTasks.push(data[i].task);
                    }
                }
            }
        }
        const distintWT = wrapTasks.filter((v, i, a) => a.indexOf(v) == i);
        setWrapupTasks(wrapTasks);
        setChatTasks(chatTasks);
    }

    useEffect(() => {
        if (statesLog.type === STATE_START_LOG_SUCCESS)
            dispatch(loadStatesLog());
    }, [statesLog.type])

    useEffect(() => {
        if (statesLog.type === STATES_LOAD_SUCCESS) {
            const taskgroup = statesLog.data.find(x => x.id === statesLog.clockInId);
            if (statesLog.logginInId) {
                const taskEntry = taskgroup.taskEntries.find(x => x.id === statesLog.logginInId);
                if (taskEntry.isPhone === true) {
                    setScheduleId(taskEntry.scheduleTypeId);
                }
            } else {
                setScheduleId(0);
                props.handleStateChange(CallStates.OFFLINE);
                setCallState(CallStates.OFFLINE);
            }
        }
    }, [statesLog.data])

    useEffect(() => {
        (async function () {
            const response = await Axios.get(`${AppSettings.ApiTwilioUrl}/api/CapabilityToken/ConvoToken`);
            if (response && response.data) {
                const client = new Client(response.data.token);
                client.on('stateChanged', async (state) => {
                    if (state === 'initialized') {
                        setConversationClient(client);
                    }
                })

                // client.on("conversationJoined", async (conversation) => {
                //     console.log("conversation joined is it?", conversation);
                //     setConversation(conversation);
                //     setHasJoined(true);
                // });
                client.on("conversationLeft", (conversation) => {
                    console.log("conversation left is it?", conversation);
                    setHasJoined(false);
                });
            }
        }
        )();
    }, [])

    const checkIfRulePresentForCampaign = (campaignName: string): Array<TaskRules> => {
        const res = taskRules.reduce((acc, a) => {
            const ch = a.campaigns && a.campaigns.filter(b => b.campaignName.includes(campaignName));
            if (ch && ch.length) acc.push({ ...a, campaigns: ch });
            return acc;
        }, []);
        return res;
    }


    const getLookupData = useCallback((attributes) => {
        let lookUpData = {};
        let rules;
        console.log("Callstate in lookup",props.callState);
        if (taskRules && attributes.hasOwnProperty("Campaigns")) {
            rules = checkIfRulePresentForCampaign(attributes.Campaigns);
        }
        if (rules && rules.length > 0) {
            let rulePresent;
            if (taskRouterWorker.state === RESERVATION_CREATED) {
                rulePresent = rules.filter(x => x.ruleType === "OnIncoming");               
            } else if (taskRouterWorker.state === RESERVATION_ACCEPTED) {
                rulePresent = rules.filter(x => x.ruleType === "OnAccept");               
            }  else if (taskRouterWorker.state === RESERVATION_WRAPUP || props.callState === CallStates.WRAPUP) {
                rulePresent = rules.filter(x => x.ruleType === "OnWrap");               
            }         
            console.log("The rules present", rulePresent);
            if (rulePresent && rulePresent.length >0) {
                if (rulePresent[0].ruleAction === "RenderToTab") {
                    const ruleString = rulePresent[0].ruleString;
                    const newString = findAndReplaceRuleString(ruleString, { task: { attributes: attributes } })
                    console.log("new string", newString);
                    lookUpData['ruleInfo'] = newString;
                }
            } else {
                lookUpData = attributes;
                const keys = Object.keys(lookUpData);
                keys && keys.forEach((key, index) => {
                    if ((typeof lookUpData[key] !== 'object') && lookUpData[key].endsWith("}}"))
                        lookUpData[key] = "No information"
                });
                if (attributes.hasOwnProperty("Caller_Reason")) {
                    lookUpData['Caller_Reason'] = attributes.Caller_Reason
                }
            }
        } else {
            lookUpData = attributes;
            const keys = Object.keys(lookUpData);
            keys && keys.forEach((key, index) => {
                if ((typeof lookUpData[key] !== 'object') && lookUpData[key].endsWith("}}"))
                    lookUpData[key] = "No information"
            });
            if (attributes.hasOwnProperty("Caller_Reason")) {
                lookUpData['Caller_Reason'] = attributes.Caller_Reason
            }
        }
        console.log("Look up data here",lookUpData);
        setCallerInfo(lookUpData);
    },[taskRouterWorker.state, props.callState]);

    const getCurrentStateTimer = async (statem) => {
        try {
            const response = await Axios.get(`${AppSettings.ApiTwilioUrl}/api/AgentPhoneStates/agent-state`);
            const currentTime = moment(Date.now()).utc().format();
            if (response.data) {
                let timerValue = 0;
                const stateStartTime = response.data.timeInUtcInState;
                if (stateStartTime && !DateHelper.isBefore(currentTime, stateStartTime) && props.callState === response.data.state) {
                    const duration = getDuration(stateStartTime, currentTime);
                    timerValue = duration.asMilliseconds();
                    timerValue = timerValue < 0 ? 0 : timerValue;
                }
                setTimer(timerValue);
            }
        } catch (e) {
            const errorMsg = e;
            console.log(e);
        }

    }

    const changeCallState = (changedState) => {
        if (changedState === CallStates.ACCEPTED) {
            props.handleStateChange(CallStates.ON_CALL);
            setShow(true);
            dispatch(acceptCall());
        }
        if (changedState === CallStates.DECLINE) {
            setShow(false);
            deviceState.connection && deviceState.connection.reject();
        }
        if (changedState === CallStates.COMPLETEWRAPUP) {
            setShow(false);
            setCallerInfo({});
            console.log(props.callState);
            if (props.callState === CallStates.WRAPUP) props.handleStateChange(CallStates.READY);
        }
        if (changedState !== CallStates.COMPLETEWRAPUP && changedState !== CallStates.ACCEPTED) {
            props.handleStateChange(changedState);
        }

    }

    const handleDataChange = (e) => {
        setOutboundCamp(e.target.value);
    }

    const handleCompleteWrapUp = () => {
        setShow(true);
    }

    const handleSelectedTask = (taskId) => {
        const reservations = [...taskRouterWorker.reservations];
        if (taskRouterWorker.currentReservation) reservations.push(taskRouterWorker.currentReservation);
        if (taskId !== null || undefined !== taskId) {
            const matchingTask = wrapupTasks.filter(x => x.sid === taskId);
            if (matchingTask) {
                setSelectedTask(matchingTask[0]);
                getLookupData(matchingTask[0].attributes);
                setShow(true);
            }
            const matchReservation = reservations && reservations.filter(x => x.task.sid === taskId);
            if (matchReservation && !isEmptyArray(matchReservation)) {
                setSelectedReservation(matchReservation[0]);
            }
        }
    }

    const handleCurrentTask = (taskId) => {
        if (onGoingCallTask && taskId === onGoingCallTask.sid) {
            setSelectedTask(onGoingCallTask);
            getLookupData(onGoingCallTask.attributes);
            setShow(true);
        }
    }

    const handleChatTask = useCallback(async (taskId) => {
        if (taskId !== null || undefined !== taskId) {
            const matchingTask = chatTasks.filter(x => x.sid === taskId);
            if (!isEmptyArray(matchingTask)) {
                setSelectedTask(matchingTask[0]);
                if (matchingTask[0].hasOwnProperty("attributes")) {
                    getLookupData(matchingTask[0].attributes);
                    const conversationSid = matchingTask[0].attributes.ConversationSid;
                    const conversation = conversationSid && conversationClient && await conversationClient.getConversationBySid(conversationSid);
                    console.log(conversation);
                    setConversation(conversation);
                }
            }
        }
        setIsChat(true);
    }, [conversationClient])

    const handleTaskWrapup = async () => {
        setIsWrap(true);
        setShow(false);
        setIsWrap(false);

    }

    const handleOutboundCall = async (toCall, type) => {
        var outboundRequest = {
            ...(type === "agent" && { "customerPhone": toCall.contactUri }),
            ...(type === "number" && { "customerPhone": "+" + toCall }),
            "dailType": type === "agent" ? "Agent" : "Number",
            "campaignName": outboundCamp
        }
        dispatch(outboundCallHandler(outboundRequest));
    }

    const handleProdcutiveOnLoad = (scheduleType) => {
        if (scheduleType && scheduleType.isProductive) {
            dispatch(getPhoneToken());
        }
    }

    const handleStateChange = async (scheduleType) => {
        if (props.callState === CallStates.ON_CALL || props.callState === CallStates.ON_HOLD) {
            alert("State change while on call not allowed. Please try after call is done");
            props.setStateChangeSuccess(false);
            return;
        }
        if (props.callState === CallStates.WRAPUP) {
            changeCallState(CallStates.COMPLETEWRAPUP);
            const response = await Axios.post(`${AppSettings.ApiTwilioUrl}/api/TwilioCallActions/WrapUp`);
            if (response && response.data) {
                const entries = response.data.entries;
                if (entries[0].eventId != null)
                    console.log('Success')

            }
        }
        props.updateActivity(scheduleType, "change", scheduleType && scheduleType.isProductive ? CallStates.READY : CallStates.IDLE);
    }

    const handleHold = (isHold) => {
        if (isHold === true)
            props.handleStateChange(CallStates.ON_HOLD);
        else
            props.handleStateChange(CallStates.ON_CALL);
    }

    const handleIncoming = async() => {
        await props.handleAcceptReservation();
        props.handleStateChange(CallStates.ON_CALL);
        setShow(true);
        dispatch(acceptCall());
    }

    const handleDecline = () => { props.handleStateChange(CallStates.DECLINE); setShow(false); }
    const handleHangUp = () => { props.handleStateChange(CallStates.WRAPUP); setShow(true); }

    const getMySkills = (assignments, rosterCampaigns: Array<ICampaign>) => {
        const skillArray = [];
        const skillType = [];
        rosterCampaigns.forEach(element => {
            if (assignments && assignments.length > 0) {
                for (var i = 0; i < assignments.length; i++) {
                    if (assignments[i].campaigns && assignments[i].campaigns.find(aa => aa.campaignId === element.campaignId)) {
                        skillArray.push(element.campaignName);
                        skillType.push(element.type);
                    }
                }
            }
        });
        setSkills(skillArray);
        setSkillType(skillType);
        return skillArray;
    }

    const handleShowSettingsMenu = () => setShowMenu(!showMenu);
    const handleBackMenu = () => setShowMenu(false);

    const renderError = () => {
        let error: string = ''; let deviceError: string = '';
        if (taskRouterWorker.state === WORKER_INIT_ERROR || taskRouterWorker.state === WORKER_ERROR) {
            error = taskRouterWorker.error;
        }
        if (deviceState.state === DEVICE_INIT_ERROR || deviceState.state === DEVICE_ERROR) {
            deviceError = deviceState.error
        }
        if (error !== '') {
            return (
                <AlertItems msg={error} severity="error" />
            )
        }
        if (deviceError !== '') {
            return (
                <AlertItems msg={deviceError} severity="warning" />
            )
        }
    }

    const renderTableForTasks = () => {
        const currentTaskInWrapupList = currentTask && wrapupTasks.filter(x => x.sid === currentTask.sid);
        return (
            <TableContainer>
                <Table className={classes.tableRoot} aria-label="simple table">
                    <TableBody>
                        {
                            wrapupTasks && wrapupTasks.map((eachTask) => {
                                return (
                                    <React.Fragment key={eachTask.sid}>
                                        <PhoneWrapupTasks
                                            worker={taskRouterWorker.worker}
                                            callerInfo={callerInfo}
                                            isWrapUp={isWrap}
                                            handleWrapUp={handleTaskWrapup}
                                            task={true}
                                            taskDetail={eachTask}
                                            attributes={eachTask.attributes}
                                            handleSelectedTask={handleSelectedTask}
                                            selectedTask={selectedTask}
                                            changeCallState={changeCallState}
                                        />
                                    </React.Fragment>
                                )

                            })
                        }
                        {
                            chatTasks && chatTasks.length > 0 && chatTasks.map((eachTask) => {
                                return (
                                    <React.Fragment key={eachTask.sid}>
                                        <ChatTasksList
                                            worker={taskRouterWorker.worker}
                                            callerInfo={callerInfo}
                                            attributes={eachTask.attributes}
                                            taskDetail={eachTask}
                                            handleSelectedTask={handleChatTask}
                                            selectedTask={selectedTask}
                                            conversation={conversation}
                                            selectedReservation={selectedReservation} />
                                    </React.Fragment>
                                )

                            })
                        }
                        {onGoingCallTask && isEmptyArray(currentTaskInWrapupList) &&
                            <PhoneTaskList
                                stateTimer={timer}
                                showMenu={showMenu}
                                callState={props.callState}
                                handleHold={handleHold}
                                handleHangUp={handleHangUp}
                                handleDecline={handleDecline}
                                handleIncoming={handleIncoming}
                                callerInfo={callerInfo}
                                handleWrapUp={handleCompleteWrapUp}
                                task={onGoingCallTask}
                                attributes={attributes}
                                phoneType={props.phoneType}
                                myConfig={props.myConfig}
                                handleSelectedTask={handleCurrentTask}
                                selectedTask={selectedTask}
                            />}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderTaskList = () => {
        //const currentTaskInWrapupList = currentTask && wrapupTasks.filter(x => x.sid === currentTask.sid);         
        return (
            <>
                <div className={classes.taskHeader}>All Tasks</div>
                <div className={classes.containerDiv}>
                    {isLoading && <CircularProgress color='primary' />}
                    {(isEmptyArray(wrapupTasks) && isEmptyArray(chatTasks)) && !currentTask && <div style={{ textAlign: 'center' }}>You have no open tasks</div>}
                    {renderError()}
                    {renderTableForTasks()}
                </div>
            </>
        )
    }

    return (
        <>
            <HeaderSummary online={taskRouterWorker.online} callState={props.callState} handleStateChange={handleStateChange} handleProdcutiveOnLoad={handleProdcutiveOnLoad} userReasons={props.userReasons} selectedSchedule={scheduleId} skills={skills} attributes={attributes} skillType={skillType} handleSignout={props.handleSignout} handleShowSettingsMenu={handleShowSettingsMenu} stateChangeSuccess={props.stateChangeSuccess} />
            <div style={{ overflow: 'auto', resize: 'vertical', minHeight: '20%', }}>
                {showMenu && <MemoSettingsMenu handleBackMenu={handleBackMenu} handleSignout={props.handleSignout} device={deviceState.device} />}
                {!showMenu && renderTaskList()}
            </div>

            {(task || selectedTask || props.phoneType === "Outbound") && <div className={classes.taskDivider} ><TaskDivider /></div>}
            <div className={classes.containerCallDiv}>
                {
                    selectedTask !== null && selectedTask && selectedTask.attributes.type === "text" &&
                    <WebChatContainer
                        callerInfo={callerInfo}
                        conversation={conversation}
                        conversationClient={conversationClient}
                        attributes={selectedTask.attributes}
                        task={selectedTask}
                        worker={taskRouterWorker.worker}
                        handleEndChat={handleTaskWrapup}
                    />
                }
                {
                    (selectedTask !== null && selectedTask && selectedTask.attributes.type !== "text") &&
                    <CallDetailContainer
                        conn={deviceState.connection}
                        callState={props.callState}
                        changeCallState={changeCallState}
                        agentSecondaryId={agentSecondaryId}
                        workspaceId={workspaceSid}
                        agentName={loggedInAgentName}
                        callerInfo={callerInfo}
                        attributes={attributes}
                        queueName={queue}
                        phoneType={attributes && attributes.direction}
                        selectedTask={selectedTask}
                        handleTaskWrapup={handleTaskWrapup}
                        handleCoachDone={() => setShow(false)}
                        scheduleId={scheduleId}
                        userReasons={props.userReasons}
                        handleIncoming={handleIncoming}
                    />
                }
                {
                    !show && props.phoneType === "Outbound" &&
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                            <span>Assign a queue</span>
                            <FormControl>
                                <NativeSelect
                                    value={outboundCamp}
                                    onChange={(handleDataChange)}
                                    name="sourceItem"
                                    style={{ color: '#ffffff', fontWeight: 'bold', marginLeft: '10px' }}
                                    classes={{
                                        icon: classes.icon,
                                    }}
                                >
                                    <option value={'None'} style={{ color: '#fff', backgroundColor: '#1D1E21' }}>None</option>
                                    {skills != null && skills
                                        .map((eachItem, index) =>
                                            <option style={{ color: '#fff', backgroundColor: '#1D1E21' }} key={eachItem + "-" + index} value={eachItem}>{eachItem}</option>
                                        )}
                                </NativeSelect>
                            </FormControl>
                        </div>
                        <DailerControl number={number} setNumber={setNumber} callState={props.callState} handleCall={handleOutboundCall} type="outbound" />
                    </>
                }
            </div>
            {/* <Grid className={classes.navContainer}>
                        <div className={classes.navButton}>
                            <ContactPage />
                            <Typography>Contacts</Typography>
                        </div>
                        <div className={classes.navButton}>
                            <History />
                            <Typography>History</Typography>
                        </div>
                        <div className={classes.navButton}>
                            <Home />
                            <Typography>Home</Typography>
                        </div>
                        <div className={classes.navButton}>
                            <List />
                            <Typography>Lists</Typography>
                        </div>
                        <div className={classes.navButton}>
                            <Dialpad />
                            <Typography>Dialpad</Typography>
                        </div>
                    </Grid> */}
        </>
    )
}