//@ts-nocheck
import createReducer from "./createReducer";
import { 
    LOAD_ASSIGNMENT_DATA_START,
    LOAD_ASSIGNMENT_DATA_SUCCESS,
    LOAD_ASSIGNMENT_DATA_ERROR,
    myAssignmentActions,
    LoadAssignmentStartedAction,
    LoadAssignmentSuccessAction,
    LoadAssignmentErrorAction,
    LoadAssignmentResponse
} from '../actions/myAssignments.action'

export interface MyAssignmentState {
    state: string;
    error: string;
    data: LoadAssignmentResponse;
}

const initialState: MyAssignmentState = {
    state: null,
    error: null,
    data: []
}

function loadAssignments(state:MyAssignmentState, action: LoadAssignmentStartedAction): MyAssignmentState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: []
    }
}

function loadAssignmentsSuccess(state:MyAssignmentState, action: LoadAssignmentSuccessAction): MyAssignmentState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadAssignmentsError(state:MyAssignmentState, action: LoadAssignmentErrorAction): MyAssignmentState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const MyAssignmentsReducer =
    (state: MyAssignmentState, action: myAssignmentActions): MyAssignmentState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_ASSIGNMENT_DATA_START]: loadAssignments,
            [LOAD_ASSIGNMENT_DATA_SUCCESS]: loadAssignmentsSuccess,
            [LOAD_ASSIGNMENT_DATA_ERROR]: loadAssignmentsError
        });

        return fnUpdateState(state, action);
    }

export default MyAssignmentsReducer;