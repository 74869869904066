//@ts-nocheck
import React, {useState} from "react";
import { Link as RouterLink } from 'react-router-dom';
import ListItem from "@mui/material/ListItem";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { getParentPath } from "./util/routerUtil";

export default function ListItemLink(props) {
    const { icon, primary, to } = props;
    const pathname = window.location.pathname;
    const validFileExtensions = ["jpg", "jpeg", "bmp", "gif", "png","xlsx"];    
    const nonLinks = pathname.split('.').pop();
    const isNonLink = validFileExtensions.includes(nonLinks);
    
    const parentPath = !isNonLink && getParentPath(pathname);

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
                // See https://github.com/ReactTraining/react-router/issues/6056
                <RouterLink to={to} {...itemProps} innerRef={ref} />
            )),
        [to],
    );

    const selectedPath = parentPath ? parentPath.path === to : null;

    return (
        <li>
            <ListItem button component={renderLink} className={props.className} selected={selectedPath} >
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}