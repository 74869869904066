import React, { useState, useEffect } from "react";
import { CircularProgress, Chip, Avatar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

type Props = {
    inProgress: boolean;
    isError: boolean;
    errorMessage: string;
    isSuccess: boolean;
    successMessage: string;
};

const useStyles = makeStyles(theme => ({
    chipStyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

export default function OperationStatusIndicator(props: Props) {
    const classes = useStyles();
    const [isDismissed, setIsDismissed] = useState(false);
    
    useEffect(() => {
          setIsDismissed(false);  
    }, [props.inProgress]);

    const buildInProgressIndicator =() => {
        return (<CircularProgress size={20} />);
    }

    const buildSuccessIndicator = () => {
        return (<Chip className={classes.chipStyle} color="primary" icon={<CheckCircleOutlineOutlinedIcon />} label={props.successMessage} onClick={handleClick} />);
    }

    const buildErrorIndicator = () => {
        return (<Chip className={classes.chipStyle} color="secondary" avatar={<Avatar>X</Avatar>} label={props.errorMessage} onClick={handleClick} />);
    }

    const handleClick = () => {
        setIsDismissed(true);
    }

    return (<React.Fragment>
        {!isDismissed && props.inProgress ? buildInProgressIndicator(): ("")}
        {!isDismissed && props.isError ? buildErrorIndicator(): ((""))}
        {!isDismissed && props.isSuccess ? buildSuccessIndicator(): ((""))}
    </React.Fragment>)
}