import {
    CustomAttributesAction,
    LoadCustomAttributesAction,
    LoadCustomAttributesError,
    LoadCustomAttributesSuccessAction,
    LOAD_CUSTOM_ATTRIBUTES,
    LOAD_CUSTOM_ATTRIBUTES_ERROR,
    LOAD_CUSTOM_ATTRIBUTES_SUCCESS,
    SaveCustomAttributesAction,
    SaveCustomAttributesSuccessAction,
    SaveCustomAttributesError,
    SAVE_CUSTOM_ATTRIBUTES,
    SAVE_CUSTOM_ATTRIBUTES_SUCCESS,
    SAVE_CUSTOM_ATTRIBUTES_ERROR ,
    EditCustomAttributesAction,
    EditCustomAttributesSuccessAction,
    EditCustomAttributesError,
    EDIT_CUSTOM_ATTRIBUTES,
    EDIT_CUSTOM_ATTRIBUTES_SUCCESS,
    EDIT_CUSTOM_ATTRIBUTES_ERROR,
    LOAD_CUSTOM_ATTRIBUTES_AFTERSAVE,
    LoadCustomAttributesAfterSave,
    DELETE_CUSTOM_ATTRIBUTES,
    DELETE_CUSTOM_ATTRIBUTES_ERROR,
    DELETE_CUSTOM_ATTRIBUTES_SUCCESS,
    DeleteCustomAttributesAction,
    DeleteCustomAttributesSuccessAction,
    DeleteCustomAttributesError
    
} from "../actions/customAttributes.action";
import createReducer from "./createReducer";

export interface CustomAttributesState {
    type: string;
    error: string;
    customAttributes: Array<any>;
}

const initialState: CustomAttributesState = {
    type: null,
    error: null,
    customAttributes: []
}

function loadCustomAttributes(state:CustomAttributesState, action: LoadCustomAttributesAction): CustomAttributesState {
    return {
        ...state,
        type: action.type,
        error: null,
        customAttributes: []
    }
}

function loadCustomAttributesSuccess(state:CustomAttributesState, action: LoadCustomAttributesSuccessAction): CustomAttributesState {
    return {
        ...state,
        type: action.type,
        error: null,
        customAttributes: action.customAttributes
    }
}

function loadCustomAttributesError(state:CustomAttributesState, action: LoadCustomAttributesError): CustomAttributesState {
    return {
        ...state,
        type: action.type,
        error: action.error,
        customAttributes: []
    }
}

function saveCustomAttributes(state:CustomAttributesState, action: SaveCustomAttributesAction): CustomAttributesState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function saveCustomAttributesSuccess(state:CustomAttributesState, action: SaveCustomAttributesSuccessAction): CustomAttributesState {
    const updated = [...state.customAttributes];
    updated.push(action.customAttribute);
    return {
        ...state,
        type: action.type,
        error: null,
        customAttributes: updated
    }
}

function saveCustomAttributesError(state:CustomAttributesState, action: SaveCustomAttributesError): CustomAttributesState {
    return {
        ...state,
        type: action.type,
        error: action.error
    }
}
function editCustomAttributes(state:CustomAttributesState, action: EditCustomAttributesAction): CustomAttributesState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function editCustomAttributesSuccess(state:CustomAttributesState, action: EditCustomAttributesSuccessAction): CustomAttributesState {
    const updated = [...state.customAttributes];
    //@ts-ignore
    const elementsIndex = updated.findIndex(ca => ca.id == action.customAttribute.id);    
    updated[elementsIndex] = {        
        ...action.customAttribute
    }
    return {
        ...state,
        type: action.type,
        error: null,
        customAttributes: updated
    }
}

function editCustomAttributesError(state:CustomAttributesState, action: EditCustomAttributesError): CustomAttributesState {
    return {
        ...state,
        type: action.type,
        error: action.error
    }
}
function loadCustomAttributesAfterSave(state:CustomAttributesState, action: LoadCustomAttributesAfterSave): CustomAttributesState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function deleteCustomAttributes(state:CustomAttributesState, action: DeleteCustomAttributesAction): CustomAttributesState {
    return {
        ...state,
        type: action.type,
        error: null
    }
}

function deleteCustomAttributesSuccess(state:CustomAttributesState, action: DeleteCustomAttributesSuccessAction): CustomAttributesState {
    const updated = [...state.customAttributes];
    console.log(action);
    const updatedList = updated.filter((item) => item.id != action.customAttributeId);
    console.log(updatedList);
    return {
        ...state,
        type: action.type,
        error: null,
        customAttributes: updatedList
    }
}

function deleteCustomAttributesError(state:CustomAttributesState, action: DeleteCustomAttributesError): CustomAttributesState {
    return {
        ...state,
        type: action.type,
        error: action.error
    }
}

const CustomAttributesReducer =
    (state: CustomAttributesState, action: CustomAttributesAction): CustomAttributesState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_CUSTOM_ATTRIBUTES]: loadCustomAttributes,
            [LOAD_CUSTOM_ATTRIBUTES_SUCCESS]: loadCustomAttributesSuccess,
            [LOAD_CUSTOM_ATTRIBUTES_ERROR]: loadCustomAttributesError,
            [SAVE_CUSTOM_ATTRIBUTES]: saveCustomAttributes,
            [SAVE_CUSTOM_ATTRIBUTES_SUCCESS]: saveCustomAttributesSuccess,
            [SAVE_CUSTOM_ATTRIBUTES_ERROR]: saveCustomAttributesError,
            [EDIT_CUSTOM_ATTRIBUTES]: editCustomAttributes,
            [EDIT_CUSTOM_ATTRIBUTES_SUCCESS]: editCustomAttributesSuccess,
            [EDIT_CUSTOM_ATTRIBUTES_ERROR]: editCustomAttributesError,
            [DELETE_CUSTOM_ATTRIBUTES]: deleteCustomAttributes,
            [DELETE_CUSTOM_ATTRIBUTES_SUCCESS]: deleteCustomAttributesSuccess,
            [DELETE_CUSTOM_ATTRIBUTES_ERROR]: deleteCustomAttributesError,
            [LOAD_CUSTOM_ATTRIBUTES_AFTERSAVE]: loadCustomAttributesAfterSave
        });

        return fnUpdateState(state, action);
    }

export default CustomAttributesReducer;
