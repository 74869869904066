import {
    OOTAction,
    LoadOOTAction,
    LoadOOTError,
    OOTResponse,
    LoadOOTSuccessAction,
    SaveOOTAction,
    SaveOOTSuccessAction,
    SaveOOTError,
    LOAD_OOT,
    LOAD_OOT_ERROR,
    LOAD_OOT_SUCCESS, 
    SAVE_OOT,
    SAVE_OOT_ERROR,
    SAVE_OOT_SUCCESS
} from "../actions/outOfOffice.action";
import createReducer from "./createReducer";

export interface OOTState {
    state: string;
    error: string;
    data: Array<OOTResponse>;
}

const initialState: OOTState = {
    state: null,
    error: null,
    data: null
}

function loadOOT(state:OOTState, action: LoadOOTAction): OOTState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadOOTSuccess(state:OOTState, action: LoadOOTSuccessAction): OOTState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadOOTError(state:OOTState, action: LoadOOTError): OOTState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function saveOOT(state:OOTState, action: SaveOOTAction): OOTState {
    return {
        ...state,
        state: action.type
    }
}

function saveOOTSuccess(state:OOTState, action: SaveOOTSuccessAction): OOTState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function saveOOTError(state:OOTState, action: SaveOOTError): OOTState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const OutOfOfficeReducer =
    (state: OOTState, action: OOTAction): OOTState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_OOT]: loadOOT,
            [LOAD_OOT_SUCCESS]: loadOOTSuccess,
            [LOAD_OOT_ERROR]: loadOOTError,
            [SAVE_OOT]: saveOOT,
            [SAVE_OOT_SUCCESS]: saveOOTSuccess,
            [SAVE_OOT_ERROR]: saveOOTError,
        });

        return fnUpdateState(state, action);
    }

export default OutOfOfficeReducer;
