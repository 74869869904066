import createReducer from "./createReducer";
import {
    UPLOAD_FORECAST_SUCCESS, UPLOAD_FORECAST_ERROR, UPLOAD_FORECAST_START, CLEAN_UP_UPLOAD_FORECAST
} from "../actions/forecast.action";

const initialState = {
    state: null,
    error: null,
    isError: false,
    data: null
};

function uploaded(state, action) {
    return {
        ...state,
        state: action.type,        
        data: action.response
    }
}

function uploadStarted(state, action) {
    return {
        ...state,        
        state: action.type
    }
}

function uploadError(state, action) {
    return {
        ...state,        
        error: action.error,
        isError: true,
        state: action.type
    }
}

function cleanUp(state, action) {
    return {
        ...state,        
        error: null,
        isError: false,
        state: null
    }
}

export default createReducer(initialState, {
    [UPLOAD_FORECAST_START]: uploadStarted,
    [UPLOAD_FORECAST_SUCCESS]: uploaded,
    [UPLOAD_FORECAST_ERROR]: uploadError,
    [CLEAN_UP_UPLOAD_FORECAST]: cleanUp

}); 