import createReducer from "./createReducer";
import {
    LOAD_WORKFLOW_START,
    LOAD_WORKFLOW_SUCCESS,
    LOAD_WORKFLOW_ERROR,
    workflowActions,
    LoadWorkflowStartAction,
    LoadWorkflowSuccessAction,
    LoadWorkflowErrorAction,
    SaveWorkflowStart,
    SaveWorkflowSuccess,
    SaveWorkflowError,
    SAVE_SELECTED_START,
    SAVE_SELECTED_ERROR,
    SAVE_SELECTED_SUCCESS
} from '../actions/workflow.action'

export interface WorkflowState {
    state: string;
    error: string;
    data: any;
}

const initialState: WorkflowState = {
    state: null,
    error: null,
    data: []
}

function loadWorkflowStart(state:WorkflowState, action: LoadWorkflowStartAction): WorkflowState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadWorkflowSuccess(state:WorkflowState, action: LoadWorkflowSuccessAction): WorkflowState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadWorkflowError(state:WorkflowState, action: LoadWorkflowErrorAction): WorkflowState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function saveWorkflowStart(state:WorkflowState, action: SaveWorkflowStart): WorkflowState {    
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function saveWorkflowSuccess(state:WorkflowState, action: SaveWorkflowSuccess): WorkflowState {
    const elementsIndex = state.data.findIndex(gs => gs.id == action.selectedParams.id);
    const data = [...state.data];
    if (elementsIndex >= 0) {
        data[elementsIndex] = {        
            ...action.selectedParams
        }
    } else {
        data.push(action.selectedParams);
    }
    
    return {
        ...state,
        data: [
            ...data
        ],
        state: action.type,
        error: null,        
    }
}

function saveWorkflowError(state:WorkflowState, action: SaveWorkflowError): WorkflowState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const WorkflowSelectedReducer =
    (state: WorkflowState, action: workflowActions): WorkflowState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_WORKFLOW_START]: loadWorkflowStart,
            [LOAD_WORKFLOW_SUCCESS]: loadWorkflowSuccess,
            [LOAD_WORKFLOW_ERROR]: loadWorkflowError,
            [SAVE_SELECTED_START] : saveWorkflowStart,
            [SAVE_SELECTED_SUCCESS]: saveWorkflowSuccess,
            [SAVE_SELECTED_ERROR]: saveWorkflowError
        });

        return fnUpdateState(state, action);
    }

export default WorkflowSelectedReducer;