//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefixLoad = "LOAD_MY_LEAVE";
export const LOAD_MY_LEAVE_START = `${actionNamePrefixLoad}_START`;
export const LOAD_MY_LEAVE_SUCCESS = `${actionNamePrefixLoad}_SUCCESS`;
export const LOAD_MY_LEAVE_ERROR = `${actionNamePrefixLoad}_ERROR`;

const actionNamePrefixSave = "SAVE_MY_LEAVE";
export const SAVE_MY_LEAVE_START = `${actionNamePrefixSave}_START`;
export const SAVE_MY_LEAVE_SUCCESS = `${actionNamePrefixSave}_SUCCESS`;
export const SAVE_MY_LEAVE_ERROR = `${actionNamePrefixSave}_ERROR`;


const actionNamePrefixUpdate = "UPDATE_LEAVE";
export const UPDATE_LEAVE_START = `${actionNamePrefixUpdate}_START`;
export const UPDATE_LEAVE_SUCCESS = `${actionNamePrefixUpdate}_SUCCESS`;
export const UPDATE_LEAVE_ERROR = `${actionNamePrefixUpdate}_ERROR`;

export interface LoadMyLeaveStartedAction {
    type: typeof LOAD_MY_LEAVE_START;
}

export interface LoadMyLeaveSuccessAction {
    type: typeof LOAD_MY_LEAVE_SUCCESS;
    data: any
}

export interface LoadMyLeaveErrorAction {
    type: typeof LOAD_MY_LEAVE_ERROR;
    error: string;
}


export interface SaveMyLeaveStartedAction {
    type: typeof SAVE_MY_LEAVE_START;
}

export interface SaveMyLeaveSuccessAction {
    type: typeof SAVE_MY_LEAVE_SUCCESS;
    data: any
}

export interface SaveMyLeaveErrorAction {
    type: typeof SAVE_MY_LEAVE_ERROR;
    error: string;
}

export interface UpdateLeaveStartedAction {
    type: typeof UPDATE_LEAVE_START;
}

export interface UpdateLeaveSuccessAction {
    type: typeof UPDATE_LEAVE_SUCCESS;
    data: any
}

export interface UpdateLeaveErrorAction {
    type: typeof UPDATE_LEAVE_ERROR;
    error: string;
}


export type myLeaveActions =    LoadMyLeaveStartedAction|
                                LoadMyLeaveSuccessAction| LoadMyLeaveErrorAction
                                | SaveMyLeaveStartedAction |
                                SaveMyLeaveSuccessAction|
                                SaveMyLeaveErrorAction |UpdateLeaveStartedAction |
                                UpdateLeaveSuccessAction|
                                UpdateLeaveErrorAction;

export const loadMyLeave = (appliedBy: string) => async(dispatch) => {
    dispatch({type: LOAD_MY_LEAVE_START})
    try {
        const response = await Axios.get(`${AppSettings.ApiSchedulerUrl}/api/Leaves?leave-applied-by=${appliedBy}`);
        dispatch({
            type: LOAD_MY_LEAVE_SUCCESS,
            data: response.data.data
         })
    }
    catch (e) {
        if (e && e.response) {
            const errorMessage = `Error: ${e.response.data.message}`;
            dispatch({
                type: LOAD_MY_LEAVE_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveMyLeave = (request) => async (dispatch) => {
    dispatch({type: SAVE_MY_LEAVE_START})
    try {
        const response = await Axios.post(`${AppSettings.ApiSchedulerUrl}/api/Leaves`, request);
        dispatch({
            type: SAVE_MY_LEAVE_SUCCESS,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.data.message}`;
            dispatch({
                type: SAVE_MY_LEAVE_ERROR,
                error: errorMessage
            })
        }
    }
}

export const updateLeave = (request) => async (dispatch) => {
    dispatch({type: UPDATE_LEAVE_START})
    try {
        const response = await Axios.put(`${AppSettings.ApiSchedulerUrl}/api/Leaves`, request);
        dispatch({
            type: UPDATE_LEAVE_SUCCESS,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.data.message}`;
            dispatch({
                type: UPDATE_LEAVE_ERROR,
                error: errorMessage
            })
        }
    }
}

export const clearLeaveState = () => async(dispatch) => {
    dispatch({type: LOAD_MY_LEAVE_START})   
}