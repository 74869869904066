import createReducer from "./createReducer";
import {DELETE_AGENT_SCHEDULE_START, DELETE_AGENT_SCHEDULE_SUCCESS, DELETE_AGENT_SCHEDULE_ERROR} from '../actions/agentSchedule.action';
import {GET_SCHEDULED_AGENT_COUNT_START, GET_SCHEDULED_AGENT_COUNT_SUCCESS, GET_SCHEDULED_AGENT_COUNT_ERROR} from '../actions/agentSchedule.action';


const initialState = {
    data: [],
    state: null,
    error: null
};

function deleteStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

function deleteError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}

function deleteSuccess(state, action) {
    return {
        ...state,
        data: action.data,
        error: null,
        state: action.type       
    }
}

function loadScheduledAgentCountStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

function loadScheduledAgentCountError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}

function loadScheduledAgentCountSuccess(state, action) {
    return {
        ...state,
        data: action.response,
        error: null,
        state: action.type       
    }
}


export default createReducer(initialState, {
    [DELETE_AGENT_SCHEDULE_START]: deleteStarted,
    [DELETE_AGENT_SCHEDULE_SUCCESS]: deleteSuccess,
    [DELETE_AGENT_SCHEDULE_ERROR]: deleteError,

    [GET_SCHEDULED_AGENT_COUNT_START]:loadScheduledAgentCountStarted,
    [GET_SCHEDULED_AGENT_COUNT_SUCCESS]:loadScheduledAgentCountSuccess,
    [GET_SCHEDULED_AGENT_COUNT_ERROR]:loadScheduledAgentCountError
}); 