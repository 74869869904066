import { 
    FETCH_RESERVATIONS, 
    RESERVATION_CREATED, 
    WORKER_ACTIVITY_UPDATED, 
    WORKER_ERROR, 
    WORKER_READY, 
    WORKER_TOKEN_UPDATE, 
    RESERVATION_CANCELLED, 
    RESERVATION_COMPLETED,
    RESERVATION_WRAPUP,
    WORKER_DISCONNECTED,
    RESERVATION_REJECTED,
    RESERVATION_ACCEPTED,
    RESERVATION_TIMEOUT,
    WORKER_ACTIVITY_MISMATCH
} from "../../actions/softPhone/actionTypes";
import { WorkerActions, WorkerInitEndAction, WorkerInitErrorAction, WorkerInitStartedAction, WORKER_INIT_ERROR, WORKER_INIT_START, WORKER_INIT_SUCCESS } from "../../actions/softPhone/worker.action";
import createReducer from "../createReducer";

export interface TaskRouterWorkerState {
    state: string;
    error: string;
    worker: any,
    workerProps: any,
    reservations: any,
    currentReservation:any;
    online: boolean,
    reservationSid: string
}

const initialState: TaskRouterWorkerState = {
    state: null,
    error: null,
    worker: null, 
    online: false,
    workerProps: null,
    reservations: null,
    currentReservation:null,
    reservationSid: null
}

function workerInitStart(state:TaskRouterWorkerState, action: WorkerInitStartedAction): TaskRouterWorkerState {    
    return {
        ...state,
        state: action.type
    }
}

function workerInitSuccess(state:TaskRouterWorkerState, action: WorkerInitEndAction): TaskRouterWorkerState {    
    return {
        ...state,
        state: action.type,
        worker: action.data.worker,
        reservations: action.data.reservations,
        error: null,
        online: true
    }
}

function workerInitError(state:TaskRouterWorkerState, action: WorkerInitErrorAction): TaskRouterWorkerState {    
    return {
        ...state,
        state: action.type,
        error: action.data, 
        online: false
    }
}
/** Worker Events update **/
function workerReadyUpdate(state:TaskRouterWorkerState,action): TaskRouterWorkerState {
    return {
        ...state,
        state: action.type,
        online: true
    }
}
function workerUpdate(state:TaskRouterWorkerState,action): TaskRouterWorkerState {
    return {
        ...state,
        state: action.type,
        workerProps: action.data
    }
}
function workerMistmatch(state:TaskRouterWorkerState,action): TaskRouterWorkerState {
    return {
        ...state,
        state: action.type
    }
}

function workerDisconnected(state:TaskRouterWorkerState,action): TaskRouterWorkerState {
    return {
        ...state,
        state: action.type,
        online: false
    }
}

function fetchReservations(state:TaskRouterWorkerState,action): TaskRouterWorkerState  {      
    //filter out completed, timeout and cancelled
    let reservations = action.reservations;
    const removeTimeout = reservations.filter(x => (x.reservationStatus !== "timeout" && x.reservationStatus !== "rejected"));
    const removeCompleted = removeTimeout.filter(x => x.task.assignmentStatus !== "completed");
    return {
        ...state,
        state: action.type,
        reservations: removeCompleted
    }
}

function reservationCreated(state:TaskRouterWorkerState,action): TaskRouterWorkerState {
    let updatedReservations = [...state.reservations];
    if (state.currentReservation !== null) {
        const moveToReservation = state.currentReservation;
        updatedReservations = [moveToReservation, ...updatedReservations]
    }
    return {
        ...state,
        state: action.type,        
        reservations: updatedReservations,
        currentReservation: action.data,
        reservationSid: action.data.sid
    }
}
function reservationAccepted(state:TaskRouterWorkerState,action): TaskRouterWorkerState {    
    let updatedReservations = [...state.reservations];
    if (action.data) {
        const checkIfReservationPresent = updatedReservations.findIndex(x => x.sid === action.data.sid);
        if(checkIfReservationPresent > -1) {
            updatedReservations[checkIfReservationPresent] = action.data;
        } else {
            const moveToReservation = action.data;
            updatedReservations = [moveToReservation, ...updatedReservations]
        }
    }
    
    return {
        ...state,
        state: action.type,
        reservations: updatedReservations,
        currentReservation: null,
        reservationSid: action.data.sid
    }
}
function reservationCancelled(state:TaskRouterWorkerState,action): TaskRouterWorkerState {
    const updatedReservations = state.reservations.filter(x => x.sid !== action.data)
    return {
        ...state,
        state: action.type,        
        reservations: updatedReservations,
        currentReservation: null,
        reservationSid: action.data
    }
}

function reservationWrapup(state:TaskRouterWorkerState,action): TaskRouterWorkerState {    
    let updatedReservations = state.reservations;  
    if (action.data) {
        console.log("action data", action.data);
        const checkIfReservationPresent = updatedReservations.findIndex(x => x.sid === action.data.sid);
        if(checkIfReservationPresent > -1) {
            updatedReservations[checkIfReservationPresent] = action.data;
        } else {
            const moveToReservation = action.data;
            updatedReservations = [moveToReservation, ...updatedReservations]
        }
    }  
    // var newReservation = {
    //     ...action.data        
    // }
    // updatedReservations.push(newReservation)  
    return {
        ...state,
        state: action.type,      
        reservations: updatedReservations,  
        reservationSid: action.data.sid
    }
}

function reservationCompleted(state:TaskRouterWorkerState,action): TaskRouterWorkerState {
    let updatedReservations = [...state.reservations];
    if (state.currentReservation !== null) {
        const moveToReservation = state.currentReservation;
        updatedReservations = [moveToReservation, ...updatedReservations]
    }
    updatedReservations = updatedReservations.filter(x => x.sid !== action.data)
    const removeRejected = updatedReservations.filter(x => (x.reservationStatus !== "rejected" || x.reservationStatus !== "timeout"))
    return {
        ...state,
        state: action.type,        
        reservations: removeRejected,
        reservationSid: action.data
    }
}

function reservationRejected(state:TaskRouterWorkerState,action): TaskRouterWorkerState {    
    return {
        ...state,
        state: action.type,                
        currentReservation: null
    }
}

function reservationTimeout(state:TaskRouterWorkerState,action): TaskRouterWorkerState {   
    let updatedReservations = [...state.reservations];    
    updatedReservations = updatedReservations.filter(x => x.sid !== action.data.sid) 
    return {
        ...state,
        state: action.type,           
        reservations: updatedReservations,     
        currentReservation: null
    }
}
const WorkerInitReducer =
    (state: TaskRouterWorkerState, action: WorkerActions): TaskRouterWorkerState => {

        const fnUpdateState = createReducer(initialState, {
            [WORKER_INIT_START]: workerInitStart,
            [WORKER_INIT_SUCCESS]: workerInitSuccess,
            [WORKER_INIT_ERROR] : workerInitError,
            [WORKER_READY]: workerReadyUpdate,
            [WORKER_TOKEN_UPDATE]:workerUpdate,
            [WORKER_ACTIVITY_UPDATED]:workerUpdate,
            [WORKER_ACTIVITY_MISMATCH]: workerMistmatch,
            [WORKER_ERROR]:workerInitError,
            [WORKER_DISCONNECTED]: workerDisconnected,
            [FETCH_RESERVATIONS]: fetchReservations,
            [RESERVATION_CREATED]: reservationCreated,
            [RESERVATION_CANCELLED]: reservationCancelled,
            [RESERVATION_COMPLETED]: reservationCompleted,
            [RESERVATION_WRAPUP]: reservationWrapup,
            [RESERVATION_REJECTED]: reservationRejected,
            [RESERVATION_ACCEPTED]:reservationAccepted,
            [RESERVATION_TIMEOUT]: reservationTimeout
        });

        return fnUpdateState(state, action);
    }

export default WorkerInitReducer;