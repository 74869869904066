import CallStates from '../../SoftPhone/CallStates';
import { 
    CALL_STARTED_SUCCESS,
    CALL_END_SUCCESS,
    WRAPUP_SUCCESS,
    ActiveCallActions,
    CallStartedAction,
    CallEndAction,
    CompleteWrapUpAction,
    CALL_INCOMING,
    CALL_ONCALL,    
    OutboundErrorAction,
    OUTBOUND_CALL_ERROR,
    CONF_CALL_STARTED_START,
    CONF_CALL_STARTED_END,
    CONF_CALL_NUMBER_UPDATE
} from '../actions/activeCalls.action';
import { RESERVATION_WRAPUP } from '../actions/softPhone/actionTypes';
import createReducer from "./createReducer";



export interface ActiveCallState {
    state: string;
    error: string;
    currentCallState: string;
    callAcceptTime: string,
    callEndTime: string,
    callWrapUpTime: string,
    conferenceNumbers: Array<any>;
}

const initialState: ActiveCallState = {
    state: null,
    error: null,
    currentCallState: null,
    callAcceptTime: null,
    callEndTime: null,
    callWrapUpTime: null,
    conferenceNumbers: []
}

function intiatedCall(state:ActiveCallState, action): ActiveCallState {
    return {
        ...state,
        state: action.type,
        currentCallState: CallStates.INCOMING
    }
}
function acceptedCall(state:ActiveCallState, action): ActiveCallState {
    return {
        ...state,
        state: action.type,
        currentCallState: CallStates.ON_CALL
    }
}

function startedCall(state:ActiveCallState, action: CallStartedAction): ActiveCallState {
    return {
        ...state,
        state: action.type,
        callAcceptTime: action.data,
        callEndTime: null,
        callWrapUpTime: null
    }
}
function confCall(state:ActiveCallState, action): ActiveCallState {
    return {
        ...state,
        state: action.type,
        conferenceNumbers: action.data
    }
}

function endConfCall(state:ActiveCallState, action): ActiveCallState {
    return {
        ...state,
        state: action.type,
        conferenceNumbers: []
    }
}


function endedCall(state:ActiveCallState, action: CallEndAction): ActiveCallState {
    return {
        ...state,
        state: action.type,
        error: null,
        callEndTime: action.data,
        conferenceNumbers: []
    }
}

function wrapUp(state:ActiveCallState, action: CompleteWrapUpAction): ActiveCallState {
    return {
        ...state,
        state: action.type,
        error: null,
        currentCallState: CallStates.WRAPUP,
        callAcceptTime: null,
        callEndTime: null,
        callWrapUpTime: null,
        conferenceNumbers: []
    }
}

function outboundError(state:ActiveCallState, action: OutboundErrorAction): ActiveCallState {
    console.log("outbound error", action.data);
    return {
        ...state,
        state: action.type,
        error: action.data
    }
}

const ActiveCallsReducer =
    (state: ActiveCallState, action: ActiveCallActions): ActiveCallState => {

        const fnUpdateState = createReducer(initialState, {
            [CALL_INCOMING]: intiatedCall,
            [CALL_STARTED_SUCCESS]: startedCall,
            [CALL_ONCALL]: acceptedCall,
            [CALL_END_SUCCESS]: endedCall,
            [WRAPUP_SUCCESS] : wrapUp,
            [OUTBOUND_CALL_ERROR]: outboundError,
            [CONF_CALL_STARTED_START]: confCall,
            [CONF_CALL_STARTED_END]: endConfCall,
            [CONF_CALL_NUMBER_UPDATE]:confCall,
            [RESERVATION_WRAPUP]: endedCall
        });

        return fnUpdateState(state, action);
    }

export default ActiveCallsReducer;
