import createReducer from "./createReducer";
import {
    GET_PREFORECAST_SUCCESS,GET_PREFORECAST_ERROR, GET_PREFORECAST_START
} from "../actions/forecast.action";

const initialState = {
    data: {},
    state: null,
    error: null,
    campaignType: null 
};

function loaded(state, action) {
    return {
        data: action.data,
        error: null,
        state: action.type,
        campaignType: action.campaignType
    }
}

function loadStarted(state, action) {
    return {
        data: state.data,
        error: null,
        state: action.type
    }
}

function loadError(state, action) {
    return {
        data: null,
        error: action.error,
        state: action.type
    }
}

export default createReducer(initialState, {
    [GET_PREFORECAST_START]: loadStarted,
    [GET_PREFORECAST_SUCCESS]: loaded,
    [GET_PREFORECAST_ERROR]: loadError
}); 
