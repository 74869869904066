//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "ASSIGN_FORM_FLOW";
export const ASSIGN_FORM_FLOW_START = `${actionNamePrefix}_START`;
export const ASSIGN_FORM_FLOW_ERROR = `${actionNamePrefix}_ERROR`;
export const ASSIGN_FORM_FLOW_SUCCESS = `${actionNamePrefix}_SUCCESS`;


const actionNamePrefixSave = "SAVE_SELECTED_FORM";
export const SAVE_SELECTED_FORM_START = `${actionNamePrefixSave}_START`;
export const SAVE_SELECTED_FORM_ERROR = `${actionNamePrefixSave}_ERROR`;
export const SAVE_SELECTED_FORM_SUCCESS = `${actionNamePrefixSave}_SUCCESS`;

export interface AssignFormFlowStartAction {
    type: typeof ASSIGN_FORM_FLOW_START;
}

export interface AssignFormFlowSuccessAction {
    type: typeof ASSIGN_FORM_FLOW_SUCCESS;
    data: any
}

export interface AssignFormFlowErrorAction {
    type: typeof ASSIGN_FORM_FLOW_ERROR;
    error: string;
}

export interface SaveFormStart {
    type: typeof SAVE_SELECTED_FORM_START,
}

export interface SaveFormSuccess {
    type: typeof SAVE_SELECTED_FORM_SUCCESS,
    data: data
}

export interface SaveFormError {
    type: typeof SAVE_SELECTED_FORM_ERROR,
    error: string
}

export type formFlowActions =   AssignFormFlowStartAction |
                                AssignFormFlowSuccessAction|
                                AssignFormFlowErrorAction | SaveFormStart | SaveFormSuccess | SaveFormError;

export const assignFlow = (request) => async (dispatch) => {
    dispatch({type: ASSIGN_FORM_FLOW_START})
    try {
       // const response = await Axios.get(`${AppSettings.ApiBaseUrl}/Form`);
        dispatch({
            type: ASSIGN_FORM_FLOW_SUCCESS,
            data: request
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: ASSIGN_FORM_FLOW_ERROR,
                error: errorMessage
            })
        }
    }
}

// export const saveSelectedSForm = (selectedData) => async (dispatch) => {
//     dispatch({type: SAVE_SELECTED_FORM_START})
//     try {
//        const response = await Axios.post(`${AppSettings.ApiBaseUrl}/Form`, selectedData);
//         dispatch({
//             type: SAVE_SELECTED_FORM_SUCCESS,
//             selectedParams: response.data
//          })
//     }
//     catch (err) {
//         if (err && err.response) {
//             const errorMessage = `Error: ${err.response.Message}`;
//             dispatch({
//                 type: SAVE_SELECTED_FORM_ERROR,
//                 error: errorMessage
//             })
//         }
//     }
// }

