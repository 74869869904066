//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefixLoad = "LOAD_RESULTS";
export const LOAD_RESULTS_START = `${actionNamePrefixLoad}_START`;
export const LOAD_RESULTS_ERROR = `${actionNamePrefixLoad}_ERROR`;
export const LOAD_RESULTS_SUCCESS = `${actionNamePrefixLoad}_SUCCESS`;

const actionNamePrefixSave = "SAVE_FORM";
export const SAVE_FORM_START = `${actionNamePrefixSave}_START`;
export const SAVE_FORM_ERROR = `${actionNamePrefixSave}_ERROR`;
export const SAVE_FORM_SUCCESS = `${actionNamePrefixSave}_SUCCESS`;

const actionNamePrefixLoadForm = "LOAD_SUBMIT_FORM";
export const LOAD_SUBMIT_FORM_START = `${actionNamePrefixLoadForm}_START`;
export const LOAD_SUBMIT_FORM_ERROR = `${actionNamePrefixLoadForm}_ERROR`;
export const LOAD_SUBMIT_FORM_SUCCESS = `${actionNamePrefixLoadForm}_SUCCESS`;

export interface SaveFormStart {
    type: typeof SAVE_FORM_START,
}

export interface SaveFormSuccess {
    type: typeof SAVE_FORM_SUCCESS,
    data: data
}

export interface SaveFormError {
    type: typeof SAVE_FORM_ERROR,
    error: string
}

export interface LoadFormSuccess {
    type: typeof LOAD_SUBMIT_FORM_SUCCESS,
}


export type formActions = SaveFormStart | SaveFormSuccess | SaveFormError | LoadFormSuccess;

export const loadFormInRecording = () => async(dispatch) => {
    dispatch({type: LOAD_SUBMIT_FORM_SUCCESS})  
}

export const saveSubmitForm = (data) => async (dispatch) => {
    dispatch({type: SAVE_FORM_START})
    try {
       const response = await Axios.post(`${AppSettings.FormUrl}/form-submissions`, data)
        dispatch({
            type: SAVE_FORM_SUCCESS,
            data: data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: SAVE_FORM_ERROR,
                error: errorMessage
            })
        }
    }
}

export const getFormResults = () => async (dispatch) => {
    dispatch({type: LOAD_RESULTS_START})
    try {
      // const response = await Axios.post(`${AppSettings.ApiBaseUrl}/Form`, selectedData);
        dispatch({
            type: LOAD_RESULTS_SUCCESS
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_RESULTS_ERROR,
                error: errorMessage
            })
        }
    }
}
