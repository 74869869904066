//@ts-nocheck
export const AppSettings = {
    ApiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    Auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    Auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
    Auth0Redirect: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    Auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    ApiV1BaseUrl: process.env.REACT_APP_API_V1_BASE_URL,
    AuthApiBaseUrl: process.env.REACT_APP_AUTH_API_BASE_URL,
    AGGridLicenseKey:  process.env.REACT_APP_AG_GRID_LICENSEKEY,
    ApiSchedulerUrl: process.env.REACT_APP_SCHEDULER_API,
    ApiRoleManagerUrl: process.env.REACT_APP_ROLE_MANAGER_API,
    Environment: process.env.REACT_APP_ENVIRONMENT,
    ApiTwilioUrl: process.env.REACT_APP_TWILIO_API,
    BackendToFrontEnd: process.env.REACT_APP_BACKEND_FOR_FRONTEND,
    CallRecordings: process.env.REACT_APP_CALLRECORDINGS,
    FormUrl: process.env.REACT_APP_FORM,
    ReservationAcceptUrl: process.env.REACT_APP_RESERVATION_ACCEPT,
    ForecastApiWSBaseUrl: process.env.REACT_APP_WS_API_BASE_URL,
    SchedulerApiWSUrl: process.env.REACT_APP_WS_SCHEDULER_API,
    TwilioApiWSBaseUrl: process.env.REACT_APP_WS_TWILIO_API,
}