//@ts-nocheck
import Axios, { AxiosError } from "axios";
import { AppSettings } from "../../appSettings";

export const LOAD_CUSTOM_ATTRIBUTES_VALUE = "LOAD_CUSTOM_ATTRIBUTES_VALUE";
export const LOAD_CUSTOM_ATTRIBUTES_VALUE_SUCCESS = "LOAD_CUSTOM_ATTRIBUTES_VALUE_SUCCESS";
export const LOAD_CUSTOM_ATTRIBUTES_VALUE_ERROR = "LOAD_CUSTOM_ATTRIBUTES_VALUE_ERROR";

export const SAVE_CUSTOM_ATTRIBUTES_VALUE = "SAVE_CUSTOM_ATTRIBUTES_VALUE";
export const SAVE_CUSTOM_ATTRIBUTES_VALUE_SUCCESS = "SAVE_CUSTOM_ATTRIBUTES_VALUE_SUCCESS";
export const SAVE_CUSTOM_ATTRIBUTES_VALUE_ERROR = "SAVE_CUSTOM_ATTRIBUTES_VALUE_ERROR";

export interface LoadCustomAttributesError {
    id: string;
    message: string;
}

export interface SaveCustomAttributesValueError {
    id: string;
    message: string;
}

export interface LoadCustomAttributesValueAction {
    type: typeof LOAD_CUSTOM_ATTRIBUTES_VALUE;
}

export interface LoadCustomAttributesValueSuccessAction {
    type: typeof LOAD_CUSTOM_ATTRIBUTES_VALUE_SUCCESS;
    customAttributesValue: Array <any>
}

export interface LoadCustomAttributesValueError {
    type: typeof LOAD_CUSTOM_ATTRIBUTES_VALUE_ERROR;
    error: string;
}

export interface SaveCustomAttributesValueAction {
    type: typeof SAVE_CUSTOM_ATTRIBUTES_VALUE
}

export interface SaveCustomAttributesValueSuccessAction {
    type: typeof SAVE_CUSTOM_ATTRIBUTES_VALUE_SUCCESS;
    message: string
}

export interface SaveCustomAttributesValueError {
    type: typeof SAVE_CUSTOM_ATTRIBUTES_VALUE_ERROR;
    error: string;
}


export type CustomAttributesValueAction = 
    LoadCustomAttributesValueAction |
    LoadCustomAttributesValueSuccessAction |
    LoadCustomAttributesValueError | 
    SaveCustomAttributesValueAction |
    SaveCustomAttributesValueSuccessAction |
    SaveCustomAttributesValueError;

export const loadCustomAttributesValue = () => async (dispatch: any) => {
    dispatch({ type: LOAD_CUSTOM_ATTRIBUTES_VALUE });

    try {
        const response = 
        await Axios.get<Array<any>>(`${AppSettings.ApiRoleManagerUrl}/api/UserAttributeValues`);
        
        dispatch({
            type: LOAD_CUSTOM_ATTRIBUTES_VALUE_SUCCESS,
            customAttributesValue: response.data
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: LOAD_CUSTOM_ATTRIBUTES_VALUE_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveCustomAttributesValue = (saveCustomData) => async (dispatch: any) => {
    dispatch({ type: SAVE_CUSTOM_ATTRIBUTES_VALUE });
    try {
        await Axios.put(`${AppSettings.ApiRoleManagerUrl}/api/UserAttributeValues/`, saveCustomData);
        dispatch({
            type: SAVE_CUSTOM_ATTRIBUTES_VALUE_SUCCESS
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;        
            dispatch({
                type: SAVE_CUSTOM_ATTRIBUTES_VALUE_ERROR,
                error: errorMessage
            })
        }
    }
}