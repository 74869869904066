//@ts-nocheck
import createReducer from "./createReducer";
import { 
    LOAD_MY_LEAVE_START,
    LOAD_MY_LEAVE_SUCCESS,
    LOAD_MY_LEAVE_ERROR, 
    SAVE_MY_LEAVE_START,
    SAVE_MY_LEAVE_SUCCESS,
    SAVE_MY_LEAVE_ERROR, 
    UPDATE_LEAVE_START,
    UPDATE_LEAVE_SUCCESS,
    UPDATE_LEAVE_ERROR, 
    myLeaveActions,
    LoadMyLeaveStartedAction,
    LoadMyLeaveSuccessAction,
    LoadMyLeaveErrorAction,
    SaveMyLeaveStartedAction,
    SaveMyLeaveSuccessAction,
    SaveMyLeaveErrorAction,
    UpdateLeaveStartedAction,
    UpdateLeaveSuccessAction,
    UpdateLeaveErrorAction
} from '../actions/myLeaveManagement.action'

export interface MyLeaveState {
    state: string;
    error: string;
    data: [];
}

const initialState: MyLeaveState = {
    state: null,
    error: null,
    data: []
}
function loadMyLeaveStart(state:MyLeaveState, action: LoadMyLeaveStartedAction): MyLeaveState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadMyLeaveSuccess(state:MyLeaveState, action: LoadMyLeaveSuccessAction): MyLeaveState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadMyLeaveError(state:MyLeaveState, action: LoadMyLeaveErrorAction): MyLeaveState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function saveMyLeaveStart(state:MyLeaveState, action: SaveMyLeaveStartedAction): MyLeaveState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function saveMyLeaveSuccess(state:MyLeaveState, action: SaveMyLeaveSuccessAction): MyLeaveState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function saveMyLeaveError(state:MyLeaveState, action: SaveMyLeaveErrorAction): MyLeaveState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function updateLeaveStart(state:MyLeaveState, action: UpdateLeaveStartedAction): MyLeaveState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function updateLeaveSuccess(state:MyLeaveState, action: UpdateLeaveSuccessAction): MyLeaveState {
    const data = [...state.data];
    const matchingIndex = data.findIndex(x => x.id === action.data.id);
    data[matchingIndex] = action.data;
    return {
        ...state,
        data: data,
        state: action.type,
        error: null
    }
}

function updateLeaveError(state:MyLeaveState, action: UpdateLeaveErrorAction): MyLeaveState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const MyLeaveManagementReducer =
    (state: MyLeaveState, action: myLeaveActions): MyLeaveState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_MY_LEAVE_START]: loadMyLeaveStart,
            [LOAD_MY_LEAVE_SUCCESS]: loadMyLeaveSuccess,
            [LOAD_MY_LEAVE_ERROR]: loadMyLeaveError,
            [SAVE_MY_LEAVE_START]: saveMyLeaveStart,
            [SAVE_MY_LEAVE_SUCCESS]: saveMyLeaveSuccess,
            [SAVE_MY_LEAVE_ERROR]: saveMyLeaveError,
            [UPDATE_LEAVE_START]: updateLeaveStart,
            [UPDATE_LEAVE_SUCCESS]: updateLeaveSuccess,
            [UPDATE_LEAVE_ERROR]: updateLeaveError
        });

        return fnUpdateState(state, action);
    }

export default MyLeaveManagementReducer;