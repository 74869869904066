//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";
import AgentUser from "../../vendor/forecast/models/AgentUser";

const actionNamePrefix = "LOAD_AGENTS_FOR_CAMPAIGNS";
export const LOAD_AGENTS_FOR_CAMPAIGN_START = `${actionNamePrefix}_START`;
export const LOAD_AGENTS_FOR_CAMPAIGN_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_AGENTS_FOR_CAMPAIGN_ERROR = `${actionNamePrefix}_ERROR`;

function loadStart(campaignId) {
    return {
        type: LOAD_AGENTS_FOR_CAMPAIGN_START,
        campaignId    
    };
}

function loadSuccess(campaignId: number, agentUsers: Array<AgentUser>) {
    return {
        type: LOAD_AGENTS_FOR_CAMPAIGN_SUCCESS,
        campaignId,
        data: agentUsers
    };
}

function loadError(error) {
    return {
        type: LOAD_AGENTS_FOR_CAMPAIGN_ERROR,
        error
    }
}

export const loadAgentsForCampaigns = (campaignId: number) => async (dispatch) => {
    dispatch(loadStart(campaignId));
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/campaign/${campaignId}/agents`);
        dispatch(loadSuccess(campaignId, response.data));
    }
    catch (e) {
        dispatch(loadError(e.response.data.Message || e.message));
    }
}