export default {
	lg: [
		{
			//Row No 1
			x: 0, y: 0, w: 8, h: 11,
			i: "Widget3"
		},
		{
			//Row No 1
			x: 0, y: 0, w: 8, h: 11,
			i: "Widget5"
		},		
		{
			//Row No 1 - 3rd one. Chart
			x: 0, y: 0, w: 8, h: 10,
			i: "Widget7"
		},
	],
	md: [
		{
			//Row No 1
			x: 0, y: 0, w: 8, h: 11,
			i: "Widget3"
		},
		{
			//Row No 1
			x: 0, y: 0, w: 8, h: 11,
			i: "Widget5"
		},		
		{
			//Row No 1 - 3rd one. Chart
			x: 0, y: 0, w: 8, h: 10,
			i: "Widget7"
		},
	],
	sm: [
		{
			//Row No 1
			x: 0, y: 0, w: 8, h: 11,
			i: "Widget3"
		},
		{
			//Row No 1
			x: 0, y: 0, w: 8, h: 11,
			i: "Widget5"
		},		
		{
			//Row No 1 - 3rd one. Chart
			x: 0, y: 0, w: 8, h: 10,
			i: "Widget7"
		},
	],
	xs: [
		{
			//Row No 1
			x: 0, y: 0, w: 8, h: 11,
			i: "Widget3"
		},
		{
			//Row No 1
			x: 0, y: 0, w: 8, h: 11,
			i: "Widget5"
		},		
		{
			//Row No 1 - 3rd one. Chart
			x: 0, y: 0, w: 8, h: 10,
			i: "Widget7"
		},
	],
	xxs: [
		{
			//Row No 1
			x: 0, y: 0, w: 8, h: 11,
			i: "Widget3"
		},
		{
			//Row No 1
			x: 0, y: 0, w: 8, h: 11,
			i: "Widget5"
		},		
		{
			//Row No 1 - 3rd one. Chart
			x: 0, y: 0, w: 8, h: 10,
			i: "Widget7"
		},
	]
};
