export default {
	lg: [
		{
			//Row No 1
			x: 0, y: 0, w: 2, h: 1,
			i: "Widget1"
		},
		{
			//Row No 1
			x: 2, y: 0, w: 2, h: 1,
			i: "Widget2"
		},		
		{
			//Row No 1 - 3rd one. Chart
			x: 4, y: 0, w: 9, h: 3,
			i: "Widget3"
		},
		{
			//Row No 2 - 1st item
			x: 9, y: 0, w: 6, h: 3,
			i: "Widget12"
		},
		{
			//Row 1 - QC
			x: 0, y: 1, w: 2, h: 1,
			i: "Widget4"
		},				
		{
			//Row No 2 - 1st item
			x: 2, y: 1, w: 2, h: 1,
			i: "Widget5"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 2, w: 2, h: 1,
			i: "Widget6"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 2, w: 2, h: 1,
			i: "Widget7"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 6, w: 8, h: 3,
			i: "Widget8"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 6, w: 8, h: 4,
			i: "Widget9"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 10, w: 8, h: 3,
			i: "Widget10"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 10, w: 8, h: 4,
			i: "Widget11"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 12, w: 8, h: 3,
			i: "Widget12"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 16, w: 8, h: 3,
			i: "Widget13"
		},
		
	],
	md: [
		{
			//Row No 1
			x: 0, y: 0, w: 2, h: 1,
			i: "Widget1"
		},
		{
			//Row No 1
			x: 2, y: 0, w: 2, h: 1,
			i: "Widget2"
		},		
		{
			//Row No 1 - 3rd one. Chart
			x: 4, y: 0, w: 9, h: 3,
			i: "Widget3"
		},
		{
			//Row No 2 - 1st item
			x: 7, y: 8, w: 6, h: 3,
			i: "Widget12"
		},
		{
			//Row 1 - QC
			x: 0, y: 1, w: 2, h: 1,
			i: "Widget4"
		},				
		{
			//Row No 2 - 1st item
			x: 2, y: 1, w: 2, h: 1,
			i: "Widget5"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 2, w: 2, h: 1,
			i: "Widget6"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 2, w: 2, h: 1,
			i: "Widget7"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 6, w: 7, h: 3,
			i: "Widget8"
		},
		{
			//Row No 2 - 1st item
			x: 7, y: 6, w: 7, h: 4,
			i: "Widget9"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 14, w: 7, h: 3,
			i: "Widget10"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 10, w: 7, h: 4,
			i: "Widget11"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 10, w: 7, h: 3,
			i: "Widget12"
		},
		{
			//Row No 2 - 1st item
			x: 7, y: 16, w: 7, h: 3,
			i: "Widget13"
		}
	],
	sm: [
		{
			//Row No 1
			x: 0, y: 0, w: 2, h: 1,
			i: "Widget1"
		},
		{
			//Row No 1
			x: 2, y: 0, w: 2, h: 1,
			i: "Widget2"
		},		
		{
			//Row No 1 - 3rd one. Chart
			x: 4, y: 0, w: 5, h: 4,
			i: "Widget3"
		},
		{
			//Row No 2 - 1st item
			x: 7, y: 0, w: 6, h: 4,
			i: "Widget12"
		},
		{
			//Row 1 - QC
			x: 0, y: 1, w: 2, h: 1,
			i: "Widget4"
		},				
		{
			//Row No 2 - 1st item
			x: 2, y: 1, w: 2, h: 1,
			i: "Widget5"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 2, w: 2, h: 1,
			i: "Widget6"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 2, w: 2, h: 1,
			i: "Widget7"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 4, w: 8, h: 4,
			i: "Widget8"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 4, w: 8, h: 4,
			i: "Widget9"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 8, w: 8, h: 4,
			i: "Widget10"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 8, w: 8, h: 4,
			i: "Widget11"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 8, w: 8, h: 4,
			i: "Widget12"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 8, w: 8, h: 4,
			i: "Widget13"
		}
	],
	xs: [
		{
			//Row No 1
			x: 0, y: 0, w: 2, h: 1,
			i: "Widget1"
		},
		{
			//Row No 1
			x: 2, y: 0, w: 2, h: 1,
			i: "Widget2"
		},		
		{
			//Row No 1 - 3rd one. Chart
			x: 4, y: 0, w: 5, h: 4,
			i: "Widget3"
		},
		{
			//Row No 2 - 1st item
			x: 7, y: 0, w: 6, h: 4,
			i: "Widget12"
		},
		{
			//Row 1 - QC
			x: 0, y: 1, w: 2, h: 1,
			i: "Widget4"
		},				
		{
			//Row No 2 - 1st item
			x: 2, y: 1, w: 2, h: 1,
			i: "Widget5"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 2, w: 2, h: 1,
			i: "Widget6"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 2, w: 2, h: 1,
			i: "Widget7"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 4, w: 8, h: 4,
			i: "Widget8"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 4, w: 8, h: 4,
			i: "Widget9"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 8, w: 8, h: 4,
			i: "Widget10"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 8, w: 8, h: 4,
			i: "Widget11"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 8, w: 8, h: 4,
			i: "Widget12"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 8, w: 8, h: 4,
			i: "Widget13"
		}
	],
	xxs: [
		{
			//Row No 1
			x: 0, y: 0, w: 2, h: 1,
			i: "Widget1"
		},
		{
			//Row No 1
			x: 2, y: 0, w: 2, h: 1,
			i: "Widget2"
		},		
		{
			//Row No 1 - 3rd one. Chart
			x: 4, y: 0, w: 5, h: 4,
			i: "Widget3"
		},
		{
			//Row No 2 - 1st item
			x: 7, y: 0, w: 6, h: 4,
			i: "Widget12"
		},
		{
			//Row 1 - QC
			x: 0, y: 1, w: 2, h: 1,
			i: "Widget4"
		},				
		{
			//Row No 2 - 1st item
			x: 2, y: 1, w: 2, h: 1,
			i: "Widget5"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 2, w: 2, h: 1,
			i: "Widget6"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 2, w: 2, h: 1,
			i: "Widget7"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 4, w: 8, h: 4,
			i: "Widget8"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 4, w: 8, h: 4,
			i: "Widget9"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 8, w: 8, h: 4,
			i: "Widget10"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 8, w: 8, h: 4,
			i: "Widget11"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 8, w: 8, h: 4,
			i: "Widget12"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 8, w: 8, h: 4,
			i: "Widget13"
		}
	]
};
