//@ts-nocheck
import createReducer from "./createReducer";
import {
    LOAD_FORECAST_SUCCESS, LOAD_FORECAST_ERROR, LOAD_FORECAST_START
} from "../actions/forecast.action";

const initialState = {
    data: {},
    state: null,
    error: null
};

function loaded(state, action) {
    const cloneOfData = Object.assign({}, action.data);
    cloneOfData[action.dataStoreKey] = action.data;

    return {
        data: cloneOfData,
        error: null,
        state: action.type
    }
}

function loadStarted(state, action) {
    const cloneOfData = Object.assign({}, state.data);

    if (cloneOfData[action.dataStoreKey]) {
        delete cloneOfData[action.dataStoreKey];
    }

    return {
        data: cloneOfData,
        error: null,
        state: action.type
    }
}

function loadError(state, action) {
    return {
        data: null,
        error: action.error,
        state: action.type
    }
}

export default createReducer(initialState, {
    [LOAD_FORECAST_START]: loadStarted,
    [LOAD_FORECAST_SUCCESS]: loaded,
    [LOAD_FORECAST_ERROR]: loadError
}); 
