//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_DASHBOARD_REPORT";
export const LOAD_DASHBOARD_REPORT_START = `${actionNamePrefix}_START`;
export const LOAD_DASHBOARD_REPORT_ERROR = `${actionNamePrefix}_ERROR`;
export const LOAD_DASHBOARD_REPORT_SUCCESS = `${actionNamePrefix}_SUCCESS`;

const actionNamePrefixV = "LOAD_DASHBOARD_VERTICAL_REPORT";
export const LOAD_DASHBOARD_VERTICAL_REPORT_START = `${actionNamePrefixV}_START`;
export const LOAD_DASHBOARD_VERTICAL_REPORT_ERROR = `${actionNamePrefixV}_ERROR`;
export const LOAD_DASHBOARD_VERTICAL_REPORT_SUCCESS = `${actionNamePrefixV}_SUCCESS`;

export interface LoadReportRequest {    
    startDate: string;
    endDate: string;
}

export interface LoadDashboardReportStartAction {
    type: typeof LOAD_DASHBOARD_REPORT_START;
}

export interface LoadDashboardReportSuccessAction {
    type: typeof LOAD_DASHBOARD_REPORT_SUCCESS;
    data: any
}

export interface LoadDashboardReportErrorAction {
    type: typeof LOAD_DASHBOARD_REPORT_ERROR;
    error: string;
}

export interface LoadDashboardVerticalReportStartAction {
    type: typeof LOAD_DASHBOARD_VERTICAL_REPORT_START;
}

export interface LoadDashboardVerticalReportSuccessAction {
    type: typeof LOAD_DASHBOARD_VERTICAL_REPORT_SUCCESS;
    verticalData: any
}

export interface LoadDashboardVerticalReportErrorAction {
    type: typeof LOAD_DASHBOARD_VERTICAL_REPORT_ERROR;
    error: string;
}

export type dashboardReportActions = LoadDashboardReportStartAction |
                                LoadDashboardReportSuccessAction|
                                LoadDashboardReportErrorAction;

export const getDashboardReport = (request: LoadReportRequest) => async (dispatch) => {
    dispatch({type: LOAD_DASHBOARD_REPORT_START})
    try {
        
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/startDate/${request.startDate}/endDate/${request.endDate}/DataSources/InventoryForecast`);        
        dispatch({
            type: LOAD_DASHBOARD_REPORT_SUCCESS,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_DASHBOARD_REPORT_ERROR,
                error: errorMessage
            })
        }
    }
}

export const getDashboardReportVertical = (request: LoadReportRequest) => async (dispatch) => {
    dispatch({type: LOAD_DASHBOARD_VERTICAL_REPORT_START})
    try {
        
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/startDate/${request.startDate}/endDate/${request.endDate}/DataSources/InventoryForecast?forecast-params-on-y-axis=true`);                
        dispatch({
            type: LOAD_DASHBOARD_VERTICAL_REPORT_SUCCESS,
            verticalData: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_DASHBOARD_VERTICAL_REPORT_ERROR,
                error: errorMessage
            })
        }
    }
}
