import { AnyAction, Reducer } from "redux";
import { Client, Conversation,  Message, Participant, User } from "@twilio/conversations";

import {
    ACTION_ATTACH_FILES,
    ACTION_ADD_MESSAGE,
    ACTION_ADD_MULTIPLE_MESSAGES,
    ACTION_ADD_PARTICIPANT,
    ACTION_DETACH_FILES,
    ACTION_REMOVE_MESSAGE,
    ACTION_REMOVE_PARTICIPANT,
    ACTION_START_SESSION,
    ACTION_UPDATE_CONVERSATION_STATE,
    ACTION_UPDATE_MESSAGE,
    ACTION_UPDATE_PARTICIPANT
} from "../../actions/softPhone/chatActionTypes";
export type FileAttachmentConfig = {
    enabled?: boolean;
    maxFileSize?: number;
    acceptedExtensions?: string[];
};

export interface ChatState  {
    conversationsClient?: Client;
    conversation?: Conversation;
    participants?: Participant[];
    users?: User[];
    messages?: Message[];
    attachedFiles?: File[];
    conversationState?: string;
    fileAttachment?: FileAttachmentConfig;
};

const initialState: ChatState = {};
initialState.fileAttachment = {
    enabled: true,
    maxFileSize: 16777216, // 16 MB
    acceptedExtensions: ["jpg", "jpeg", "png", "amr", "mp3", "mp4", "pdf", "txt"]
}

function detachFiles(attachedFiles: File[] = [], filesToDetach: File[] = []): File[] {
    return (attachedFiles || []).filter(
        (file: File) =>
            !filesToDetach.some(
                (fileToDetach: File) =>
                    file.name === fileToDetach.name &&
                    file.type === fileToDetach.type &&
                    file.size === fileToDetach.size
            )
    );
}

export const ChatReducer: Reducer = (state: ChatState = initialState, action: AnyAction): ChatState => {
    switch (action.type) {
        case ACTION_START_SESSION: {
            return {
                ...state,
                conversationsClient: action.payload.conversationsClient,
                conversation: action.payload.conversation,
                conversationState: action.payload.conversationState,
                users: action.payload.users,
                participants: action.payload.participants,
                messages: action.payload.messages
            };
        }
        case ACTION_ADD_MULTIPLE_MESSAGES: {
            return {
                ...state,
                messages: [...action.payload.messages, ...(state.messages || [])]
            };
        }
        case ACTION_ADD_MESSAGE: {
            return {
                ...state,
                messages: [...(state.messages || []), action.payload.message]
            };
        }
        case ACTION_REMOVE_MESSAGE: {
            return {
                ...state,
                messages: [...(state.messages || []).filter((m) => m.sid !== action.payload.message.sid)]
            };
        }
        case ACTION_UPDATE_MESSAGE: {
            return {
                ...state,
                messages: [
                    ...(state.messages || []).reduce((acc: Message[], m) => {
                        if (m.sid === action.payload.message.sid) {
                            acc.push(action.payload.message);
                        } else {
                            acc.push(m);
                        }
                        return acc;
                    }, [])
                ]
            };
        }
        case ACTION_ATTACH_FILES: {
            return {
                ...state,
                attachedFiles: [...(state.attachedFiles || []), ...action.payload.filesToAttach]
            };
        }
        case ACTION_DETACH_FILES: {
            const filesToDetach = action.payload.filesToDetach as File[];
            return {
                ...state,
                attachedFiles: detachFiles(state.attachedFiles, filesToDetach)
            };
        }
        case ACTION_ADD_PARTICIPANT: {
            return {
                ...state,
                participants: [...(state.participants || []), action.payload.participant],
                users: [...(state.users || []), action.payload.user]
            };
        }
        case ACTION_REMOVE_PARTICIPANT: {
            return {
                ...state,
                participants: [...(state.participants || []).filter((p) => p.sid !== action.payload.participant.sid)],
                users: [...(state.users || []).filter((u) => u.identity !== action.payload.participant.identity)]
            };
        }
        case ACTION_UPDATE_PARTICIPANT: {
            return {
                ...state,
                participants: [
                    ...(state.participants || []).reduce((acc: Participant[], p) => {
                        if (p.sid === action.payload.participant.sid) {
                            acc.push(action.payload.participant);
                        } else {
                            acc.push(p);
                        }
                        return acc;
                    }, [])
                ]
            };
        }
        case ACTION_UPDATE_CONVERSATION_STATE: {
            return {
                ...state,
                conversationState: action.payload.conversationState
            };
        }

        default:
            return state;
    }
};
