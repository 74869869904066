import { CALL_INCOMING, CALL_ONCALL } from "../../activeCalls.action";
import {RESERVATION_ACCEPTED, RESERVATION_CANCELLED, RESERVATION_COMPLETED, RESERVATION_CREATED, RESERVATION_REJECTED, RESERVATION_TIMEOUT, RESERVATION_WRAPUP, WORKER_TOKEN_UPDATE } from "../actionTypes";

export const initReservationListeners = (worker: any, dispatch: any) => {    
    worker.addListener("reservation.created", (reservation: any) => {
        dispatch({
            type: RESERVATION_CREATED,
            data: reservation
        });
        dispatch({
            type: CALL_INCOMING
        })
    });
    worker.addListener("reservation.accepted", (reservation: any) => {        
        dispatch({
            type: RESERVATION_ACCEPTED,
            data: reservation
        });
        dispatch({
            type: CALL_ONCALL
        })
    });    
    worker.addListener("reservation.completed", (reservation: any) => {       
        dispatch({
            type: RESERVATION_COMPLETED,
            data: reservation.sid  
        });
    });
    worker.addListener("reservation.canceled", (reservation: any) => {        
        dispatch({
            type: RESERVATION_CANCELLED,
            data: reservation.sid
        });
    });
    worker.addListener("reservation.wrapup", (reservation: any) => {       
        dispatch({
            type: RESERVATION_WRAPUP,
            data: reservation   
        });
    });
    worker.addListener("reservation.rejected", (reservation: any) => {    
        console.log("in reject listner")   
        dispatch({
            type: RESERVATION_REJECTED,
            data: reservation   
        });
    });
    worker.addListener("reservation.timeout", (reservation: any) => {    
        console.log("in timeout reservation listener")   
        dispatch({
            type: RESERVATION_TIMEOUT,
            data: reservation   
        });
    });
};
