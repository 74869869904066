import createReducer from "./createReducer";
import { 
    LOAD_AGENTS_FOR_CAMPAIGN_START, 
    LOAD_AGENTS_FOR_CAMPAIGN_SUCCESS, 
    LOAD_AGENTS_FOR_CAMPAIGN_ERROR } 
from "../actions/agentsForCampaigns.action";

const initialState = {
    data: [],
    campaignId: 0,
    state: null,
    error: null
};

function loadStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type,
        campaignId: action.campaignId
    }
}

function loaded(state, action) {
    return {
        ...state,
        data: action.data,
        campaignId: action.campaignId,
        error: null,
        state: action.type,
    }
}

function loadError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}

export default createReducer(initialState, {
    [LOAD_AGENTS_FOR_CAMPAIGN_START]: loadStarted,
    [LOAD_AGENTS_FOR_CAMPAIGN_SUCCESS]: loaded,
    [LOAD_AGENTS_FOR_CAMPAIGN_ERROR]: loadError
}); 