//@ts-nocheck
import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PrivateRoute from "../auth0/PrivateRoute";

/* loader component for Suspense*/
import PageLoader from "./PageLoader";

import Base from "./Base";
import BasePage from "./BasePage";
import ScrollToTop from "./ScrollToTop";
import attributesContainer from "../vendor/staff/attributes/attributesContainer";

// import PrivateRoute from "./components/Auth0/PrivateRoute";
// import BaseHorizontal from "./components/Layout/BaseHorizontal";

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const myProfile = lazy(() => import("../MyProfile/MyProfile"));
const dashboard = lazy(() => import("../dashboard/DashboardContainer"));
const marketPlace = lazy(() => import("../marketPlace/MarketPlaceContainer"));
const agentMarketPlace = lazy(() => import("../marketPlace/AgentMarketPlace/AgentMarketPlaceContainer"));
const businessMarketPlace = lazy(() => import("../marketPlace/BusinessMarketPlace/BusinessMarketPlaceContainer"));
const newPost = lazy(() => import("../marketPlace/BusinessMarketPlace/Posts/NewPostContainer"));
const contractorWork = lazy(() => import("../ContractorWork/ContractorWorkContainer"));
const forecastDashboardContainer = lazy(() => import("../vendor/forecast/forecastDashboard/ForecastDashbordContainer"));
const uploadForecast = lazy(() => import("../vendor/forecast/uploadForecast/UploadContainer"));
const adjustForecast = lazy(() => import("../vendor/forecast/adjustForecast/AdjustForecastContainer"));
const weeklyForecast = lazy(() => import("../vendor/forecast/weeklyForecast/WeeklyForecastContainer"));
const importScheduler = lazy(() => import("../vendor/staff/importAgentSchedules/ImportAgentSchedulerContainer"));
const manageAgentSchedule = lazy(() => import("../vendor/staff/manageAgentSchedule/ManageAgentScheduleContainer"));
const conflictManager = lazy(() => import("../vendor/forecast/conflictAgentSchedules/ConflictAgentScheduleContainer"));
const deleteAgentSchedules = lazy(() => import("../vendor/administration/deleteAgentSchedules/DeleteAgentSchedulesContainer"));
const UserManagementContainer = lazy(() => import("../vendor/administration/UserManagement/UserManagementContainer"));
const contractPerformace = lazy(() => import("../ContractorWork/performance/PerformanceContainer"));
const vendorPerformace = lazy(() => import("../vendor/performance/vendorPerformanceContainer"));
const agentStates = lazy(() => import("../vendor/realTime/AgentStates/AgentStatesGridContainer"));
const vendorDashboard = lazy(() => import("../vendor/dashboard/DashboardTabContainer"));
const reportBuilder = lazy(() => import("../reportBuilder/reportBuilderContainer"));
const workforce = lazy(() => import("../reportBuilder/workforce"));
const projectForecastReport = lazy(() => import("../vendor/projectForecastReport/projectForecastReportContainer"));
const projectAgentSchedule = lazy(() => import("../vendor/projectAgentSchedule/ProjectAgentScheduleContainer"));
const agentRosterMatrix = lazy(() => import("../vendor/staff/agentRosterMatrix/AgentRosterMatrixContainer"));
const agentAssignmentMatrix = lazy(() => import("../vendor/staff/agentAssignmentMatrix/AgentAssignmentMatrixContainer"));
const manageAgentAllocation = lazy(() => import("../vendor/administration/ManageAgentAllocationContainer"));
const importAgentUsers = lazy(() => import("../vendor/importAgentUsers/ImportAgentUsersContainer"));
const planning = lazy(() => import("../vendor/planning/planningContainer"));
const staffDashboard = lazy(() => import("../vendor/staff/dashboard/DashboardContainer"));
const staffModelling = lazy(() => import("../vendor/staffModelling/dashboard/DashboardContainer"));
const administration = lazy(() => import("../Administration/dashboard/DashboardContainer"));
const projectsCampaigns = lazy(() => import("../Administration/projectsCampaigns/ProjectsCampaignContainer"));
const slotTypes = lazy(() => import("../Administration/scheduleSlots/ScheduleSlotsContainer"));
const targets = lazy(() => import("../Administration/targets/TargetGrid"));
const contactCenter = lazy(() => import("../Administration/contactCenter/ContactCenterContainer"));
const dataCollection = lazy(() => import("../Administration/dataCollection/DataCollectionContainer"));
const myWork = lazy(() => import("../MyWork/dashboard/DashboardContainer"));
const tasks = lazy(() => import("../Tasks/PhoneTaskLead"));
const actualUpload = lazy(() => import("../vendor/staffModelling/ActualUploadRecords/ActualUploadRecordsContainer"));
//const actualUpload = lazy(() => import("../vendor/staffModelling/ActualUploadRecords/ActualUploadRecordsServer"));
const workflow = lazy(() => import("../Administration/workflowManagement/WorkFlowContainer"));
const leaveConfig = lazy(() => import("../Administration/leaveManagement/LeaveManagementContainer"))
const states = lazy(()=>import("../StatesLog/StatesLoggingContainer"));
const agentdemo = lazy(()=>import("../vendor/realTime/AgentStates/AgentStatesDemoGrid"));
const phoneDemo = lazy(()=>import("../SoftPhone/TwilioExampleApp"));
const campaignEntries = lazy(()=>import("../StatesLog/AgentCampaignLogs"));
const callRecording = lazy(()=>import("../Administration/CallRecordings/CallRecordingsContainer"));
const userDetails = lazy(()=>import("../marketPlace/BusinessMarketPlace/PipeLineTab/UserDetails"));
const shadowing = lazy(() => import("../Shadowing/ShadowingBarge"));
// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    /* See full project for reference */
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn'

    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        {/* See full project for reference */}
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <>
                <Base>

                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <ScrollToTop>
                                    <Suspense fallback={<PageLoader />}>
                                        <Switch location={location}>
                                            {

                                                /*
                                                <PrivateRoute exact path="/" component={waitFor(1)} />
                                                <PrivateRoute path="/1" component={waitFor(2)} />
                                                <PrivateRoute path="/2" component={waitFor(3)} />
        
                                                <PrivateRoute exact path="/3" component={waitFor(4)} />
                                                <PrivateRoute exact path="/4" component={waitFor(5)} /> 
                                                */

                                            }
                                            <PrivateRoute exact path="/" component={waitFor(dashboard)} />
                                            <PrivateRoute exact path="/myProfile" component={waitFor(myProfile)} />
                                            <PrivateRoute exact path="/states" component={waitFor(states)} />
                                            <PrivateRoute exact path="/tasks" component={waitFor(tasks)} />
                                            <PrivateRoute exact path="/agent-market-place" component={waitFor(agentMarketPlace)} />
                                            <PrivateRoute exact path="/market-place" component={waitFor(businessMarketPlace)} />   
                                            <PrivateRoute exact path="/post" component={waitFor(newPost)} />   
                                            <PrivateRoute exact path="/contractor-work" component={waitFor(contractorWork)} />
                                            <PrivateRoute exact path="/contractor-performance" component={waitFor(contractPerformace)} />



                                            <PrivateRoute exact path="/forecasting" component={waitFor(forecastDashboardContainer)} />                                            
                                            <PrivateRoute exact path="/daily-forecast" component={waitFor(uploadForecast)} />                                            
                                            <PrivateRoute exact path="/vendor-adjust-forecast" component={waitFor(adjustForecast)} />
                                            <PrivateRoute exact path="/vendor-weekly-forecast" component={waitFor(weeklyForecast)} />
                                            <PrivateRoute exact path="/staff-modelling" component={waitFor(staffModelling)} />
                                            <PrivateRoute exact path="/staff-import-schedules" component={waitFor(importScheduler)} />
                                            <PrivateRoute exact path="/staff" component={waitFor(staffDashboard)} />                                            
                                            <PrivateRoute exact path="/vendor-agent-schedules" component={waitFor(manageAgentSchedule)} />
                                            <PrivateRoute exact path="/vendor-conflict-manager" component={waitFor(conflictManager)} />
                                            <PrivateRoute exact path="/vendor-remove-agent-schedules" component={waitFor(deleteAgentSchedules)} />
                                            <PrivateRoute exact path="/user-management" component={waitFor(UserManagementContainer )} />
                                            <PrivateRoute exact path="/vendor-performance" component={waitFor(vendorPerformace)} />
                                            <PrivateRoute exact path="/agent-states" component={waitFor(agentStates)} />
                                            <PrivateRoute exact path="/agent-demo" component={waitFor(agentdemo)} />
                                            <PrivateRoute exact path="/vendor-dashboard" component={waitFor(vendorDashboard)} />
                                            <PrivateRoute exact path="/report-builder" component={waitFor(reportBuilder)} />
                                            <PrivateRoute exact path="/workforce" component={waitFor(workforce)} />
                                            <PrivateRoute exact path="/project-forecast-report" component={waitFor(projectForecastReport)} />
                                            <PrivateRoute exact path="/project-agent-schedule" component={waitFor(projectAgentSchedule)} />
                                            <PrivateRoute exact path="/vendor-manage-agent-allocation" component={waitFor(manageAgentAllocation)} />
                                            <PrivateRoute exact path="/agent-skill-matrix" component={waitFor(agentRosterMatrix)} />
                                            <PrivateRoute exact path="/agent-assignment-matrix" component={waitFor(agentAssignmentMatrix)} />
                                            <PrivateRoute exact path="/upload-agent-users" component={waitFor(importAgentUsers)} />
                                            <PrivateRoute exact path="/vendor-planning" component={waitFor(planning)} />
                                            <PrivateRoute exact path="/staff-attributes" component={waitFor(attributesContainer)} />
                                            <PrivateRoute exact path="/administration" component={waitFor(administration)} />
                                            <PrivateRoute exact path="/projects-campaigns" component={waitFor(projectsCampaigns)} />
                                            <PrivateRoute exact path="/slot-types" component={waitFor(slotTypes)} />
                                            <PrivateRoute exact path="/workflow" component={waitFor(workflow)} />                                            
                                            <PrivateRoute exact path="/leave-config" component={waitFor(leaveConfig)} />
                                            <PrivateRoute exact path="/targets" component={waitFor(targets)} />
                                            <PrivateRoute exact path="/contact-center" component={waitFor(contactCenter)} />
                                            <PrivateRoute exact path="/data-collection" component={waitFor(dataCollection)} />
                                            <PrivateRoute exact path="/my-work" component={waitFor(myWork)} />
                                            <PrivateRoute exact path="/actual-records" component={waitFor(actualUpload)} />
                                            <PrivateRoute exact path="/studioWorkflow" component={waitFor(phoneDemo)} />
                                            <PrivateRoute exact path="/camapign-entries" component={waitFor(campaignEntries)} />                                            
                                            <PrivateRoute exact path="/call-recordings" component={waitFor(callRecording)} />
                                            <PrivateRoute exact path="/userDetails/:id" component={waitFor(userDetails)}/>
                                            <PrivateRoute exact path="/shadow" component={waitFor(shadowing)} />
                                            <Redirect to="/" />
                                        </Switch>
                                    </Suspense>
                                </ScrollToTop>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </Base>
            </>
        )
    }
}

export default withRouter(Routes);
