import React, { useEffect, useRef, useState } from 'react';
import { Slider, Theme, Typography, Button, LinearProgress, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { isEmptyArray } from "formik";
import useLoudness from '../hooks/useLoudness';
import { ReactComponent as Mute } from './Icons/MuteLevel.svg';
import { ReactComponent as NoMute } from './Icons/NoMuteLevel.svg';

interface AudioProps {
    device: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuContainer: {
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        title: {
            color: '#fff',
            paddingLeft: '10px'
        },
        selectBox: {
            height: '20px',
            width: '250px',
            background: '#373F51',
            color: '#fff'
        },
        btnCls: {
            borderRadius: '20px',
            fontSize: '10px',
            padding: '8px'
        },
        inputDevices: {
            display: 'flex',
            width: '250px',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        iconBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '50%',
            padding: '0 10px 0 10px',
            marginLeft: '30px'
        },
        textStyle: {
            color: '#ffffff',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            alignItems: 'center'
        },
        itemContainer: {
            display: 'flex',
            flexDirection: 'row',
            height: '30px',
            padding: '25px',
            alignItems: 'center',
            cursor: 'pointer',

        },
        textContent: {
            marginLeft: '10px',
            display: 'flex',
            flexDirection: 'column'
        },
        headerText: {
            fontSize: '15px',
            color: '#fff'
        },
        smallDesc: {
            fontSize: '12px',
            color: '#A6A6A6'
        },
        iconContent: {
            padding: '10px'
        },
        icons: {
            color: "#F8F8F8",
            height: '20px'
        },
        textField:{
            '& label.Mui-focused': {
                color: 'white',
                 },
                 '& label': {
                    color: 'white',
                     },
                '& .MuiInput-underline:after': {
                 borderBottomColor: 'white',
                },
               '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: 'white',
                },
                '&:hover fieldset': {
                 borderColor: 'white',
                  },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
                },
               

        }
    })
);




function NetworkSettings(props: AudioProps) {
    const classes = useStyles();
    const [maxCallSignalingTimeoutMs, setMaxCallSignalingTimeoutMs] = useState(30000);

    const handleChange = (event) => {
        setMaxCallSignalingTimeoutMs(event.target.value);
    }
    const handleMaxValue = () => {
        if (props.device) {
            //@ts-ignore
            props.device.updateOptions({maxCallSignalingTimeoutMs: parseInt(maxCallSignalingTimeoutMs)});            
        }
        console.log(props.device);
    }
    
    return (
        <div style={{minHeight: '300px', maxHeight:'350px', overflowY:'auto'}}>
            <div>
                <Typography variant="body1" className={classes.title}>Network</Typography>
                <div style={{padding:'10px', color:"#fff"}}>
                     <TextField
                            margin="dense"
                            name="maxCallSignalingTimeoutMs"
                            label="Max Call Timeout"
                            type="number"
                            sx={{ input: { color: '#fff'} }}
                            className={classes.textField}
                            value={maxCallSignalingTimeoutMs}
                            onChange={handleChange}
                            fullWidth
                        />
                    <Button variant='contained' color="primary" onClick={handleMaxValue}>Update Max</Button>
                </div>
                
             </div>  
        </div>
    )
}

const MemoNetworkSettings = React.memo(NetworkSettings);
export default MemoNetworkSettings;