//@ts-nocheck
import Axios, { AxiosError } from "axios";
import { AppSettings } from "../../appSettings";
import { SaveAttributeRequest } from "../../vendor/staff/attributes/mappers/convertToSaveAttributes";

export const LOAD_ATTRIBUTES = "LOAD_ATTRIBUTES";
export const LOAD_ATTRIBUTES_SUCCESS = "LOAD_ATTRIBUTES_SUCCESS";
export const LOAD_ATTRIBUTES_ERROR = "LOAD_ATTRIBUTES_ERROR";

export const SAVE_ATTRIBUTES = "SAVE_ATTRIBUTES";
export const SAVE_ATTRIBUTES_SUCCESS = "SAVE_ATTRIBUTES_SUCCESS";
export const SAVE_ATTRIBUTES_ERROR = "SAVE_ATTRIBUTES_ERROR";

export interface LoadAttributesError {
    id: string;
    message: string;
}

export interface SystemUserResponse {
    id: string;
    email: string;
    userFullName: string;
}

export interface GetAttributesResponse {
    systemUser: SystemUserResponse;
    roleId: number;
    managerUserId: string;
    supervisorUserId: string;
}

export interface SaveAttributesError {
    id: string;
    message: string;
}

export interface LoadAttributesAction {
    type: typeof LOAD_ATTRIBUTES;
}

export interface LoadAttributesSuccessAction {
    type: typeof LOAD_ATTRIBUTES_SUCCESS;
    attributes: Array<GetAttributesResponse>
}

export interface LoadAttributesError {
    type: typeof LOAD_ATTRIBUTES_ERROR;
    error: string;
}

export interface SaveAttributesAction {
    type: typeof SAVE_ATTRIBUTES
}

export interface SaveAttributesSuccessAction {
    type: typeof SAVE_ATTRIBUTES_SUCCESS;
    message: string
}

export interface SaveAttributesError {
    type: typeof SAVE_ATTRIBUTES_ERROR;
    error: string;
}


export type AttributesAction = 
    LoadAttributesAction |
    LoadAttributesSuccessAction |
    LoadAttributesError | 
    SaveAttributesAction |
    SaveAttributesSuccessAction |
    SaveAttributesError;

export const loadAttributes = () => async (dispatch: any) => {
    dispatch({ type: LOAD_ATTRIBUTES });

    try {
        const response = 
        await Axios.get<Array<GetAttributesResponse>>(`${AppSettings.ApiRoleManagerUrl}/api/roleAssignments`);
        
        dispatch({
            type: LOAD_ATTRIBUTES_SUCCESS,
            attributes: response.data
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: LOAD_ATTRIBUTES_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveAttributes = (saveAttributesData: SaveAttributeRequest[]) => async (dispatch: any) => {
    dispatch({ type: SAVE_ATTRIBUTES });

    try {
        await Axios.post(`${AppSettings.ApiRoleManagerUrl}/api/roleAssignments/`, saveAttributesData);
        dispatch({
            type: SAVE_ATTRIBUTES_SUCCESS
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: SAVE_ATTRIBUTES_ERROR,
                error: errorMessage
            })
        }
    }
}
