const actionNamePrefix = "SAVE_SELECTED";
export const SAVE_SELECTED_STARTDATE = `${actionNamePrefix}_STARTDATE`;
export const SAVE_SELECTED_ENDDATE = `${actionNamePrefix}_ENDDATE`;
export const SAVE_SELECTED_CAMPAIGN = `${actionNamePrefix}_CAMPAIGN`;

function saveStartDate(startDate) {
    return {
        type: SAVE_SELECTED_STARTDATE,
        startDate: startDate
    };
}

function saveEndDate(endDate) {
    return {
        type: SAVE_SELECTED_ENDDATE,
        endDate: endDate
    };
}

function saveCampaign(campaign) {
    return {
        type: SAVE_SELECTED_CAMPAIGN,
        campaign: campaign
    };
}
export const setSelectedStartDateUI = (selectedDate) => (dispatch) => {
    dispatch(saveStartDate(selectedDate));
}

export const setSelectedEndDateUI = (selectedDate) => (dispatch) => {
    dispatch(saveEndDate(selectedDate));
}

export const setSelectedCampaignUI = (campaign) => (dispatch) => {
    dispatch(saveCampaign(campaign));
}