import createReducer from "./createReducer";

import {
    RECALCULATE_STAFFED_COUNT_START,
    RECALCULATE_STAFFED_COUNT_SUCCESS,
    RECALCULATE_STAFFED_COUNT_ERROR,
    RECALCULATE_EQUAL_WEIGHTAGE_START,
    RECALCULATE_EQUAL_WEIGHTAGE_SUCCESS,
    RECALCULATE_EQUAL_WEIGHTAGE_ERROR,
    CLEANUP_RECALCULATE_STAFFED_COUNT,
    CLEANUP_RECALCULATE_EQUAL_WEIGHTAGE
}
from '../actions/recalculateStaffedCount.action';

const initialState = {
    state: null,
    error: null,
    campaignId: null,
    jobId: null,
    equalWeightageJobId: null
};

function started(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        jobId: null,
        equalWeightageJobId: null,
        state: action.type
    }
}

function error(state, action) {
    return {
        ...state,
        data: null,
        error: action.error,
        state: action.type
    }
}

function success(state, action) {
    return {
        ...state,
        campaignId: action.campaignId,
        jobId: action.jobId,
        equalWeightageJobId: null,
        error: null,
        state: action.type
    }
}

function equalWeightageStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        jobId: null,
        equalWeightageJobId: null,
        state: action.type
    }
}

function equalWeightageError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error,
        state: action.type
    }
}

function equalWeightageSuccess(state, action) {
    return {
        ...state,
        campaignId: action.projectId,
        jobId: null,
        equalWeightageJobId: action.jobId,
        error: null,
        state: action.type
    }
}

function cleanUp(state, action) {
    return {
        ...state,
        campaignId: null,
        jobId: null,
        equalWeightageJobId: null,
        error: null,
        state: action.type
    }
}

export default createReducer(initialState, {
    [RECALCULATE_STAFFED_COUNT_START]: started,
    [RECALCULATE_STAFFED_COUNT_SUCCESS]: success,
    [RECALCULATE_STAFFED_COUNT_ERROR]: error,
    [RECALCULATE_EQUAL_WEIGHTAGE_START]: equalWeightageStarted,
    [RECALCULATE_EQUAL_WEIGHTAGE_SUCCESS]: equalWeightageSuccess,
    [RECALCULATE_EQUAL_WEIGHTAGE_ERROR]: equalWeightageError,
    [CLEANUP_RECALCULATE_STAFFED_COUNT]: cleanUp,
    [CLEANUP_RECALCULATE_EQUAL_WEIGHTAGE]: cleanUp
}); 