//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";
import { CAMPAIGN_TYPE_INVENTORY } from "../../vendor/forecast/models/CampaignTypes";

const actionNamePrefix = "LOAD_AGENT_FORCAST";
export const LOAD_AGENT_START = `${actionNamePrefix}_START`;
export const LOAD_AGENT_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_AGENT_ERROR = `${actionNamePrefix}_ERROR`;

function loadStart() {
    return {
        type: LOAD_AGENT_START        
    };
}

function loadSuccess(forecast) {
    return {
        type: LOAD_AGENT_SUCCESS,
        data: forecast        
    };
}

function loadError(error) {
    return {
        type: LOAD_AGENT_ERROR,
        error
    }
}

export const loadAgents = () => async (dispatch) => {
    dispatch(loadStart());
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/SystemUser`);
        dispatch(loadSuccess(response.data));
    }
    catch (e) {
        dispatch(loadError(e.response.data.Message || e.message));
    }
}