export default {
	lg: [
		{
			//Row No 1
			x: 0, y: 0, w: 2, h: 1,
			i: "Widget1"
		},
		{
			//Row No 1
			x: 2, y: 0, w: 2, h: 1,
			i: "Widget2"
		},		
		{
			//Row No 1 - 3rd one. Service level
			x: 4, y: 0, w: 4, h: 2,
			i: "Widget3"
		},
		{
			//Row 1 - QC
			x: 8, y: 0, w: 2, h: 2,
			i: "Widget4"
		},				
		{
			//Row No 2 - 1st item
			x: 10, y: 0, w: 4, h: 2,
			i: "Widget5"
		},
		{
			//Row No 2 - 1st item
			x: 10, y: 2, w: 5, h: 3,
			i: "Widget6"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 1, w: 2, h: 1,
			i: "Widget7"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 1, w: 2, h: 1,
			i: "Widget8"
		},
		{
			//Row No 2 - 1st item
			x: 4, y: 2, w: 6, h: 1,
			i: "Widget9"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 2, w: 2, h: 1,
			i: "Widget10"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 2, w: 2, h: 1,
			i: "Widget11"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 5, w: 10, h: 2,
			i: "Widget12"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 8, w: 8, h: 4,
			i: "Widget13"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 8, w: 8, h: 4,
			i: "Widget14"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 12, w: 8, h: 3,
			i: "Widget15"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 12, w: 8, h: 3,
			i: "Widget16"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 15, w: 8, h: 3,
			i: "Widget17"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 15, w: 8, h: 3,
			i: "Widget18"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 18, w: 8, h: 2,
			i: "Widget19"
		}
	],
	md: [
		{
			//Row No 1, top left
			x: 0, y: 0, w: 2, h: 1,
			i: "Widget1"
		},
		{
			//Row No 1
			x: 2, y: 0, w: 2, h: 1,
			i: "Widget2"
		},		
		{
			//Row No 1 - 3rd one. Service level
			x: 4, y: 0, w: 4, h: 2,
			i: "Widget3"
		},
		{
			//Row 1 - QC
			x: 8, y: 0, w: 2, h: 2,
			i: "Widget4"
		},				
		{
			//Row No 2 - 1st item
			x: 10, y: 0, w: 4, h: 2,
			i: "Widget5"
		},
		{
			//Row No 2 - 1st item
			x: 10, y: 2, w: 4, h: 3,
			i: "Widget6"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 1, w: 2, h: 1,
			i: "Widget7"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 1, w: 2, h: 1,
			i: "Widget8"
		},
		{
			//Row No 2 - 1st item
			x: 4, y: 2, w: 6, h: 1,
			i: "Widget9"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 2, w: 2, h: 1,
			i: "Widget10"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 2, w: 2, h: 1,
			i: "Widget11"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 5, w: 10, h: 2,
			i: "Widget12"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 8, w: 7, h: 4,
			i: "Widget13"
		},
		{
			//Row No 2 - 1st item
			x: 7, y: 8, w: 7, h: 4,
			i: "Widget14"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 12, w: 7, h: 3,
			i: "Widget15"
		},
		{
			//Row No 2 - 1st item
			x: 7, y: 12, w: 7, h: 3,
			i: "Widget16"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 15, w: 7, h: 3,
			i: "Widget17"
		},
		{
			//Row No 2 - 1st item
			x: 7, y: 15, w: 7, h: 3,
			i: "Widget18"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 18, w: 10, h: 3,
			i: "Widget19"
		}
	],
	sm: [
		{
			//Row No 1, top left
			x: 0, y: 0, w: 2, h: 1,
			i: "Widget1"
		},
		{
			//Row No 1
			x: 2, y: 0, w: 2, h: 1,
			i: "Widget2"
		},		
		{
			//Row No 1 - 3rd one. Service level
			x: 4, y: 0, w: 4, h: 2,
			i: "Widget3"
		},
		{
			//Row 1 - QC
			x: 6, y: 0, w: 2, h: 2,
			i: "Widget4"
		},				
		{
			//Row No 2 - 1st item
			x: 8, y: 0, w: 4, h: 2,
			i: "Widget5"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 2, w: 4, h: 4,
			i: "Widget6"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 1, w: 2, h: 1,
			i: "Widget7"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 1, w: 2, h: 1,
			i: "Widget8"
		},
		{
			//Row No 2 - 1st item
			x: 4, y: 2, w: 6, h: 1,
			i: "Widget9"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 2, w: 2, h: 1,
			i: "Widget10"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 2, w: 2, h: 1,
			i: "Widget11"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 5, w: 10, h: 3,
			i: "Widget12"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 8, w: 6, h: 4,
			i: "Widget13"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 8, w: 6, h: 4,
			i: "Widget14"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 12, w: 6, h: 3,
			i: "Widget15"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 12, w: 6, h: 3,
			i: "Widget16"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 15, w: 6, h: 3,
			i: "Widget17"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 15, w: 6, h: 3,
			i: "Widget18"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 18, w: 8, h: 4,
			i: "Widget19"
		}
	],
	xs: [
		{
			//Row No 1, top left
			x: 0, y: 0, w: 2, h: 1,
			i: "Widget1"
		},
		{
			//Row No 1
			x: 2, y: 0, w: 2, h: 1,
			i: "Widget2"
		},		
		{
			//Row No 1 - 3rd one. Service level
			x: 4, y: 0, w: 4, h: 2,
			i: "Widget3"
		},
		{
			//Row 1 - QC
			x: 6, y: 0, w: 2, h: 2,
			i: "Widget4"
		},				
		{
			//Row No 2 - 1st item
			x: 8, y: 0, w: 4, h: 2,
			i: "Widget5"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 2, w: 4, h: 4,
			i: "Widget6"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 1, w: 2, h: 1,
			i: "Widget7"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 1, w: 2, h: 1,
			i: "Widget8"
		},
		{
			//Row No 2 - 1st item
			x: 4, y: 2, w: 6, h: 1,
			i: "Widget9"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 2, w: 2, h: 1,
			i: "Widget10"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 2, w: 2, h: 1,
			i: "Widget11"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 5, w: 10, h: 3,
			i: "Widget12"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 8, w: 10, h: 4,
			i: "Widget13"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 8, w: 10, h: 4,
			i: "Widget14"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 12, w: 10, h: 3,
			i: "Widget15"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 12, w: 10, h: 3,
			i: "Widget16"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 15, w: 10, h: 3,
			i: "Widget17"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 15, w: 10, h: 3,
			i: "Widget18"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 18, w: 12, h: 4,
			i: "Widget19"
		}
	],
	xxs: [
		{
			//Row No 1, top left
			x: 0, y: 0, w: 2, h: 1,
			i: "Widget1"
		},
		{
			//Row No 1
			x: 2, y: 0, w: 2, h: 1,
			i: "Widget2"
		},		
		{
			//Row No 1 - 3rd one. Service level
			x: 4, y: 0, w: 4, h: 2,
			i: "Widget3"
		},
		{
			//Row 1 - QC
			x: 6, y: 0, w: 2, h: 2,
			i: "Widget4"
		},				
		{
			//Row No 2 - 1st item
			x: 8, y: 0, w: 4, h: 2,
			i: "Widget5"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 2, w: 4, h: 4,
			i: "Widget6"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 1, w: 2, h: 1,
			i: "Widget7"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 1, w: 2, h: 1,
			i: "Widget8"
		},
		{
			//Row No 2 - 1st item
			x: 4, y: 2, w: 6, h: 1,
			i: "Widget9"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 2, w: 2, h: 1,
			i: "Widget10"
		},
		{
			//Row No 2 - 1st item
			x: 2, y: 2, w: 2, h: 1,
			i: "Widget11"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 5, w: 10, h: 3,
			i: "Widget12"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 8, w: 10, h: 4,
			i: "Widget13"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 8, w: 10, h: 4,
			i: "Widget14"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 12, w: 10, h: 3,
			i: "Widget15"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 12, w: 10, h: 3,
			i: "Widget16"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 15, w: 10, h: 3,
			i: "Widget17"
		},
		{
			//Row No 2 - 1st item
			x: 8, y: 15, w: 10, h: 3,
			i: "Widget18"
		},
		{
			//Row No 2 - 1st item
			x: 0, y: 18, w: 12, h: 4,
			i: "Widget19"
		}
	]
};
