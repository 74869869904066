import Axios from "axios";
import { AppSettings } from "../../appSettings";
import { CAMPAIGN_TYPE_INVENTORY, CAMPAIGN_TYPE_PHONE } from "../../vendor/forecast/models/CampaignTypes";

const actionNamePrefix = "UPLOAD_ACTUAL_INTAKE";
export const UPLOAD_ACTUAL_INTAKE_START = `${actionNamePrefix}_START`;
export const UPLOAD_ACTUAL_INTAKE_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const UPLOAD_ACTUAL_INTAKE_ERROR = `${actionNamePrefix}_ERROR`;
export const CLEAN_UP_ACTUAL_INTAKE_FORECAST = `${actionNamePrefix}_CLEANUP`;


function uploadStart() {
    return {
        type: UPLOAD_ACTUAL_INTAKE_START
    };
}

function uploadSuccess(response) {
    return {
        type: UPLOAD_ACTUAL_INTAKE_SUCCESS,
        response: response
    };
}

function uploadError(error) {
    return {
        type: UPLOAD_ACTUAL_INTAKE_ERROR,
        error
    }
}

export const uploadActualIntake = (file, campaignType) => async (dispatch) => {
    dispatch(uploadStart());
    const formData = new FormData();
    formData.append('file', file);
    const config = { headers: { 'content-type': 'multipart/form-data', } };

    try {
        let response = null;
        let result = null;
        if (campaignType.toUpperCase() === CAMPAIGN_TYPE_INVENTORY) {
            response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/InventoryActualIntake`, formData, config);
            result = {
                ...response.data,
                "type": CAMPAIGN_TYPE_INVENTORY
            }
            dispatch(uploadSuccess(result));
        }
        else {
            response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/PhoneActual`, formData, config);
            result = {
                ...response.data,
                "type": CAMPAIGN_TYPE_PHONE
            }
            dispatch(uploadSuccess(result));
        }
    }
    catch (e) {
        //@ts-ignore
        dispatch(uploadError(e.response.data.Message || e.message));
    }

}

function cleanUp() {
    return {
        type: CLEAN_UP_ACTUAL_INTAKE_FORECAST
    };
}

export const cleanUpActualIntakeForecast = () => (dispatch) => {
    dispatch(cleanUp())
}