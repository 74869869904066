//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_FORM";
export const LOAD_FORM_START = `${actionNamePrefix}_START`;
export const LOAD_FORM_ERROR = `${actionNamePrefix}_ERROR`;
export const LOAD_FORM_SUCCESS = `${actionNamePrefix}_SUCCESS`;


const actionNamePrefixSave = "SAVE_SELECTED_FORM";
export const SAVE_SELECTED_FORM_START = `${actionNamePrefixSave}_START`;
export const SAVE_SELECTED_FORM_ERROR = `${actionNamePrefixSave}_ERROR`;
export const SAVE_SELECTED_FORM_SUCCESS = `${actionNamePrefixSave}_SUCCESS`;

export interface LoadFormStartAction {
    type: typeof LOAD_FORM_START;
}

export interface LoadFormSuccessAction {
    type: typeof LOAD_FORM_SUCCESS;
    data: any
}

export interface LoadFormErrorAction {
    type: typeof LOAD_FORM_ERROR;
    error: string;
}

export interface SaveFormStart {
    type: typeof SAVE_SELECTED_FORM_START,
}

export interface SaveFormSuccess {
    type: typeof SAVE_SELECTED_FORM_SUCCESS,
    selectedParams: data
}

export interface SaveFormError {
    type: typeof SAVE_SELECTED_FORM_ERROR,
    error: string
}

export type formActions = LoadFormStartAction |
                                LoadFormSuccessAction|
                                LoadFormErrorAction | SaveFormStart | SaveFormSuccess | SaveFormError;

export const getForms = (type?:string, businessId:number) => async (dispatch) => {
    dispatch({type: LOAD_FORM_START})
    let url = `${AppSettings.FormUrl}/form-definitions?businessId=${businessId}`;
       
    if (type !== null) {
        url = url+`&purpose=${type}`
    }

    try {    
      const response = await Axios.get(url);      
        dispatch({
            type: LOAD_FORM_SUCCESS,
            data: response.data.Items
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_FORM_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveSelectedSForm = (selectedData) => async (dispatch) => {
    dispatch({type: SAVE_SELECTED_FORM_START})        
    try {
       const response = await Axios.post(`${AppSettings.FormUrl}/form-definitions`, selectedData);
        dispatch({
            type: SAVE_SELECTED_FORM_SUCCESS,
            selectedParams: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: SAVE_SELECTED_FORM_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveUpdatedForm = (selectedData) => async (dispatch) => {
    dispatch({type: SAVE_SELECTED_FORM_START})        
    try {
       const response = await Axios.put(`${AppSettings.FormUrl}/form-definitions`, selectedData);
        dispatch({
            type: SAVE_SELECTED_FORM_SUCCESS,
            selectedParams: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: SAVE_SELECTED_FORM_ERROR,
                error: errorMessage
            })
        }
    }
}