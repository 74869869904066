import { LOAD_PHONE, 
        LOAD_PHONE_SUCCESS, 
        LOAD_PHONE_ERROR,
        PhoneTokenAction, 
        LoadPhoneAction,
        LoadPhoneSuccessAction,
        LoadPhoneError, 
        PhoneTokenResponse,
        StartPhoneLoggingStartAction,
        StartPhoneLoggingSuccessAction,
        StartPhoneLoggingErrorAction,
        StopPhoneLoggingStartAction,
        StopPhoneLoggingSuccessAction,
        StopPhoneLoggingErrorAction} from '../actions/phoneToken.action';

import { STATE_START_PHONE_LOG_START, STATE_START_PHONE_LOG_SUCCESS, STATE_START_PHONE_LOG_ERROR,
    STATE_STOP_PHONE_LOG_START, STATE_STOP_PHONE_LOG_SUCCESS, STATE_STOP_PHONE_LOG_ERROR, } from '../actions/statesLog.action';
import createReducer from "./createReducer";


export interface PhoneTokenState {
    state: string;
    error: string;
    data: PhoneTokenResponse,
    isForceSignout: boolean,
    isSignedIn: boolean
}

const initialState: PhoneTokenState = {
    state: null,
    error: null,
    data: null,
    isSignedIn: false,
    isForceSignout: false
}

function loadPhone(state:PhoneTokenState, action: LoadPhoneAction): PhoneTokenState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadPhoneSuccess(state:PhoneTokenState, action: LoadPhoneSuccessAction): PhoneTokenState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadPhoneError(state:PhoneTokenState, action: LoadPhoneError): PhoneTokenState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function signInPhoneStart(state:PhoneTokenState, action: StartPhoneLoggingStartAction): PhoneTokenState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function signInPhoneSuccess(state:PhoneTokenState, action: StartPhoneLoggingSuccessAction): PhoneTokenState {
    return {
        ...state,
        state: action.type,
        error: null,
        isSignedIn: true
    }
}

function signInPhoneError(state:PhoneTokenState, action: StartPhoneLoggingErrorAction): PhoneTokenState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        isSignedIn: false
    }
}

function signOutPhoneStart(state:PhoneTokenState, action: StopPhoneLoggingStartAction): PhoneTokenState {
    return {
        ...state,
        state: action.type
    }
}

function signOutPhoneSuccess(state:PhoneTokenState, action: StopPhoneLoggingSuccessAction): PhoneTokenState {
    return {
        ...state,
        state: action.type,
        error: null,
        isSignedIn: false,
        isForceSignout: action.isForceSignout
    }
}

function signOutPhoneError(state:PhoneTokenState, action: StopPhoneLoggingErrorAction): PhoneTokenState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        isSignedIn: true
    }
}

const PhoneTokenReducer =
    (state: PhoneTokenState, action: PhoneTokenAction): PhoneTokenState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_PHONE]: loadPhone,
            [LOAD_PHONE_SUCCESS]: loadPhoneSuccess,
            [LOAD_PHONE_ERROR]: loadPhoneError,
            [STATE_START_PHONE_LOG_START]:signInPhoneStart,
            [STATE_START_PHONE_LOG_SUCCESS]:signInPhoneSuccess,
            [STATE_START_PHONE_LOG_ERROR]:signInPhoneError,
            [STATE_STOP_PHONE_LOG_START]:signOutPhoneStart,
            [STATE_STOP_PHONE_LOG_SUCCESS]:signOutPhoneSuccess,
            [STATE_STOP_PHONE_LOG_ERROR]:signOutPhoneError,
        });

        return fnUpdateState(state, action);
    }

export default PhoneTokenReducer;
