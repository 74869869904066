import React, { useRef, useEffect, useState } from 'react';
import * as $ from 'jquery';
import { Button, CircularProgress } from '@mui/material';
import Axios from 'axios';
import { checkIfLiquidString, checkIfStringHasLiquid, findAndReplaceRuleString, getLiquidString, getValues } from './utils/liquidString';
(window as any).jQuery = $;
(window as any).$ = $;
require('jquery-ui-sortable');
require('formBuilder');
require('formBuilder/dist/form-render.min.js');

interface ChatFormProps {
    formDef: any,
    taskAttributes?: any,
    isPhone: boolean
}

export default function ChatForm (props: ChatFormProps) {
   console.log(props);
    const formRender = useRef(null);
    const [formId, setFormId] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [emptyField, setEmptyField] = useState(false);
    const [sucess, setSuccess] = useState('')
    useEffect(() => {        
        if (props.formDef && props.formDef.hasOwnProperty("formDefinition")) {
            const form = props.formDef;
            const formDefinition = JSON.parse(form.formDefinition);
            const attributes = props.taskAttributes;
            console.log(attributes);
            if (attributes) {
                formDefinition.forEach(element => {
                    console.log(element);
                    if(element.hasOwnProperty("value")){
                        const word = element['value'];
                        console.log(word);                        
                        if (checkIfStringHasLiquid(word)) {
                            const value = findAndReplaceRuleString(word, { task: { attributes: attributes } })
                            console.log(value);
                            element['value'] = value;
                        }
                    }
                });
                console.log("updated form definition", formDefinition)
                $(formRender.current).formRender('setData', formDefinition);
            }

            setFormId(form.id);            
            $(formRender.current).formRender({
                formData: formDefinition
            });
        }
    }, [props.formDef]);



    const handleSubmit = async (event) => {
        setIsSubmit(true);
        event.preventDefault();
        
        const formDataValues = $(formRender.current).formRender("userData");    
        console.log(formDataValues);
        var finalObject = {};
        formDataValues.forEach(element => {
            if (element.userData && element.userData[0]) {
                if (element.label) {
                    finalObject[element.label] = element.userData[0]
                } else {
                    finalObject[element.name] = element.userData[0]
                }
            }
        });
        const requiredFields = formDataValues.filter(x => x.required === true);
        if (requiredFields != null) {
            //check for empty userData
            const ifAnyEmpty = requiredFields.some(x => x.userData[0] === '');
            if (ifAnyEmpty) {
                setEmptyField(true);
                setIsSubmit(false);
                return;
            } else {
                setEmptyField(false);
            }
        }  
        let submitData;
        const submitUrl = finalObject['submitApi'];
        if (finalObject.hasOwnProperty('submitApi')) {
            delete finalObject['submitApi'];
        }        
        if (props.isPhone) {
            submitData = {                
                ...finalObject,
            }
            
            console.log(submitUrl);
            if (submitUrl) {
                try {
                    var xhr = new XMLHttpRequest();
                    xhr.open("POST", submitUrl);
                    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest'); 
                    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
                    xhr.setRequestHeader('Content-type', 'application/json;charset=utf-8');
                    xhr.send(JSON.stringify(submitData));
                    setSuccess("Submitted successfully!")
                    console.log("Pushed to Zapier successfully!");
                    setIsSubmit(false);
                } catch(e) {
                    setIsSubmit(false);
                    console.error(e);
                }
            }

        } else {
            submitData = {
                formId: formId,
                purpose: props.formDef.purpose,
                businessId: 2,
                ...props.taskAttributes,
                ...finalObject,
            };       
            const payloadPostTo = props.taskAttributes?.payloadPostTo;        
            if (payloadPostTo) {
                try {
                    var xhr = new XMLHttpRequest();
                    xhr.open("POST", payloadPostTo);
                    xhr.send(JSON.stringify(submitData));
                    setSuccess("Submitted successfully!")
                    console.log("Pushed to Zapier successfully!");
                    setIsSubmit(false);
                } catch(e) {
                    setIsSubmit(false);
                    console.error(e);
                }
            }        
        }
        
        setIsSubmit(false);
    }
    
    return (
        <div>            
            <form onSubmit={handleSubmit}>
                <div id="fb-form-render" ref={formRender} />
                {
                !props.isPhone && !props.taskAttributes?.payloadPostTo ? "The from cannot be submitted, no payload post provided." :
                    ( isSubmit ? <CircularProgress sx={{color: '#fff'}} /> : <Button onClick={handleSubmit} variant="contained" >Save</Button> )
                }   
                { emptyField && <p>Fill in required fields</p>}             
                {sucess}                
            </form>
            
        </div>
    )
}