import { Box, Button, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import KeypadButton from "./KeypadButton";
import { ReactComponent as Answer } from "./Icons/Answer.svg";
import CallStates from "./CallStates";
import { element } from "prop-types";

interface IDailerProps {
  number: any;
  handlePress: (val) => void;
  handleOutboundCall: (val, type) => void;
  callState: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    keypad: {
      display: "grid",
      listStyle: "none",
      padding: 0,
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "0px",
    },
    dailerContainer: {
      padding:"0px 20px"
    },
    listStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    keypadBtn: {
      color: "#fff",
      backgroundColor: "#1D1F28",
      borderRadius: "50px",
      marginBottom: "4px",
      "&:hover": {
        opacity: "0.5",
      },
    },
  })
);

const lists = [
  { id: "1", title: "" },
  { id: "2", title: "ABC" },
  { id: "3", title: "DEF" },
  { id: "4", title: "GHI" },
  { id: "5", title: "JKL" },
  { id: "6", title: "MNO" },
  { id: "7", title: "PQRS" },
  { id: "8", title: "TUV" },
  { id: "9", title: "WXYZ" },
  { id: "+", title: "" },
  { id: "0", title: "" },
];

export default function Dailer(props: IDailerProps) {
  const classes = useStyles();
  const [btnColor, setBtnColor] = useState("");
  const [style, setStyle] = useState(true);

  const [dailed, setDailed] = useState(false);

  const handleNumberChange = (event) => {
    props.handlePress(event.target.value);
  };

  const handleBackSpace = () => {
    setStyle(false);
    const number = props.number;
    props.handlePress(number.substring(0, number.length - 1));
    setTimeout(changeBtnColor, 300);
  };

  const changeBtnColor = () => {
    setBtnColor("");
    setStyle(true);
  };

  const handleNumberPressed = (newNumber) => {
    return () => {
      setBtnColor(newNumber);
      props.handlePress(`${props.number}${newNumber}`);
      setTimeout(changeBtnColor, 300);
    };
  };

  return (
    <div className={classes.dailerContainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "2px solid grey",
        }}
      >
        <PhoneInput
          country={"us"}
          onlyCountries={["us"]}
          value={props.number}
          onChange={props.handlePress}
          containerStyle={{ width: "80%" }}
          inputStyle={{
            color: "#fff",
            backgroundColor: "#152238",
            border: "none",
          }}
        />
        {props.number &&
          props.number.length >= 11 &&
          !dailed &&
          props.callState === CallStates.READY && (
            <div style={{ textAlign: "center" }}>
              <Answer
                onClick={() => {
                  setDailed(true);
                  props.handleOutboundCall(props.number, "number");
                }}
              />
            </div>
          )}
        {dailed ? (
          <span style={{ color: "red" }}>
            Connecting...
            <Button
              variant="contained"
              style={{ fontSize: "9px" }}
              onClick={() => {
                setDailed(false);
              }}
            >
              Cancel
            </Button>{" "}
          </span>
        ) : (
          ""
        )}
      </div>
      <ol className={classes.keypad}>
        {lists.map((x) => (
          <li className={classes.listStyle} key={x.id}>
            <Button
              className={classes.keypadBtn}
              style={{
                backgroundColor: x.id === btnColor ? "#cecece" : "#1D1F28",
                color: x.id === btnColor ? "#000" : "#fff",
              }}
              onClick={handleNumberPressed(x.id)}
            >
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Box>{x.id}</Box>
                <Box style={{ fontSize: "7px", color: "grey" }}>{x.title}</Box>
              </Box>
            </Button>
          </li>
        ))}
        {props.number && props.number.length > 0 && (
          <li className={classes.listStyle}>
            <Button
              className={classes.keypadBtn}
              style={{ backgroundColor: style ? "#1D1F28" : "#cecece" }}
              onClick={handleBackSpace}
            >
              &lt;&lt;
            </Button>
          </li>
        )}
      </ol>
    </div>
  );
}
