//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

import {
    initRealtimeDashboard,
    initHistoricalDashboard,
    initInventoryDashboard,
    initAgentStates,
    IDashboardTabConfig,
    DashboardTypes,
    IDashboarLayout
} from '../data/dashboardConfig.data';

const actionNamePrefix = "LOAD_DASHBOARD";
export const LOAD_DASHBOARD_START = `${actionNamePrefix}_START`;
export const LOAD_DASHBOARD_ERROR = `${actionNamePrefix}_ERROR`;
export const LOAD_DASHBOARD_SUCCESS = `${actionNamePrefix}_SUCCESS`;

const actionNameImportPrefix = "IMPORT_DASHBOARD";
export const IMPORT_DASHBOARD_START = `${actionNameImportPrefix}_START`;
export const IMPORT_DASHBOARD_ERROR = `${actionNameImportPrefix}_ERROR`;
export const IMPORT_DASHBOARD_SUCCESS = `${actionNameImportPrefix}_SUCCESS`;

const actionNamePrefixAdd = "ADD_DASHBOARD";
export const ADD_DASHBOARD_SUCCESS = `${actionNamePrefixAdd}_SUCCESS`;

const actionNamePrefixUpdateDrag = "UPDATE_DRAG";
export const UPDATE_DRAG_SUCCESS = `${actionNamePrefixUpdateDrag}_SUCCESS`;

const actionNamePrefixUpdateGridLayout = "UPDATE_DASHBOARD_GRID_LAYOUT";
export const UPDATE_DASHBOARD_GRID_LAYOUT_SUCCESS = `${actionNamePrefixUpdateGridLayout}_SUCCESS`;

const actionNamePrefixDelete = "DELETE_DASHBOARD";
export const DELETE_DASHBOARD_START = `${actionNamePrefixDelete}_START`;
export const DELETE_DASHBOARD_SUCCESS = `${actionNamePrefixDelete}_SUCCESS`;
export const DELETE_DASHBOARD_ERROR = `${actionNamePrefixDelete}_ERROR`;


const actionNamePrefixSave = "SAVE_SELECTED_DASHBOARD";
export const SAVE_SELECTED_DASHBOARD_START = `${actionNamePrefixSave}_START`;
export const SAVE_SELECTED_DASHBOARD_ERROR = `${actionNamePrefixSave}_ERROR`;
export const SAVE_SELECTED_DASHBOARD_SUCCESS = `${actionNamePrefixSave}_SUCCESS`;

const actionNamePrefixUpdate = "UPDATE_DASHBOARDS";
export const UPDATE_DASHBOARDS_START = `${actionNamePrefixUpdate}_START`;
export const UPDATE_DASHBOARDS_ERROR = `${actionNamePrefixUpdate}_ERROR`;
export const UPDATE_DASHBOARDS_SUCCESS = `${actionNamePrefixUpdate}_SUCCESS`;

const actionNamePrefixUpdateSingle = "UPDATE_SINGLE_DASHBOARDS";
export const UPDATE_SINGLE_DASHBOARDS_START = `${actionNamePrefixUpdateSingle}_START`;
export const UPDATE_SINGLE_DASHBOARDS_ERROR = `${actionNamePrefixUpdateSingle}_ERROR`;
export const UPDATE_SINGLE_DASHBOARDS_SUCCESS = `${actionNamePrefixUpdateSingle}_SUCCESS`;

const actionNamePrefixUpdateWidgetParams = "UPDATE_WIDGET_PARAMS";
export const UPDATE_DASHBOARD_WIDGET_PARAMS_SUCCESS = `${actionNamePrefixUpdateWidgetParams}_SUCCESS`;

export interface AddDashboardSuccessAction {
    type: typeof ADD_DASHBOARD_SUCCESS;
    data: any
}
export interface UpdateDashboardGridLayoutSuccessAction {
    type: typeof UPDATE_DASHBOARD_GRID_LAYOUT_SUCCESS;
    data: {
        id: string,
        layout: IDashboarLayout
    }
}

export interface UpdateDashboardWidgetParamsSuccessAction {
    type: typeof UPDATE_DASHBOARD_WIDGET_PARAMS_SUCCESS,
    data: {
        dashboardId: string,
        widgetId: string,
        params: any,
        config?: any
    }
}

export interface DeleteDashboardStartAction {
    type: typeof DELETE_DASHBOARD_START;
}
export interface DeleteDashboardSuccessAction {
    type: typeof DELETE_DASHBOARD_SUCCESS;
    data: string
}
export interface DeleteDashboardErrorAction {
    type: typeof DELETE_DASHBOARD_ERROR;
    error: string
}
export interface UpdateDragSuccessAction {
    type: typeof UPDATE_DRAG_SUCCESS;
    data: { id: string, val: boolean }
}

export interface LoadDashboardStartAction {
    type: typeof LOAD_DASHBOARD_START;
}

export interface LoadDashboardSuccessAction {
    type: typeof LOAD_DASHBOARD_SUCCESS;
    data: any
}

export interface LoadDashboardErrorAction {
    type: typeof LOAD_DASHBOARD_ERROR;
    error: string;
}

export interface SaveDashboardStart {
    type: typeof SAVE_SELECTED_DASHBOARD_START,
}

export interface SaveDashboardSuccess {
    type: typeof SAVE_SELECTED_DASHBOARD_SUCCESS,
    selectedParams: data
}

export interface SaveDashboardError {
    type: typeof SAVE_SELECTED_DASHBOARD_ERROR,
    error: string
}
export interface UpdateDashboardStart {
    type: typeof UPDATE_DASHBOARDS_START,
}

export interface UpdateDashboardSuccess {
    type: typeof UPDATE_DASHBOARDS_SUCCESS
    data: any
}

export interface UpdateDashboardError {
    type: typeof UPDATE_DASHBOARDS_ERROR,
    error: string
}

export interface UpdateSingleDashboardStart {
    type: typeof UPDATE_SINGLE_DASHBOARDS_START,
}

export interface UpdateSingleDashboardSuccess {
    type: typeof UPDATE_SINGLE_DASHBOARDS_SUCCESS
    widgetParams: data,
    widgetKey: string
}

export interface UpdateSingleDashboardError {
    type: typeof UPDATE_SINGLE_DASHBOARDS_ERROR,
    error: string
}

export interface ImportDashbaordConfigSuccess {
    type: typeof IMPORT_DASHBOARD_SUCCESS;
    data: any
}



export type dashboardActions = AddDashboardSuccessAction| LoadDashboardStartAction |
                                LoadDashboardSuccessAction|
                                LoadDashboardErrorAction | SaveDashboardStart | SaveDashboardSuccess | 
                                SaveDashboardError | UpdateDashboardStart | UpdateDashboardSuccess | 
                                UpdateDashboardError | UpdateSingleDashboardStart | UpdateSingleDashboardSuccess
                                | UpdateSingleDashboardError| ImportDashbaordConfigSuccess;

export const addDashboard = (newDashboard: IDashboardTabConfig) => async (dispatch) => {
    try {
        // call api
        dispatch({
            type: ADD_DASHBOARD_SUCCESS,
            data: newDashboard
         })
    } catch (error) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: DELETE_DASHBOARD_ERROR,
                error: errorMessage
            })
        }
    }
}

export const updateDrag = (id: string, val: boolean) => async (dispatch) => {
    try {
        // call api
        dispatch({
            type: UPDATE_DRAG_SUCCESS,
            data: { id, val }
         })
    } catch (error) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: DELETE_DASHBOARD_ERROR,
                error: errorMessage
            })
        }
    }
}

export const deleteDashboard = (id: string) => async (dispatch) => {
    dispatch({ type: DELETE_DASHBOARD_START })
    try {
        // call api
        dispatch({
            type: DELETE_DASHBOARD_SUCCESS,
            data: id
        });
    } catch (error) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: DELETE_DASHBOARD_ERROR,
                error: errorMessage
            })
        }
    }
}

export const updateDashboardGridLayout = (id: string, layout: IDashboarLayout) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_DASHBOARD_GRID_LAYOUT_SUCCESS,
            data: { id, layout }
        });
    } catch (error) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: DELETE_DASHBOARD_ERROR,
                error: errorMessage
            })
        }
    }
}

export const updateDashboardWidgetParams = (dashboardId: string, widgetId: string, params: any, config?: any) => (dispatch) => {
    dispatch({
        type: UPDATE_DASHBOARD_WIDGET_PARAMS_SUCCESS,
        data: {
            dashboardId, widgetId, params, config
        }
    })
}

export const loadDashboard = () => async (dispatch) => {
    dispatch({type: LOAD_DASHBOARD_START})
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/Dashboard`);
        let data: IDashboardTabConfig[] = [];
        if (response && response.data.dashbaordConfig) {
            data = response.data && JSON.parse(response.data.dashbaordConfig);
        };        
        const realtimeDashboardConfig = (data && data.find((item) => item.type === 'realtime') )|| initRealtimeDashboard;
        const historicalDashboardConfig = (data && data.find((item) => item.type === 'history')) || initHistoricalDashboard;
        const inventoryDashboardConfig = (data && data.find((item) => item.type === 'inventory')) || initInventoryDashboard;
        const agentStatesConfig = (data && data.find((item) => item.type === 'agentStates')) || initAgentStates;

        dispatch({
            type: LOAD_DASHBOARD_SUCCESS,
            data: [
                realtimeDashboardConfig, historicalDashboardConfig, inventoryDashboardConfig, agentStatesConfig,
                ...data && data.filter((item) => item.type === DashboardTypes.Custom)
            ]
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_DASHBOARD_ERROR,
                error: errorMessage
            })
        }
    }
}

export const importDashboardConfig = (selectedData) => async(dispatch) => {
    dispatch({type: IMPORT_DASHBOARD_START})
    try {
        dispatch({
            type: IMPORT_DASHBOARD_SUCCESS,
            data: [                
                ...selectedData
            ]
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: IMPORT_DASHBOARD_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveSelectedDashboard = (selectedData) => async (dispatch) => {
    dispatch({type: SAVE_SELECTED_DASHBOARD_START})
    try {             
        
        dispatch({
            type: SAVE_SELECTED_DASHBOARD_SUCCESS,
            selectedParams: selectedData
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: SAVE_SELECTED_DASHBOARD_ERROR,
                error: errorMessage
            })
        }
    }
}

export const updateDashboards = (dashboardConfig) => async (dispatch) => {
    dispatch({type: UPDATE_DASHBOARDS_START})
    try {
        dispatch({
            type: UPDATE_DASHBOARDS_SUCCESS,
            data: dashboardConfig
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: UPDATE_DASHBOARDS_ERROR,
                error: errorMessage
            })
        }
    }
}

export const updateSingleDashboard = (selectionParms, key) => async (dispatch) => {
    dispatch({type: UPDATE_SINGLE_DASHBOARDS_START})
    try {
       //const response = await Axios.post(`${AppSettings.ApiBaseUrl}/Form`, selectedData);
        dispatch({
            type: UPDATE_SINGLE_DASHBOARDS_SUCCESS,
            widgetParams: selectionParms,
            widgetKey: key
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: UPDATE_SINGLE_DASHBOARDS_ERROR,
                error: errorMessage
            })
        }
    }
}
