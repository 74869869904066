import React, { useEffect, useState } from "react";
// import {  Link, LinkOff } from "@mui/icons-material";
import { ValueGetterParams } from "@ag-grid-community/all-modules";
import { Button, IconButton } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';

export default function UploadBtn(params: ValueGetterParams) {
    const btnClickedHandler = () => {   
        //@ts-ignore
        params.clicked(params.data);    
    }

    const getIcon = () => {
        if (params.node.groupData) return (<div></div>);
        
        return (
            <IconButton style={{padding:'0'}} onClick={btnClickedHandler} size="large">
                <ImageIcon style={{height:'20px', width:'20px'}} />
            </IconButton>
        );
    }

    return (<div>
        {getIcon()}
    </div>)
}