//@ts-nocheck
import createReducer from "./createReducer";
import {
    LOAD_MY_CONTRACTS_START,
    LOAD_MY_CONTRACTS_SUCCESS,
    LOAD_MY_CONTRACTS_ERROR   
} from "../actions/myContracts.action";

const initialState = {
    data: [],
    state: null,
    error: null   
};

function loaded(state, action) {
    return {
        ...state,
        data: action.data,
        error: null,
        state: action.type        
    }
}

function loadStarted(state, action) {
    return {
        ...state,
        data: [],
        error: null,
        state: action.type
    }
}

function loadError(state, action) {
    return {
        ...state,
        data: null,
        error: action.error
    }
}

export default createReducer(initialState, {
    [LOAD_MY_CONTRACTS_START]: loadStarted,
    [LOAD_MY_CONTRACTS_SUCCESS]: loaded,
    [LOAD_MY_CONTRACTS_ERROR]: loadError   
}); 