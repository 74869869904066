//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_WIDGET";
export const LOAD_WIDGET_START = `${actionNamePrefix}_START`;
export const LOAD_WIDGET_ERROR = `${actionNamePrefix}_ERROR`;
export const LOAD_WIDGET_SUCCESS = `${actionNamePrefix}_SUCCESS`;

const actionNamePrefixAdd = "ADD_WIDGET";
export const ADD_WIDGET_SUCCESS = `${actionNamePrefixAdd}_SUCCESS`;


const actionNamePrefixSave = "SAVE_SELECTED_WIDGET";
export const SAVE_SELECTED_WIDGET_START = `${actionNamePrefixSave}_START`;
export const SAVE_SELECTED_WIDGET_ERROR = `${actionNamePrefixSave}_ERROR`;
export const SAVE_SELECTED_WIDGET_SUCCESS = `${actionNamePrefixSave}_SUCCESS`;

const actionNamePrefixUpdate = "UPDATE_WIDGETS";
export const UPDATE_WIDGETS_START = `${actionNamePrefixUpdate}_START`;
export const UPDATE_WIDGETS_ERROR = `${actionNamePrefixUpdate}_ERROR`;
export const UPDATE_WIDGETS_SUCCESS = `${actionNamePrefixUpdate}_SUCCESS`;

const actionNamePrefixRemove = "REMOVE_WIDGET";
export const REMOVE_WIDGET_START = `${actionNamePrefixRemove}_START`;
export const REMOVE_WIDGET_ERROR = `${actionNamePrefixRemove}_ERROR`;
export const REMOVE_WIDGET_SUCCESS = `${actionNamePrefixRemove}_SUCCESS`;

const actionNamePrefixUpdateSingle = "UPDATE_SINGLE_WIDGET";
export const UPDATE_SINGLE_WIDGET_START = `${actionNamePrefixUpdateSingle}_START`;
export const UPDATE_SINGLE_WIDGET_ERROR = `${actionNamePrefixUpdateSingle}_ERROR`;
export const UPDATE_SINGLE_WIDGET_SUCCESS = `${actionNamePrefixUpdateSingle}_SUCCESS`;

export interface AddWidgetSuccessAction {
    type: typeof ADD_WIDGET_SUCCESS;
    data: any
}

export interface LoadWidgetStartAction {
    type: typeof LOAD_WIDGET_START;
}

export interface LoadWidgetSuccessAction {
    type: typeof LOAD_WIDGET_SUCCESS;
    data: any
}

export interface LoadWidgetErrorAction {
    type: typeof LOAD_WIDGET_ERROR;
    error: string;
}

export interface SaveWidgetStart {
    type: typeof SAVE_SELECTED_WIDGET_START,
}

export interface SaveWidgetSuccess {
    type: typeof SAVE_SELECTED_WIDGET_SUCCESS,
    selectedParams: data
}

export interface SaveWidgetError {
    type: typeof SAVE_SELECTED_WIDGET_ERROR,
    error: string
}
export interface UpdateWidgetStart {
    type: typeof UPDATE_WIDGETS_START,
}

export interface UpdateWidgetSuccess {
    type: typeof UPDATE_WIDGETS_SUCCESS
    widgets: data,
    cols: any
}

export interface UpdateWidgetError {
    type: typeof UPDATE_WIDGETS_ERROR,
    error: string
}

export interface RemoveWidgetStart {
    type: typeof REMOVE_WIDGET_START,
}

export interface RemoveWidgetSuccess {
    type: typeof REMOVE_WIDGET_SUCCESS
    key: string,
}

export interface RemoveWidgetError {
    type: typeof REMOVE_WIDGET_ERROR,
    error: string
}

export interface UpdateSingleWidgetStart {
    type: typeof UPDATE_SINGLE_WIDGET_START,
}

export interface UpdateSingleWidgetSuccess {
    type: typeof UPDATE_SINGLE_WIDGET_SUCCESS
    widgetParams: data,
    widgetKey: string
}

export interface UpdateSingleWidgetError {
    type: typeof UPDATE_SINGLE_WIDGET_ERROR,
    error: string
}

export type widgetActions = AddWidgetSuccessAction| LoadWidgetStartAction |
                                LoadWidgetSuccessAction|
                                LoadWidgetErrorAction | SaveWidgetStart | SaveWidgetSuccess | 
                                SaveWidgetError | UpdateWidgetStart | UpdateWidgetSuccess | 
                                UpdateWidgetError | UpdateSingleWidgetStart | UpdateSingleWidgetSuccess
                                | UpdateSingleWidgetError | RemoveWidgetStart |RemoveWidgetSuccess |RemoveWidgetError;

export const addWidget = () => async (dispatch) => {
    dispatch({
        type: ADD_WIDGET_SUCCESS,
        data: {}
     })
}

export const loadWidget = () => async (dispatch) => {
    dispatch({type: LOAD_WIDGET_START})
    try {
       // const response = await Axios.get(`${AppSettings.ApiBaseUrl}/Form`);
        dispatch({
            type: LOAD_WIDGET_SUCCESS,
           // data: widgetParams
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_WIDGET_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveSelectedWidget = (selectedData) => async (dispatch) => {
    dispatch({type: SAVE_SELECTED_WIDGET_START})
    try {
       //const response = await Axios.post(`${AppSettings.ApiBaseUrl}/Form`, selectedData);
        dispatch({
            type: SAVE_SELECTED_WIDGET_SUCCESS,
            selectedParams: selectedData
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: SAVE_SELECTED_WIDGET_ERROR,
                error: errorMessage
            })
        }
    }
}

export const updateWidgets = (widgets, cols) => async (dispatch) => {
    dispatch({type: UPDATE_WIDGETS_START})
    try {
       //const response = await Axios.post(`${AppSettings.ApiBaseUrl}/Form`, selectedData);
        dispatch({
            type: UPDATE_WIDGETS_SUCCESS,
            widgets: widgets,
            cols: cols
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: UPDATE_WIDGETS_ERROR,
                error: errorMessage
            })
        }
    }
}

export const removeWidget = (key) => async (dispatch) => {
    dispatch({type: REMOVE_WIDGET_START})
    try {
       //const response = await Axios.post(`${AppSettings.ApiBaseUrl}/Form`, selectedData);
        dispatch({
            type: REMOVE_WIDGET_SUCCESS,
            key: key
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: REMOVE_WIDGET_ERROR,
                error: errorMessage
            })
        }
    }
}


export const updateSingleWidget = (selectionParms, key) => async (dispatch) => {
    dispatch({type: UPDATE_SINGLE_WIDGET_START})
    try {
       //const response = await Axios.post(`${AppSettings.ApiBaseUrl}/Form`, selectedData);
        dispatch({
            type: UPDATE_SINGLE_WIDGET_SUCCESS,
            widgetParams: selectionParms,
            widgetKey: key
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: UPDATE_SINGLE_WIDGET_ERROR,
                error: errorMessage
            })
        }
    }
}
