import createReducer from "./createReducer";
import {
    SAVE_SELECTED_ACTIVITY
} from '../actions/currentActivity.action'

export interface CurrentActivityState {
    type: string;
    error: string;
    selectedCurrentActivity: any;
}

const initialState:CurrentActivityState = {
    selectedCurrentActivity: null,
    type: '',
    error: ''
};

function saveSelectedActivity (state: CurrentActivityState, action) {
    console.log("save selected activity", action);
    return{
        ...state,
        selectedCurrentActivity: action.selectedActivity,
        type: action.type
    }
}

const CurrentActivityReducer =
    (state: CurrentActivityState, action): CurrentActivityState => {

        const fnUpdateState = createReducer(initialState, {
            [SAVE_SELECTED_ACTIVITY]: saveSelectedActivity
        });

        return fnUpdateState(state, action);
    }

export default CurrentActivityReducer;
