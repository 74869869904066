import createReducer from "./createReducer";
import {
    LOAD_SCHEDULE_TEMPLATES_START,
    LOAD_SCHEDULE_TEMPLATES_SUCCESS,
    LOAD_SCHEDULE_TEMPLATES_ERROR,
    scheudleTemplateActions,
    LoadScheduleTemplatesStartAction,
    LoadScheduleTemplatesSuccessAction,
    LoadScheduleTemplatesErrorAction,
    SaveScheduleTemplateStart,
    SaveScheduleTemplateSuccess,
    SaveScheduleTemplateError,
    SAVE_SCHEDULE_TEMPLATES_START,
    SAVE_SCHEDULE_TEMPLATES_SUCCESS,
    SAVE_SCHEDULE_TEMPLATES_ERROR
} from '../actions/scheduleTemplate.action'

export interface ScheduleTemplateState {
    state: string;
    error: string;
    data: any;
}

const initialState: ScheduleTemplateState = {
    state: null,
    error: null,
    data: []
}

function loadScheduleTemplatesStart(state:ScheduleTemplateState, action: LoadScheduleTemplatesStartAction): ScheduleTemplateState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadScheduleTemplatesSuccess(state:ScheduleTemplateState, action: LoadScheduleTemplatesSuccessAction): ScheduleTemplateState {    
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadScheduleTemplatesError(state:ScheduleTemplateState, action: LoadScheduleTemplatesErrorAction): ScheduleTemplateState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

function saveScheduleTemplateStart(state:ScheduleTemplateState, action: SaveScheduleTemplateStart): ScheduleTemplateState {    
    return {
        ...state,
        state: action.type,
        error: null,

    }
}

function saveScheduleTemplateSuccess(state:ScheduleTemplateState, action: SaveScheduleTemplateSuccess): ScheduleTemplateState {    
    const data = [...state.data];
    data.push(action.template);
    return {
        ...state,
        data: [
            ...data
        ],
        state: action.type,
        error: null,        
    }
}

function saveScheduleTemplateError(state:ScheduleTemplateState, action: SaveScheduleTemplateError): ScheduleTemplateState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const ScheduleTemplateReducer =
    (state: ScheduleTemplateState, action: scheudleTemplateActions): ScheduleTemplateState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_SCHEDULE_TEMPLATES_START]: loadScheduleTemplatesStart,
            [LOAD_SCHEDULE_TEMPLATES_SUCCESS]: loadScheduleTemplatesSuccess,
            [LOAD_SCHEDULE_TEMPLATES_ERROR]: loadScheduleTemplatesError,
            [SAVE_SCHEDULE_TEMPLATES_START] : saveScheduleTemplateStart,
            [SAVE_SCHEDULE_TEMPLATES_SUCCESS]: saveScheduleTemplateSuccess,
            [SAVE_SCHEDULE_TEMPLATES_ERROR]: saveScheduleTemplateError
        });

        return fnUpdateState(state, action);
    }

export default ScheduleTemplateReducer;