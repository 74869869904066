import React, { useEffect, useState } from 'react';
import {
    FormControl,
    NativeSelect,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    Table,
    IconButton,
    Typography,
    TableRow,
    TableCell,
    TableContainer,
    Select,
    MenuItem,
    Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { ReactComponent as Answer } from './Icons/Answer.svg';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CallStates from './CallStates';

interface IAgentCallList {
    handleOutboundCall: (val, type) => void;
    handleDailType: (val) => void;
    agentsList: Array<any>,
    phoneType: string,
    setAgent?:(val) =>void;
    callState: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            //width: '50%',
            padding: '0 10px 0 10px',
            marginLeft: '30px'
        },
        textStyle: {
            color: '#ffffff',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            alignItems: 'center'
        },
        icon: {
            fill: '#ffffff',
        },
        connect: {
            color: 'red',
            display: 'flex',
            alignItems: 'center'
        }


    })
);


export default function AgentCallList(props:IAgentCallList) {

    const [dailed, setDialed] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(0);

    const classes = useStyles();

    const handleAgentChange = (e) => {
        setSelectedAgent(e.target.value);
        if (props.phoneType === 'conference') {
            props.setAgent(e.target.value);
        }
        
    }

    const handleOpen = () => { props.handleDailType('agents') }
    const renderPhoneState = (currentState) => {
        console.log(currentState);
        if (currentState === 'Idle') return <RadioButtonCheckedIcon style={{ color: 'yellow' }} />
        if (currentState === 'Offline' || currentState === null) return <RadioButtonCheckedIcon style={{ color: 'red' }} />
        if (currentState === 'Ready' || currentState === CallStates.INCOMING || currentState === CallStates.ON_CALL
            || currentState === CallStates.ON_HOLD || currentState === CallStates.WRAPUP || currentState === CallStates.COMPLETEWRAPUP) {
            return <RadioButtonCheckedIcon style={{ color: 'green' }} />
        }

    }
    
    return (
        <div className={clsx(classes.iconBar, classes.textStyle)} style={{ display: 'flex', marginLeft: '30px' }}>
            <Select
                style={{ marginLeft: '5px', color: '#fff' }}
                value={selectedAgent}
                onChange={handleAgentChange}
                onOpen={handleOpen}
                autoWidth={true}
                name='action'
                fullWidth
            >
                <MenuItem value={0}> None</MenuItem>
                {props.agentsList != null && props.agentsList
                    .map((eachItem, index) =>
                        <MenuItem style={{ color: '#000000' }} key={eachItem.name + "-" + index} value={eachItem}>
                            <div style={{ display: 'flex', alignItems: 'center' }}><span>{eachItem.name}</span><span>{renderPhoneState(eachItem.currentPhoneState)} </span><span> {eachItem.currentPhoneState !== null && " - " + eachItem.currentPhoneState}</span></div>
                        </MenuItem>
                    )}
                    

            </Select>
            {
                props.phoneType === 'outbound' && selectedAgent ? (!dailed && props.callState === CallStates.READY &&
                <div style={{ textAlign: 'center', marginLeft: '30px' }}>
                    <Answer onClick={() => { setDialed(true); props.handleOutboundCall(selectedAgent, 'agent') }} />
                </div>
                ) : ''
            }
            {dailed ? <div className={classes.connect}><span>Connecting...</span><Button style={{ fontSize: '9px', background: 'gray' }} onClick={() => { setDialed(false); }}>Cancel</Button>  </div> : ''}
        </div>
    )
}