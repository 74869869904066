//@ts-nocheck
import React from 'react';
import Skeleton from '@mui/material/Skeleton';

// See more loading icons here:
// https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons
const PageLoader = () => (
    <div className="page-loader">
        <Skeleton variant="text" /><br />
        <Skeleton variant="rectangular" width={500} height={80} /><br />
        <Skeleton variant="rectangular" width={500} height={218} />
        <em className="fas fa-circle-notch fa-spin fa-2x text-muted"></em>
    </div>
)

export default PageLoader;