//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";
import { BusinessResponse } from "./administration.action";

const actionNamePrefix = "LOAD_BUSINESS";
export const LOAD_BUSINESS_START = `${actionNamePrefix}_START`;
export const LOAD_BUSINESS_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_BUSINESS_ERROR = `${actionNamePrefix}_ERROR`;

export interface LoadBusinessStart {
    type: typeof LOAD_BUSINESS_START;
}

export interface LoadBusinessSuccessAction {
    type: typeof LOAD_BUSINESS_SUCCESS;
    data: BusinessResponse
}

export interface LoadBusinessError {
    type: typeof LOAD_BUSINESS_ERROR;
    error: string;
}

export type BusinessAction =
    LoadBusinessStart |
    LoadBusinessSuccessAction |
    LoadBusinessError;

export const loadBusiness = () => async (dispatch: any) => {
    dispatch({ type: LOAD_BUSINESS_START });

    try {
        const response =
            await Axios.get<BusinessResponse>(`${AppSettings.ApiBaseUrl}/Business`);

        dispatch({
            type: LOAD_BUSINESS_SUCCESS,
            data: response.data[0]
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `[Error Code -${err.response.status}] Error: ${err.response.statusText}`;
            dispatch({
                type: LOAD_BUSINESS_ERROR,
                error: errorMessage
            })
        }
    }
}