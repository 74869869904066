//@ts-nocheck
import createReducer from "./createReducer";
import { 
    LOAD_MY_APPLIED_HOURS_ERROR,
    LOAD_MY_APPLIED_HOURS_START,
    LOAD_MY_APPLIED_HOURS_SUCCESS,
    myAppliedHoursActions,
    LoadMyAppliedHoursStartedAction,
    LoadMyAppliedHoursSuccessAction,
    LoadMyAppliedHoursErrorAction
} from '../actions/myAppliedHours.action'

export interface MyAppliedHoursState {
    state: string;
    error: string;
    data: [];
}

const initialState: MyAppliedHoursState = {
    state: null,
    error: null,
    data: []
}

function loadMyAppliedHrsStart(state:MyAppliedHoursState, action: LoadMyAppliedHoursStartedAction): MyAppliedHoursState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadMyAppliedHrsSuccess(state:MyAppliedHoursState, action: LoadMyAppliedHoursSuccessAction): MyAppliedHoursState {    
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadMyAppliedHrsError(state:MyAppliedHoursState, action: LoadMyAppliedHoursErrorAction): MyAppliedHoursState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const MyAppliedHoursReducer =
    (state: MyAppliedHoursState, action: myAppliedHoursActions): MyAppliedHoursState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_MY_APPLIED_HOURS_START]: loadMyAppliedHrsStart,
            [LOAD_MY_APPLIED_HOURS_SUCCESS]: loadMyAppliedHrsSuccess,
            [LOAD_MY_APPLIED_HOURS_ERROR]: loadMyAppliedHrsError
        });

        return fnUpdateState(state, action);
    }

export default MyAppliedHoursReducer;