//@ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { drawerWidth } from "./Dimensions";
import LeftNav from './LeftNav';

import Button from '@mui/material/Button';
import { useAuth0 } from "../auth0/react-auth0-wrapper";
import { Avatar, Menu, MenuItem, Box, Grid } from '@mui/material';
import InnerHeader from "./InnerHeader";
import PageLoader from './PageLoader';

import Notifications from '../MyWork/dashboard/Notifications'
import Messages from '../MyWork/dashboard/Messages'
import PhoneSidebarContainer from '../SoftPhone/PhoneSidebarContainer';
import { LOAD_BUSINESS_SUCCESS } from '../store/actions/loggedInBusiness.action';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: theme.spacing.unit * 7 + 1,
        //     [theme.breakpoints.up('sm')]: {
        //         marginLeft: theme.spacing.unit * 7 + 1,
        //     }        
    },
    innerHeader: {
        padding: 0
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        //marginLeft: 10,
        marginLeft: drawerWidth,
    },
    contentHeight: {
        // height:'700px',
        paddingRight: '25px'
    },
    mainContentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        height: '100%',
        marginLeft: 10
    },
    contentContainer: {
        width: '100%',
        display: 'flex'
    },
    avatarHeight: {
        height: '40px'
    },
    mainRightSideBar: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent:'end',
        // position: 'fixed',
        height:'100%',
        width:'48px'
    },
    placeHolderImg: {
        height:'35px',
        width:'135px',
        fontSize:'18px',
        lineHeight:'35px',
        color:'#fff'
    }
}));

export default function Base(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [userPicture, setUserPicture] = useState(null);
    const updatedProfileState = useSelector((state: AppState) => state.myProfile);    
    const loggedInBusiness = useSelector((state:AppState) => state.loggedInBusiness );
    const { loading, logout, user, userAccessToken, isAuthenticated } = useAuth0();
    const history = useHistory();

    useEffect(() => {
        if (user) {
            setUserPicture(user.picture);
        }
    }, [user]);

    useEffect(() => {
        if (updatedProfileState && updatedProfileState.data) {
            const { picture } = updatedProfileState.data;
            setUserPicture(picture);
        }
    }, [updatedProfileState]);    

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const myProfileClick = () => {
        history.push("/myProfile");
    }

    const logoutHandler = (event) => {
        logout();
        event.preventDefault();
    }

    const renderLogoPlaceholder = () => {
        return (
            <div className={classes.placeHolderImg}>Company Logo</div>
        )
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                        size="large">
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" className={classes.title}>
                        <Box display="flex" alignItems="center">
                            {
                               loggedInBusiness.data && Object.keys(loggedInBusiness.data).length > 0 && loggedInBusiness.data.logoUrl ? 
                                <img src={loggedInBusiness.data.logoUrl} height="35" /> : renderLogoPlaceholder()
                            }
                            {/* {AppSettings.Environment === "Prod" ? <img src="Norsou360Logo.png" style={{height:'50px', width:'100px'}} /> : <img src="NovelCXLogo.png" height="25px;" /> }&nbsp;&nbsp;                         */}
                        </Box>
                    </Typography>
                    {(
                        <div>
                            <Messages></Messages>
                            <Notifications></Notifications>
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                <Avatar> {userPicture ? <img className={classes.avatarHeight} src={userPicture} title='user-logo' /> : 'H'} </Avatar>
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}                                
                                keepMounted
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={myProfileClick}>My Profile</MenuItem>
                                <MenuItem>Version012723</MenuItem>
                                <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose} size="large">
                        {theme.direction ===    'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <LeftNav />
            </Drawer>
            <div className={classes.contentContainer}>
                <div style={{width:'98%'}} >
                <div className={clsx(classes.content, {
                            [classes.contentShift]: open,
                        }, classes.innerHeader)}>
                            <InnerHeader />
                            <div>
                                <main className={clsx(classes.content, classes.contentHeight)}>
                                    <div className={classes.mainContentShift}>
                                        {/* {!loading ? <PersistentDrawerRight children={props.children} /> : <PageLoader />} */}
                                        {!loading ? props.children : <PageLoader />}
                                    </div>
                                </main>
                            </div>
                        </div>
                </div>
                <div className={classes.mainRightSideBar}>
                   {userAccessToken && <PhoneSidebarContainer /> } 
                </div>
            </div>
        </div>
    );
}