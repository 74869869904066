import createReducer from "./createReducer";
import { 
    LOAD_DASHBOARD_PEOPLE_REPORT_START, 
    LOAD_DASHBOARD_PEOPLE_REPORT_SUCCESS, 
    LOAD_DASHBOARD_PEOPLE_REPORT_ERROR,
    dashboardPeopleReportActions,
    LoadDashboardPeopleReportStartAction,
    LoadDashboardPeopleReportSuccessAction,
    LoadDashboardPeopleReportErrorAction
} from "../actions/dashboardPeopleReport.action";

export interface DashboardPeopleReportState {
    state: string;
    error: string;
    data: any;
}

const initialState: DashboardPeopleReportState = {
    state: null,
    error: null,
    data: []
}

function loadDashboardPeopleReportStart(state:DashboardPeopleReportState, action: LoadDashboardPeopleReportStartAction): DashboardPeopleReportState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: null
    }
}

function loadDashboardPeopleReportSuccess(state:DashboardPeopleReportState, action: LoadDashboardPeopleReportSuccessAction): DashboardPeopleReportState {    
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function loadDashboardPeopleReportError(state:DashboardPeopleReportState, action: LoadDashboardPeopleReportErrorAction): DashboardPeopleReportState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}
const DashboardPeopleReportReducer =
    (state: DashboardPeopleReportState, action: dashboardPeopleReportActions): DashboardPeopleReportState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_DASHBOARD_PEOPLE_REPORT_START]: loadDashboardPeopleReportStart,
            [LOAD_DASHBOARD_PEOPLE_REPORT_SUCCESS]: loadDashboardPeopleReportSuccess,
            [LOAD_DASHBOARD_PEOPLE_REPORT_ERROR]: loadDashboardPeopleReportError,
           
        });

        return fnUpdateState(state, action);
    }

export default DashboardPeopleReportReducer;