import Axios from "axios";
import { Dispatch } from "redux";
import { AppSettings } from "../../../appSettings";
import { FETCH_RESERVATIONS } from "./actionTypes";
import { initReservationListeners } from "./listeners/initReservationListeners";
import { initWorkerListener } from "./listeners/initWorkerListeners";
import { WORKER_INIT_ERROR, WORKER_INIT_SUCCESS } from "./worker.action";

const getMappings = async () => {
    const response = await Axios.get(`${AppSettings.ApiTwilioUrl}/api/ScheduleTypeMapping`);
    return response.data
}

export const getWorkerToken = async () => {
    try {
        const response = await Axios.get(`${AppSettings.ApiTwilioUrl}/api/CapabilityToken`);
        if (response && response.data) {
            const WORKER_TOKEN = response.data.token;
            return WORKER_TOKEN;
        }
    } catch (e) {
        console.log(JSON.stringify(e));
        return null;
    }
    
}

const getOfflineMapping = (mappings) => {
    let activitySid;
    const offlineMapping = mappings && mappings.filter(x => x.scheduleTypeName === "Offline");
    if (offlineMapping) activitySid = offlineMapping[0].twilioActivitySid;
    return activitySid;
}

export const fetchReservation = async (worker, dispatch: any) => {
    let reservationsItems;
    await worker.fetchReservations(
        function(error, reservations) {
            if(error) {
                console.log(error.code);
                console.log(error.message);
                return;
            }            
            reservationsItems = reservations.data;  
            dispatch({
                type:FETCH_RESERVATIONS,
                reservations: reservationsItems
            })                               
        }
    );
}


export function initPhoneWorker() {
    return async (dispatch: Dispatch) => {
        const mappings = await getMappings();        
        let worker; 
        try {
            if (mappings) {
                const WORKER_TOKEN = await getWorkerToken();
                if (WORKER_TOKEN !== null) {
                    const offlineSid = getOfflineMapping(mappings);
                    //@ts-ignore 
                    worker = new Twilio.TaskRouter.Worker(WORKER_TOKEN, true, null, offlineSid, false, null, 5);                
                } else {
                    console.log("Is coming in Agent not registered");
                    dispatch({
                        type: WORKER_INIT_ERROR,
                        data: "Agent not registered."
                    })    
                    return;
                }                
            } else {
                //dispatch error that mappings not found.
                console.log("Is mappings not found?");
                dispatch({
                    type: WORKER_INIT_ERROR,
                    data: "Failed to intialize worker as now Schedule mappings found!"
                })
                return;
            }
        } catch (e) {
            console.log("catch error",JSON.stringify(e));
            dispatch({
                type: WORKER_INIT_ERROR,
                data: JSON.stringify(e)
            })
            return;
        }
        
        dispatch({
            type: WORKER_INIT_SUCCESS,
            data: {
                worker
            }
        });

        fetchReservation(worker, dispatch);
        initWorkerListener(worker, dispatch);
        initReservationListeners(worker, dispatch);
    }

}