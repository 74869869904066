import { ValueGetterParams } from "@ag-grid-community/core";
import React, { useState } from "react";
import { useEffect } from "react";

export default function CheckboxRenderer(params: ValueGetterParams) {

	let colId = params.column.getColId();
	let boolVal = false;
	const [isChecked, setIsChecked] = useState(boolVal);
	useEffect(() => {
		if (params.data[colId] && params.data[colId] === true) {
			setIsChecked(true);
		} else {
			setIsChecked(false);
		}
	},[params.data[colId]])	

	const checkedHandler = (e) => {
		if (params.node.groupData) return (<div></div>);
		let checked = e.target.checked;
		setIsChecked(checked);
		params.node.setDataValue(colId, checked);
	}


	return (
		<input
			type="checkbox"
			onClick={checkedHandler}
			onChange={checkedHandler}
			checked={isChecked}
		/>
	);

}
