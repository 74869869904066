import React, { useEffect, useRef, useState } from 'react';
import { Slider, Theme, Typography, Button, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { isEmptyArray } from "formik";
import useLoudness from '../hooks/useLoudness';
import { ReactComponent as Mute } from './Icons/MuteLevel.svg';
import { ReactComponent as NoMute } from './Icons/NoMuteLevel.svg';

interface AudioProps {
    device: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuContainer: {
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        title: {
            color: '#fff',
            paddingLeft: '10px'
        },
        selectBox: {
            height: '20px',
            width: '250px',
            background: '#373F51',
            color: '#fff'
        },
        btnCls: {
            borderRadius: '20px',
            fontSize: '10px',
            padding: '8px'
        },
        inputDevices: {
            display: 'flex',
            width: '250px',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        iconBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '50%',
            padding: '0 10px 0 10px',
            marginLeft: '30px'
        },
        textStyle: {
            color: '#ffffff',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            alignItems: 'center'
        },
        itemContainer: {
            display: 'flex',
            flexDirection: 'row',
            height: '30px',
            padding: '25px',
            alignItems: 'center',
            cursor: 'pointer',

        },
        textContent: {
            marginLeft: '10px',
            display: 'flex',
            flexDirection: 'column'
        },
        headerText: {
            fontSize: '15px',
            color: '#fff'
        },
        smallDesc: {
            fontSize: '12px',
            color: '#A6A6A6'
        },
        iconContent: {
            padding: '10px'
        },
        icons: {
            color: "#F8F8F8",
            height: '20px'
        }
    })
);




function AudioDevices(props: AudioProps) {
    const classes = useStyles();
    const [inputDevice, setInputDevice] = useState(null)
    const [running, setRunning, loudness] = useLoudness();
    const [inputVal, setInputVal] = useState(0);
    const [outputVal, setOutputVal] = useState(0);
    const [microphone, setMicrophone] = useState(null);
    const speakerDevices = useRef(null);
    const micDevices = useRef(null);
    const [value, setValue] = React.useState(30);
    const [microvalue, setMicrovalue] = React.useState(30);
    const [toggle, setToggle] = useState(true);

    console.log(running);
    console.log(loudness);

    useEffect(() => {
        renderDevices("input");
        renderDevices("output");
    }, [])

    navigator.mediaDevices.ondevicechange = (e) => {
        //do your thing
        renderDevices("input");
        renderDevices("output");
    }

    const testMicrophone = () => {
        setToggle(!toggle);
        if (!toggle) {
            console.log("STOP")
            stopMicrophone()
        } else {
            console.log("STaRT")
            volumeMeter();
        }

    }

    //Display prompt and log selected device or error
    const getSelectedDevice = async () => {

        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
            return;
        }

        let stream = null
        let devicesList = [];
        await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(s => {
                stream = s
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    devicesList = devices;
                })
            })
            .catch(error => {
                console.log('Error :', error)
            })

        if (stream && stream.id) {
            const devices = await navigator.mediaDevices.enumerateDevices();
            console.log("HeadSet",devices);
            const audioInputs = devices.filter((d) => d.kind === "audioinput");
            const audioOutputs = devices.filter((d) => d.kind === "audiooutput");
            setInputDevice(audioInputs);
            return {
                inputDevices: audioInputs,
                outputDevices: audioOutputs
            };
        } else {
            console.log("No stream");
            return;
        }

    }
    const volumeMeter = () => {
        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        })
            .then(function (stream) {
                const audioContext = new AudioContext();
                const analyser = audioContext.createAnalyser();
                const microphone = audioContext.createMediaStreamSource(stream);
                const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
                setMicrophone(microphone);
                analyser.smoothingTimeConstant = 0.8;
                analyser.fftSize = 1024;

                microphone.connect(analyser);
                analyser.connect(scriptProcessor);
                scriptProcessor.connect(audioContext.destination);
                scriptProcessor.onaudioprocess = function () {
                    const array = new Uint8Array(analyser.frequencyBinCount);
                    analyser.getByteFrequencyData(array);
                    const arraySum = array.reduce((a, value) => a + value, 0);
                    const average = arraySum / array.length;
                    setOutputVal(average);
                    console.log(Math.round(average));
                    // colorPids(average);
                };
            })
            .catch(function (err) {
                /* handle the error */
                console.error(err);
            });
    }

    const stopMicrophone = () => {
        console.log(microphone);
        microphone.disconnect();
    }

    const handleSpeakerDevices = () => {
        var selectedDeviceIds = [].slice.call(speakerDevices.current.childNodes)
            .filter(function (node) { return node.selected; })
            .map(function (node) { return node.getAttribute('data-id'); });

        props.device.audio.speakerDevices.set(selectedDeviceIds);
    }

    const handleDeviceChange = (e) => {
        alert('inside')
        console.log(e);

        var selectedDevices = [].slice
            .call(micDevices.current.children)
            .filter(function (node) {
                console.log(node.selected);
                return node.selected;
            })
            .map(function (node) {
                return node.getAttribute('data-id');
            });
        console.log(selectedDevices);
        console.log(props.device);
        //setInputDevice(e.traget.value) 
        //props.device && props.device
        props.device && props.device.audio.setInputDevice(selectedDevices[0]).then(() => {
            console.info('Success!');
        });
        //setInputDevice
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleMicChange = (event, newValue) => {
        setMicrovalue(newValue);
    };

    const renderTrial = (devices, type) => {
        let devicesElement;
        if (type === "input") devicesElement = micDevices.current;
        if (type === "output") devicesElement = speakerDevices.current;
        if (devicesElement !== null) {
            //@ts-ignore
            devicesElement.innerHTML = "";
            return devices.forEach(function (device) {
                var option = document.createElement('option');
                option.label = device.label;
                option.setAttribute('data-id', device.deviceId);
                //@ts-ignore
                devicesElement.appendChild(option);
            });
        }
    }

    const renderDevices = async (type) => {
        const devices = await getSelectedDevice();
        let availableDevices;
        if (type === "input") availableDevices = devices.inputDevices;
        if (type === "output") availableDevices = devices.outputDevices;

        const isDevices = isEmptyArray(availableDevices) || availableDevices === null;
        return (
            <div className={clsx(classes.iconBar, classes.textStyle)} style={{ display: 'flex', marginLeft: '30px' }}>
                {isDevices ? "No devices found" : renderTrial(availableDevices, type)}
            </div>
        )
    }
    console.log("Output value", outputVal);
    return (
        <div style={{minHeight: '300px', maxHeight:'350px', overflowY:'auto'}}>
            <div>
                <Typography variant="body1" className={classes.title}>Speaker</Typography>
                <div>
                    <div className={classes.menuContainer}>
                        <Button variant="contained" color="primary" className={classes.btnCls} onClick={testMicrophone}>Test Speaker</Button>
                        <select ref={speakerDevices} className={classes.selectBox} onChange={handleSpeakerDevices}></select>
                    </div>
                    <div className={classes.menuContainer}>
                        <span style={{ color: '#fff' }}>Input Level:</span>
                        <div className={classes.inputDevices}>
                            <Mute /><LinearProgress variant="determinate" value={inputVal} style={{ width: '200px' }} /><NoMute />
                        </div>
                    </div>
                    <div className={classes.menuContainer}>
                        <span style={{ color: '#fff' }}>Volume:</span>
                        <div className={classes.inputDevices}>
                            <Mute />
                            <Slider value={value} onChange={handleChange} color="secondary" aria-labelledby="continuous-slider" />
                            <NoMute />
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <Typography variant="body1" className={classes.title}>Microphone</Typography>
                <div>
                    <div className={classes.menuContainer}>
                        <Button variant="contained" color="primary" className={classes.btnCls} onClick={testMicrophone}>Test Mic</Button>
                        <select ref={micDevices} className={classes.selectBox} onChange={handleDeviceChange}></select>
                    </div>
                    <div className={classes.menuContainer}>
                        <span style={{ color: '#fff' }}>Output Level:</span>
                        <div className={classes.inputDevices}>
                            <Mute /><LinearProgress variant="determinate" value={outputVal} style={{ width: '200px' }} /><NoMute />
                        </div>
                    </div>
                    <div className={classes.menuContainer}>
                        <span style={{ color: '#fff' }}>Volume:</span>
                        <div className={classes.inputDevices}>
                            <Mute />
                            <Slider value={microvalue} onChange={handleMicChange} color="secondary" aria-labelledby="continuous-slider" />
                            <NoMute />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MemoAudioDevices = React.memo(AudioDevices);
export default MemoAudioDevices;