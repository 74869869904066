import { Tooltip } from '@mui/material';
import Axios from 'axios';
import React,{useState} from 'react'
import PhoneInput from 'react-phone-input-2';
import { AppSettings } from '../appSettings';
import AgentCallList from './AgentCallList';
import Dailer from './Dailer';
import { ReactComponent as UserAvatar } from './Icons/user_avatar.svg';
import  './DialerControl.css'

interface IDailerControlProps {
    handleCall?:(val, type) => void;
    setNumber?:(val) => void;
    number?: any,
    callState?: string,
    type: string,
    setAgent?: (val) => void;
    setAgentName?:(val) => void;
}

export default function DailerControl(props: IDailerControlProps) {
    const [dialType, setDialType] = useState('keypad');
    const [agentsList, setAgentsList] = useState(null);

    const getSelectedAgent = (agent) => {
        if (props.type === 'conference')
            props.setAgent(agent.contactUri);
        if(dialType === 'agents') props.setAgentName(agent.name)
    }

    const handleDailType = (type) => {
        setDialType(type);
        if (type === 'agents') {
            (async function () {
                try {
                    const response = await Axios.get(`${AppSettings.ApiTwilioUrl}/AgentConfigure/Online`);
                    if (response.data) {
                        console.log("Agents in my business", response.data);
                        setAgentsList(response.data);
                    }
                } catch (e) {
                    const errorMsg = e;
                    console.log(e);
                }
            })();
        }        
    }


    return (
        <div style={{ display: "flex", alignItems: "baseline", paddingLeft:'10px', justifyContent:'center' }}>
            <div style={{ display: "flex" }}>
                <Tooltip title="Dial an agent">
                    <UserAvatar style={{ height: '17px', cursor: 'pointer' }} onClick={() => handleDailType('agents')} />
                </Tooltip>
                <Tooltip title="Dial a number">
                    <span style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleDailType('keypad')}>#</span>
                </Tooltip>
            </div>

            {dialType === 'keypad' && props.type === 'outbound' && <Dailer number={props.number} handlePress={props.setNumber} handleOutboundCall={props.handleCall} callState={props.callState} />}
            {dialType === 'keypad' && props.type === 'conference' && <PhoneInput containerClass='react-tel-input' country={'us'} onlyCountries={['us']} value={props.number} onChange={props.setNumber} />}
            {dialType === 'agents' && <AgentCallList handleOutboundCall={props.handleCall} callState={props.callState} handleDailType={handleDailType} agentsList={agentsList} phoneType={props.type} setAgent={getSelectedAgent} /> }
            
        </div>
    )
}