//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefixProject = "SHOW_MODAL_PROJECT";
export const SHOW_MODAL_PROJECT_OPEN = `${actionNamePrefixProject}_OPEN`;
export const SHOW_MODAL_PROJECT_CLOSE = `${actionNamePrefixProject}_CLOSE`;

const actionNamePrefixCampaign = "SHOW_MODAL_CAMPAIGN";
export const SHOW_MODAL_CAMPAIGN_OPEN = `${actionNamePrefixCampaign}_OPEN`;
export const SHOW_MODAL_CAMPAIGN_CLOSE = `${actionNamePrefixCampaign}_CLOSE`;

const actionNamePrefix = "LOAD_PROJECTS_CAMPAIGNS";
export const LOAD_PROJECTS_CAMPAIGNS_START = `${actionNamePrefix}_START`;
export const LOAD_PROJECTS_CAMPAIGNS_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_PROJECTS_CAMPAIGNS_ERROR = `${actionNamePrefix}_ERROR`;

const actionNamePrefixProjectSave = "SAVE_PROJECTS";
export const SAVE_PROJECTS_START = `${actionNamePrefixProjectSave}_START`;
export const SAVE_PROJECTS_SUCCESS = `${actionNamePrefixProjectSave}_SUCCESS`;
export const SAVE_PROJECTS_ERROR = `${actionNamePrefixProjectSave}_ERROR`;

const actionNamePrefixProjectUpdate = "UPDATE_PROJECTS";
export const UPDATE_PROJECTS_START = `${actionNamePrefixProjectUpdate}_START`;
export const UPDATE_PROJECTS_SUCCESS = `${actionNamePrefixProjectUpdate}_SUCCESS`;
export const UPDATE_PROJECTS_ERROR = `${actionNamePrefixProjectUpdate}_ERROR`;

const actionNamePrefixCampaignSave = "SAVE_CAMPAIGNS";
export const SAVE_CAMPAIGNS_START = `${actionNamePrefixCampaignSave}_START`;
export const SAVE_CAMPAIGNS_SUCCESS = `${actionNamePrefixCampaignSave}_SUCCESS`;
export const SAVE_CAMPAIGNS_ERROR = `${actionNamePrefixCampaignSave}_ERROR`;

const actionNamePrefixCampaignUpdate = "UPDATE_CAMPAIGNS";
export const UPDATE_CAMPAIGNS_START = `${actionNamePrefixCampaignUpdate}_START`;
export const UPDATE_CAMPAIGNS_SUCCESS = `${actionNamePrefixCampaignUpdate}_SUCCESS`;
export const UPDATE_CAMPAIGNS_ERROR = `${actionNamePrefixCampaignUpdate}_ERROR`;

export interface ICampaign {
        campaignId: number,
		campaignName: string,
		alias: string,
		startDate: string,
		type: string,
        baseLinePerMin: any,
        hoursOfOperation: any,
		projectId: number,
        workDescription: string,
        campaignRosterCount: number,
        campaignAssignmentCount: number,
        active: boolean,
        accountId: string,
        sid:string,
        channelId: string
        subTypes: []
}

export interface IProjectCampaign {
    projectId: number,
    projectName: string,
    alias: string,
    minimumRatePerMinute: any,
    hoursOfOperation: any,
    projectRosterCount: number,
    projectAssignmentCount: number,
    campaigns: Array<ICampaign>,
    active: boolean,
    colorCode: string,
    description: string
}

export interface BusinessResponse {
    id:number,
    legalName:string,
    websiteUrl:string,
    logoUrl:string,
    ratings:number,
    projects: Array<IProjectCampaign>
    campaignCount: number,
    rosterCount: number
}

export interface ISaveProject {
    projectName: string
}

export interface ISaveCampaign {
    name: string,
    projectId: number
}

export interface ShowModalProjectAction {
    type: typeof SHOW_MODAL_PROJECT
}

export interface ShowModalCampaignAction {
    type: typeof SHOW_MODAL_CAMPAIGN
}

export interface LoadProjectCampaignAction {
    type: typeof LOAD_PROJECTS_CAMPAIGNS_START
}

export interface LoadProjectCampaignSuccess {
    type: typeof LOAD_PROJECTS_CAMPAIGNS_SUCCESS;
    projectCampaigns: BusinessResponse
}

export interface LoadProjectCampaignError {
    type: typeof LOAD_PROJECTS_CAMPAIGNS_ERROR;
    message: string;
}

export interface SaveProjectAction {
    type: typeof SAVE_PROJECTS_START
}

export interface SaveProjectSuccessAction {
    type: typeof SAVE_PROJECTS_SUCCESS;
    project: IProjectCampaign,
    message: string
}

export interface SaveProjectError {
    type: typeof SAVE_PROJECTS_ERROR;
    error: string;
}

export interface UpdateProjectAction {
    type: typeof UPDATE_PROJECTS_START
}

export interface UpdateProjectSuccessAction {
    type: typeof UPDATE_PROJECTS_SUCCESS;
    project: ISaveProject,
    message: string
}

export interface UpdateProjectError {
    type: typeof UPDATE_PROJECTS_ERROR;
    error: string;
}

export interface SaveCampaignAction {
    type: typeof SAVE_CAMPAIGNS_START
}

export interface SaveCampaignSuccessAction {
    type: typeof SAVE_CAMPAIGNS_SUCCESS;
    campaign: ICampaign,
    message: string
}

export interface SaveCampaignError {
    type: typeof SAVE_CAMPAIGNS_ERROR;
    error: string;
}

export interface UpdateCampaignAction {
    type: typeof UPDATE_CAMPAIGNS_START
}

export interface UpdateCampaignSuccessAction {
    type: typeof UPDATE_CAMPAIGNS_SUCCESS
    message: string,
    campaign: ISaveCampaign
}

export interface UpdateCampaignError {
    type: typeof UPDATE_CAMPAIGNS_ERROR;
    error: string;
}

export type ProjectCampaignActions =
    LoadProjectCampaignAction |
    LoadProjectCampaignSuccess |
    LoadProjectCampaignError |
    SaveProjectAction |
    SaveProjectSuccessAction | 
    SaveProjectError |
    UpdateProjectAction |
    UpdateProjectSuccessAction |
    UpdateProjectError |
    SaveCampaignAction |
    SaveCampaignSuccessAction |
    SaveCampaignError |
    UpdateCampaignAction |
    UpdateCampaignSuccessAction |
    UpdateCampaignError;

export const showModalProject = () => async (dispatch) => {
    dispatch({type: SHOW_MODAL_PROJECT_OPEN});
}

export const showModalCampaign = () => async (dispatch) => {
    dispatch({type: SHOW_MODAL_CAMPAIGN_OPEN});
}

export const closeModalProject = () => async (dispatch) => {
    dispatch({type: SHOW_MODAL_PROJECT_CLOSE});
}

export const closeModalCampaign = () => async (dispatch) => {
    dispatch({type: SHOW_MODAL_CAMPAIGN_CLOSE});
}


export const getProjectsAndCampaigns = () => async (dispatch) => {
    dispatch({ type: LOAD_PROJECTS_CAMPAIGNS_START });

    try {
        const response = await 
        Axios.get<BusinessResponse>(`${AppSettings.ApiBaseUrl}/Business`);
        dispatch({
            type: LOAD_PROJECTS_CAMPAIGNS_SUCCESS,
            projectCampaigns: response.data[0]
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_PROJECTS_CAMPAIGNS_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveProject = (saveProject: ISaveProject) => async (dispatch: any) => {
    dispatch({ type: SAVE_PROJECTS_START });

    try {
        const response = await Axios.post(`${AppSettings.ApiBaseUrl}/Projects`, saveProject);
        dispatch({
            type: SAVE_PROJECTS_SUCCESS,
            project: response.data
        });
    } catch (err) {
        if (err && err.response) {
            let errors = "";
            if (err.response.data.errors && err.response.data.errors !== null) {
                errors = JSON.stringify(err.response.data.errors);
            } else {
                errors = err.response.data.Message;
            }
            const errorMessage = `Error: ${errors}`;
            dispatch({
                type: SAVE_PROJECTS_ERROR,
                error: errorMessage
            })
        }
    }
}

export const updateProject = (updatedProject: ISaveProject) => async (dispatch: any) => {
    dispatch({ type: UPDATE_PROJECTS_START });

    try {
        await Axios.put(`${AppSettings.ApiBaseUrl}/Projects/Update`, updatedProject);
        dispatch({
            type: UPDATE_PROJECTS_SUCCESS,
            project: updatedProject
        });
    } catch (err) {
        if (err && err.response) {
            let errors = "";
            if (err.response.data.errors && err.response.data.errors !== null) {
                errors = JSON.stringify(err.response.data.errors);
            } else {
                errors = err.response.data.Message;
            }
            
            const errorMessage = `Error: ${errors}`;
            dispatch({
                type: UPDATE_PROJECTS_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveCampaign = (updatedValue: ISaveCampaign) => async (dispatch: any) => {
    dispatch({ type: SAVE_CAMPAIGNS_START });

    try {
        const response = await Axios.post(`${AppSettings.ApiBaseUrl}/Campaigns`, updatedValue);
        dispatch({
            type: SAVE_CAMPAIGNS_SUCCESS,
            campaign: response.data
        });
    } catch (err) {
        if (err && err.response) {
            let errors = "";
            if (err.response.data.errors && err.response.data.errors !== null) {
                errors = JSON.stringify(err.response.data.errors);
            } else {
                errors = err.response.data.Message;
            }
            const errorMessage = `Error: ${errors}`;
            dispatch({
                type: SAVE_CAMPAIGNS_ERROR,
                error: errorMessage
            })
        }
    }
}

export const updateCampaign = (updatedCampaign: ISaveCampaign) => async (dispatch: any) => {
    dispatch({ type: UPDATE_CAMPAIGNS_START });

    try {
       await Axios.put(`${AppSettings.ApiBaseUrl}/Campaigns/Update`, updatedCampaign);
        dispatch({
            type: UPDATE_CAMPAIGNS_SUCCESS,
            campaign: updatedCampaign
        });
    } catch (err) {
        if (err && err.response) {
            let errors = "";
            if (err.response.data.errors && err.response.data.errors !== null) {
                errors = JSON.stringify(err.response.data.errors);
            } else {
                errors = err.response.data.Message;
            }
            const errorMessage = `Error: ${errors}`;
            dispatch({
                type: UPDATE_CAMPAIGNS_ERROR,
                error: errorMessage
            })
        }
    }
}