//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "LOAD_PROJECT_INVENTORY_FORECAST";
export const LOAD_PROJECT_INVENTORY_FORECAST_START = `${actionNamePrefix}_START`;
export const LOAD_PROJECT_INVENTORY_FORECAST_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_PROJECT_INVENTORY_FORECAST_ERROR = `${actionNamePrefix}_ERROR`;
export const CLEANUP_PROJECT_INVENTORY_FORECAST = `CLEANUP_PROJECT_INVENTORY_FORECAST`;

function loadStart() {
    return {
        type: LOAD_PROJECT_INVENTORY_FORECAST_START
    };
}

function loadSuccess(forecast) {
    return {
        type: LOAD_PROJECT_INVENTORY_FORECAST_SUCCESS,
        data: forecast
    };
}

function loadError(error) {
    return {
        type: LOAD_PROJECT_INVENTORY_FORECAST_ERROR,
        error
    }
}

function cleanUpReport() {
    return {
        type: CLEANUP_PROJECT_INVENTORY_FORECAST
    }   
}

export const getProjectInventoryForecast = (projectForecastRequest, campaignType) => async (dispatch) => {
    dispatch(loadStart);
    try {        
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/project/${projectForecastRequest.projectId}/fromDateTime/${projectForecastRequest.fromDateTime}/toDateTime/${projectForecastRequest.toDateTime}/ProjectInventoryForecast?campaign-type=${campaignType}`);
        dispatch(loadSuccess(response.data));
    }
    catch (e) {
        dispatch(loadError(e.response.data.Message || e.message));
    }
}

export const cleanUpReportData = () => (dispatch) => {
    dispatch(cleanUpReport());
}

const dummyShedules = async (request) => {
    const data = [{ "id": 414, "intakeBacklog": 0.0, "intakeNew": 30.0, "caseWorkTimeInSeconds": 600, "targetOccupancy": 85.0, "shrinkage": 15.0, "staffRequired": 13.8, "staffed": 1.0, "staffRequiredOverridden": true, "workloadCapacity": 25.5, "maxOutput": 2.2, "expectedOccupancy": null, "inventoryForecastId": 414, "actualWorkComplete": null, "importedActualOutput": false, "inventoryForecast": { "id": 414, "campaignId": 2, "intakeBacklog": 0.0, "intakeNew": 30.0, "caseWorkTimeInSeconds": 600, "slotStartTimeInUtc": "2020-04-01T04:00:00", "slotEndTimeInUtc": "2020-04-01T04:30:00", "slotStartTimeInTimezone": "12:00 AM", "slotEndTimeInTimezone": "12:30 AM", "date": "01-04-2020", "timezone": "(UTC-05:00) Indiana (East)", "sortKey": 202004010000 } },
    { "id": 432, "intakeBacklog": 27.8, "intakeNew": 2.0, "caseWorkTimeInSeconds": 600, "targetOccupancy": 85.0, "shrinkage": 15.0, "staffRequired": 13.7, "staffed": 1.0, "staffRequiredOverridden": true, "workloadCapacity": 25.5, "maxOutput": 2.2, "expectedOccupancy": null, "inventoryForecastId": 432, "actualWorkComplete": null, "importedActualOutput": false, "inventoryForecast": { "id": 432, "campaignId": 2, "intakeBacklog": 68.0, "intakeNew": 2.0, "caseWorkTimeInSeconds": 600, "slotStartTimeInUtc": "2020-04-01T04:30:00", "slotEndTimeInUtc": "2020-04-01T05:00:00", "slotStartTimeInTimezone": "12:30 AM", "slotEndTimeInTimezone": "01:00 AM", "date": "01-04-2020", "timezone": "(UTC-05:00) Indiana (East)", "sortKey": 202004010030 } },
    { "id": 431, "intakeBacklog": 27.6, "intakeNew": 1.0, "caseWorkTimeInSeconds": 600, "targetOccupancy": 85.0, "shrinkage": 15.0, "staffRequired": 13.2, "staffed": 2.0, "staffRequiredOverridden": true, "workloadCapacity": 51.0, "maxOutput": 4.3, "expectedOccupancy": null, "inventoryForecastId": 431, "actualWorkComplete": null, "importedActualOutput": false, "inventoryForecast": { "id": 431, "campaignId": 2, "intakeBacklog": 68.0, "intakeNew": 1.0, "caseWorkTimeInSeconds": 600, "slotStartTimeInUtc": "2020-04-01T05:00:00", "slotEndTimeInUtc": "2020-04-01T05:30:00", "slotStartTimeInTimezone": "01:00 AM", "slotEndTimeInTimezone": "01:30 AM", "date": "01-04-2020", "timezone": "(UTC-05:00) Indiana (East)", "sortKey": 202004010100 } },
    { "id": 430, "intakeBacklog": 24.3, "intakeNew": 1.0, "caseWorkTimeInSeconds": 600, "targetOccupancy": 85.0, "shrinkage": 15.0, "staffRequired": 11.7, "staffed": null, "staffRequiredOverridden": true, "workloadCapacity": 0.0, "maxOutput": 0.0, "expectedOccupancy": null, "inventoryForecastId": 430, "actualWorkComplete": null, "importedActualOutput": false, "inventoryForecast": { "id": 430, "campaignId": 2, "intakeBacklog": 69.0, "intakeNew": 1.0, "caseWorkTimeInSeconds": 600, "slotStartTimeInUtc": "2020-04-01T05:30:00", "slotEndTimeInUtc": "2020-04-01T06:00:00", "slotStartTimeInTimezone": "01:30 AM", "slotEndTimeInTimezone": "02:00 AM", "date": "01-04-2020", "timezone": "(UTC-05:00) Indiana (East)", "sortKey": 202004010130 } },
    { "id": 496, "intakeBacklog": 284.3, "intakeNew": 2.0, "caseWorkTimeInSeconds": 600, "targetOccupancy": null, "shrinkage": null, "staffRequired": 0.0, "staffed": null, "staffRequiredOverridden": true, "workloadCapacity": 0.0, "maxOutput": 0.0, "expectedOccupancy": null, "inventoryForecastId": 496, "actualWorkComplete": null, "importedActualOutput": false, "inventoryForecast": { "id": 496, "campaignId": 2, "intakeBacklog": 12.0, "intakeNew": 2.0, "caseWorkTimeInSeconds": 600, "slotStartTimeInUtc": "2020-04-03T03:00:00", "slotEndTimeInUtc": "2020-04-03T03:30:00", "slotStartTimeInTimezone": "11:00 PM", "slotEndTimeInTimezone": "11:30 PM", "date": "02-04-2020", "timezone": "(UTC-05:00) Indiana (East)", "sortKey": 202004022300 } }, { "id": 495, "intakeBacklog": 286.3, "intakeNew": 5.0, "caseWorkTimeInSeconds": 600, "targetOccupancy": null, "shrinkage": null, "staffRequired": 0.0, "staffed": null, "staffRequiredOverridden": true, "workloadCapacity": 0.0, "maxOutput": 0.0, "expectedOccupancy": null, "inventoryForecastId": 495, "actualWorkComplete": null, "importedActualOutput": false, "inventoryForecast": { "id": 495, "campaignId": 2, "intakeBacklog": 15.0, "intakeNew": 5.0, "caseWorkTimeInSeconds": 600, "slotStartTimeInUtc": "2020-04-03T03:30:00", "slotEndTimeInUtc": "2020-04-03T04:00:00", "slotStartTimeInTimezone": "11:30 PM", "slotEndTimeInTimezone": "12:00 AM", "date": "02-04-2020", "timezone": "(UTC-05:00) Indiana (East)", "sortKey": 202004022330 } }];

    return data;
  }