//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "LOAD_TARGETS_TYPES";
export const LOAD_TARGETS_TYPES_START = `${actionNamePrefix}_START`;
export const LOAD_TARGETS_TYPES_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_TARGETS_TYPES_ERROR = `${actionNamePrefix}_ERROR`;

const actionNamePrefixSave = "SAVE_TARGETS_TYPES";
export const SAVE_TARGETS_TYPES_START = `${actionNamePrefixSave}_START`;
export const SAVE_TARGETS_TYPES_SUCCESS = `${actionNamePrefixSave}_SUCCESS`;
export const SAVE_TARGETS_TYPES_ERROR = `${actionNamePrefixSave}_ERROR`;

export interface LoadTargetsTypesStart {
    type: typeof LOAD_TARGETS_TYPES_START
}

export interface LoadTargetsTypesSuccess {
    type: typeof LOAD_TARGETS_TYPES_SUCCESS;
    data: Array<any>
}

export interface LoadTargetsTypesError {
    type: typeof LOAD_TARGETS_TYPES_ERROR;
    message: string;
}

export interface SaveTargetsTypesStart {
    type: typeof SAVE_TARGETS_TYPES_START
}

export interface SaveTargetsTypesSuccess {
    type: typeof SAVE_TARGETS_TYPES_SUCCESS;
    data: any
}

export interface SaveTargetsTypesError {
    type: typeof SAVE_TARGETS_TYPES_ERROR;
    message: string;
}

export type targetsTypeActions =
    LoadTargetsTypesStart |
    LoadTargetsTypesSuccess |
    LoadTargetsTypesError |
    SaveTargetsTypesStart |
    SaveTargetsTypesSuccess |
    SaveTargetsTypesError ;

export const getTargets = () => async (dispatch) => {
    dispatch({ type: LOAD_TARGETS_TYPES_START });
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/api/Targets`);
        dispatch({
            type: LOAD_TARGETS_TYPES_SUCCESS,
            data: response.data
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_TARGETS_TYPES_ERROR,
                error: errorMessage
            })
        }
    }
}

export const saveTargets = (saveTargetsType) => async (dispatch) => {
    dispatch({ type: SAVE_TARGETS_TYPES_START });

    try {
        const response = await Axios.put(`${AppSettings.ApiBaseUrl}/api/Targets`, saveTargetsType);
        dispatch({
            type: SAVE_TARGETS_TYPES_SUCCESS,
            data: saveTargetsType
        });
    } catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: SAVE_TARGETS_TYPES_ERROR,
                error: errorMessage
            })
        }
    }
}

