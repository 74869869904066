import {
    AttributesAction,
    LoadAttributesAction,
    LoadAttributesError,
    GetAttributesResponse,
    LoadAttributesSuccessAction,
    LOAD_ATTRIBUTES,
    LOAD_ATTRIBUTES_ERROR,
    LOAD_ATTRIBUTES_SUCCESS, 
    SAVE_ATTRIBUTES,
    SAVE_ATTRIBUTES_SUCCESS,
    SAVE_ATTRIBUTES_ERROR,
    SaveAttributesAction,
    SaveAttributesSuccessAction,
    SaveAttributesError
} from "../actions/attributes.action";
import createReducer from "./createReducer";

export interface AttributesState {
    state: string;
    error: string;
    attributes: Array<GetAttributesResponse>;
}

const initialState: AttributesState = {
    state: null,
    error: null,
    attributes: []
}

function loadAttributes(state:AttributesState, action: LoadAttributesAction): AttributesState {
    return {
        ...state,
        state: action.type,
        error: null,
        attributes: []
    }
}

function loadAttributesSuccess(state:AttributesState, action: LoadAttributesSuccessAction): AttributesState {
    return {
        ...state,
        state: action.type,
        error: null,
        attributes: action.attributes
    }
}

function loadAttributesError(state:AttributesState, action: LoadAttributesError): AttributesState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        attributes: []
    }
}

function saveAttributes(state:AttributesState, action: SaveAttributesAction): AttributesState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function saveAttributesSuccess(state:AttributesState, action: SaveAttributesSuccessAction): AttributesState {
    return {
        ...state,
        state: action.type,
        error: null
    }
}

function saveAttributesError(state:AttributesState, action: SaveAttributesError): AttributesState {
    return {
        ...state,
        state: action.type,
        error: action.error
    }
}

const AttributesReducer =
    (state: AttributesState, action: AttributesAction): AttributesState => {

        const fnUpdateState = createReducer(initialState, {
            [LOAD_ATTRIBUTES]: loadAttributes,
            [LOAD_ATTRIBUTES_SUCCESS]: loadAttributesSuccess,
            [LOAD_ATTRIBUTES_ERROR]: loadAttributesError,
            [SAVE_ATTRIBUTES]: saveAttributes,
            [SAVE_ATTRIBUTES_SUCCESS]: saveAttributesSuccess,
            [SAVE_ATTRIBUTES_ERROR]: saveAttributesError
        });

        return fnUpdateState(state, action);
    }

export default AttributesReducer;
