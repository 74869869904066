//@ts-nocheck
import Axios from "axios";
import { AppSettings } from "../../appSettings";

const actionNamePrefix = "RECALCULATE_STAFFED_COUNT";
export const RECALCULATE_STAFFED_COUNT_START = `${actionNamePrefix}_START`;
export const RECALCULATE_STAFFED_COUNT_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const RECALCULATE_STAFFED_COUNT_ERROR = `${actionNamePrefix}_ERROR`;
export const CLEANUP_RECALCULATE_STAFFED_COUNT = `${actionNamePrefix}_CLEANUP`;

const actionNameEqualPrefix = "RECALCULATE_EQUAL_WEIGHTAGE";
export const RECALCULATE_EQUAL_WEIGHTAGE_START = `${actionNameEqualPrefix}_START`;
export const RECALCULATE_EQUAL_WEIGHTAGE_SUCCESS = `${actionNameEqualPrefix}_SUCCESS`;
export const RECALCULATE_EQUAL_WEIGHTAGE_ERROR = `${actionNameEqualPrefix}_ERROR`;
export const CLEANUP_RECALCULATE_EQUAL_WEIGHTAGE = `${actionNameEqualPrefix}_CLEANUP`;

export interface EqualWeightageRequestParams {
    calcStartDateTimeInUtc: string,
    calcEndDateTimeInUtc: string
}

function start(campaignId: number) {
    return {
        type: RECALCULATE_STAFFED_COUNT_START,
        campaignId
    };
}

function success(campaignId: number, jobId: string) {
    return {
        type: RECALCULATE_STAFFED_COUNT_SUCCESS,
        campaignId,
        jobId
    };
}

function error(campaignId: number, error) {
    return {
        type: RECALCULATE_STAFFED_COUNT_ERROR,
        error,
        campaignId
    }
}

function cleanUpCount() {
    return {
        type: CLEANUP_RECALCULATE_STAFFED_COUNT
    }
}

function cleanUpEqualCount() {
    return {
        type: CLEANUP_RECALCULATE_EQUAL_WEIGHTAGE
    }
}

function equalWeightageStart(projectId: number) {
    return {
        type: RECALCULATE_EQUAL_WEIGHTAGE_START,
        projectId
    };
}

function equalWeightageSuccess(projectId: number, jobId: string) {
    return {
        type: RECALCULATE_EQUAL_WEIGHTAGE_SUCCESS,
        projectId,
        jobId
    };
}

function equalWeightageError(projectId: number, error) {
    return {
        type: RECALCULATE_EQUAL_WEIGHTAGE_ERROR,
        error,
        projectId
    }
}

export const recalculateInventoryStaffedCount = (campaignId: number) => async (dispatch) => {
    dispatch(start(campaignId));
    try {
        const response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/campaign/${campaignId}/InventoryStaffedCountRecalculate`);
        dispatch(success(campaignId, response.data.jobId));
    } catch (e) {
        dispatch(error(campaignId, e.response.data.Message || e.message));
    }
}

export const recalculateEqualWeightageCount = (campaignId: number, request: EqualWeightageRequestParams) => async (dispatch) => {
    dispatch(start(campaignId));
    try {
        const response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/campaign/${campaignId}/EqualWeightStaffedCountRecalculate`, request);        
        dispatch(success(campaignId, response.data.jobId));
    } catch (e) {
        dispatch(error(campaignId, e.response.data.Message || e.message));
    }
}

export const recalculateProjectEqualWeightageCount = (projectId: number, request: EqualWeightageRequestParams) => async (dispatch) => {
    dispatch(equalWeightageStart(projectId));
    try {
        const response = await Axios.post(`${AppSettings.ApiBaseUrl}/api/project/${projectId}/EqualWeightStaffedCountRecalculate`, request);        
        dispatch(equalWeightageSuccess(projectId, response.data.jobId));
    } catch (e) {
        dispatch(equalWeightageError(projectId, e.response.data.Message || e.message));
    }
}


export const cleanUpStaffedCount = () => (dispatch) => {
    dispatch(cleanUpCount());
}

export const cleanUpEqualWeightageCount = () => (dispatch) => {
    dispatch(cleanUpEqualCount());
}