import { ValueGetterParams } from "@ag-grid-community/core";
import React, { useState } from "react";

export default function NumberInputRenderer(params: ValueGetterParams) {

	let colId = params.column.getColId();
	const [value, setValue] = useState(params.data[colId]);

	const handleDataChange = (e) => {
		if (params.node.groupData) return (<div></div>);
		let checked = e.target.value;
		setValue(checked);
		params.node.setDataValue(colId, checked);
	}


	return (
		<input
			type="number"
			onChange={handleDataChange}
			value={value}
		/>
	);

}
