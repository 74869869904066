export const getLiquidString = (e) => {
    const value = e;
    let word = '';
    var start_pos = value.indexOf('{') + 2;
    var end_pos = value.indexOf('}', start_pos);
    var text_to_get = value.substring(start_pos, end_pos);
    return text_to_get;

    // let startIndex = 2;
    // //selectionStart;
    // while (value[startIndex - 1] !== ' ' && startIndex > 0) {
    //     word = value[startIndex - 1] + word;
    //     startIndex--;
    // }
    // let startWord = startIndex;
    // //startIndex = selectionStart;
    // while (value[startIndex] !== ' ' && startIndex < value.length) {
    //     word += value[startIndex];
    //     startIndex++;
    // }
    // console.log(word);
    // return {
    //     word,
    //     index: startWord
    // };
};

export const checkIfLiquidString = (string) => {
    return string.startsWith('{{') && string.endsWith('}}');
}

export const getValues = (values, attributeString) => {
    if (undefined === values) return;
    if (undefined === attributeString || attributeString === "") return;
    const atts = attributeString.split('.');
    if (attributeString.split('.')?.length === 1) return values[attributeString];
    else return getValues(values[atts[0]], attributeString.replace(atts[0] + '.', ''))
}

const setValues = (obj, att, val) => {
    if (undefined === obj) return;
    const atts = att.split('.');
    if (atts?.length === 1) {
        obj[att] = val
    }
    else return setValues(obj[atts[0]], att.replace(atts[0] + '.', ''), val)
}

export const checkIfStringHasLiquid = (string) => {
    const matches = string.match(/({{[\s\S]*?}})/g);
    if (matches && matches.length > 0) 
        return true;
    else
        return false;
}

export const findAndReplaceRuleString = (rulesString, attributesArray) => {
    const matches = rulesString.match(/({{[\s\S]*?}})/g);
    var newString = rulesString;
    for (const match of matches) {
        const property = getLiquidString(match);
        newString = newString.replace(match, getValues(attributesArray, property));
    }
    console.log("new string", newString);
    return newString
}




