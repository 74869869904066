//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_MY_BASE_SCHEDULE";
export const LOAD_MY_BASE_SCHEDULE_START = `${actionNamePrefix}_START`;
export const LOAD_MY_BASE_SCHEDULE_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_MY_BASE_SCHEDULE_ERROR = `${actionNamePrefix}_ERROR`;

export interface LoadMyBaseScheduleStartedAction {
    type: typeof LOAD_MY_BASE_SCHEDULE_START;
}

export interface LoadMyBaseScheduleSuccessAction {
    type: typeof LOAD_MY_BASE_SCHEDULE_SUCCESS;
    data: any
}

export interface LoadMyBaseScheduleErrorAction {
    type: typeof LOAD_MY_BASE_SCHEDULE_ERROR;
    error: string;
}

export type myMyBaseScheduleActions = LoadMyBaseScheduleStartedAction |
                                LoadMyBaseScheduleSuccessAction|
                                LoadMyBaseScheduleErrorAction;

export const loadMyBaseSchedule = () => async (dispatch) => {
    dispatch({type: LOAD_MY_BASE_SCHEDULE_START})
    try {
        const response = await Axios.get(`${AppSettings.ApiSchedulerUrl}/api/BaseScheduleCampaign`);
        dispatch({
            type: LOAD_MY_BASE_SCHEDULE_SUCCESS,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_MY_BASE_SCHEDULE_ERROR,
                error: errorMessage
            })
        }
    }
}

