//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_SHIFT";
export const LOAD_SHIFT_START = `${actionNamePrefix}_START`;
export const LOAD_SHIFT_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_SHIFT_ERROR = `${actionNamePrefix}_ERROR`;

export interface LoadShiftStartedAction {
    type: typeof LOAD_SHIFT_START;
}

export interface LoadShiftSuccessAction {
    type: typeof LOAD_SHIFT_SUCCESS;
    data: any
}

export interface LoadShiftErrorAction {
    type: typeof LOAD_SHIFT_ERROR;
    error: string;
}

export type shiftActions = LoadShiftStartedAction |
                                LoadShiftSuccessAction|
                                LoadShiftErrorAction;

export const loadShift = (resourceId: number, dateInBtz: string) => async (dispatch) => {
    dispatch({type: LOAD_SHIFT_START})
    try {
        //GetShift?leaveId=${leaveId}
        const response = await Axios.get(`${AppSettings.ApiSchedulerUrl}/resources/${resourceId}/date/${dateInBtz}/Shifts`);
        dispatch({
            type: LOAD_SHIFT_SUCCESS,
            data: response.data
         })
    }
    catch (err) {
        if (err && err.response) {
            const errorMessage = `Error: ${err.response.Message}`;
            dispatch({
                type: LOAD_SHIFT_ERROR,
                error: errorMessage
            })
        }
    }
}

