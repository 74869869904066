import React, { useEffect, useState, useRef } from 'react';
import { Theme, FormControl, NativeSelect, MenuItem, Select, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ReactComponent as WhatsAppSm } from './Icons/watsAppSmall.svg';
import { ReactComponent as FacebookSm } from './Icons/fbSmall.svg';
import { ReactComponent as MobileSm } from './Icons/mobileSmall.svg';
import { ReactComponent as ChatSmall } from './Icons/chatSmall.svg';
import { ReactComponent as IndicatorMute } from './Icons/IndicatorMute.svg';
import { ReactComponent as IndicatorMuteActive } from './Icons/MikeSmall.svg';
import { ReactComponent as Speaker } from './Icons/speaker.svg';
import { ReactComponent as NovelLogo } from './Icons/NovelLogo.svg';
import { ReactComponent as Menu } from './Icons/menu.svg';
import clsx from 'clsx';
import useLoudness from '../hooks/useLoudness';
import useMuteWarning from '../hooks/useMuteWarning';
import Timer from 'react-compound-timer/build';
import Axios from 'axios';
import { AppSettings } from '../appSettings';
import moment from 'moment';
import { DateHelper } from 'bryntum-scheduler';
import { getDuration } from '../vendor/staff/manageAgentSchedule/util';
import { RadioButtonCheckedSharp } from '@mui/icons-material';

interface HeaderSummaryProps {
    callState: string,
    userReasons: Array<any>,
    handleStateChange: (scheduleType) => void;
    handleProdcutiveOnLoad: (scheduleType) => void;
    handleSignout:() => void;
    handleShowSettingsMenu: () => void;
    selectedSchedule: number,
    skills: Array<any>,
    attributes: any,
    skillType: Array<any>,
    online: boolean,
    stateChangeSuccess: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerSummary: {
            backgroundColor: '#252935',
            border: '1px solid #373F51',
        },
        taskBars: {
            borderBottom: '1px solid #373F51',
            display: 'flex',
            justifyContent: 'space-around'
        },
        taskHeader: {
            textTransform: 'uppercase',
            padding: 5,
            backgroundColor: '#252935',
            border: '1px solid #373F51',
            color: '#FFFFFF',
            height: '30px',
            fontWeight: 'bold'
        },
        iconBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '50%',
            padding: '0 10px 0 10px'
        },
        textStyle: {
            color: '#ffffff',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            alignItems: 'center'
        },
        scrollText: {
            /* animation properties */
            // -mozTransform: 'translateX(100%)',
            // -webkitTransform: 'translateX(100%)',
            transform: 'translateX(50%)',
            display:'flex',

            // -moz-animation: my-animation 15s linear infinite;
            // -webkit-animation: my-animation 15s linear infinite;
            animation: `$myAnimation 15s linear infinite`
        },
        "@keyframes myAnimation": {
            "from": {
                transform: "translateX(100%)"
            },
            "to": {
                transform: "translateX(-100%)"
            }
        },
        scrollTextStyle: {
            borderRight: '1px solid grey',
            paddingLeft: '2px',
            minWidth:'200px'
        },
        icon: {
            fill: '#ffffff',
        }

    })
);

export default function HeaderSummary(props: HeaderSummaryProps) {
    const classes = useStyles();
    const [sourceItem, setSourceItem] = useState(props.selectedSchedule);
    const [prevSourceItem, setPrevSourceItem] = useState(null);
    const [prevCallState, setPrevCallState] = useState(null);
    const [resetStateChange, setRestStateChange] = useState(false);
    const headerTiRef = useRef(null);
    const [muted, setMuted] = useState(false);
    const [running, setRunning, loudness] = useLoudness();
    const [showMuteWarning] = useMuteWarning(loudness, running);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        if (!props.stateChangeSuccess && resetStateChange) {
            setSourceItem(prevSourceItem);
            setRestStateChange(false)
        }        
    },[props.stateChangeSuccess, resetStateChange])
    
    useEffect(() => {
        //Get agent phone state agent-state // && prevCallState === props.callState
        (async function () {
            try {
                const response = await Axios.get(`${AppSettings.ApiTwilioUrl}/api/AgentPhoneStates/agent-state`);
                const currentTime = moment(Date.now()).utc().format();
            if ( response.data) {
                let timerValue = 0;       
                const stateStartTime = response.data.timeInUtcInState;        
                if (stateStartTime && !DateHelper.isBefore(currentTime,stateStartTime) && props.callState === response.data.state) {            
                    const duration = getDuration(stateStartTime,currentTime);
                    timerValue = duration.asMilliseconds();
                    timerValue = timerValue < 0 ? 0 : timerValue;                    
                }
                setTimer(timerValue);
                setPrevCallState(props.callState);
                if (timerValue === 0 ) {
                    headerTiRef.current && headerTiRef.current.reset();
                    headerTiRef.current && headerTiRef.current.start();
                }
            }
            } catch (e) {
                const errorMsg = e;
                console.log(e);
            }
        })();
    },[props.callState])

    useEffect(() => {
        if (props.selectedSchedule > 0) {
            setSourceItem(props.selectedSchedule);
        } else {
            setSourceItem(0);
        }

    }, [props.selectedSchedule])

    const handleMute = () => {
        // connection.mute(!muted);
        setMuted(!muted);
        setRunning(!muted);
    };

    const handleDataChange = (e) => {
        headerTiRef.current && headerTiRef.current.stop();
        //prev value store and keep 
        setPrevSourceItem(sourceItem);
        setRestStateChange(true);
        //error then change it back to prev. Else new value.
        setSourceItem(e.target.value);
        setTimer(0);
        const selectedState = props.userReasons.find(x => x.id == e.target.value);
        props.handleStateChange(selectedState);
        headerTiRef.current && headerTiRef.current.reset();
        headerTiRef.current && headerTiRef.current.start();
    }

    const muteWarning = (
        <p className="warning">Are you speaking? You are on mute here trial!</p>
    );

    const renderIconsBar = () => {
        const isWatsApp = props.skillType && props.skillType.includes("WhatsApp");
        const isFacebook = props.skillType && props.skillType.includes("Facebook");
        const isPhone = props.skillType &&  props.skillType.includes("PHONE");
        const isChat = props.skillType &&  props.skillType.includes("Chat");
        return (
            <div className={classes.taskBars}>
                <div className={classes.iconBar} style={{ borderRight: '1px solid grey' }}>
                    {isWatsApp && <WhatsAppSm />}
                    {isFacebook && <FacebookSm /> }
                    {isPhone && <MobileSm />}
                    {isChat && <ChatSmall />}
                </div>
                <div className={classes.iconBar}>
                    {/* {!muted ? <IndicatorMute height={17} onClick={handleMute} /> : <IndicatorMuteActive onClick={handleMute} />} */}

                    {/* <Speaker style={{ height: '17px' }} /> */}
                    <Tooltip title={props.online ? "Phone online" : "Phone offline"}>
                        <RadioButtonCheckedSharp style={props.online ? {color:'green'} : {color: 'red'}} />
                    </Tooltip>                    
                    {/* <NovelLogo width={17} height={17} /> */}
                    <Menu width={18} height={13} onClick={props.handleShowSettingsMenu}/>                    
                </div>
            </div>
        )
    }

    const renderTimer = () => {
        return (
            <Timer initialTime={timer}
                ref={headerTiRef}
                lastUnit='h'
                formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
            >
                {({ start, resume, pause, stop, reset, timerState }) => (
                    <div><p style={{ fontSize: '10px', padding: 0, margin: 0 }}><Timer.Hours />:<Timer.Minutes />:<Timer.Seconds /></p></div>
                )}
            </Timer>
        )
    }

    const renderStatusStatesBar = () => {
        return (
            <div className={classes.taskBars}>
                <span className={clsx(classes.iconBar, classes.textStyle)} key={timer}>{props.callState} {renderTimer()}</span>
                <div className={clsx(classes.iconBar, classes.textStyle)}>
                    <FormControl>
                        <NativeSelect
                            value={sourceItem}
                            onChange={handleDataChange}
                            name="sourceItem"
                            style={{ color: '#ffffff', fontWeight: 'bold',  }}
                            classes={{
                                icon: classes.icon,
                            }}
                        >
                            {sourceItem === 0 && <option value={0} style={{ color: '#fff',backgroundColor:'#1D1E21' }}>None</option>}
                            {props.userReasons != null && props.userReasons
                                .map((eachItem, index) =>
                                    <option style={{ color: '#fff',backgroundColor:'#1D1E21' }} key={eachItem.name + "-" + index} value={eachItem.id}>{eachItem.name}</option>
                                )}
                        </NativeSelect>
                    </FormControl>
                </div>
            </div>
        )
    }

    const renderSkillList = () => {
        return (
            <div className={clsx(classes.taskBars, classes.textStyle)}>
                <div style={{ width: '400px', overflow: 'hidden' }}>
                    <div className={classes.scrollText}>
                        {
                            props.skills && props.skills.map((eachSkill) => {
                                return (<span key={eachSkill} className={classes.scrollTextStyle}>{eachSkill} &nbsp;</span>)
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.headerSummary}>
            {renderIconsBar()}
            {renderStatusStatesBar()}
            {renderSkillList()}            
        </div>
    );
}