import moment from "moment";
import { GetAttributesResponse } from "../../../../store/actions/attributes.action";
import { RolesResponse } from "../../../../store/actions/roles.action";
import {getMapOfRoleIdAndName} from "./convertToSaveAttributes";

export interface AttributeRowDef {
    systemUserId: string;
    systemUserName: string;
    systemEmailId: string;
    role: string;
    supervisorUserName: string;
    managerUserName: string;
}

export function convertToMapAttributes(
    roles: RolesResponse[],
    attributes: GetAttributesResponse[], customAttributes, customAttributesValues): AttributeRowDef[] {

    const mapOfRoleIdAndName = getMapOfRoleIdAndNameFromID(roles);
    const allRowDefs: Array<AttributeRowDef> = new Array<AttributeRowDef>();

    attributes.forEach((eachAssignment) => {
        let rowDef: AttributeRowDef = {
            systemUserId: eachAssignment.systemUser.id,
            systemUserName: eachAssignment.systemUser.userFullName,
            systemEmailId: eachAssignment.systemUser.email,
            role: eachAssignment.roleId === 0 ? '' : mapOfRoleIdAndName.get(eachAssignment.roleId),
            supervisorUserName : eachAssignment.supervisorUserId === null ? '' : getUserName(eachAssignment.supervisorUserId, attributes),
            managerUserName: eachAssignment.managerUserId === null ? getManagerOfSupervisor(roles,eachAssignment.supervisorUserId, attributes) : getUserName(eachAssignment.managerUserId, attributes),            
        }
        var flag = false;
        if (customAttributesValues && customAttributesValues.length > 0) {
            customAttributesValues.forEach(eachCustom => {
                if (eachAssignment.systemUser.id === eachCustom.userId) {
                    const newValues =[];
                    const attrs = eachCustom.attributes;
                    customAttributes.forEach(each => { 
                        if (!(each.id in attrs)) {
                            newValues.push(each);
                        }
                    });                              
                    flag = true;
                    rowDef = {
                        ...rowDef,
                        ...eachCustom.attributes
                    }
                    if (newValues && newValues.length > 0) {
                        newValues.forEach(element => {
                            rowDef = {
                                ...rowDef,
                                ...(element.attributeTypeName === 'Number' && { [element.id]: element.numberDefaultValue }),
                                ...(element.attributeTypeName === 'String' && { [element.id]: element.stringDefaultValue }),
                                ...(element.attributeTypeName === 'Boolean' && { [element.id]: element.booleanDefaultValue }),
                                ...(element.attributeTypeName === 'Date' && { [element.id]: moment(element.dateDefaultValue).format("yyyy-MM-DD") }),
                                ...(element.attributeTypeName === 'DateTime' && { [element.id]: element.dateTimeDefaultValue }),                        
                            }    
                        });
                    }
                } 
                
                if (!flag) {
                    customAttributes.forEach(element => {
                        rowDef = {
                            ...rowDef,
                            ...(element.attributeTypeName === 'Number' && { [element.id]: element.numberDefaultValue }),
                            ...(element.attributeTypeName === 'String' && { [element.id]: element.stringDefaultValue }),
                            ...(element.attributeTypeName === 'Boolean' && { [element.id]: element.booleanDefaultValue }),
                            ...(element.attributeTypeName === 'Date' && { [element.id]: moment(element.dateDefaultValue).format("yyyy-MM-DD") }),
                            ...(element.attributeTypeName === 'DateTime' && { [element.id]: element.dateTimeDefaultValue }),                        
                        }   
                    });
                }
            });        
        } else {
            customAttributes.forEach(element => {
                rowDef = {
                    ...rowDef,
                    ...(element.attributeTypeName === 'Number' && { [element.id]: element.numberDefaultValue }),
                    ...(element.attributeTypeName === 'String' && { [element.id]: element.stringDefaultValue }),
                    ...(element.attributeTypeName === 'Boolean' && { [element.id]: element.booleanDefaultValue }),
                    ...(element.attributeTypeName === 'Date' && { [element.id]: moment(element.dateDefaultValue).format("yyyy-MM-DD") }),
                    ...(element.attributeTypeName === 'DateTime' && { [element.id]: element.dateTimeDefaultValue }),                        
                }   
            });
       }
        
        allRowDefs.push(rowDef);
    })
    return allRowDefs;
}

export function getUserName(id: string, attributes) {    
    const matchingUser = attributes.find(at => at.systemUser.id === id);    
    return matchingUser.systemUser.userFullName;
}

function getMapOfRoleIdAndNameFromID(roles: RolesResponse[]): Map<number, string> {
    const mapOfRoleIdAndName = new Map<number, string>();

    roles.forEach(eachRole => {
        mapOfRoleIdAndName.set(eachRole.id, eachRole.name);
    });

    return mapOfRoleIdAndName;
}

export function getManagerOfSupervisor(roles: RolesResponse[],supervisorId: string, attributes) {
    
    if (supervisorId != null) {
        const mapOfRoleIdAndName = getMapOfRoleIdAndName(roles);
        const supervisorRoleId = mapOfRoleIdAndName.get("Supervisor");
        const matchingSupervisorUser = attributes.find(at => at.systemUser.id === supervisorId && at.roleId === supervisorRoleId);        
        if (matchingSupervisorUser && matchingSupervisorUser.managerUserId) {
            return getUserName(matchingSupervisorUser.managerUserId, attributes);
        } else {
            return null;
        }
        
    }

    return null
    
}