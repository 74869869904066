//@ts-nocheck
import createReducer from "./createReducer";
import {
    TIMER,
    TIMER_GET,
    TIMER_START,
    TIMER_STOP,
    TIMER_ERROR,
    StateTimerActions,
    StateTimerStartAction,
    StateTimerStopAction,
    StateTimerErrorAction
} from '../actions/stateTimer.action'

export interface StateTimerState {
    state: string;
    error: string;
    data: [];
}

const initialState: StateTimerState = {
    state: null,
    error: null,
    data: null
}

function stateTimer(state:StateTimerState, action: StateTimerAction): StateTimerState {
    return {
        ...state,
        state: action.type
    }
}

function stateTimerGet(state:StateTimerState, action: StateTimerGetAction): StateTimerState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function stateTimerStart(state:StateTimerState, action: StateTimerStartAction): StateTimerState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: [
            ...data,
            action.data
        ]
    }
}

function stateTimerStop(state:StateTimerState, action: StateTimerStopAction): StateTimerState {
    return {
        ...state,
        state: action.type,
        error: null,
        data: action.data
    }
}

function stateTimerError(state:StateTimerState, action: StateTimerErrorAction): StateTimerState {
    return {
        ...state,
        state: action.type,
        error: action.error,
        data: action.data
    }
}

const StateTimerReducer =
    (state: StateTimerState, action: StateTimerActions): StateTimerState => {

        const fnUpdateState = createReducer(initialState, {
            [TIMER]: stateTimer,
            [TIMER_GET]: stateTimerGet,
            [TIMER_START]: stateTimerStart,
            [TIMER_STOP]: stateTimerStop,
            [TIMER_ERROR]: stateTimerError
        });

        return fnUpdateState(state, action);
    }

export default StateTimerReducer;