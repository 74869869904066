import { Device } from "@twilio/voice-sdk";
import { DEVICE_CALL_CONNECTED, DEVICE_CALL_DISCONNECTED, DEVICE_CONNECT, DEVICE_DISCONNECT, DEVICE_ERROR, DEVICE_INCOMING, DEVICE_INIT_ERROR, DEVICE_INIT_START, DEVICE_INIT_SUCCESS, DEVICE_REJECT, RESERVATION_WRAPUP, WORKER_ACTIVITY_MISMATCH } from "../../actions/softPhone/actionTypes";

import { DeviceEndAction, DeviceErrorAction, DeviceInitActions, DeviceStartedAction } from "../../actions/softPhone/initPhoneDevice";

import createReducer from "../createReducer";

export interface DeviceState {
    state: string;
    error: string;
    device: Device,
    connection: any
}

const initialState: DeviceState = {
    state: null,
    error: null,
    device: null,
    connection: null
}

function deviceInitStart(state:DeviceState, action: DeviceStartedAction): DeviceState {    
    return {
        ...state,
        state: action.type
    }
}

function deviceInitSuccess(state:DeviceState, action: DeviceEndAction): DeviceState {
    return {
        ...state,
        state: action.type,
        device: action.data,
        error: null
    }
}

function deviceInitError(state:DeviceState, action: DeviceErrorAction): DeviceState {    
    return {
        ...state,
        state: action.type,
        error: action.data
    }
}

function deviceError(state:DeviceState, action): DeviceState {    
    return {
        ...state,
        state: action.type,
        error: action.data,
        //connection: null
    }
}
function deviceIncomingCallActions(state:DeviceState, action): DeviceState {   
    return {
        ...state,
        state: action.type,
        connection: action.data,
        error: null
    }
}

function deviceDisconnectedCall(state:DeviceState, action): DeviceState {
    return {
        ...state,
        state: action.type,
        //connection: null
    }
}

function deviceReject(state:DeviceState, action): DeviceState {
    return {
        ...state,
        state: action.type,
        connection: null
    }
}

const DeviceReducer =
    (state: DeviceState, action: DeviceInitActions): DeviceState => {

        const fnUpdateState = createReducer(initialState, {
            [DEVICE_INIT_START]: deviceInitStart,
            [DEVICE_INIT_SUCCESS]: deviceInitSuccess,
            [DEVICE_INIT_ERROR] : deviceInitError,
            [DEVICE_ERROR]: deviceError,            
            [DEVICE_INCOMING]: deviceIncomingCallActions,
            [DEVICE_CALL_CONNECTED]: deviceIncomingCallActions,
            [DEVICE_CALL_DISCONNECTED]: deviceIncomingCallActions,
            [DEVICE_REJECT]: deviceReject,
            [DEVICE_CONNECT]:deviceIncomingCallActions,
            [DEVICE_DISCONNECT]: deviceReject,
            [RESERVATION_WRAPUP]:deviceDisconnectedCall,
            [WORKER_ACTIVITY_MISMATCH]:deviceDisconnectedCall
            
        });

        return fnUpdateState(state, action);
    }

export default DeviceReducer;