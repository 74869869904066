import React, { useState } from 'react';

export default function FileUpload( props ) {
    
    const fileMaxSize = 1000000000 // bytes
    const acceptedFileTypes = 'application/pdf, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet, application/x-msexcel, application/msword';
    const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });
    const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);

    const initialState = {
        open: null,
        selectedFile: null,
    };

    let data = {};

    const [state, updateState] = useState(initialState);
    const setState = (data) => updateState(prevState => ({ ...prevState, ...data }));

    

    const verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0]
            console.log(currentFile);
            const currentFileType = currentFile.type;
            const currentFileSize = currentFile.size;
            if (currentFileSize > fileMaxSize) {
                alert("This file is not allowed. " + currentFileSize + " bytes is too large");
                return false;                
            }
            
            if (!acceptedFileTypesArray.includes(currentFileType)) {
                alert("This file is not allowed. Only files with pdf/doc/xls/xlsx extensions are allowed.");
                return false;
            }
            return true;
        } 
    }

    const changeInputvalue = event => {
        event.target.value = '';
        setState(initialState);
    }

    const fileChangeHandler = event => {
        const files = event.target.files; 
        if (files && files.length > 0) {
            const isVerified = verifyFile(files);
            if (isVerified) {
                // imageBase64Data 
                const currentFile = files[0];
                setSelectedFile(currentFile);
                setIsFilePicked(true);
            }
        } 
    } 
    
    const handleSubmission = () => {
		const formData = new FormData();

		formData.append('File', selectedFile);

		
	};

    return (
        <>
        <label htmlFor="file-upload" className="upload-photo">Upload File</label> 
        <input id="file-upload" type='file' multiple={false} onChange={fileChangeHandler} onClick={changeInputvalue} />
        
        {isFilePicked ? (
				<div>
                    
					<p>Filename: {//@ts-ignore
                      selectedFile.name
                    }</p>
					<p>Filetype: {//@ts-ignore
                    selectedFile.type
                    }</p>
					<p>Size in bytes: {//@ts-ignore
                    selectedFile.size
                    }</p>
					<p>
						lastModifiedDate:{' '}
						{ //@ts-ignore
                        selectedFile.lastModifiedDate.toLocaleDateString()}
					</p>
				</div>
			) : (
				<p>Select a file to show details</p>
			)}
            <button onClick={handleSubmission}>Submit</button>            
        </>
    );
}

