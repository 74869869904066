import moment from "moment-timezone";
import dayjs from "dayjs";

export function convertToDisplayFormat(dateString) {
    return dayjs(dateString).format("MM-DD-YYYY");
}

export function convertLocalDateToUtc(date: Date, hours: number, minutes: number, seconds: number, milliseconds: number) : moment.Moment {
    // Moment in EST Time zone. This is set in App.js when application starts.
    const momentForDate = moment([date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds, milliseconds]);
    return momentForDate.utc();
}

export function convertToStartOfDay(date: Date, timezone: string) : moment.Moment {
    return moment.tz(date, timezone).startOf("day");
}

export function convertToEndOfDay(date: Date, timezone: string) : moment.Moment {
    return moment.tz(date, timezone).endOf("day");
}


export function gridDateTimeFormatter(dataValue) {
    if (dataValue) {        
        var dateParts = dataValue.split('T');
        return `${dateParts[0]} ${dateParts[1]}`;
    }
}

export function gridDateTimeValueFormatter(dataValue) {
    if (dataValue) {        
        var dateParts = dataValue.split('T');
        var date = dateParts[0]; 
        return `${dateParts[0]}`;
    }
}


export function gridDateComparator(date1, date2) {
    var date1Number = _monthToNum(date1);
    var date2Number = _monthToNum(date2);
    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }

    return date1Number - date2Number;
}

// HELPER FOR DATE COMPARISON
function _monthToNum(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    //Format here is MM-DD-YYYY
    var yearNumber = date.substring(6, 10);
    var dayNumber = date.substring(3, 5);
    var monthNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

export function gridDateTimeComparator(date1, date2) {
    var d1 =  +new Date(date1);
    var d2 =  +new Date(date2);
    
    return d1 - d2;
}