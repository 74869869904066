//@ts-nocheck
import createReducer from "./createReducer";
import {
    UpdateProfileAction,
    UpdateProfileErrorAction,
    UpdateProfileSuccessAction,
    UPDATE_MY_PROFILE_START,
    UPDATE_MY_PROFILE_SUCCESS,UPDATE_MY_PROFILE_ERROR, IUserUpdate
} from "../actions/myProfile.action";

export interface MyProfileState {
    action: string;
    data: IUserUpdate;
    error: string;
}

const initialState: MyProfileState = {
    action: null,
    data: null,
    error: null
}


function saveProfileStart(state: MyProfileState, action: MyProfileActions): MyProfileState {
    return {
        ...state,
        action: action.type,
        error: null,
        data: null
    }
}

function saveProfileSuccess(state: ProjectCampaignState, action: MyProfileActions): ProjectCampaignState {    
    return {
        ...state,
        action: action.type,
        error: null,
        data: action.profile
    }
}

function saveProfileError(state: ProjectCampaignState, action: SaveProjectError): ProjectCampaignState {
    return {
        ...state,
        action: action.type,
        error: action.error
    }
}

const MyProfileReducer =
    (state: MyProfileState, action: MyProfileActions): MyProfileState => {

        const fnUpdateState = createReducer(initialState, {
            [UPDATE_MY_PROFILE_START]: saveProfileStart,
            [UPDATE_MY_PROFILE_SUCCESS]: saveProfileSuccess,
            [UPDATE_MY_PROFILE_ERROR]: saveProfileSuccess            
        });

        return fnUpdateState(state, action);
    }

export default MyProfileReducer;

