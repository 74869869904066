//@ts-nocheck
import { AppSettings } from "../../appSettings";
import Axios from "axios";

const actionNamePrefix = "LOAD_SKILL_MATRIX_DATA";
export const LOAD_SKILL_MATRIX_DATA_START = `${actionNamePrefix}_START`;
export const LOAD_SKILL_MATRIX_DATA_SUCCESS = `${actionNamePrefix}_SUCCESS`;
export const LOAD_SKILL_MATRIX_DATA_ERROR = `${actionNamePrefix}_ERROR`;

const actionPrefixNameSave = "SAVE_SKILL_MATRIX_DATA";
export const SAVE_SKILL_MATRIX_DATA_START = `${actionPrefixNameSave}_START`;
export const SAVE_SKILL_MATRIX_DATA_SUCCESS = `${actionPrefixNameSave}_SUCCESS`;
export const SAVE_SKILL_MATRIX_DATA_ERROR = `${actionPrefixNameSave}_ERROR`;

export interface IUpdateAgentSkillMatrix {
    projectId: number,
    agentUserId: string,
    hasSkill: boolean,
    campaignSkills: any[]
}

function loadStart() {
    return {
        type: LOAD_SKILL_MATRIX_DATA_START
    };
}

function loadSuccess(data) {
    return {
        type: LOAD_SKILL_MATRIX_DATA_SUCCESS,
        data: data
    };
}

function loadError(error) {
    return {
        type: LOAD_SKILL_MATRIX_DATA_ERROR,
        error
    }
}

export const loadMatrixData = () => async (dispatch) => {
    dispatch(loadStart())
    try {
        const response = await Axios.get(`${AppSettings.ApiBaseUrl}/ProjectMatrix`);        
        dispatch(loadSuccess(response.data[0]))
    }
    catch (e) {
        dispatch(loadError(e.response.data.Message || e.message));
    }
}

export const cleanData = () => async (dispatch) => {
    dispatch(loadStart());
}

function saveStart() {
    return {
        type: SAVE_SKILL_MATRIX_DATA_START
    };
}

function saveSuccess() {
    return {
        type: SAVE_SKILL_MATRIX_DATA_SUCCESS
    };
}

function saveError(error) {
    return {
        type: SAVE_SKILL_MATRIX_DATA_ERROR,
        error
    }
}

export const updateAgentMatrix = ( updatedValue: Array<IUpdateAgentSkillMatrix>) => async (dispatch) => {
    
    dispatch(saveStart())    

    try {
        await Axios.post(`${AppSettings.ApiBaseUrl}/ProjectMatrix`, updatedValue);
        dispatch(saveSuccess())
    } 
    catch (e) {
        dispatch(saveError(e.response.data.Message || e.message));
    }
}