import { useState, useEffect } from "react";
import Meyda from "meyda";

const useLoudness = () => {
	const [analyser, setAnalyser] = useState(null);
	const [running, setRunning] = useState(false);
	const [loudness, setLoudness] = useState(0);

	const getMedia = async () => {
		try {
			return await navigator.mediaDevices.getUserMedia({
				audio: true,
				video: false
			});


		} catch (err) {
			console.error("Error:", err);
		}
	};

	//Display prompt and log selected device or error
	const getSelectedDevice = async () => {
		const devices = await navigator.mediaDevices.enumerateDevices();
		const audioInputs = devices.filter((d) => d.kind === "audioinput");
		return audioInputs;
	}

	useEffect(() => {
		const audioContext = new AudioContext();
		const highPass = audioContext.createBiquadFilter();
		highPass.frequency.setValueAtTime(300, audioContext.currentTime);
		const lowPass = audioContext.createBiquadFilter();
		lowPass.frequency.setValueAtTime(3400, audioContext.currentTime);
		let selectedDevices; let emptyDevices;
		let newAnalyser;
		
		(async function () {
			selectedDevices = await getSelectedDevice();
			emptyDevices = selectedDevices.filter(x => x.deviceId === '');
			if (selectedDevices.length === emptyDevices.length) {
				alert('No input device permission. Enable');
				return;
			}
		})();
		getMedia().then(stream => {
			if (audioContext.state === "closed") {
				return;
			}
			const source = audioContext.createMediaStreamSource(stream);
			source.connect(highPass);
			highPass.connect(lowPass);

			newAnalyser = Meyda.createMeydaAnalyzer({
				audioContext: audioContext,
				source: lowPass,
				bufferSize: 8192,
				featureExtractors: ["loudness"],
				callback: features => {
					//console.log(features);
					setLoudness(
						loudness => (loudness + features.loudness.total * 0.2) / 2
					);
				}
			});
			setAnalyser(newAnalyser);
		}).catch(function (err) {
			alert("Permission denined."+ err.message);
			console.log(err.name + ": " + err.message);
		});

		return () => {
			if (newAnalyser) {
				newAnalyser.stop();
			}
			if (audioContext) {
				audioContext.close();
			}
		};
	}, []);

	useEffect(() => {
		if (analyser) {
			if (running) {
				analyser.start();
			} else {
				analyser.stop();
			}
		}
	}, [running, analyser]);

	return [running, setRunning, loudness] as const;
};

export default useLoudness;
